import { createQueryString } from 'helpers/utility';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import openNotification from 'components/Notification';
import { ENDPOINTS } from 'consts';
import superFetch from 'helpers/superFetch';
import TabletActions from 'components/NewTable/redux/actions';
import { apiRequest } from 'redux/helpers';
import actions from 'redux/marketing/marketingEmailTemplates/listPage/actions';
import { setUrl } from 'helpers/urlSync';

export function* getTemplatesListRequest() {
  yield takeEvery(actions.GET_TEMPLATES_LIST_REQUEST, function*() {
    const { pagination, sortValues, searchValue } = yield select(state => state.tableReducer);
    const queryUrl = `${ENDPOINTS.EMAIL_TEMPLATE}?${createQueryString({
      pagination,
      multiSort: sortValues,
      fields: searchValue,
    })}`;
    const data = yield apiRequest(superFetch.get, queryUrl);

    setUrl(queryUrl);

    if (data.errors) {
      yield put(actions.getTemplatesListError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(
        actions.getTemplatesListSuccess({
          emailTemplates: data.emailTemplates
        }),
      );
      yield put(TabletActions.setPagination(data.pagination))
    }
  });
}

export function* deleteTemplateRequest() {
  yield takeEvery(actions.DELETE_REQUEST, function*({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.EMAIL_TEMPLATE}/${payload.id}`,
    );
    const emailTemplateState = yield select(state => state);
    if (data.errors) {
      yield put(actions.deleteTemplateError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: actions.GET_TEMPLATES_LIST_REQUEST,
        payload: emailTemplateState.emailTemplates,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getTemplatesListRequest),
    fork(deleteTemplateRequest),
  ]);
}
