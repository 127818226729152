const actions = {
  GET_ACTION_LOGS_REQUEST: 'GET_ACTION_LOGS_REQUEST',
  GET_ACTION_LOGS_SUCCESS: 'GET_ACTION_LOGS_SUCCESS',
  GET_ACTION_LOGS_ERROR: 'GET_ACTION_LOGS_ERROR',

  GET_LOG_DETAILS_REQUEST: 'GET_LOG_DETAILS_REQUEST',
  GET_LOG_DETAILS_SUCCESS: 'GET_LOG_DETAILS_SUCCESS',
  GET_LOG_DETAILS_ERROR: 'GET_LOG_DETAILS_ERROR',

  GET_LOG_ENTITY_TYPES_REQUEST: 'GET_LOG_ENTITY_TYPES_REQUEST',
  GET_LOG_ENTITY_TYPES_SUCCESS: 'GET_LOG_ENTITY_TYPES_SUCCESS',
  GET_LOG_ENTITY_TYPES_ERROR: 'GET_LOG_ENTITY_TYPES_ERROR',

  getActionLogsRequest: (opts) => ({ type: actions.GET_ACTION_LOGS_REQUEST, payload: opts }),
  getActionLogsSuccess: (data, pagination) => ({
    type: actions.GET_ACTION_LOGS_SUCCESS,
    payload: { data, pagination },
  }),
  getActionLogsError: (error) => ({ type: actions.GET_ACTION_LOGS_ERROR, payload: error }),

  getLogDetailsRequest: (logId) => ({ type: actions.GET_LOG_DETAILS_REQUEST, payload: logId }),
  getLogDetailsSuccess: (data) => ({ type: actions.GET_LOG_DETAILS_SUCCESS, payload: data }),
  getLogDetailsError: (error) => ({ type: actions.GET_LOG_DETAILS_ERROR, payload: error }),

  getLogEntityTypesRequest: () => ({ type: actions.GET_LOG_ENTITY_TYPES_REQUEST }),
  getLogEntityTypesSuccess: (data) => ({
    type: actions.GET_LOG_ENTITY_TYPES_SUCCESS,
    payload: data,
  }),
  getLogEntityTypesError: () => ({ type: actions.GET_LOG_ENTITY_TYPES_ERROR }),
};

export default actions;
