const manageRolesAndClaimsActions = {
    GET_ROLES_AND_CLAIMS_REQUEST: 'GET_ROLES_AND_CLAIMS_REQUEST',
    GET_ROLES_AND_CLAIMS_SUCCESS: 'GET_ROLES_AND_CLAIMS_SUCCESS',
    GET_ROLES_AND_CLAIMS_ERROR: 'GET_ROLES_AND_CLAIMS_ERROR',

    GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_ERROR: 'GET_ROLES_ERROR',

    ADD_CLAIM_TO_REQUEST: 'ADD_CLAIM_TO_REQUEST',
    ADD_CLAIM_TO_SUCCESS: 'ADD_CLAIM_TO_SUCCESS',
    ADD_CLAIM_TO_ERROR: 'ADD_CLAIM_TO_ERROR',

    REMOVE_CLAIM_FROM_ROLE_REQUEST: 'REMOVE_CLAIM_FROM_ROLE_REQUEST',
    REMOVE_CLAIM_FROM_ROLE_SUCCESS: 'REMOVE_CLAIM_FROM_ROLE_SUCCESS',
    REMOVE_CLAIM_FROM_ROLE_ERROR: 'REMOVE_CLAIM_FROM_ROLE_ERROR',

    CREATE_NEW_ROLE_REQUEST: 'CREATE_NEW_ROLE_REQUEST',
    CREATE_NEW_ROLE_SUCCESS: 'CREATE_NEW_ROLE_SUCCESS',
    CREATE_NEW_ROLE_ERROR: 'CREATE_NEW_ROLE_ERROR',

    RENAME_ROLE_REQUEST: 'RENAME_ROLE_REQUEST',
    RENAME_ROLE_SUCCESS: 'RENAME_ROLE_SUCCESS',
    RENAME_ROLE_ERROR: 'RENAME_ROLE_ERROR',

    DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
    DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
    DELETE_ROLE_ERROR: 'DELETE_ROLE_ERROR',

    TOGGLE_NEW_ROLE_MODAL: 'TOGGLE_NEW_ROLE_MODAL',

    CHANGE_LOCATION_BASED_REQUEST: 'CHANGE_LOCATION_BASED_REQUEST',
    CHANGE_LOCATION_BASED_SUCCESS: 'CHANGE_LOCATION_BASED_SUCCESS',
    CHANGE_LOCATION_BASED_ERROR: 'CHANGE_LOCATION_BASED_ERROR',

    getRolesAndClaimsRequest: () => ({
      type: manageRolesAndClaimsActions.GET_ROLES_AND_CLAIMS_REQUEST,
      }),

    getRolesAndClaimsSuccess: ({roles}) => ({
      type: manageRolesAndClaimsActions.GET_ROLES_AND_CLAIMS_SUCCESS,
      payload: roles
    }),

    getRolesAndClaimsError: () => ({
      type: manageRolesAndClaimsActions.GET_ROLES_AND_CLAIMS_ERROR,
    }),

      /* get roles */

    getRolesRequest: () => ({
      type: manageRolesAndClaimsActions.GET_ROLES_REQUEST
    }),

    getRolesSuccess: (roles) => ({
      type: manageRolesAndClaimsActions.GET_ROLES_SUCCESS,
      payload: roles
    }),

    getRoleError: () => ({
      type: manageRolesAndClaimsActions.GET_ROLES_ERROR
    }),

      /* remove claims from role */

    removeClaimsFromRoleRequest: ({roleId, claimId}) => ({
      type: manageRolesAndClaimsActions.REMOVE_CLAIM_FROM_ROLE_REQUEST,
      payload: {
       roleId,
       claimId
      }
    }),

    removeClaimsFromRoleSuccess: () => ({
      type: manageRolesAndClaimsActions.REMOVE_CLAIM_FROM_ROLE_SUCCESS,
    }),


    removeClaimsFromRoleError: () => ({
      type: manageRolesAndClaimsActions.REMOVE_CLAIM_FROM_ROLE_ERROR,
    }),

    /* add claims to role */

    addClaimToRoleRequest: ({roleId, claimId}) => ({
      type: manageRolesAndClaimsActions.ADD_CLAIM_TO_REQUEST,
      payload: {
        roleId,
        claimId
      }
    }),

    addClaimsToRoleSuccess: () => ({
      type: manageRolesAndClaimsActions.ADD_CLAIM_TO_SUCCESS
    }),

    addClaimsToRoleError: () => ({
      type: manageRolesAndClaimsActions.ADD_CLAIM_TO_ERROR
    }),

    /* ADD NEW ROLE */

    createNewRoleRequest: (name) => ({
      type: manageRolesAndClaimsActions.CREATE_NEW_ROLE_REQUEST,
      payload: {
        name
      }
    }),

    createNewRoleSuccess: () => ({
      type: manageRolesAndClaimsActions.CREATE_NEW_ROLE_SUCCESS
    }),

    createNewRoleError: () => ({
      type: manageRolesAndClaimsActions.CREATE_NEW_ROLE_SUCCESS
    }),

    /* DELETE ROLE */

    deleteRoleRequest: ({roleId}) => ({
      type: manageRolesAndClaimsActions.DELETE_ROLE_REQUEST,
      payload: {
        roleId
      }
    }),

    deleteRoleSuccess: () => ({
      type: manageRolesAndClaimsActions.DELETE_ROLE_SUCCESS
    }),

    deleteRoleError: () => ({
      type: manageRolesAndClaimsActions.DELETE_ROLE_ERROR
    }),

    /* RENAME ROLE */

    renameRoleRequest: ({roleId, name}) => ({
      type: manageRolesAndClaimsActions.RENAME_ROLE_REQUEST,
      payload: {
        roleId,
        name
      }
    }),

    renameRoleSuccess: () => ({
      type: manageRolesAndClaimsActions.RENAME_ROLE_SUCCESS
    }),

    renameRoleError: () => ({
      type: manageRolesAndClaimsActions.RENAME_ROLE_ERROR
    }),

    toggleNewRoleModal: () => ({
      type: manageRolesAndClaimsActions.TOGGLE_NEW_ROLE_MODAL,
    }),

    changeLocationBasedRequest: (isLocationBased, id) => ({
      type: manageRolesAndClaimsActions.CHANGE_LOCATION_BASED_REQUEST,
      payload: {
        isLocationBased,
        id
      }
    }),

    changeLocationBasedSuccess: () => ({
      type: manageRolesAndClaimsActions.CHANGE_LOCATION_BASED_SUCCESS
    }),

    changeLocationBasedError: () => ({
      type: manageRolesAndClaimsActions.CHANGE_LOCATION_BASED_ERROR
    }),

  };
  export default manageRolesAndClaimsActions;
