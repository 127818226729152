const DOCUMENT = 'MARKETING_EMAIL_TEMPLATE_';

const marketingEmailTemplateActions = {
  CLEAR: `${DOCUMENT}CLEAR`,

  GET_TEMPLATE_REQUEST: `GET_${DOCUMENT}REQUEST`,
  GET_TEMPLATE_SUCCESS: `GET_${DOCUMENT}SUCCESS`,
  GET_TEMPLATE_ERROR: `GET_${DOCUMENT}ERROR`,

  UPDATE_TEMPLATE_REQUEST: `UPDATE_${DOCUMENT}REQUEST`,
  UPDATE_TEMPLATE_SUCCESS: `UPDATE_${DOCUMENT}SUCCESS`,
  UPDATE_TEMPLATE_ERROR: `UPDATE_${DOCUMENT}ERROR`,

  UPDATE_EDIT_TEMPLATE_FIELD: `UPDATE_EDIT_TEMPLATE_FIELD_${DOCUMENT}ERROR`,

  clearTemplate: () => ({
    type: marketingEmailTemplateActions.CLEAR,
  }),

  getTemplateRequest: id => ({
    type: marketingEmailTemplateActions.GET_TEMPLATE_REQUEST,
    payload: { id },
  }),

  getTemplateSuccess: emailTemplate => ({
    type: marketingEmailTemplateActions.GET_TEMPLATE_SUCCESS,
    payload: { emailTemplate },
  }),

  getTemplateError: error => ({
    type: marketingEmailTemplateActions.GET_TEMPLATE_ERROR,
    payload: { error },
  }),

  updateTemplateRequest: (formData, formName) => ({
    type: marketingEmailTemplateActions.UPDATE_TEMPLATE_REQUEST,
    payload: { formData, formName },
  }),

  updateTemplateSuccess: (emailTemplate, formName) => ({
    type: marketingEmailTemplateActions.UPDATE_TEMPLATE_SUCCESS,
    payload: { emailTemplate, formName },
  }),

  updateTemplateError: error => ({
    type: marketingEmailTemplateActions.UPDATE_TEMPLATE_ERROR,
    payload: { error },
  }),

  updateEditTemplateField: (key, value) => {
    const payload = {};
    payload[key] = value;
    return {
      type: marketingEmailTemplateActions.UPDATE_EDIT_TEMPLATE_FIELD,
      payload,
    };
  },
};

export default marketingEmailTemplateActions;
