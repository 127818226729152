import { all, put, select, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import { ENDPOINTS } from 'consts';
import superFetch from 'helpers/superFetch';
import mailingActions from 'redux/marketing/mailing/actions';
import { apiRequest } from 'redux/helpers';
import openNotification from 'components/Notification';
import { MAILING_STATUSES } from 'consts/mailingStatuses';
import mailingRecipientsActions from 'redux/marketing/mailing/mailingRecipients/actions';

export function* getMailingState() {
  yield takeEvery(mailingActions.GET_MAILING_STATE, function* ({ payload }) {
    const { mailingId, boxName, selectAllVisible } = payload;
    const mailing =
      yield apiRequest(
        superFetch.get,
        `${ENDPOINTS.MAILINGS_AND}${mailingId}`
      );
    if (mailing.interval){
      mailing.interval = `${mailing.interval}`
    }

    const mailingSenders =
      yield apiRequest(
        superFetch.get,
        `${ENDPOINTS.MAILING_SENDERS_AND}${mailing.id}`
      );

    const senders =
      yield apiRequest(
        superFetch.get,
        `${ENDPOINTS.AUTH_CREDENTIALS}`
      );

    if (mailing.error) {
      yield put(mailingActions.getMailingStateError(mailing.errors));
      yield openNotification('error', 'Error', mailing.errors);
    } else {
      yield put(mailingActions.getMailingStateSuccess({
        ...mailing,
        mailingRecipients: Object.fromEntries(mailing.recipientIds.map(id => [id, true])),
        received: Object.fromEntries(mailing.received.map((record) =>
          [record.recipientId, record.sentAt]))
      }, mailingSenders, senders));
    }
    if(selectAllVisible) {
      yield put(mailingRecipientsActions.selectAllVisible(mailing.id))
    }
    if (boxName) {
      mailingActions.changeView(boxName, false)
    }
  })
}

function* updateMailing() {
  yield takeEvery(mailingActions.UPDATE_MAILING, function* ({ payload }) {
    const { currentMailing } = yield select((state) => state.Mailing);
    const newMailing = {
      ...currentMailing,
      ...payload.mailing,
    };
    newMailing.numberRecipients = newMailing.numberRecipients || 0;
    newMailing.progress = newMailing.progress || 0;
    const mailing = yield apiRequest(superFetch.put,
      `${ENDPOINTS.MAILINGS_AND}${currentMailing.id}`, newMailing);
    if (mailing.errors) {
      yield put(mailingActions.updateMailingError(mailing, 'settings' ));
      yield openNotification('error', 'Error', mailing.errors);
    } else {
      const shoudSelectAllVisible = currentMailing.groups.length === 0
        && mailing.groups.length !== 0;
      yield put(
        mailingActions.getMailingState( mailing.id, 'settings', shoudSelectAllVisible )
      );
    }
  });
}

function* addSender() {
  yield takeEvery(mailingActions.ADD_SENDER, function* ({ payload }) {
    const { mailingId, authCredentialId } = payload;
    const sender = {
      mailingId,
      authCredentialId
    };
    yield apiRequest(
      superFetch.post,
      `${ENDPOINTS.MAILING_SENDERS}`,
      sender
    );
    const mailingSenders =
      yield apiRequest(
        superFetch.get,
        `${ENDPOINTS.MAILING_SENDERS_AND}${mailingId}`
      );
    yield put(mailingActions.changeSenderSuccess(mailingSenders))
  });
}

function* removeSender() {
  yield takeEvery(mailingActions.REMOVE_SENDER, function* ({ payload }) {
    const { mailingId, authCredentialId } = payload;
    yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.MAILING_SENDERS_AND}${authCredentialId}`,
    );
    const mailingSenders =
      yield apiRequest(
        superFetch.get,
        `${ENDPOINTS.MAILING_SENDERS_AND}${mailingId}`
      );
    yield put(mailingActions.changeSenderSuccess(mailingSenders))
  });
}

function* changeStatusMailingRequest() {
  yield takeEvery(mailingActions.CHANGE_STATUS_REQUEST, function* ({ payload }) {
      const data = yield apiRequest(
        superFetch.put,
        `${ENDPOINTS.MAILINGS}/${payload.id}`,
        {...payload}
      );

      if (data.errors) {
        yield put(mailingActions.changeStatusMailingError());
        yield openNotification('error', 'Error', data.errors);
      } else {
        data.received = Object.fromEntries(data.received.map((record) =>
          [record.recipientId, record.sentAt]));
        yield put(
          mailingActions.changeStatusMailingSuccess({ ...data }),
        );
      }
  });
}

function* startNow() {
  yield takeEvery(mailingActions.START_NOW, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.MAILINGS}/${payload.id}`,
      { status: MAILING_STATUSES.PAUSE }
    );
    if (data.errors) {
      yield put(mailingActions.changeStatusMailingError());
      yield openNotification('error', 'Error', data.errors);
    }
    yield put(mailingActions.changeStatusMailingRequest(
      payload.id,
      MAILING_STATUSES.ACTIVE,
      moment().utc(),
      payload.sendToSender,
    ));
  });
}

export default function* rootSaga() {
  yield all([
    getMailingState(),
    updateMailing(),
    changeStatusMailingRequest(),
    startNow(),
    addSender(),
    removeSender(),
  ]);
}
