const DOCUMENT = 'USERS_';

const usersActions = {
  GET_USERS_REQUEST: `${DOCUMENT}GET_REQUEST`,
  GET_USERS_SUCCESS: `${DOCUMENT}GET_SUCCESS`,
  GET_USERS_ERROR: `${DOCUMENT}GET_ERROR`,

  SET_INITIAL_USERS_STATE: `${DOCUMENT}SET_INITIAL_USERS_STATE`,

  UPDATE_USER_REQUEST: `${DOCUMENT}UPDATE_REQUEST`,
  UPDATE_USER_SUCCESS: `${DOCUMENT}UPDATE_SUCCESS`,
  UPDATE_USER_ERROR: `${DOCUMENT}UPDATE_ERROR`,

  INVITE_USER_REQUEST: `${DOCUMENT}INVITE_REQUEST`,
  INVITE_USER_SUCCESS: `${DOCUMENT}INVITE_SUCCESS`,
  INVITE_USER_ERROR: `${DOCUMENT}INVITE_ERROR`,

  DELETE_USER_REQUEST: `${DOCUMENT}DELETE_REQUEST`,
  DELETE_USER_SUCCESS: `${DOCUMENT}DELETE_SUCCESS`,
  DELETE_USER_ERROR: `${DOCUMENT}DELETE_ERROR`,

  RESET_PASSWORD_REQUEST: `${DOCUMENT}RESET_PASSWORD_REQUEST`,
  RESET_PASSWORD_SUCCESS: `${DOCUMENT}RESET_PASSWORD_SUCCESS`,
  RESET_PASSWORD_ERROR: `${DOCUMENT}RESET_PASSWORD_ERROR`,

  TOGGLE_INVITE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,
  CHANGE_PENDING_USERS_PAGE: `${DOCUMENT}CHANGE_PENDING_PAGE`,
  CHANGE_PENDING_USERS_PAGE_SIZE: `${DOCUMENT}CHANGE_PENDING_PAGE_SIZE`,

  GET_PENDING_USERS_REQUEST: `${DOCUMENT}GET_PENDING_REQUEST`,
  GET_PENDING_USERS_SUCCESS: `${DOCUMENT}GET_PENDING_SUCCESS`,
  GET_PENDING_USERS_ERROR: `${DOCUMENT}GET_PENDING_ERROR`,

  DELETE_PENDING_USERS_REQUEST: `${DOCUMENT}DELETE_PENDING_REQUEST`,
  DELETE_PENDING_USERS_SUCCESS: `${DOCUMENT}DELETE_PENDING_SUCCESS`,
  DELETE_PENDING_USERS_ERROR: `${DOCUMENT}DELETE_PENDING_ERROR`,

  COMPLETE_INVITE_REQUEST: 'COMPLETE_INVITE_REQUEST',
  RESET_STATE: `${DOCUMENT}RESET_STATE`,

  getUsersRequest: (payload) => ({
    type: usersActions.GET_USERS_REQUEST,
    payload,
  }),

  getUsersSuccess: (payload) => ({
    type: usersActions.GET_USERS_SUCCESS,
    payload,
  }),

  getUsersError: () => ({
    type: usersActions.GET_USERS_ERROR,
  }),

  setInitialUsersState: (payload) => ({
    type: usersActions.SET_INITIAL_USERS_STATE,
    payload,
  }),

  /* UPDATE USERS ACTIONS */

  updateUserRequest: (payload) => ({
    type: usersActions.UPDATE_USER_REQUEST,
    payload,
  }),

  updateUserSuccess: (payload) => ({
    type: usersActions.UPDATE_USER_SUCCESS,
    payload,
  }),

  updateUserError: (payload) => ({
    type: usersActions.UPDATE_USER_ERROR,
    payload,
  }),

  /* INVITE NEW USER */
  inviteUserRequest: ({ roleId, email, locations }) => ({
    type: usersActions.INVITE_USER_REQUEST,
    payload: {
      invitationEmail: email,
      roleId,
      locations,
    },
  }),

  inviteUserSuccess: () => ({
    type: usersActions.INVITE_USER_SUCCESS,
  }),

  inviteUserError: () => ({
    type: usersActions.INVITE_USER_ERROR,
  }),
  /* COMPLETE INVITE ACTION */

  completeInvite: payload => ({
    type: usersActions.COMPLETE_INVITE_REQUEST,
    payload,
  }),

  deleteUserRequest: payload => ({
    type: usersActions.DELETE_USER_REQUEST,
    payload,
  }),

  deleteUserSuccess: () => ({
    type: usersActions.DELETE_USER_SUCCESS,
  }),

  deleteUserError: (payload) => ({
    type: usersActions.DELETE_USER_ERROR,
    payload,
  }),

  resetPasswordRequest: (payload) => ({
    type: usersActions.RESET_PASSWORD_REQUEST,
    payload,
  }),
  resetPasswordSuccess: () => ({
    type: usersActions.RESET_PASSWORD_SUCCESS,
  }),
  resetPasswordError: (payload) => ({
    type: usersActions.RESET_PASSWORD_ERROR,
    payload,
  }),

  toggleInviteModal: () => ({
    type: usersActions.TOGGLE_INVITE_MODAL,
  }),

  changePendingUsersPage: (page) => ({
    type: usersActions.CHANGE_PENDING_USERS_PAGE,
    payload: { page },
  }),

  changePendingUsersPageSize: (pageSize) => ({
    type: usersActions.CHANGE_PENDING_USERS_PAGE_SIZE,
    payload: { pageSize },
  }),

  /* GET PENDING USERS ACTION */

  getPendingUsersRequest: (payload) => ({
    type: usersActions.GET_PENDING_USERS_REQUEST,
    payload,
  }),

  getPendingUsersSuccess: (payload) => ({
    type: usersActions.GET_PENDING_USERS_SUCCESS,
    payload,
  }),

  getPendingUsersError: () => ({
    type: usersActions.GET_PENDING_USERS_ERROR,
  }),

  deletePendingUserRequest: (payload) => ({
    type: usersActions.DELETE_PENDING_USERS_REQUEST,
    payload,
  }),

  deletePendingUserSuccess: () => ({
    type: usersActions.DELETE_PENDING_USERS_SUCCESS,
  }),

  deletePendingUserError: (error) => ({
    type: usersActions.DELETE_PENDING_USERS_ERROR,
    error,
  }),

  resetState: () => ({
    type: usersActions.RESET_STATE,
  }),
};
export default usersActions;
