import actions from 'redux/marketing/marketingEmailTemplates/detailEmailTemplatePage/actions';
import { handleAllSpinners } from 'redux/projects/helpers';

const emailTemplate = {
  id: 0,
  name: '',
  campaign: null,
  campaignId: null,
  subject: '',
  template: '',
  createdAt: '',
  updatedAt: '',
  userId: 0,
  imageIds: null,
  testSender: null,
  testReceiver: '',
};

const initialState = {
  emailTemplate: {
    emailTemplate,
    loading: false,
  },
  editView: {
    info: {
      status: false,
      loading: false,
    },
  },
  editEmailTemplateField: {
    template: '',
    imageIds: null,
    userId: 0,
  },
};

export default function MarketingEmailTemplateReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.CLEAR:
      return {
        ...initialState,
      };

    case actions.GET_TEMPLATE_REQUEST:
      return {
        ...state,
        emailTemplate: {
          ...state.emailTemplate,
          loading: true,
        },
        ...handleAllSpinners(state, true),
      };

    case actions.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        emailTemplate: {
          ...state.emailTemplate,
          emailTemplate: payload.emailTemplate,
          loading: false,
        },
        editEmailTemplateField: {
          ...state.editEmailTemplateField,
          ...payload.emailTemplate,
        },
        ...handleAllSpinners(state, false),
      };

    case actions.GET_TEMPLATE_ERROR:
      return {
        ...state,
        emailTemplate: {
          ...state.emailTemplate,
          emailTemplate: payload.emailTemplate,
          loading: false,
        },
        ...handleAllSpinners(state, false),
      };

    case actions.UPDATE_TEMPLATE_REQUEST:
      return {
        ...state,
        editView: {
          ...state.editView,
          [payload.formName]: {
            ...state.editView[payload.formName],
            loading: true,
          },
        },
      };

    case actions.UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        emailTemplate: {
          emailTemplate: payload.emailTemplate,
          loading: false,
        },
        ...handleAllSpinners(state, false),
      };

    case actions.UPDATE_EDIT_TEMPLATE_FIELD:
      return {
        ...state,
        editEmailTemplateField: {
          ...state.editEmailTemplateField,
          ...payload,
        },
      };

    // Default
    default:
      return state;
  }
}
