const DOCUMENT = 'MARKETING_EMAIL_TEMPLATES_';

const marketingEmailTemplatesActions = {
  GET_TEMPLATES_LIST_REQUEST: `GET_${DOCUMENT}REQUEST`,
  GET_TEMPLATES_LIST_SUCCESS: `GET_${DOCUMENT}SUCCESS`,
  GET_TEMPLATES_LIST_ERROR: `GET_${DOCUMENT}ERROR`,

  SET_INITIAL_TEMPLATES_LIST_STATE: `SET_INITIAL_${DOCUMENT}STATE`,

  DELETE_REQUEST: `DELETE_${DOCUMENT}REQUEST`,
  DELETE_SUCCESS: `DELETE_${DOCUMENT}SUCCESS`,
  DELETE_ERROR: `DELETE_${DOCUMENT}ERROR`,

  SHOW_ADD_MODAL: `${DOCUMENT}SHOW_ADD_MODAL`,

  getTemplatesListRequest: payload => {
    return {
      type: marketingEmailTemplatesActions.GET_TEMPLATES_LIST_REQUEST,
      payload,
    };
  },

  getTemplatesListSuccess: payload => ({
    type: marketingEmailTemplatesActions.GET_TEMPLATES_LIST_SUCCESS,
    payload,
  }),

  getTemplatesListError: error => ({
    type: marketingEmailTemplatesActions.GET_TEMPLATES_LIST_ERROR,
    payload: error,
  }),

  setInitialTemplatesListState: payload => ({
    type: marketingEmailTemplatesActions.SET_INITIAL_TEMPLATES_LIST_STATE,
    payload,
  }),

  deleteTemplateRequest: id => ({
    type: marketingEmailTemplatesActions.DELETE_REQUEST,
    payload: {
      id,
    },
  }),

  deleteTemplateSuccess: id => ({
    type: marketingEmailTemplatesActions.DELETE_SUCCESS,
    payload: {
      id,
    },
  }),

  deleteTemplateError: () => ({
    type: marketingEmailTemplatesActions.DELETE_ERROR,
  }),

  showAddModal: isAddModalActive => ({
    type: marketingEmailTemplatesActions.SHOW_ADD_MODAL,
    payload: { isAddModalActive },
  }),
};

export default marketingEmailTemplatesActions;
