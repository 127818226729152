const projectsFutureActions = {
  GET_FUTURE_PROJECTS_LIST_REQUEST: 'GET_FUTURE_PROJECTS_LIST_REQUEST',
  GET_FUTURE_PROJECTS_LIST_SUCCESS: 'GET_FUTURE_PROJECTS_LIST_SUCCESS',
  GET_FUTURE_PROJECTS_LIST_ERROR: 'GET_FUTURE_PROJECTS_LIST_ERROR',

  ADD_FUTURE_PROJECTS_REQUEST: 'ADD_FUTURE_PROJECTS_REQUEST',
  ADD_FUTURE_PROJECTS_SUCCESS: 'ADD_FUTURE_PROJECTS_SUCCESS',
  ADD_FUTURE_PROJECTS_ERROR: 'ADD_FUTURE_PROJECTS_ERROR',

  UPDATE_FUTURE_PROJECTS_REQUEST: 'UPDATE_FUTURE_PROJECTS_REQUEST',
  UPDATE_FUTURE_PROJECTS_SUCCESS: 'UPDATE_FUTURE_PROJECTS_SUCCESS',
  UPDATE_FUTURE_PROJECTS_ERROR: 'UPDATE_FUTURE_PROJECTS_ERROR',

  DELETE_FUTURE_PROJECTS_REQUEST: 'DELETE_FUTURE_PROJECTS_REQUEST',
  DELETE_FUTURE_PROJECTS_SUCCESS: 'DELETE_FUTURE_PROJECTS_SUCCESS',
  DELETE_FUTURE_PROJECTS_ERROR: 'DELETE_FUTURE_PROJECTS_ERROR',

  ADD_FUTURE_VACANCY_REQUEST: 'ADD_FUTURE_VACANCY_REQUEST',
  ADD_FUTURE_VACANCY_SUCCESS: 'ADD_FUTURE_VACANCY_SUCCESS',
  ADD_FUTURE_VACANCY_ERROR: 'ADD_FUTURE_VACANCY_ERROR',

  UPDATE_FUTURE_VACANCY_REQUEST: 'UPDATE_FUTURE_VACANCY_REQUEST',
  UPDATE_FUTURE_VACANCY_SUCCESS: 'UPDATE_FUTURE_VACANCY_SUCCESS',
  UPDATE_FUTURE_VACANCY_ERROR: 'UPDATE_FUTURE_VACANCY_ERROR',

  DELETE_FUTURE_VACANCY_REQUEST: 'DELETE_FUTURE_VACANCY_REQUEST',
  DELETE_FUTURE_VACANCY_SUCCESS: 'DELETE_FUTURE_VACANCY_SUCCESS',
  DELETE_FUTURE_VACANCY_ERROR: 'DELETE_FUTURE_VACANCY_ERROR',

  START_PROJECT_REQUEST: 'START_PROJECT_REQUEST',
  START_PROJECT_SUCCESS: 'START_PROJECT_SUCCESS',
  START_PROJECT_ERROR: 'START_PROJECT_ERROR',

  ARCHIVE_PROJECT_REQUEST: 'ARCHIVE_PROJECT_REQUEST',
  ARCHIVE_PROJECT_SUCCESS: 'ARCHIVE_PROJECT_SUCCESS',
  ARCHIVE_PROJECT_ERROR: 'ARCHIVE_PROJECT_ERROR',

  GET_RESOURCE_BY_TECHNOLOGY_REQUEST: 'GET_RESOURCE_BY_TECHNOLOGY_REQUEST',
  GET_RESOURCE_BY_TECHNOLOGY_SUCCESS: 'GET_RESOURCE_BY_TECHNOLOGY_SUCCESS',
  GET_RESOURCE_BY_TECHNOLOGY_ERROR: 'GET_RESOURCE_BY_TECHNOLOGY_ERROR',

  GET_RESOURCE_BY_TECHNOLOGY_TYPE_REQUEST: 'GET_RESOURCE_BY_TECHNOLOGY_TYPE_REQUEST',
  GET_RESOURCE_BY_TECHNOLOGY_TYPE_SUCCESS: 'GET_RESOURCE_BY_TECHNOLOGY_TYPE_SUCCESS',
  GET_RESOURCE_BY_TECHNOLOGY_TYPE_ERROR: 'GET_RESOURCE_BY_TECHNOLOGY_TYPE_ERROR',

  SET_FUTURE_PROJECTS_PAGINATION: 'SET_FUTURE_PROJECTS_PAGINATION',
  SET_FUTURE_PROJECTS_SEARCH: 'SET_FUTURE_PROJECTS_SEARCH',
  SET_FUTURE_PROJECTS_NEXT_PAGE: 'SET_FUTURE_PROJECTS_NEXT_PAGE',

  GET_FUTURE_PROJECTS_SECTIONS_REQUEST: 'GET_FUTURE_PROJECTS_SECTIONS_REQUEST',
  GET_FUTURE_PROJECTS_SECTIONS_SUCCESS: 'GET_FUTURE_PROJECTS_SECTIONS_SUCCESS',
  GET_FUTURE_PROJECTS_SECTIONS_ERROR: 'GET_FUTURE_PROJECTS_SECTIONS_ERROR',

  ADD_FUTURE_PROJECTS_SECTIONS_REQUEST: 'ADD_FUTURE_PROJECTS_SECTIONS_REQUEST',
  ADD_FUTURE_PROJECTS_SECTIONS_SUCCESS: 'ADD_FUTURE_PROJECTS_SECTIONS_SUCCESS',
  ADD_FUTURE_PROJECTS_SECTIONS_ERROR: 'ADD_FUTURE_PROJECTS_SECTIONS_ERROR',

  UPDATE_FUTURE_PROJECTS_SECTIONS_REQUEST: 'UPDATE_FUTURE_PROJECTS_SECTIONS_REQUEST',
  UPDATE_FUTURE_PROJECTS_SECTIONS_SUCCESS: 'UPDATE_FUTURE_PROJECTS_SECTIONS_SUCCESS',
  UPDATE_FUTURE_PROJECTS_SECTIONS_ERROR: 'UPDATE_FUTURE_PROJECTS_SECTIONS_ERROR',

  REMOVE_FUTURE_PROJECTS_SECTIONS_REQUEST: 'REMOVE_FUTURE_PROJECTS_SECTIONS_REQUEST',
  REMOVE_FUTURE_PROJECTS_SECTIONS_SUCCESS: 'REMOVE_FUTURE_PROJECTS_SECTIONS_SUCCESS',
  REMOVE_FUTURE_PROJECTS_SECTIONS_ERROR: 'REMOVE_FUTURE_PROJECTS_SECTIONS_ERROR',

  UPDATE_FUTURE_PROJECT_SECTION_REQUEST: 'UPDATE_FUTURE_PROJECT_SECTION_REQUEST',
  UPDATE_FUTURE_PROJECT_SECTION_SUCCESS: 'UPDATE_FUTURE_PROJECT_SECTION_SUCCESS',
  UPDATE_FUTURE_PROJECT_SECTION_ERROR: 'UPDATE_FUTURE_PROJECT_SECTION_ERROR',

  RESCHEDULE_FUTURE_PROJECTS_REQUEST: 'RESCHEDULE_FUTURE_PROJECTS_REQUEST',
  RESCHEDULE_FUTURE_PROJECTS_SUCCESS: 'RESCHEDULE_FUTURE_PROJECTS_SUCCESS',
  RESCHEDULE_FUTURE_PROJECTS_ERROR: 'RESCHEDULE_FUTURE_PROJECTS_ERROR',

  SET_SELECTED_PROJECT: 'SET_SELECTED_PROJECT',

  TOGGLE_MODAL: 'PROJECTS_FUTURE_TOGGLE_MODAL',
  TOGGLE_RESCHEDULE_MODAL: 'PROJECTS_FUTURE_TOGGLE_RESCHEDULE_MODAL',
  RESET_STATE: 'RESET_STATE',

  getFutureProjectsListRequest: payload => ({
    type: projectsFutureActions.GET_FUTURE_PROJECTS_LIST_REQUEST,
    payload,
  }),

  getFutureProjectsListSuccess: payload => ({
    type: projectsFutureActions.GET_FUTURE_PROJECTS_LIST_SUCCESS,
    payload,
  }),

  getFutureProjectsListError: error => ({
    type: projectsFutureActions.GET_FUTURE_PROJECTS_LIST_ERROR,
    payload: error,
  }),

  addFutureProjectRequest: project => ({
    type: projectsFutureActions.ADD_FUTURE_PROJECTS_REQUEST,
    payload: project,
  }),

  addFutureProjectSuccess: isModalActive => ({
    type: projectsFutureActions.ADD_FUTURE_PROJECTS_SUCCESS,
    payload: {
      isModalActive,
    },
  }),

  addFutureProjectError: error => ({
    type: projectsFutureActions.ADD_FUTURE_PROJECTS_ERROR,
    payload: error,
  }),

  updateFutureProjectRequest: (formData, formName, realodProjectList) => ({
    type: projectsFutureActions.UPDATE_FUTURE_PROJECTS_REQUEST,
    payload: { formData, formName, realodProjectList },
  }),

  updateFutureProjectSuccess: (project, formName) => ({
    type: projectsFutureActions.UPDATE_FUTURE_PROJECTS_SUCCESS,
    payload: { project, formName },
  }),

  updateFutureProjectError: error => ({
    type: projectsFutureActions.UPDATE_FUTURE_PROJECTS_ERROR,
    payload: { error },
  }),

  deleteFutureProjectRequest: id => ({
    type: projectsFutureActions.DELETE_FUTURE_PROJECTS_REQUEST,
    payload: {
      id,
    },
  }),

  deleteFutureProjectSuccess: () => ({
    type: projectsFutureActions.DELETE_FUTURE_PROJECTS_SUCCESS,
  }),

  deleteFutureProjectError: () => ({
    type: projectsFutureActions.DELETE_FUTURE_PROJECTS_ERROR,
  }),

  startProjectRequest: payload => ({
    type: projectsFutureActions.START_PROJECT_REQUEST,
    payload
  }),

  startProjectSuccess: () => ({
    type: projectsFutureActions.START_PROJECT_SUCCESS,
  }),

  startProjectError: () => ({
    type: projectsFutureActions.START_PROJECT_ERROR,
  }),

  archiveProjectRequest: payload => ({
    type: projectsFutureActions.ARCHIVE_PROJECT_REQUEST,
    payload
  }),

  archiveProjectSuccess: () => ({
    type: projectsFutureActions.ARCHIVE_PROJECT_SUCCESS,
  }),

  archiveProjectError: () => ({
    type: projectsFutureActions.ARCHIVE_PROJECT_ERROR,
  }),

  addFutureVacancyRequest: (vacancy, projectId) => ({
    type: projectsFutureActions.ADD_FUTURE_VACANCY_REQUEST,
    payload: {
      vacancy,
      projectId,
    },
  }),

  addFutureVacancySuccess: payload => ({
    type: projectsFutureActions.ADD_FUTURE_VACANCY_SUCCESS,
    payload,
  }),

  addFutureVacancyError: error => ({
    type: projectsFutureActions.ADD_FUTURE_VACANCY_ERROR,
    payload: error,
  }),

  updateFutureVacancyRequest: (vacancy, projectId) => ({
    type: projectsFutureActions.UPDATE_FUTURE_VACANCY_REQUEST,
    payload: {
      vacancy,
      projectId,
    },
  }),

  updateFutureVacancySuccess: payload => ({
    type: projectsFutureActions.UPDATE_FUTURE_VACANCY_SUCCESS,
    payload,
  }),

  updateFutureVacancyError: error => ({
    type: projectsFutureActions.UPDATE_FUTURE_VACANCY_ERROR,
    payload: error,
  }),

  deleteFutureVacancyRequest: (vacancyId, projectId) => ({
    type: projectsFutureActions.DELETE_FUTURE_VACANCY_REQUEST,
    payload: {
      vacancyId,
      projectId,
    },
  }),

  deleteFutureVacancySuccess: payload => ({
    type: projectsFutureActions.DELETE_FUTURE_VACANCY_SUCCESS,
    payload,
  }),

  deleteFutureVacancyError: () => ({
    type: projectsFutureActions.DELETE_FUTURE_VACANCY_ERROR,
  }),

  getResourceByTechnologyRequest: (id, key) => ({
    type: projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_REQUEST,
    payload: {
      id,
      key,
    },
  }),

  getResourceByTechnologySuccess: payload => ({
    type: projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_SUCCESS,
    payload,
  }),

  getResourceByTechnologyError: error => ({
    type: projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_ERROR,
    payload: error,
  }),

  getResourceByTechnologyTypeRequest: (id, key) => ({
    type: projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_TYPE_REQUEST,
    payload: {
      id,
      key,
    },
  }),

  getResourceByTechnologyTypeSuccess: payload => ({
    type: projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_TYPE_SUCCESS,
    payload,
  }),

  getResourceByTechnologyTypeError: error => ({
    type: projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_TYPE_ERROR,
    payload: error,
  }),

  setPagination: payload => ({
    type: projectsFutureActions.SET_FUTURE_PROJECTS_PAGINATION,
    payload,
  }),

  setNextPage: payload => ({
    type: projectsFutureActions.SET_FUTURE_PROJECTS_NEXT_PAGE,
    payload,
  }),

  setSearch: payload => ({
    type: projectsFutureActions.SET_FUTURE_PROJECTS_SEARCH,
    payload,
  }),

  setSelectedProject: payload => ({
    type: projectsFutureActions.SET_SELECTED_PROJECT,
    payload,
  }),

  toggleModal: payload => ({
    type: projectsFutureActions.TOGGLE_MODAL,
    payload,
  }),

  getFutureProjectsSectionsRequest: payload => ({
    type: projectsFutureActions.GET_FUTURE_PROJECTS_SECTIONS_REQUEST,
    payload,
  }),

  getFutureProjectsSectionsSuccess: payload => ({
    type: projectsFutureActions.GET_FUTURE_PROJECTS_SECTIONS_SUCCESS,
    payload,
  }),

  getFutureProjectsSectionsError: error => ({
    type: projectsFutureActions.GET_FUTURE_PROJECTS_SECTIONS_ERROR,
    payload: error,
  }),

  addFutureProjectsSectionsRequest: payload => ({
    type: projectsFutureActions.ADD_FUTURE_PROJECTS_SECTIONS_REQUEST,
    payload
  }),

  addFutureProjectsSectionsSuccess: payload => ({
    type: projectsFutureActions.ADD_FUTURE_PROJECTS_SECTIONS_SUCCESS,
    payload,
  }),

  addFutureProjectsSectionsError: error => ({
    type: projectsFutureActions.ADD_FUTURE_PROJECTS_SECTIONS_ERROR,
    payload: error,
  }),

  updateFutureProjectsSectionsRequest: payload => ({
    type: projectsFutureActions.UPDATE_FUTURE_PROJECTS_SECTIONS_REQUEST,
    payload,
  }),

  updateFutureProjectsSectionsSuccess: payload => ({
    type: projectsFutureActions.UPDATE_FUTURE_PROJECTS_SECTIONS_SUCCESS,
    payload,
  }),

  updateFutureProjectsSectionsError: error => ({
    type: projectsFutureActions.UPDATE_FUTURE_PROJECTS_SECTIONS_ERROR,
    payload: error,
  }),

  removeFutureProjectsSectionsRequest: id => ({
    type: projectsFutureActions.REMOVE_FUTURE_PROJECTS_SECTIONS_REQUEST,
    payload: { id },
  }),

  removeFutureProjectsSectionsSuccess: payload => ({
    type: projectsFutureActions.REMOVE_FUTURE_PROJECTS_SECTIONS_SUCCESS,
    payload,
  }),

  removeFutureProjectsSectionsError: error => ({
    type: projectsFutureActions.REMOVE_FUTURE_PROJECTS_SECTIONS_ERROR,
    payload: error,
  }),

  updateFutureProjectSectionRequest: payload => ({
    type: projectsFutureActions.UPDATE_FUTURE_PROJECT_SECTION_REQUEST,
    payload,
  }),

  updateFutureProjectSectionSuccess: payload => ({
    type: projectsFutureActions.UPDATE_FUTURE_PROJECT_SECTION_SUCCESS,
    payload,
  }),

  updateFutureProjectSectionError: error => ({
    type: projectsFutureActions.UPDATE_FUTURE_PROJECT_SECTION_ERROR,
    payload: error,
  }),

  rescheduleFutureProjectsRequest: payload => ({
    type: projectsFutureActions.RESCHEDULE_FUTURE_PROJECTS_REQUEST,
    payload,
  }),

  rescheduleFutureProjectsSuccess: payload => ({
    type: projectsFutureActions.RESCHEDULE_FUTURE_PROJECTS_SUCCESS,
    payload,
  }),

  rescheduleFutureProjectsError: error => ({
    type: projectsFutureActions.RESCHEDULE_FUTURE_PROJECTS_ERROR,
    payload: error,
  }),

  toggleRescheduleModal: payload => ({
    type: projectsFutureActions.TOGGLE_RESCHEDULE_MODAL,
    payload,
  }),

  resetFutureProjectsState: () => {
    return {
      type: projectsFutureActions.RESET_STATE
    }
  }
};

export default projectsFutureActions;
