import { images } from 'settings';
import profileActions from 'redux/profile/actions';
import appAactions from '../app/actions'

const initialProfile = {
  id: 0,
  email: '',
  firstName: '',
  lastName: '',
  avatarUrl: '',
  role: {
    id: 0,
    name: '',
  },
};

const initialState = {
  isEditView: false,
  isModalActive: false,
  profile: initialProfile,
  formState: initialProfile,
  fileUrl: '',
  file: '',
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case profileActions.FORM_CLOSE:
      return {
        ...state,
        isEditView: false,
        file: null,
      };
    // View change
    case profileActions.PROFILE_VIEW:
      return {
        ...state,
        isEditView: false,
      };
    case profileActions.PROFILE_EDIT:
      return {
        ...state,
        isEditView: true,
      };
    case profileActions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
        editView: initialState.isEditView,
        editForm: initialState.editForm,
      };
    // Change password
    case profileActions.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case profileActions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case profileActions.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      }
    // Get profile request
    case profileActions.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case profileActions.GET_SUCCESS:
      return {
        ...state,
        profile: {
          ...action.payload,
          avatarUrl: action.payload.avatarUrl || images.avatarPlaceholder,
        },
        loading: false,
      };
    case profileActions.GET_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case profileActions.UPDATE_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case profileActions.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isEditView: false,
        profile: {
          ...action.payload,
          avatarUrl: action.payload.avatarUrl || images.avatarPlaceholder,
        },
      };
    case profileActions.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case profileActions.UPLOAD_AVATAR:
      return {
        ...state,
        loading: true,
      };
    case profileActions.UPLOAD_AVATAR_SUCCESS:
      const { fileUrl, file } = action.payload;
      return {
        ...state,
        file,
        fileUrl,
        loading: false,
      };
    case profileActions.UPLOAD_AVATAR_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case appAactions.CHANGE_CURRENT:
      return {
        ...state,
        isEditView: false,
      }

    // Default
    default:
      return state;
  }
}
