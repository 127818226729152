import mailingActions from 'redux/marketing/mailing/actions';

const initMailing = {
  id: null,
  title: '',
  startedDate: null,
  numberRecipients: 0,
  interval: null,
  progress: null,
  emailTemplateId: null,
  mailingSenders: [],
  mailingRecipients: {},
  received: {},
  workerAt: null,
  status: 0,
  groups: [],
};

const initState = {
  currentMailing: initMailing,
  senders: [],
  loading: false,
  editView: {
    settings: {
      status: false,
      loading: false,
    },
    senders: {
      status: false,
      loading: false,
    },
    recipients: {
      status: false,
      loading: false,
    },
  },
};

export default function mailingReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case mailingActions.CHANGE_VIEW:
      return {
        ...state,
        editView: {
          ...state.editView,
          [payload.boxName]: {
            ...state.editView[payload.boxName],
            status: payload.status,
          },
        },
      };

    case mailingActions.GET_MAILING_STATE:
      return {
        ...state,
        editView: {
          settings: {
            loading: true,
          },
          senders: {
            loading: true,
          },
          recipients: {
            loading: true,
          },
        },
      };

    case mailingActions.GET_MAILING_STATE_SUCCESS:
      return {
        ...state,
        senders: [...payload.senders],
        currentMailing: {
          ...payload.mailing,
          mailingSenders: payload.mailingSenders,
          interval: payload.mailing.interval ? +payload.mailing.interval : null,
        },
        editView: {
          settings: {
            ...state.editView.settings,
            loading: false,
          },
          senders: {
            ...state.editView.senders,
            loading: false,
          },
          recipients: {
            ...state.editView.recipients,
            loading: false,
          },
        },
      };

    case mailingActions.GET_MAILING_STATE_ERROR:
      return {
        ...state,
        error: payload.error,
        editView: {
          settings: {
            loading: false,
          },
          senders: {
            loading: false,
          },
          recipients: {
            loading: false,
          },
        },
        loading: false,
      };

    case mailingActions.SET_CURRENT_MAILING_RECIPIENTS:
      return {
        ...state,
      };

    case mailingActions.SET_CURRENT_MAILING_RECIPIENTS_SUCCESS:
      return {
        ...state,
        currentMailing: {
          ...state.currentMailing,
          mailingRecipients: {
            ...payload,
          },
        },
      };

    case mailingActions.UPDATE_MAILING:
      return {
        ...state,
        editView: {
          ...state.editView,
          [payload.boxName]: {
            ...state.editView[payload.boxName],
            loading: true,
          },
        },
      };

    case mailingActions.UPDATE_MAILING_SUCCESS:
      return {
        ...state,
        currentMailing: {
          ...initMailing,
          ...payload.mailing,
          interval: payload.mailing.interval ? +payload.mailing.interval : null,
        },
        editView: {
          ...state.editView,
          [payload.boxName]: {
            status: false,
            loading: false,
          },
        },
      };

    case mailingActions.ADD_SENDER:
      return {
        ...state,
        editView: {
          ...state.editView,
          senders: {
            ...state.editView.senders,
            loading: true,
          },
        },
      };

    case mailingActions.REMOVE_SENDER:
      return {
        ...state,
        editView: {
          ...state.editView,
          senders: {
            ...state.editView.senders,
            loading: true,
          },
        },
      };

    case mailingActions.CHANGE_SENDERS_SUCCESS:
      return {
        ...state,
        currentMailing: {
          ...state.currentMailing,
          mailingSenders: [...payload],
        },
        editView: {
          ...state.editView,
          senders: {
            ...state.editView.senders,
            loading: false,
          },
        },
      };

    case mailingActions.UPDATE_MAILING_ERROR:
      return {
        ...state,
        error: payload.error,
        editView: {
          settings: {
            loading: false,
          },
          senders: {
            loading: false,
          },
          recipients: {
            loading: false,
          },
        },
      };

    case mailingActions.CHANGE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case mailingActions.CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        currentMailing: {
          ...state.currentMailing,
          ...payload,
        },
        loading: false,
      };

    case mailingActions.CHANGE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case mailingActions.SET_RECIPIENTS:
      return {
        ...state,
        currentMailing: {
          ...state.currentMailing,
          ...payload,
        },
      };

    case mailingActions.START_NOW:
      return {
        ...state,
      };

    case mailingActions.RESET_STATE:
      return {
        ...initState,
      };

    default:
      return state;
  }
}
