import forgotPassActions from 'redux/forgotPassword/actions';

const initState = {
  loading: false,
};

export default function forgotPassReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case forgotPassActions.FORGOT_PASS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case forgotPassActions.FORGOT_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case forgotPassActions.FORGOT_PASS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};