const resourcesEvaluationActions = {
  GET_RESOURCES_EVALUATION_REQUEST: `GET_RESOURCES_EVALUATION_REQUEST`,
  GET_RESOURCES_EVALUATION_SUCCESS: `GET_RESOURCES_EVALUATION_SUCCESS`,
  GET_RESOURCES_EVALUATION_ERROR: `GET_RESOURCES_EVALUATION_ERROR`,
  RESOURCES_EVALUATION_TOGGLE_MODAL: `RESOURCES_EVALUATION_TOGGLE_MODAL`,
  RESOURCES_EVALUATION_TOGGLE_HISTORY_MODAL: `RESOURCES_EVALUATION_TOGGLE_HISTORY_MODAL`,
  RESOURCES_EVALUATION_ADD: `RESOURCES_EVALUATION_ADD`,
  RESOURCES_EVALUATION_ADD_SUCCESS: `RESOURCES_EVALUATION_ADD_SUCCESS`,
  RESOURCES_HISTORY_EVALUATION_ADD: `RESOURCES_HISTORY_EVALUATION_ADD`,
  RESOURCES_HISTORY_EVALUATION_ADD_SUCCESS: `RESOURCES_HISTORY_EVALUATION_ADD_SUCCESS`,
  RESOURCES_HISTORY_EVALUATION_ERROR: `RESOURCES_HISTORY_EVALUATION_ERROR`,
  RESOURCES_EVALUATION_UPDATE: `RESOURCES_EVALUATION_UPDATE`,
  RESOURCES_EVALUATION_UPDATE_ERROR: `RESOURCES_EVALUATION_UPDATE_ERROR`,
  RESOURCES_EVALUATION_DELETE: `RESOURCES_EVALUATION_DELETE`,
  RESOURCES_EVALUATION_DELETE_ERROR: `RESOURCES_EVALUATION_DELETE_ERROR`,

  getResourcesEvaluationRequest(payload, ignoreSetUrl) {
    return {
      type: resourcesEvaluationActions.GET_RESOURCES_EVALUATION_REQUEST,
      payload,
      ignoreSetUrl
    };
  },
  getResourcesEvaluationSuccess(payload) {
    return {
      type: resourcesEvaluationActions.GET_RESOURCES_EVALUATION_SUCCESS,
      payload,
    };
  },
  getResourcesEvaluationError(payload) {
    return {
      type: resourcesEvaluationActions.GET_RESOURCES_EVALUATION_ERROR,
      payload,
    };
  },

  toggleModal: (evaluation) => ({
    type: resourcesEvaluationActions.RESOURCES_EVALUATION_TOGGLE_MODAL,
    payload: { evaluation }
  }),

  toggleHistoryModal: (evaluation) => ({
    type: resourcesEvaluationActions.RESOURCES_EVALUATION_TOGGLE_HISTORY_MODAL,
    payload: { evaluation }
  }),

  addEvaluation: payload => ({
    type: resourcesEvaluationActions.RESOURCES_EVALUATION_ADD,
    payload,
  }),

  addEvaluationSuccess: payload => ({
    type: resourcesEvaluationActions.RESOURCES_EVALUATION_ADD_SUCCESS,
    payload,
  }),

  addHistoryEvaluation: payload => ({
      type: resourcesEvaluationActions.RESOURCES_HISTORY_EVALUATION_ADD,
      payload,
    }),

  addHistoryEvaluationSuccess: payload => ({
    type: resourcesEvaluationActions.RESOURCES_HISTORY_EVALUATION_ADD_SUCCESS,
    payload,
  }),

  getHistoryEvaluationError: payload => ({
    type: resourcesEvaluationActions.RESOURCES_HISTORY_EVALUATION_ERROR,
    payload,
  }),

  updateEvaluation: (evaluation, evaluationId) => ({
    type: resourcesEvaluationActions.RESOURCES_EVALUATION_UPDATE,
    payload: { evaluation, evaluationId },
  }),

  updateEvaluationError: (payload) => ({
    type: resourcesEvaluationActions.RESOURCES_EVALUATION_UPDATE_ERROR,
    payload,
  }),

  deleteEvaluation: (id) => ({
    type: resourcesEvaluationActions.RESOURCES_EVALUATION_DELETE,
    payload: { id },
  }),
};

export default resourcesEvaluationActions;
