/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';

import appActions from 'redux/app/actions';
import TableActions from 'components/NewTable/redux/actions';
import PropTypes from 'prop-types';
import LogoMobile from 'components/theme/utility/mobileLogo';
import Logo from 'components/theme/utility/logo';
import TopbarWrapper from 'containers/Topbar/topbar.style';

const { toggleCollapsed } = appActions;

const Topbar = ({ 
  toggleCollapsed, 
  collapsed, 
  changeCurrent, 
  isMobileView, 
  isCollapsed, 
  current, 
  setFilters,
  tableReducer,
}) => {
    
  return (
    <TopbarWrapper className={isMobileView && 'mobileView'}>
      { isMobileView ? (
        <LogoMobile
          toggleCollapsed={toggleCollapsed} 
          collapsed={collapsed}
          changeCurrent={changeCurrent}
          current={current[0]}
          tableReducer={tableReducer}
          setFilters={setFilters}
        />
        ) : (
          <div className="normalView" onClick={toggleCollapsed} role="button" tabIndex={0}>
            <Logo
              collapsed={isCollapsed}
              changeCurrent={changeCurrent}
            />
          </div>
        )}
    </TopbarWrapper>
  );
}

Topbar.propTypes = {
  isMobileView: PropTypes.bool.isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  current: PropTypes.array,
  isCollapsed: PropTypes.bool.isRequired,
  changeCurrent: PropTypes.func.isRequired,
	setFilters: PropTypes.func,
	tableReducer: PropTypes.object,
}

Topbar.defaultProps = {
  current: [''],
	setFilters: () => {},
	tableReducer: {}
}

export default connect(
  state => ({
    ...state.AppReducer,
    tableReducer: state.tableReducer
  }),
  { 
    toggleCollapsed,
    setFilters: TableActions.setFilters,
  },
)(Topbar);
