const DOCUMENT = 'CLIENTS_';

const actions = {
  GET_CLIENTS_REQUEST: 'GET_CLIENTS_REQUEST',
  GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
  GET_CLIENTS_ERROR: 'GET_CLIENTS_ERROR',
  ADD_CLIENT_REQUEST: 'ADD_CLIENT_REQUEST',
  ADD_CLIENT_SUCCESS: 'ADD_CLIENT_SUCCESS',
  ADD_CLIENT_ERROR: 'ADD_CLIENT_ERROR',
  DELETE_CLIENT_REQUEST: 'DELETE_CLIENT_REQUEST',
  DELETE_CLIENT_SUCCESS: 'DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_ERROR: 'DELETE_CLIENT_ERROR',

  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,

  getClientsRequest: (isSetPagination) => ({
    type: actions.GET_CLIENTS_REQUEST,
    payload: { isSetPagination }
  }),
  getClientsSuccess: clients => ({
    type: actions.GET_CLIENTS_SUCCESS,
    payload: { clients },
  }),
  getClientsError: error => ({
    type: actions.GET_CLIENTS_ERROR,
    payload: { error },
  }),
  addClientRequest: client => ({
    type: actions.ADD_CLIENT_REQUEST,
    payload: client,
  }),
  addClientSuccess: client => ({
    type: actions.ADD_CLIENT_SUCCESS,
    payload: { client },
  }),
  addClientError: error => ({
    type: actions.ADD_CLIENT_ERROR,
    payload: { error },
  }),
  deleteClientRequest: id => ({
    type: actions.DELETE_CLIENT_REQUEST,
    payload: { id },
  }),
  deleteClientSuccess: id => ({
    type: actions.DELETE_CLIENT_SUCCESS,
    payload: { id },
  }),
  deleteClientError: error => ({
    type: actions.DELETE_CLIENT_ERROR,
    payload: { error },
  }),

  toggleModal: () => ({
    type: actions.TOGGLE_MODAL,
  }),
};

export default actions;
