const ACTION_LOGS = {
  ENTITY_LINKS: {
    resource: id => `/resources/${id}`,
    project: id => `/projects/${id}`,
  },
  COLLECTIONS: {
    assignmentTypeId: {
      1: 'Internal',
      2: 'Billable',
      3: 'Non-Billable',
      4: 'Bench',
    },
    projectStatusId: {
      1: 'Upcoming',
      2: 'Active',
      3: 'On Hold',
      4: 'Closed',
    },
    vacationStatusId: {
      1: 'New',
      2: 'Approved',
      3: 'Rejected',
    },
  },
  TYPE: {
    DELETED: 'deleted',
    UPDATED: 'updated',
    CREATED: 'created',
    BULK_DELETED: 'bulk deleted',
    BULK_CREATED: 'bulk created',
  },
  ENTITY_TYPES: {
    ROLE_CLAIM: 'roleClaim',
    RESOURCE_TECHONOLOGY: 'resourceTechnology',
    PROJECT_CLIENT: 'projectClient',
    RESOURCE_PROJECT: 'resourceProject',
    USER_LOCATION: 'userLocation',
  },
};

export default ACTION_LOGS;
