import actions from 'redux/technologies/actions';

export const PAGE_SIZE_LOCALSTORAGE = 'Technologies_PageSize';

const initState = {
  isModalActive: false,
  loading: false,
  selectedTechnology: {
    id: null,
    title: '',
    abbreviation: '',
    type: '',
    color: '',
  },
  technologies: [],
  technologyTypes: [],
};

export default function technologyReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_TECHNOLOGY_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TECHNOLOGY_TYPES_SUCCESS:
      return {
        ...state,
        technologyTypes: action.payload.technologyTypes,
        loading: false,
      };
    case actions.GET_TECHNOLOGY_TYPES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.GET_TECHNOLOGIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TECHNOLOGIES_SUCCESS:
      return {
        ...state,
        technologies: action.payload.technologies || [],
        loading: false,
      };
    case actions.GET_TECHNOLOGIES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.SET_INITIAL_TECHNOLOGIES_STATE:
      return {
        ...state,
        ...action.payload,
        loading: true
      };
    case actions.ADD_TECHNOLOGY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        isModalActive: false,
        technologies: [
          ...state.technologies,
          {
            ...action.payload.technology,
            technologyType: state.technologyTypes.find(item => 
              item.id === action.payload.technology.technologyTypeId
            )
          }
        ],
        loading: false,
      };
    case actions.ADD_TECHNOLOGY_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_TECHNOLOGY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_TECHNOLOGY_SUCCESS:
      const { technology } = action.payload;
      return {
        ...state,
        technologies: [
          ...state.technologies.map(item => item.id === technology.id ? technology : item)
        ],
        loading: false,
      };
    case actions.UPDATE_TECHNOLOGY_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_TECHNOLOGY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        technologies: state.technologies.filter(item => item.id !== action.payload.technologyId),
        loading: false,
      };
    case actions.DELETE_TECHNOLOGY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
        selectedTechnology: action.payload.technology || initState.selectedTechnology,
      };
    default:
      return state;
  }
};
