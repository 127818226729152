import React  from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/theme/Feedback/Modal';

export default () => {
  const ConfirmLogout = props => {

    const onOk = () => {
      const { onOk } = props;
      onOk();
    }

    const cancelHandler = () => {
      const { onCancel } = props;
      onCancel();
    };

      const { onClose, isVisible } = props;
      return (
        <Modal
          onClose={onClose}
          onCancel={cancelHandler}
          onOk={onOk}
          visible={isVisible}
          title={<h3>Are you sure you want to logout?</h3>}
        />
      );

  }

  ConfirmLogout.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
  };

  return ConfirmLogout
}
