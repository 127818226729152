const IS_LOGGED_IN = 'IS_LOGGED_IN';

export const setAuthStatus = value => {
  localStorage.setItem(IS_LOGGED_IN, value);
};

export const getAuthStatus = () => {
  const loggedIn = localStorage.getItem(IS_LOGGED_IN); 
  return loggedIn && loggedIn.toString() === 'true';
};

