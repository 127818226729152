export const LOGS_ENTITY_TYPES = {
    'dashboard': {
      entityType: [
      'techLevel',
      'englishLevel',
      'projectTechnology',
      'project',
      'resourceTechnology',
      'resource',
      'technology',
    ]},
  'viewDocument': {
      entityType: [
      'document',
    ]},
  'editDocument': {
      entityType: [
      'document',
      'documentLocation',
      'documentRole',
    ]},
    'profile': { entityType: ['user'] },
    'resourceList': {
      entityType: [
      'techLevel',
      'englishLevel',
      'resourceRole',
      'technology',
      'resource',
      'resourceTechnology',
      'resourceProject',
      'resourceProjectHistory',
      'evaluation',
    ]},
    'resourceByProject': {
      entityType: [
      'project',
      'resource',
      'resourceTechnology',
      'technology',
      'resourceProject',
      'resourceProjectHistory',
      'projectFormerResource',
      'resourceHistory',
    ]},
    'resourceByAssignmentType': {
      entityType: [
      'techLevel',
      'englishLevel',
      'resourceRole',
      'technology',
      'resource',
      'project',
      'resourceTechnology',
      'resourceProject',
      'resourceProjectHistory',
    ]},
    'resourcePlaning': {
      entityType: [
      'resourceRole',
      'techLevel',
      'englishLevel',
      'technology',
      'resource',
    ]},
    'resourceHistory': {
      entityType: [
      'resource',
      'project',
      'resourceProjectHistory',
      'resourceHistory',
    ]},
    'resourcesEvaluation': {
      entityType: [
      'resource',
      'evaluation',
    ]},
    'evaluationDetailPage': (id) => ({
      fields: {
        entityId: id,
        entityType: [
          'resource',
          'resourceTechnology',
          'evaluation',
        ],
      },
    }),
    'projectsList': {
      entityType: [
      'project',
      'resource',
      'technology',
      'projectTechnology',
      'resourceProject',
      'resourceProjectHistory',
      'projectClient',
    ]},
    'clientsList': { entityType: ['client'] },
    'ongoingProject': {
      entityType: [
      'technology',
      'resource',
      'resourceProjectHistory',
      'resourceProject',
      'projectTechnology',
    ]},
    'timelineProject': {
      entityType: [
      'projectTechnology',
      'resourceProject',
      'resourceProjectHistory',
      'resource',
      'project',
      'technology',
    ]},
    'futureProjects': {
      entityType: [
      'projectSection',
      'projectVacancy',
      'project',
      'technology',
    ]},
    'roles': { entityType: ['role', 'roleClaim'] },
    'projectStatus': { entityType: ['projectStatus'] },
    'usersList': { entityType: ['user', 'userLocation'] },
    'actionLOGS_LAST_DATE': {
      entityType: [
      'resourceTechnology',
      'resource',
      'vacationSettings',
      'techLevel',
      'englishLevel',
      'client',
      'campaign',
      'authCredential',
      'resourceProject',
      'roleClaim',
      'user',
      'resourceSalary',
      'resourceSigningBonus',
      'resourceOvertime',
      'resourceReferralBonus',
      'project',
      'projectTechnology',
      'resourceComment',
      'group',
      'recipient',
      'groupRecipient',
      'task',
      'mailing',
      'groupMailing',
      'invite',
      'resourceRole',
      'role',
      'resourceHistory',
      'signature',
      'mailingSender',
      'mailingRecipient',
      'technology',
      'emailTemplate',
      'holiday',
      'holidayLocation',
      'projectVacancy',
      'vacation',
      'projectSection',
      'resourceContract',
      'projectFormerResource',
      'userLocation',
      'inviteLocation',
      'resourceProjectHistory',
      'evaluation',
      'projectClient',
    ]},
    'resourceRole': { entityType: ['resourceRole'] },
    'technologies': { entityType: ['technology'] },
    'levels': { entityType: ['techLevel', 'englishLevel'] },
    'mailings': { entityType: ['task', 'mailing', 'groupMailing', 'group', 'campaign'] },
    'mailingDetailPage':  { entityType: ['task', 'mailing', 'groupMailing'] },
    'emailTemplates':  { entityType: ['emailTemplate', 'campaign'] },
    'emailTemplateDetailPage': { entityType: ['emailTemplate'] },
    'campaigns': { entityType: ['campaign'] },
    'recipients': { entityType: ['recipient', 'groupRecipient', 'group'] },
    'groups': { entityType: ['group'] },
    'senders': { entityType: ['authCredential'] },
    'detailSenderPage': { entityType: ['authCredential', 'signature'] },
    'projectDetailPage': (id) => ({
      pagination: { pageSize: 0 },
      fields: {
        entityId: id,
        entityType: [
          'projectTechnology',
          'resource',
          'resourceProjectHistory',
          'resourceProject',
          'project',
        ],
      },
    }),
    'resourceDetailPage': (id) => ({
      fields: {
        entityId: id,
        entityType: [
          'resourceSalary',
          'resourceSigningBonus',
          'resourceOvertime',
          'resourceReferralBonus',
          'evaluation',
          'resource',
          'resourceTechnology',
          'resourceProject',
          'resourceProjectHistory',
          'resourceComment',
        ],
      },
    }),
  };
