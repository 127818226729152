import clientDetailsActions from './actions';

const initialClientDetailsState = {
  client: {},
  loading: false,
  isEditMode: {
    mainInfo: {
      status: false,
      loading: false,
    },
    contactsInfo: {
      status: false,
      loading: false,
    }
  }
}

export default function ClientDetailsPageReducer(state = initialClientDetailsState, action) {
  const { type, payload } = action;

  switch (type) {
    case clientDetailsActions.CHANGE_BLOCK_MODE:
      return {
        ...state,
        isEditMode: {
          ...state.isEditMode,
          [payload.blockName]: {
            ...state.isEditMode[payload.blockName],
            status: payload.status,
          }
        }
      }

    case clientDetailsActions.GET_CLIENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case clientDetailsActions.GET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        client: payload,
        loading: false,
      }

    case clientDetailsActions.GET_CLIENT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      }

    case clientDetailsActions.UPDATE_CLIENT_DETAILS_REQUEST:
      return {
        ...state,
        isEditMode: {
          ...state.isEditMode,
          [payload.blockName]: {
            ...state.isEditMode[payload.blockName],
            loading: true,
          }
        }
      }

    case clientDetailsActions.UPDATE_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          ...payload.data
        },
        isEditMode: {
          ...state.isEditMode,
          [payload.blockName]: {
            ...state.isEditMode[payload.blockName],
            status: false,
            loading: false,
          }
        }
      }

    case clientDetailsActions.UPDATE_CLIENT_DETAILS_ERROR:
      return {
        ...state,
        isEditMode: {
          ...state.isEditMode,
          [payload.blockName]: {
            ...state.isEditMode[payload.blockName],
            loading: false,
          }
        }
      }

    default:
      return state;
  }
}
