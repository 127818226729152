import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Auth from 'redux/auth/reducer';
import AppReducer from 'redux/app/reducer';
import ActionLog from 'redux/actionLog/reducer';
import ResourceRoles from 'redux/resourceRoles/reducer';
import ProjectStatuses from 'redux/projectStatuses/reducer';
import Technologies from 'redux/technologies/reducer';
import Resources from 'redux/resources/tableResource/reducer';
import ManageRoleReducer from 'redux/manageRoleAccess/reducer';
import Users from 'redux/users/reducer';
import Profile from 'redux/profile/reducer';
import Clients from 'redux/clients/reducer';
import Levels from 'redux/levels/reducer';
import Resource from 'redux/resources/resource/reducer';
import Types from 'redux/types/reducer';
import Location from 'redux/location/reducer';
import Project from 'redux/projects/detailPage/reducer';
import ProjectsList from 'redux/projects/reducer';
import ProjectsOngoing from 'redux/projects/ongoingPage/reducer';
import ProjectsFutureReducer from 'redux/projects/futurePage/reducer';
import Stats from 'redux/stats/reducer';
import SettingsReducer from 'redux/settings/reducer';
import ResourcesByType from 'redux/resources/resourcesByType/reducer';
import ResourceTimeline from 'redux/resources/resourseTimeline/reducer'
import ForgotPassword from 'redux/forgotPassword/reducer';
import ResourceByProject from 'redux/resources/resourceByProject/reducer';
import Recipients from 'redux/marketing/recipients/reducer';
import MarketingEmailTemplate
  from 'redux/marketing/marketingEmailTemplates/detailEmailTemplatePage/reducer';
import MarketingEmailTemplates from 'redux/marketing/marketingEmailTemplates/listPage/reducer';
import Mailing from 'redux/marketing/mailing/reducer';
import MailingRecipients from 'redux/marketing/mailing/mailingRecipients/reducer';
import Mailings from 'redux/marketing/mailings/listPage/reducer';
import MarketingGroups from 'redux/marketing/marketingGroups/reducer';
import MarketingCampaigns from 'redux/marketing/marketingCampaigns/reducer';
import MarketingSenders from 'redux/marketing/marketingSenders/reducer';
import ProjectManagers from 'redux/projectManagers/reducer';
import profileReducer from 'redux/profile/reducer';
import tableReducer from 'components/NewTable/redux/reducer';
import DashboardReducer from 'redux/dashboard/reducer';
import VacationReducer from 'redux/vacation/reducer';
import VacationSettingsReducer from 'redux/vacation/settings/reducer';
import EvaluationReducer from 'redux/resources/evaluationDetails/reducer';
import ResourcesHistoryReducer from './resources/resourсeHistory/reducer';
import ResourcesEvaluationReducer from "./resources/resourceEvaluation/reducer";
import ClientDetailsPageReducer from './clients/clientDetails/reducer';
import DocumentsReducer from './documents/reducer';
import potentialProjectsReducer from './projects/potentialProjectsPage/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    Auth,
    AppReducer,
    ActionLog,
    ResourceRoles,
    Technologies,
    Resources,
    Resource,
    ManageRoleReducer,
    Users,
    Profile,
    profileReducer,
    Clients,
    Levels,
    Types,
    Location,
    Project,
    ProjectsList,
    ProjectsOngoing,
    ProjectsFutureReducer,
    ResourcesByType,
		ResourceTimeline,
    Stats,
    SettingsReducer,
    ForgotPassword,
    ResourceByProject,
    MarketingEmailTemplate,
    MarketingEmailTemplates,
    Recipients,
    MarketingGroups,
    Mailing,
    MailingRecipients,
    Mailings,
    MarketingCampaigns,
    MarketingSenders,
    ProjectManagers,
    tableReducer,
    DashboardReducer,
    VacationReducer,
    VacationSettingsReducer,
    ResourcesHistoryReducer,
    ResourcesEvaluationReducer,
    EvaluationReducer,
    ProjectStatuses,
    ClientDetailsPageReducer,
    DocumentsReducer,
    potentialProjectsReducer,
  });
