import resourceTimelineActions from 'redux/resources/resourseTimeline/actions';

export const PAGE_SIZE_LOCALSTORAGE = 'ResourcePlanning_PageSize';

const getInitState = () => ({
  loading: false,
  roles: [],
  resources: [],
});

export default function resourceTimelineReducer(state = getInitState(), action) {
  const { type, payload } = action;

  switch (type) {

    /* get initialState */
    case resourceTimelineActions.GET_INITIAL_RESOURCE_TIMELINE_STATE:
      return {
        ...state,
        loading: true
      }

    case resourceTimelineActions.GET_INITIAL_RESOURCE_TIMELINE_STATE_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      }

    case resourceTimelineActions.GET_INITIAL_RESOURCE_TIMELINE_STATE_ERROR:
      return {
        ...state,
        loading: false
      }

    case resourceTimelineActions.SET_INITIAL_RESOURCE_TIMELINE_STATE:
      return {
        ...state,
        ...payload,
        loading: true
      }

    /* get RESOURCE_TIMELINE */
    case resourceTimelineActions.GET_RESOURCE_TIMELINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case resourceTimelineActions.GET_RESOURCE_TIMELINE_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case resourceTimelineActions.GET_RESOURCE_TIMELINE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case resourceTimelineActions.RESET_STATE:
      return {
        ...getInitState()
      };

    default:
      return state;
  }
}
