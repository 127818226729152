import resourcesActions from 'redux/resources/tableResource/actions';

export const PAGE_SIZE_LOCALSTORAGE = 'ResourcesList_PageSize';

const getInitState = () => ({
  loading: false,
  isModalActive: false,
  selectedResource: {
    name: '',
    techLevel: '',
    englishLevel: '',
    location: '',
    resourceRole: '',
    email: '',
    phone: '',
    assignmentType: '',
  },
  filters: null,
  roles: [],
  locations: [],
  resources: [],
  simpleListAllResources: [],
  showDrawer: false,
});

export default function manageRoleReducer(state = getInitState(), action) {
  const { type, payload } = action;
  const { roles } = payload || [];

  switch (type) {

    /* get initialState */
    case resourcesActions.GET_INITIAL_RESOURCE_STATE:
      return {
        ...state,
        loading: true
      }

    case resourcesActions.GET_INITIAL_RESOURCE_STATE_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      }

    case resourcesActions.GET_INITIAL_RESOURCE_STATE_ERROR:
      return {
        ...state,
        loading: false
      }

    case resourcesActions.SET_INITIAL_RESOURCE_STATE:
      return {
        ...state,
        ...payload,
        loading: true
      }

    /* get CHANGED RESOURCES */
    case resourcesActions.GET_CHANGED_RESOURCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case resourcesActions.GET_CHANGED_RESOURCE_SUCCESS:
      return {
        ...state,
        resources: state.resources.map(item => item.id === payload.id ? payload : item),
        loading: false,
      };
    case resourcesActions.GET_CHANGED_RESOURCE_ERROR:
      return {
        ...state,
        loading: false,
      };

    /* get RESOURCES */
    case resourcesActions.GET_RESOURCES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case resourcesActions.GET_RESOURCES_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case resourcesActions.GET_RESOURCES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case resourcesActions.GET_RESOURCES_SIMPLE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case resourcesActions.GET_RESOURCES_SIMPLE_LIST_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case resourcesActions.GET_RESOURCES_SIMPLE_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    case resourcesActions.GET_RESOURCES_FOR_EVALUATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case resourcesActions.GET_RESOURCES_FOR_EVALUATIONS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case resourcesActions.GET_RESOURCES_FOR_EVALUATIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    /* get ALL SIMPLE LIST RESOURCES */
    case resourcesActions.GET_SIMPLE_LIST_ALL_RESOURCES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case resourcesActions.GET_SIMPLE_LIST_ALL_RESOURCES_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case resourcesActions.GET_SIMPLE_LIST_ALL_RESOURCES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case resourcesActions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
        selectedResource: getInitState().selectedResource,
      };

    case resourcesActions.UPDATE_FORM:
      const { column } = payload;
      return {
        ...state,
        selectedResource: {
          ...state.selectedResource,
          [column]: payload.value,
        },
      };

    /* get resource roles */

    case resourcesActions.GET_ROLES_REQUEST:
      return {
        ...state,
        loading: true
      }

    case resourcesActions.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles,
        loading: false
      }

    case resourcesActions.GET_ROLES_ERROR:
      return {
        ...state,
        loading: false
      }

    /* add resource */

    case resourcesActions.ADD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case resourcesActions.ADD_SUCCESS:
      return {
        ...state,
        isModalActive: false,
        resources: [
          state.resources,
          payload.resource
        ],
        selectedResource: {
          ...getInitState().selectedResource
        },
        loading: false
      }

    case resourcesActions.ADD_ERROR: {
      return{
        ...state,
        selectedResource: {
          ...getInitState().selectedResource
        },
        loading: false
      }
    }

    /* delete resource */

    case resourcesActions.DELETE_RESOURCE:
      return {
        ...state,
        loading: true
      };

    case resourcesActions.DELETE_RESOURCE_SUCCESS:
      return {
        ...state,
        resources: state.resources.filter(item => item.id !== action.payload.id),
        loading: false,
      }

    case resourcesActions.DELETE_RESOURCE_ERROR:
      return {
        ...state,
        loading: false
      }

    case resourcesActions.EXPORT_DATA:
      return {
        ...state,
      }

    //  GET Resource by type

    case resourcesActions.GET_BY_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case resourcesActions.GET_BY_TYPE_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case resourcesActions.GET_BY_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case resourcesActions.SHOW_DRAWER:
      return {
        ...state,
        showDrawer: true,
      };

    case resourcesActions.HIDE_DRAWER:
      return {
        ...state,
        showDrawer: false,
      };

    case resourcesActions.RESET_STATE:
      return {
        ...getInitState()
      };

    default:
      return state;
  }
}
