const resourcesHistoryActions = {

  GET_RESOURCES_HISTORY_REQUEST: `GET_RESOURCES_HISTORY_REQUEST`,
  GET_RESOURCES_HISTORY_SUCCESS: `GET_RESOURCES_HISTORY_SUCCESS`,
  GET_RESOURCES_HISTORY_ERROR: `GET_RESOURCES_HISTORY_ERROR`,

  DELETE_RESOURCES_HISTORY_REQUEST: `DELETE_RESOURCES_HISTORY_REQUEST`,
  DELETE_RESOURCES_HISTORY_SUCCESS: `DELETE_RESOURCES_HISTORY_SUCCESS`,
  DELETE_RESOURCES_HISTORY_ERROR: `DELETE_RESOURCES_HISTORY_ERROR`,

  UPDATE_RESOURCES_HISTORY_REQUEST: `UPDATE_RESOURCES_HISTORY_REQUEST`,
  UPDATE_RESOURCES_HISTORY_SUCCESS: `UPDATE_RESOURCES_HISTORY_SUCCESS`,
  UPDATE_RESOURCES_HISTORY_ERROR: `UPDATE_RESOURCES_HISTORY_ERROR`,

  CREATE_RESOURCE_HISTORY_REQUEST: `CREATE_RESOURCE_HISTORY_REQUEST`,
  CREATE_RESOURCE_HISTORY_SUCCESS: `CREATE_RESOURCE_HISTORY_SUCCESS`,
  CREATE_RESOURCE_HISTORY_ERROR: `CREATE_RESOURCE_HISTORY_ERROR`,

  EXPORT_DATA: `RESOURCES_HISTORY_EXPORT_DATA`,
  TOGGLE_MODAL: `RESOURCES_HISTORY_TOGGLE_MODAL`,
  RESET_RESOURCE_HISTORY_STATE: `RESET_RESOURCE_HISTORY_STATE`,

  getResourcesHistoryRequest(payload, ignoreSetUrl) {
    return {
      type: resourcesHistoryActions.GET_RESOURCES_HISTORY_REQUEST,
      payload,
      ignoreSetUrl
    };
  },
  getResourcesHistorySuccess(resourceProjectHistories, allResourcesByHistory, allProjectsByHistory) {
    return {
      type: resourcesHistoryActions.GET_RESOURCES_HISTORY_SUCCESS,
      payload: {
        resourceProjectHistories,
        allResourcesByHistory,
        allProjectsByHistory
      },
    };
  },
  getResourcesHistoryError(payload) {
    return {
      type: resourcesHistoryActions.GET_RESOURCES_HISTORY_ERROR,
      payload,
    };
  },

  deleteResourcesHistoryRequest: (id, resourceId) => ({
    type: resourcesHistoryActions.DELETE_RESOURCES_HISTORY_REQUEST,
    payload: { resourceId, id },
  }),
  deleteResourcesHistorySuccess: (id) => ({
      type: resourcesHistoryActions.DELETE_RESOURCES_HISTORY_SUCCESS,
      payload: { id },
  }),
  deleteResourcesHistoryError: (payload) => ({
    type: resourcesHistoryActions.DELETE_RESOURCES_HISTORY_SUCCESS,
    payload,
  }),

  updateResourcesHistoryRequest: (value, columnsKey, record, object) => ({
    type: resourcesHistoryActions.UPDATE_RESOURCES_HISTORY_REQUEST,
    payload: {value, columnsKey, record, object},
  }),
  updateResourcesHistorySuccess: payload => ({
    type: resourcesHistoryActions.UPDATE_RESOURCES_HISTORY_SUCCESS,
    payload,
  }),
  updateResourcesHistoryError: payload => ({
    type: resourcesHistoryActions.UPDATE_RESOURCES_HISTORY_ERROR,
    payload,
  }),

  createResourceHistoryRequest: (payload) => ({
    type: resourcesHistoryActions.CREATE_RESOURCE_HISTORY_REQUEST,
    payload,
  }),
  createResourceHistorySuccess: (payload) => ({
    type: resourcesHistoryActions.CREATE_RESOURCE_HISTORY_SUCCESS,
    payload,
  }),
  createResourceHistoryError: (payload) => ({
    type: resourcesHistoryActions.CREATE_RESOURCE_HISTORY_ERROR,
    payload,
  }),

  exportData: (payload) => ({
    type: resourcesHistoryActions.EXPORT_DATA,
    payload
  }),
  toggleModal: () => ({
    type: resourcesHistoryActions.TOGGLE_MODAL,
  }),

  resetResourcesHistoryState: () => ({
    type: resourcesHistoryActions.RESET_RESOURCE_HISTORY_STATE,
  }),

};

export default resourcesHistoryActions;
