export const FUTURE_PROJECT_ISSUE_SIDE = [
  {
    title: 'Our Side',
    value: 'our',
  },
  {
    title: 'Client Side',
    value: 'client',
  },
  {
    title: 'Done',
    value: 'done',
  },
];

export const TIERID = [
 1,2,3,4
]

export const ISSUE_SIDE_COLORS = {
  'our': '#EF5350',
  'client': '#EF881E',
  'done': '#46CB6E'
}

export const FUTURE_PROJECT_VACANCY_STATUS = {
  INITIAL: 1,
  POTENTIAL: 2,
  APPROVED: 3,
};

export const VACANCY_STATUSES = {
  1: 'Not-Determined',
  2: 'Not-Approved',
  3: 'Approved',
}

export const VACANCY_COLORS = {
  1: '#EF5350',
  2: '#EF881E',
  3: '#46CB6E'
}

export const FUTURE_PROJECT_VACANCY_STATUSES = [
  {
    title: 'Not-Determined',
    value: FUTURE_PROJECT_VACANCY_STATUS.INITIAL,
  },
  {
    title: 'Not-Approved',
    value: FUTURE_PROJECT_VACANCY_STATUS.POTENTIAL,
  },
  {
    title: 'Approved',
    value: FUTURE_PROJECT_VACANCY_STATUS.APPROVED,
  },
];

export const FUTURE_PROJECT_QUERY_TYPES = {
  BY_VACANCY: 'byVacancy',
};
