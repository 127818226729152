export const RECIPIENTS_TYPES = [
  {
    id: "QA",
    title: "QA",
  },
  {
    id: "Development",
    title: "Development",
  },
  {
    id: "Mobile",
    title: "Mobile",
  },
  {
    id: "Security",
    title: "Security",
  }
];

export const INDUSTRY_TYPES = [
  {
    id: "Ecommerce",
    title: "Ecommerce",
  },
  {
    id: "Education",
    title: "Education",
  },
  {
    id: "Finance",
    title: "Finance",
  },
  {
    id: "Logistics",
    title: "Logistics",
  },
  {
    id: "Manufacturing",
    title: "Manufacturing",
  },
  {
    id: "Media",
    title: "Media",
  },
  {
    id: "Medical",
    title: "Medical",
  },
  {
    id: "Not-for-Profit",
    title: "Not-for-Profit",
  },
  {
    id: "Software",
    title: "Software",
  },
  {
    id: "Start-up",
    title: "Start-up",
  },
];

export const EMAIL_RESULT_TYPE = [
  {
    id: "inactive",
    title: "inactive",
    color: "gold",
  },
  {
    id: "reply",
    title: "reply",
    color: "green",
  },
];
