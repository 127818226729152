import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import resourcesActions from 'redux/resources/tableResource/actions';
import { apiRequest } from 'redux/helpers';
import locationAction from 'redux/location/actions';
import technologiesActions from 'redux/technologies/actions';
import { ENDPOINTS } from 'consts';
import { createQueryString } from 'helpers/utility';
import openNotification from 'components/Notification';
import levelActions from 'redux/levels/actions';
import typeActions from 'redux/types/actions';
import Logger from 'library/logger';
import { setUrl, resolveImgUrl } from 'helpers/urlSync';
import tableActions from 'components/NewTable/redux/actions';
import { notification } from 'antd';

export function* getChangedResource() {
  yield takeEvery(resourcesActions.GET_CHANGED_RESOURCE_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.get, `${ENDPOINTS.RESOURCE_AND}${payload.id}`);
    data.avatar = data.avatar ? resolveImgUrl(data.avatar) : '';
    if (data.errors) {
      yield put(resourcesActions.getChangedResourceError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourcesActions.getChangedResourceSuccess(data));
    }
  });
}

export function* resourcesRequest() {
  yield takeEvery(resourcesActions.GET_RESOURCES_REQUEST, function* ({payload, ignoreSetUrl}) {
    const { pagination, sortValues, searchValue } = yield select(state => state.tableReducer);
    // eslint-disable-next-line max-len
    const searchValues = payload?.defaultFilters ? {...searchValue, ...payload.defaultFilters} : searchValue;
    const subParams = {};
    if(payload) {
      if(payload.qType)
        subParams.qType = payload.qType;
    }

    const queryUrl = `${ENDPOINTS.RESOURCE}?${createQueryString({
      pagination,
      multiSort: sortValues,
      fields: searchValues,
      ...subParams,}
    )}`;
    const resources = yield apiRequest(
      superFetch.get,
      queryUrl,
    );

    if (!ignoreSetUrl) {
      setUrl(queryUrl)
    }

    if (resources.errors) {
      yield put(resourcesActions.resourcesError(resources.errors));
      yield openNotification('error', 'Error', resources.errors);
    } else {
      yield put(resourcesActions.resourcesSuccess(
        {
          resources: resources.resources.map(e => ({ ...e, key: e.id })),
        },
      ));
      yield put(tableActions.setPagination(resources.pagination));
    }
  });
}

export function* getSimpleListAllResourcesRequest() {
  yield takeEvery(resourcesActions.GET_SIMPLE_LIST_ALL_RESOURCES_REQUEST, function* ({ payload }) {
    const resources = yield apiRequest(
      superFetch.get,
      `/resource/simpleList?limit=all&page=1&statusFilter=${payload?.hired},${payload?.terminated}`,
    );

    if (resources.errors) {
      yield put(resourcesActions.getSimpleListAllResourcesError(resources.errors));
      yield openNotification('error', 'Error', resources.errors);
    } else {
      yield put(resourcesActions.getSimpleListAllResourcesSuccess(
        {
          simpleListAllResources: resources.resources,
        },
      ));
    }
  });
}

export function* resourcesSimpleListRequest() {
  yield takeEvery(resourcesActions.GET_RESOURCES_SIMPLE_LIST_REQUEST, function* ({payload, ignoreSetUrl}) {
    const { pagination, sortValues, searchValue } = yield select(state => state.tableReducer);
    // eslint-disable-next-line max-len
    const searchValues = payload?.defaultFilters ? {...searchValue, ...payload.defaultFilters} : searchValue;
    const subParams = {};
    if(payload) {
      if(payload.qType)
        subParams.qType = payload.qType;
    }

    const queryUrl = `${ENDPOINTS.RESOURCE_SIMPLE_LIST}?${createQueryString({
      pagination,
      multiSort: sortValues,
      fields: searchValues,
      ...subParams,}
    )}`;
    const resources = yield apiRequest(
      superFetch.get,
      queryUrl,
    );

    if (!ignoreSetUrl) {
      setUrl(queryUrl)
    }

    if (resources.errors) {
      yield put(resourcesActions.resourcesSimpleListError(resources.errors));
      yield openNotification('error', 'Error', resources.errors);
    } else {
      yield put(resourcesActions.resourcesSimpleListSuccess(
        {
          resources: resources.resources.map(e => ({ ...e, key: e.id })),
        },
      ));
      yield put(tableActions.setPagination(resources.pagination));
    }
  });
}

export function* resourcesForEvaluationsRequest() {
  yield takeEvery(resourcesActions.GET_RESOURCES_FOR_EVALUATIONS_REQUEST, function* ({payload, ignoreSetUrl}) {
    const { sortValues, searchValue } = yield select(state => state.tableReducer);
    // eslint-disable-next-line max-len
    const searchValues = payload?.defaultFilters ? {...searchValue, ...payload.defaultFilters} : searchValue;
    const subParams = {};
    if(payload) {
      if(payload.qType)
        subParams.qType = payload.qType;
    }

    const queryUrl = `${ENDPOINTS.RESOURCE_FOR_EVALUATION}?${createQueryString({
      multiSort: sortValues,
      fields: searchValues,
      ...subParams,}
    )}`;
    const resources = yield apiRequest(
      superFetch.get,
      queryUrl,
    );

    if (!ignoreSetUrl) {
      setUrl(queryUrl)
    }

    if (resources.errors) {
      yield put(resourcesActions.resourcesForEvaluationsError(resources.errors));
      yield openNotification('error', 'Error', resources.errors);
    } else {
      yield put(resourcesActions.resourcesForEvaluationsSuccess(
        {
          resources: resources.resources.map(e => ({ ...e, key: e.id })),
        },
      ));
      yield put(tableActions.setPagination(resources.pagination));
    }
  });
}

export function* resourcesGetInitialState() {
  yield takeEvery(resourcesActions.GET_INITIAL_RESOURCE_STATE, function* () {
    const roles = yield apiRequest(superFetch.get, ENDPOINTS.RESOURCE_ROLES);
    if (roles.errors) {
      yield(resourcesActions.getResourceRolesERROR(roles.errors));
      yield openNotification('error', 'Error', roles.errors);
    } else {
      yield put({
        type: resourcesActions.GET_INITIAL_RESOURCE_STATE_SUCCESS,
        payload: {
          roles,
        },
      });

      const assignments = yield apiRequest(superFetch.get, `${ENDPOINTS.TYPE_ASSIGNMENT}`);
      if (assignments.error || assignments.errors) {
        yield put(typeActions.getAssignmentsError(assignments.errors));
        yield openNotification('error', 'Error', roles.errors);
      } else {
        yield put({
          type: typeActions.GET_ASSIGNMENT_SUCCESS,
          payload: assignments,
        });
      }

      const locations = yield apiRequest(superFetch.get, ENDPOINTS.LOCATION);
      const technologies = yield apiRequest(superFetch.get, `${ENDPOINTS.TECHNOLOGY}?limit=all`);
      if (locations.errors || technologies.errors) {
        if (locations.errors) {
          yield put(locationAction.getLocationError((locations.errors)));
        }
        yield openNotification('error', 'Error', locations.errors);
        if (technologies.errors) {
          yield put(technologiesActions.getTechnologiesError(technologies.errors));
        }
        yield openNotification('error', 'Error', technologies.errors);
      } else {
        yield put(locationAction.getLocationSuccess(locations));
        yield put(
          technologiesActions.getTechnologiesSuccess(
            technologies.technologies,
            technologies.pagination,
          ),
        );
      }

      const techLvl = yield apiRequest(superFetch.get, `${ENDPOINTS.TECH_LVL}?limit=all`);
      const englishLvl = yield apiRequest(superFetch.get, `${ENDPOINTS.ENG_LVL}?limit=all`);
      if (techLvl.errors || englishLvl.errors) {
        if (techLvl.errors) {
          yield openNotification('error', 'Error', techLvl.errors);
        } else {
          yield openNotification('error', 'Error', englishLvl.errors);
        }
      } else {
        yield put(levelActions.getTechLevelsSuccess(techLvl));
        yield put(levelActions.getEnglishLevelsSuccess(englishLvl));
      }
    }
  });
}

export function* createResourceRequest() {
  yield takeEvery(resourcesActions.ADD_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.post, `${ENDPOINTS.RESOURCE}`, payload);
    if (data.errors || data.error) {
      yield put({
        type: resourcesActions.ADD_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourcesActions.toggleModal())
      yield put({
        type: resourcesActions.GET_RESOURCES_REQUEST,
      });
    }
  });
}

export function* deleteResourceRequest() {
  yield takeEvery(resourcesActions.DELETE_RESOURCE_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.delete, `${ENDPOINTS.RESOURCE_AND}${payload.id}`);
    const resourcesState = yield select((state) => state.Resources);

    if (data.errors) {
      yield put({
        type: resourcesActions.DELETE_RESOURCE_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: resourcesActions.GET_RESOURCES_REQUEST,
        payload: {
          ...resourcesState,
          fields: resourcesState.searchValue,
        },
      });
    }
  });
}

export function* exportDataRequest() {
  yield takeEvery(resourcesActions.EXPORT_DATA, function* ({ payload }) {
    try {
      const queryParams = {};
      const { selectedColumns, sort, fields } = payload;

      if (selectedColumns) {
        let columns = [...selectedColumns];
        if (selectedColumns.find(item => item === 'name'))
          columns = ['firstName', 'lastName', ...selectedColumns.filter(item => item !== 'name')];
        queryParams.columns = { columns } || '';
      }

      notification.success({
        message: `Download file`,
        description:
          'Your will be downloaded in a few moments. Please wait.',
        placement: 'top',
        duration: 5,
      });

      const blob = yield apiRequest(
        superFetch.post,
        `${ENDPOINTS.RESOURCE_EXPORT}?${createQueryString({
          fields: fields || null,
          multiSort: sort || null,
          pagination: { pageSize: 0}
          })}`,
        queryParams.columns,
      );

      if (blob.errors) {
        yield openNotification('error', 'Error', blob.errors);

      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'report.xlsx';
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (e) {
      Logger().error(e);
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(getChangedResource),
    fork(resourcesRequest),
    fork(createResourceRequest),
    fork(deleteResourceRequest),
    fork(resourcesGetInitialState),
    fork(exportDataRequest),
    fork(getSimpleListAllResourcesRequest),
    fork(resourcesSimpleListRequest),
    fork(resourcesForEvaluationsRequest)
  ]);
}
