import usersActions from 'redux/users/actions';

export const USERS_PAGE_SIZE_LOCALSTORAGE = 'Users_PageSize';
export const PENDING_USERS_PAGE_SIZE_LOCALSTORAGE = 'PendingUsers_PageSize';

const getInitState = () => ({
  loading: false,
  users: [],
  roles: [],
  isModalActive: false,
  pendingUsers: {
    invites: [],
    pagination: {
      pageSize: +localStorage.getItem(PENDING_USERS_PAGE_SIZE_LOCALSTORAGE),
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
    },
  },
});

export default function usersReducer(state = getInitState(), action) {
  const { type, payload } = action;
  const { users, roles, sorter, pendingUsers } = payload || [];

  switch (type) {
    case usersActions.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case usersActions.GET_USERS_SUCCESS:
      return {
        ...state,
        users,
        roles,
        sorter,
        loading: false,
      };
    case usersActions.GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case usersActions.SET_INITIAL_USERS_STATE:
      return {
        ...state,
        ...payload,
        loading: true
      };

    /* update user reducer */

    case usersActions.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case usersActions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.map(item => item.id === payload.id ? payload : item),
      };

    case usersActions.UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    /* INVITE USER */

    case usersActions.INVITE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case usersActions.INVITE_USER_ERROR:
      return {
        ...state,
        loading: false
      };

    case usersActions.INVITE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isModalActive: false,
      };

    /* INVITE USER */

    case usersActions.COMPLETE_INVITE_REQUEST:
      return {
        ...state,
      };

    case usersActions.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case usersActions.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case usersActions.DELETE_USER_ERROR:
      return {
        ...state,
        error: payload,
      };
    case usersActions.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case usersActions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case usersActions.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case usersActions.TOGGLE_INVITE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
      };
    case usersActions.CHANGE_PENDING_USERS_PAGE:
      return {
        ...state,
        pendingUsers: {
          ...state.pendingUsers,
          pagination: {
            ...state.pendingUsers.pagination,
            currentPage: payload.page,
          },
        },
      };
    case usersActions.CHANGE_PENDING_USERS_PAGE_SIZE:
      if (action.payload.pageSize) {
        localStorage.setItem(PENDING_USERS_PAGE_SIZE_LOCALSTORAGE, action.payload.pageSize);
      } else {
        localStorage.removeItem(PENDING_USERS_PAGE_SIZE_LOCALSTORAGE);
      }
      return {
        ...state,
        pendingUsers: {
          ...state.pendingUsers,
          pagination: {
            ...state.pendingUsers.pagination,
            currentPage: 1,
            pageSize: payload.pageSize,
          },
        },
      };

    /* Pending Users */

    case usersActions.GET_PENDING_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case usersActions.GET_PENDING_USERS_SUCCESS:
      return {
        ...state,
        pendingUsers: {
          ...state.pendingUsers,
          invites: pendingUsers.invites,
          pagination: {
            pageSize: state.pendingUsers.pagination.pageSize,
            ...pendingUsers.pagination,
          },
        },
        loading: false,
      };
    case usersActions.GET_PENDING_USERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case usersActions.DELETE_PENDING_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case usersActions.DELETE_PENDING_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case usersActions.DELETE_PENDING_USERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case usersActions.RESET_STATE:
      return {
        ...getInitState()
      };

    default:
      return state;
  }
};
