import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from 'helpers/AsyncFunc';
import PropTypes from 'prop-types';
import { checkPermission } from 'helpers/utility';
import { CLAIM_TYPES } from 'consts';

const routes = [
  {
    path: 'roles',
    component: asyncComponent(() => import('containers/roleAccess')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_ROLE, claims),
  },
  {
    path: 'resources',
    component: asyncComponent(() => import('containers/Resources/resourcesPage')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_RESOURCE, claims),
  },
  {
    path: 'users',
    component: asyncComponent(() => import('containers/Users')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_USER, claims),
  },
  {
    path: 'profile',
    component: asyncComponent(() => import('containers/Profile')),
  },
  {
    path: 'clients',
    component: asyncComponent(() => import('containers/Clients/clientsPage')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_CLIENT, claims),
  },
  {
    path: 'settings/levels',
    component: asyncComponent(() => import('containers/Levels')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_LEVELS, claims),
  },
  {
    path: 'settings/technologies',
    component: asyncComponent(() => import('containers/Technologies')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_TECHNOLOGY, claims),
  },
  {
    path: 'settings/resource-roles',
    component: asyncComponent(() => import('containers/ResourceRoles')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_RESOURCE_ROLE, claims),
  },
  {
    path: 'settings/statuses',
    component: asyncComponent(() => import('containers/ProjectStatuses')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_PROJECT_STATUS, claims),
  },
  {
    path: 'projects/future',
    component: asyncComponent(() => import('containers/Projects/FuturePage')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_FUTURE_PROJECT, claims),
  },
  {
    path: 'projects/potential',
    component: asyncComponent(() => import('containers/Projects/PotentialPage')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_FUTURE_PROJECT, claims),
  },
  {
    path: 'projects/ongoing',
    component: asyncComponent(() => import('containers/Projects/OngoingPage')),
    onEnter: (claims) => checkPermission(CLAIM_TYPES.VIEW_ONGOING_PROJECT, claims)
  },
  {
    path: 'projects/timeline',
    component: asyncComponent(() => import('containers/Projects/TimelinePage')),
    onEnter: (claims) => checkPermission(CLAIM_TYPES.VIEW_TIMELINE_PROJECT, claims)
  },
  {
    path: 'projects',
    component: asyncComponent(() => import('containers/Projects/projectsPage')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_PROJECT, claims),
  },
  {
    path: 'marketing',
    component: asyncComponent(() => import('containers/Marketing/marketingPage')),
  },
  {
    path: 'marketing/email-templates',
    component: asyncComponent(() =>
      import('containers/Marketing/MarketingEmailTemplates/ListPage'),
    ),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_EMAIL_TEMPLATE, claims),
  },
  {
    path: 'marketing/senders',
    component: asyncComponent(() => import('containers/Marketing/Senders')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_AUTH_CREDENTIAL, claims),
  },
  {
    path: 'marketing/mailings',
    // eslint-disable-next-line max-len
    component: asyncComponent(() => import('containers/Marketing/Mailings/ListPage')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_MAILING, claims),
  },
  {
    path: 'marketing/groups',
    component: asyncComponent(() => import('containers/Marketing/MarketingGroup')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_GROUPS, claims),
  },
  {
    path: 'marketing/campaigns',
    component: asyncComponent(() => import('containers/Marketing/Campaigns')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_CAMPAIGN, claims),
  },
  {
    path: 'marketing/recipients',
    component: asyncComponent(() => import('containers/Marketing/Recipients')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_RECIPIENT, claims),
  },
  {
    path: 'actionLogs',
    component: asyncComponent(() => import('containers/ActionLog/actionLogPage')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_ACTION_LOGS, claims),
  },
  {
    path: 'no-permission',
    component: asyncComponent(() => import('containers/NoPermission')),
  },
  {
    path: 'dashboard',
    component: asyncComponent(() => import('containers/Dashboard')),
  },
  {
    path: 'view-documents',
    component: asyncComponent(() => import('containers/Documents/ViewDocuments')),
  },
  {
    path: 'edit-documents',
    component: asyncComponent(() => import('containers/Documents/EditDocuments')),
  },
  {
    path: 'vacations/settings',
    component: asyncComponent(() => import('containers/Vacations/SettingsVacations')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_VACATION_SETTINGS, claims),
  },
  {
    path: 'vacations',
    component: asyncComponent(() => import('containers/Vacations')),
    onEnter: claims => checkPermission(CLAIM_TYPES.VIEW_VACATION_PAGE, claims),
  },
];

export default function AppRouter(props) {
  const { url, style, claims } = props;

  return (
    <div className="scrollBox" style={{ overflowY: 'auto', height: '100%', ...style }}>
      <Switch>
        {routes.map(singleRoute => {
          const { path, exact, onEnter, component: Component, ...otherProps } = singleRoute;
          return (
            <Route
              {...otherProps}
              exact={exact}
              key={singleRoute.path}
              path={`${url}${singleRoute.path}`}
              render={props =>
                onEnter && !onEnter(claims) ? (
                  <Redirect to="/no-permission" />
                ) : (
                  <Component {...props} />
                )}
            />
          );
        })}
        <Redirect to="/dashboard" />
      </Switch>
    </div>
  );
}

AppRouter.propTypes = {
  style: PropTypes.object,
  url: PropTypes.string.isRequired,
  claims: PropTypes.array,
};

AppRouter.defaultProps = {
  style: null,
  claims: [],
};
