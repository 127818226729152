import tableActions from './actions';

const initialState = {
  searchValue: {},
  sortValues: [],
  pagination: {
    pageSize: 0,
    currentPage: 1,
    totalItems: 0,
  },
  tableCols: [],
  visibleColumns: [],
  isImportModalActive: false,
  lastModifiedDate: "",
  loadingLastModified: false,
};

export default function tableReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    /* GET LAST UPDATE */
    case tableActions.GET_LAST_MODIFIED_DATE_REQUEST:
      return {
        ...state,
        loadingLastModified: true,
      };
    case tableActions.GET_LAST_MODIFIED_DATE_SUCCESS:
      return {
        ...state,
        ...payload,
        loadingLastModified: false,
      };
    case tableActions.GET_LAST_MODIFIED_DATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case tableActions.SET_FILTERS_SUCCESS:
      return {
        ...state,
        searchValue: payload.searchValue,
        pagination: payload.pagination,
      };
    case tableActions.SET_COLUMNS_SUCCESS:
      return {
        ...state,
        tableCols: payload
      };
    case tableActions.SET_VISIBLE_COLUMNS_SUCCESS:
      return {
        ...state,
        visibleColumns: payload.visibleColumns,
        tableCols: payload.columns
      };

    case tableActions.SET_SORTERS_SUCCESS:
      return {
        ...state,
        sortValues: payload.sort
      };

    case tableActions.SET_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...payload
        }
      };

    case tableActions.SET_IMPORT_MODAL_STATE:
      return {
        ...state,
        isImportModalActive: payload
      };

    case tableActions.SET_INITIAL_STATE:
      return {
        ...state,
        ...payload,
      };

    case tableActions.RESET_STATE:
      return {
        ...initialState
      }

    default:
      return state
  }
}
