import vacationSettingsActions from 'redux/vacation/settings/actions';
import * as handlers from 'redux/vacation/settings/handlers';
import appAactions from '../../app/actions'

const receiversForm = {
  loading: false,
  toggleEdit: false,
};

const holidaysForm = {
  editedHolidays: {},
  loading: false,
  toggleEdit: false,
};

const initState = {
  loading: false,
  emailList: [],
  holidays: [],
  receiversForm,
  holidaysForm,
};

export default function vacationSettingsReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case vacationSettingsActions.GET_VACATION_RECEIVERS_REQUEST:
      return handlers.onlyLoadingTrue(state);

    case vacationSettingsActions.GET_VACATION_RECEIVERS_SUCCESS:
      return handlers.getVacationReceiversSuccess(state, payload);

    case vacationSettingsActions.GET_VACATION_RECEIVERS_ERROR:
      return handlers.onlyLoadingFalse(state);

    case vacationSettingsActions.GET_VACATION_HOLIDAYS_REQUEST:
      return handlers.onlyLoadingTrue(state);

    case vacationSettingsActions.GET_VACATION_HOLIDAYS_SUCCESS:
      return handlers.getVacationHolidaysSuccess(state, payload);

    case vacationSettingsActions.GET_VACATION_HOLIDAYS_ERROR:
      return handlers.onlyLoadingFalse(state);

    case vacationSettingsActions.CREATE_VACATION_HOLIDAYS_REQUEST:
      return handlers.onlyLoadingTrue(state);

    case vacationSettingsActions.CREATE_VACATION_HOLIDAYS_SUCCESS:
      return handlers.updateVacationHolidaysSuccess(state);

    case vacationSettingsActions.CREATE_VACATION_HOLIDAYS_ERROR:
      return handlers.onlyLoadingFalse(state);

    case vacationSettingsActions.UPDATE_VACATION_HOLIDAYS_REQUEST:
      return handlers.onlyLoadingTrue(state);

    case vacationSettingsActions.UPDATE_VACATION_HOLIDAYS_SUCCESS:
      return handlers.updateVacationHolidaysSuccess(state);

    case vacationSettingsActions.UPDATE_VACATION_HOLIDAYS_ERROR:
      return handlers.onlyLoadingFalse(state);

    case vacationSettingsActions.UPDATE_VACATION_RECEIVERS_REQUEST:
      return handlers.onlyLoadingTrue(state);

    case vacationSettingsActions.UPDATE_VACATION_RECEIVERS_SUCCESS:
      return handlers.updateVacationReceiversSuccess(state, payload);

    case vacationSettingsActions.UPDATE_VACATION_RECEIVERS_ERROR:
      return handlers.onlyLoadingFalse(state);

    case vacationSettingsActions.DELETE_VACATION_HOLIDAYS_REQUEST:
      return handlers.onlyLoadingTrue(state);

    case vacationSettingsActions.DELETE_VACATION_HOLIDAYS_SUCCESS:
      return handlers.deleteVacationHolidaysSuccess(state, payload);

    case vacationSettingsActions.DELETE_VACATION_HOLIDAYS_ERROR:
      return handlers.onlyLoadingFalse(state);

    case vacationSettingsActions.TOGGLE_EDIT_RECEIVERS:
      return handlers.toggleEditReceivers(state);

    case vacationSettingsActions.TOGGLE_EDIT_HOLIDAYS:
      return handlers.toggleEditHolidays(state);

    case vacationSettingsActions.SET_EDITED_HOLIDAY:
      return handlers.setEditedHoliday(state, payload);

    case appAactions.CHANGE_CURRENT:
      return handlers.onlyEditFalse(state);

    default:
      return state;
  }
}
