import actions from 'redux/clients/actions';

const initState = {
  isModalActive: false,
  loading: false,
  clients: [],
};

export default function ClientsReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_CLIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload.clients,
        loading: false,
      };
    case actions.GET_CLIENTS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_CLIENT_SUCCESS:
      state.clients.unshift(action.payload.client)
      return {
        ...state,
        clients: [
          ...state.clients,
        ],
        isModalActive: false,
        loading: false,
      };
    case actions.ADD_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state.clients.filter(item => item.id !== action.payload.id),
        loading: false,
      };
    case actions.DELETE_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
        selectedClient: initState.selectedClient,
      };
    default:
      return state;
  }
}
