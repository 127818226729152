import resourcesHistoryActions from 'redux/resources/resourсeHistory/actions';

const getInitState = () => ({
  isModalActive: false,
  loading: false,
  resourceProjectHistories: [],
  allResourcesByHistory: [],
  allProjectsByHistory: [],
});

export default function ResourcesHistoryReducer(state = getInitState(), action) {
  const { type, payload } = action;

  switch (type) {
    case resourcesHistoryActions.GET_RESOURCES_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case resourcesHistoryActions.GET_RESOURCES_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        resourceProjectHistories: action.payload.resourceProjectHistories,
        allResourcesByHistory: action.payload.allResourcesByHistory,
        allProjectsByHistory: action.payload.allProjectsByHistory,
      }
    case resourcesHistoryActions.GET_RESOURCES_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
      }

    case resourcesHistoryActions.DELETE_RESOURCES_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case resourcesHistoryActions.DELETE_RESOURCES_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        resourceProjectHistories: state.resourceProjectHistories.filter(elem =>
          elem.id !== payload.id),
      }
    case resourcesHistoryActions.DELETE_RESOURCES_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
      }

    case resourcesHistoryActions.UPDATE_RESOURCES_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case resourcesHistoryActions.UPDATE_RESOURCES_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case resourcesHistoryActions.UPDATE_RESOURCES_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
      }

    case resourcesHistoryActions.CREATE_RESOURCE_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case resourcesHistoryActions.CREATE_RESOURCE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        resourceProjectHistories: [
          ...state.resourceProjectHistories,
          ...payload,
        ],
      }
    case resourcesHistoryActions.CREATE_RESOURCE_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
      }

    case resourcesHistoryActions.EXPORT_DATA:
      return {
        ...state,
      }
    case resourcesHistoryActions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
      };

    case resourcesHistoryActions.RESET_RESOURCE_HISTORY_STATE:
      return {
        isModalActive: false,
        loading: false,
        resourceProjectHistories: [],
        allResourcesByHistory: [],
        allProjectsByHistory: [],
      };

    default:
      return state;
  }
}
