import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import profileActions from 'redux/profile/actions';
import { CONTENT_TYPES, ENDPOINTS } from 'consts';
import superFetch from 'helpers/superFetch';
import { resolveImgUrl } from 'helpers/urlSync';
import getDataAsUrl from 'helpers/fileReader';
import openNotification from 'components/Notification';
import { apiRequest } from 'redux/helpers';

function* getProfile() {
  yield takeEvery(profileActions.GET_REQUEST, function*() {
    const data = yield apiRequest(superFetch.get, ENDPOINTS.CURRENT_USER);
    if (data.errors) {
      yield put(profileActions.getError(data));
      yield openNotification('error', 'Error', data.errors);
    } else {
      if (!data) {
        return;
      }
      data.avatarUrl = data.avatarUrl ? resolveImgUrl(data.avatarUrl) : '';
      yield put(profileActions.getSuccess(data));
    }
  });
}

function* changePassword() {
  yield takeEvery(profileActions.CHANGE_PASSWORD_REQUEST, function*({ payload }) {
    const data = yield apiRequest(superFetch.patch, ENDPOINTS.CHANGE_PASSWORD, payload);
    if (data.errors) {
      yield put(profileActions.getError(data));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(profileActions.toggleModal());
      yield openNotification('success', 'Success', 'Your password has been changed');
    }
  });
}

function* updateProfile() {
  yield takeEvery(profileActions.UPDATE_PROFILE, function*({ payload }) {
    const { file } = yield select(state => state.Profile);
    const newPayload = { ...payload };
    if (file) {
      newPayload.avatar = file;
    }
    const data = yield apiRequest(
      superFetch.put,
      ENDPOINTS.CURRENT_USER,
      newPayload,
      CONTENT_TYPES.MULTIPART_DATA,
    );
    if (!data.errors) {
      if (newPayload.avatar) {
        data.avatarUrl = yield call(getDataAsUrl, newPayload.avatar);
      } else {
        data.avatarUrl = data.avatarUrl ? resolveImgUrl(data.avatarUrl) : '';
      }
      if (!file) {
        const {
          profile: { avatarUrl },
        } = yield select(state => state.Profile);
        data.avatarUrl = avatarUrl || '';
      }
      yield put(profileActions.updateProfileSuccess(data));

      yield put(profileActions.profileView());
    } else {
      yield put(profileActions.updateProfileError(data));
      yield openNotification('error', 'Error', data.errors);
    }
  });
}

function* uploadAvatar() {
  yield takeEvery(profileActions.UPLOAD_AVATAR, function*({ payload }) {
    const result = yield call(getDataAsUrl, payload.file);
    if (!result) {
      yield put(profileActions.uploadAvatarError());
    } else {
      yield put(
        profileActions.uploadAvatarSuccess({
          fileUrl: result,
          file: payload.file,
        }),
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getProfile), fork(updateProfile), fork(changePassword), fork(uploadAvatar)]);
}
