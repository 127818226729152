import { all, takeEvery, put, fork } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import { apiRequest } from 'redux/helpers';
import forgotPassActions from 'redux/forgotPassword/actions';
import { ENDPOINTS } from 'consts';
import openNotification from 'components/Notification';
import { push } from 'react-router-redux';

export function* forgotPassRequest() {
  yield takeEvery(forgotPassActions.FORGOT_PASS_REQUEST, function* ({ payload }) {
    const result = yield apiRequest(superFetch.post, `${ENDPOINTS.FORGOT_PASSWORD}`, payload);

    if (result.errors) {
      yield put(forgotPassActions.forgotPassError());
      yield openNotification('error', 'Error', result.errors);
    } else {
      yield put(forgotPassActions.forgotPassSuccess());
      yield openNotification('success', 'Success',
        'A password change link has been sent to your e-mail address.');
    }
  });
};

export function* completeResetPassword() {
  yield takeEvery(forgotPassActions.COMPLETE_RESET_PASSWORD_REQUEST, function* ({ payload }) {
    const { token, email, newPassword } = payload;
    const request = {
      token,
      email,
      password: newPassword,
    }
    const result = yield apiRequest(superFetch.post, `${ENDPOINTS.COMPLETE_RESET}`, request);

    if (result.errors) {
      yield put(forgotPassActions.completeResetPasswordError());
      yield openNotification('error', 'Error', result.errors);
    } else {
      yield put(forgotPassActions.completeResetPasswordSuccess());
      yield put(push('/signin'));
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(forgotPassRequest),
    fork(completeResetPassword),
  ]);
};
