import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Layout, Spin } from 'antd';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import DEVICE_VIEWS from 'consts/deviceTypes';
import profileActions from 'redux/profile/actions';
import authAction from 'redux/auth/actions';
import appActions from 'redux/app/actions';
import Sidebar from 'containers/Sidebar/Sidebar';
import { themeConfig } from 'settings';
import themes from 'settings/themes';
import PropTypes from 'prop-types';
import AppRouter from 'containers/App/AppRouter';
import AppHolder from 'containers/App/commonStyle';
import classNames from 'classnames';
import 'containers/App/global.css';

const { Content } = Layout;
const { logout, getUserDetails } = authAction;
const { getProfile } = profileActions;
const { toggleAll } = appActions;

export const App = props => {
  const {
    match,
    toggleAll,
    view,
    auth: {userDetails, fetched, collapsed}
  } = props;

  const [appHeight,setAppHeight] = useState(window.innerHeight);
  useEffect(() => {
    const { getProfile, getUserDetails } = props;
    getUserDetails();
    getProfile();
    window.addEventListener('resize', () => setAppHeight(window.innerHeight));
    return () => {
      window.removeEventListener('resize', () => setAppHeight(null));
    }
  },[]);

  return (
    <ThemeProvider theme={themes[themeConfig.theme]}>
      <AppHolder>
        <Layout style={{ height: appHeight }}>
          {fetched ? (
            <div>
              <Debounce time="1000" handler="onResize">
                <WindowResizeListener
                  onResize={
                    windowSize => toggleAll(windowSize.windowWidth, windowSize.windowHeight)
                  }
                />
              </Debounce>
              <Layout
                style={{
                  flexDirection: 'row',
                  overflow: 'hidden'
                }}
              >
                <Sidebar url={match.url} view={view} appHeight={appHeight} />
                <Layout
                  className={classNames('isoContentMainLayout', {
                    collapsedMainLayout: !collapsed,
                  })}
                  style={{
                    height: appHeight,
                  }}
                >
                  <Content
                    className="isomorphicContent"
                    style={{
                      marginTop: DEVICE_VIEWS.MOBILE_VIEW === view ? 70 : 0,
                      padding: '0 0 0 0',
                      flexShrink: '0',
                      background: '#f1f3f6',
                      position: 'relative',
                      height: DEVICE_VIEWS.MOBILE_VIEW === view ? 'calc(100% - 70px)' : '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <AppRouter url={match.url} claims={userDetails.claims} />
                  </Content>
                </Layout>
              </Layout>
            </div>
          ) : (
            <div className="app-spin-wrapper">
              <Spin />
            </div>
          )}
        </Layout>
      </AppHolder>
    </ThemeProvider>
  );
}

export default connect(
  state => ({
    auth: state.Auth,
    collapsed: state.AppReducer.collapsed,
    view: state.AppReducer.view,
  }),
  { logout, toggleAll, getProfile, getUserDetails },
)(App);

App.propTypes = {
  match: PropTypes.object.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  toggleAll: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  getProfile: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    userDetails: PropTypes.shape({
      claims: PropTypes.array,
    }).isRequired,
    fetched: PropTypes.bool.isRequired,
    collapsed: PropTypes.bool.isRequired,
  }).isRequired,
};
