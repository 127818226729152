import marketingCampaignsActions from 'redux/marketing/marketingCampaigns/actions';

const initState = {
  campaigns: [],
  loading: false,
  isModalActive: false,
};

export default function marketingCampaignsReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case marketingCampaignsActions.GET_CAMPAIGNS_STATE:
      return {
        ...state,
        loading: true,
      };

    case marketingCampaignsActions.SET_CAMPAIGNS_STATE:
      return {
        ...state,
        campaigns: [...payload.campaigns],
        loading: false,
      };

    case marketingCampaignsActions.ERROR_CAMPAIGNS_STATE:
      return {
        ...state,
        loading: false,
      };

    case marketingCampaignsActions.ADD_CAMPAIGN:
      return {
        ...state,
        loading: true,
      };

    case marketingCampaignsActions.ADD_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, payload],
        isModalActive: false,
        loading: false,
      };

    case marketingCampaignsActions.UPDATE_CAMPAIGN:
      return {
        ...state,
        loading: false,
      };

    case marketingCampaignsActions.DELETE_CAMPAIGN:
      return {
        ...state,
        loading: true,
      };

    case marketingCampaignsActions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
      };

    default:
      return state;
  }
}
