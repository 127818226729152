import { all, put, takeEvery } from 'redux-saga/effects';
import { ENDPOINTS } from 'consts';
import superFetch from 'helpers/superFetch';
import marketingGroupsActions from 'redux/marketing/marketingGroups/actions';
import { apiRequest } from 'redux/helpers';
import openNotification from 'components/Notification';

export function* getGroupsState() {
  yield takeEvery(marketingGroupsActions.GET_GROUPS_STATE, function* () {
    const groups = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.MARKETING_GROUP}`,
    );

    if (groups.errors) {
      yield put(marketingGroupsActions.errorGroupsState(groups.errors));
      yield openNotification('error', 'Error',  groups.errors);
    } else {
      yield put(marketingGroupsActions.setGroupsState({
        groups
      }));
    }
  })
}

function* updateGroup() {
  yield takeEvery(marketingGroupsActions.UPDATE_GROUP, function* ({ payload }) {
    const { id } = payload;
    const groups = yield apiRequest(superFetch.put,
      `${ENDPOINTS.MARKETING_GROUP_AND}${id}`, payload);
    if (groups.errors) {
      yield put(marketingGroupsActions.errorGroupsState(groups.errors));
      yield openNotification('error', 'Error', groups.errors);
    } else {
      yield put(marketingGroupsActions.getGroupsState());
    }
  });
}

function* deleteGroup() {
  yield takeEvery(marketingGroupsActions.DELETE_GROUP, function* ({ payload }) {
    const groups = yield apiRequest(superFetch.delete,
      `${ENDPOINTS.MARKETING_GROUP_AND}${payload}`
    );
    if (groups.errors) {
      yield put(marketingGroupsActions.errorGroupsState(groups.errors));
      yield openNotification('error', 'Error', groups.errors);
    } else {
      yield put(marketingGroupsActions.getGroupsState());
    }
  });
}

function* addGroup() {
  yield takeEvery(marketingGroupsActions.ADD_GROUP, function* ({ payload }) {
    const groups = yield apiRequest(superFetch.post,
      `${ENDPOINTS.MARKETING_GROUP}`, payload
    );
    if (groups.errors) {
      yield put(marketingGroupsActions.errorGroupsState(groups.errors));
      yield openNotification('error', 'Error', groups.errors);
    } else {
      yield put(marketingGroupsActions.addGroupSuccess(groups));
    }
  });
}

export default function* rootSaga() {
  yield all([
    getGroupsState(),
    updateGroup(),
    deleteGroup(),
    addGroup()
  ]);
}
