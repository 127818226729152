import { notification } from 'antd';

const openNotification = (type = '', title = '', description = '', duration = 3, other) => {
  const descriptionRes = Array.isArray(description) ? description.join('. ') : description;

  notification[type]({
    message: title,
    description: descriptionRes, 
    duration,
    ...other,
  });
};

export default openNotification;