const DOCUMENT = 'RESOURCES_';
const resourcesActions = {
  GET_INITIAL_RESOURCE_STATE: `GET_INITIAL_${DOCUMENT}STATE`,
  GET_INITIAL_RESOURCE_STATE_SUCCESS: `GET_INITIAL_${DOCUMENT}STATE_SUCCESS`,
  GET_INITIAL_RESOURCE_STATE_ERROR: `GET_INITIAL_${DOCUMENT}STATE_ERROR`,

  SET_INITIAL_RESOURCE_STATE: `SET_INITIAL_${DOCUMENT}STATE`,

  GET_RESOURCES_REQUEST: `GET_${DOCUMENT}REQUEST`,
  GET_RESOURCES_SUCCESS: `GET_${DOCUMENT}SUCCESS`,
  GET_RESOURCES_ERROR: `GET_${DOCUMENT}ERROR`,
  /* get Resources */

  GET_RESOURCES_SIMPLE_LIST_REQUEST: `GET_${DOCUMENT}SIMPLE_LIST_REQUEST`,
  GET_RESOURCES_SIMPLE_LIST_SUCCESS: `GET_${DOCUMENT}SIMPLE_LIST_SUCCESS`,
  GET_RESOURCES_SIMPLE_LIST_ERROR: `GET_${DOCUMENT}SIMPLE_LIST_ERROR`,

  GET_RESOURCES_FOR_EVALUATIONS_REQUEST: `GET_${DOCUMENT}FOR_EVALUATIONS_REQUEST`,
  GET_RESOURCES_FOR_EVALUATIONS_SUCCESS: `GET_${DOCUMENT}FOR_EVALUATIONS_SUCCESS`,
  GET_RESOURCES_FOR_EVALUATIONS_ERROR: `GET_${DOCUMENT}FOR_EVALUATIONS_ERROR`,

  GET_SIMPLE_LIST_ALL_RESOURCES_REQUEST: `GET_SIMPLE_LIST_ALL_${DOCUMENT}REQUEST`,
  GET_SIMPLE_LIST_ALL_RESOURCES_SUCCESS: `GET_SIMPLE_LIST_ALL_${DOCUMENT}SUCCESS`,
  GET_SIMPLE_LIST_ALL_RESOURCES_ERROR: `GET_SIMPLE_LIST_ALL_${DOCUMENT}ERROR`,

  GET_CHANGED_RESOURCE_REQUEST: `${DOCUMENT}RESOURCE_GET_REQUEST`,
  GET_CHANGED_RESOURCE_SUCCESS: `${DOCUMENT}RESOURCE_GET_SUCCESS`,
  GET_CHANGED_RESOURCE_ERROR: `${DOCUMENT}RESOURCE_GET_ERROR`,

  GET_ROLES_REQUEST: `GET_${DOCUMENT}ROLES_REQUEST`,
  GET_ROLES_SUCCESS: `GET_${DOCUMENT}ROLES_SUCCESS`,
  GET_ROLES_ERROR: `GET_${DOCUMENT}ROLES_ERROR`,

  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,
  UPDATE_FORM: `${DOCUMENT}UPDATE_FORM`,

  ADD_REQUEST: `ADD_${DOCUMENT}REQUEST`,
  ADD_SUCCESS: `ADD_${DOCUMENT}SUCCESS`,
  ADD_ERROR: `ADD_${DOCUMENT}ERROR`,

  DELETE_RESOURCE_REQUEST: `${DOCUMENT}DELETE_RESOURCE_REQUEST`,
  DELETE_RESOURCE_SUCCESS: `${DOCUMENT}DELETE_RESOURCE_SUCCESS`,
  DELETE_RESOURCE_ERROR: `${DOCUMENT}DELETE_RESOURCE_ERROR`,

  EXPORT_DATA: `${DOCUMENT}EXPORT_DATA`,

  SHOW_DRAWER: `${DOCUMENT}SHOW_DRAWER`,
  HIDE_DRAWER: `${DOCUMENT}HIDE_DRAWER`,

  RESET_STATE: `${DOCUMENT}RESET_STATE`,

  getInitialResourceState: payload => ({
    type: resourcesActions.GET_INITIAL_RESOURCE_STATE,
    payload,
  }),

  getInitialResourceStateSuccess: (newState) => ({
    type: resourcesActions.GET_INITIAL_RESOURCE_STATE_SUCCESS,
    payload: {
      newState
    }
  }),

  getInitialResourceStateError: () => ({
    type: resourcesActions.GET_INITIAL_RESOURCE_STATE_ERROR
  }),

  setInitialResourceState: payload => ({
    type: resourcesActions.SET_INITIAL_RESOURCE_STATE,
    payload,
  }),


/* get Changed Resource */

  getChangedResource: (id) => ({
    type: resourcesActions.GET_CHANGED_RESOURCE_REQUEST,
    payload : { id }
  }),

  getChangedResourceSuccess: (payload) => ({
    type: resourcesActions.GET_CHANGED_RESOURCE_SUCCESS,
    payload
  }),

  getChangedResourceError: error => ({
    type: resourcesActions.GET_CHANGED_RESOURCE_ERROR,
    payload: {
      error,
    },
  }),

  /* get Resources */

  resourcesRequest: (payload, ignoreSetUrl) => ({
    type: resourcesActions.GET_RESOURCES_REQUEST,
    payload,
    ignoreSetUrl
  }),

  resourcesSuccess: (payload) => ({
    type: resourcesActions.GET_RESOURCES_SUCCESS,
    payload
  }),

  resourcesError: error => ({
    type: resourcesActions.GET_RESOURCES_ERROR,
    payload: {
      error,
    },
  }),

  resourcesSimpleListRequest: (payload, ignoreSetUrl) => ({
    type: resourcesActions.GET_RESOURCES_SIMPLE_LIST_REQUEST,
    payload,
    ignoreSetUrl
  }),

  resourcesSimpleListSuccess: (payload) => ({
    type: resourcesActions.GET_RESOURCES_SIMPLE_LIST_SUCCESS,
    payload
  }),

  resourcesSimpleListError: error => ({
    type: resourcesActions.GET_RESOURCES_SIMPLE_LIST_ERROR,
    payload: {
      error,
    },
  }),

  resourcesForEvaluationsRequest: (payload, ignoreSetUrl) => ({
    type: resourcesActions.GET_RESOURCES_FOR_EVALUATIONS_REQUEST,
    payload,
    ignoreSetUrl
  }),

  resourcesForEvaluationsSuccess: (payload) => ({
    type: resourcesActions.GET_RESOURCES_FOR_EVALUATIONS_SUCCESS,
    payload
  }),

  resourcesForEvaluationsError: error => ({
    type: resourcesActions.GET_RESOURCES_FOR_EVALUATIONS_ERROR,
    payload: {
      error,
    },
  }),

  /* get All SIMPLE LIST Resources */
  getSimpleListAllResourcesRequest: (payload) => ({
    type: resourcesActions.GET_SIMPLE_LIST_ALL_RESOURCES_REQUEST,
    payload
  }),

  getSimpleListAllResourcesSuccess: (payload) => ({
    type: resourcesActions.GET_SIMPLE_LIST_ALL_RESOURCES_SUCCESS,
    payload
  }),

  getSimpleListAllResourcesError: error => ({
    type: resourcesActions.GET_SIMPLE_LIST_ALL_RESOURCES_ERROR,
    payload: {
      error,
    },
  }),

  toggleModal: () => ({
    type: resourcesActions.TOGGLE_MODAL,
  }),

  updateForm: (column, value) => ({
    type: resourcesActions.UPDATE_FORM,
    payload: { column, value },
  }),

  /* GET resource Roles */

  getResourceRoles: () => ({
    type: resourcesActions.GET_ROLES_REQUEST,
  }),

  getResourceRolesSuccuss: () => ({
    type: resourcesActions.GET_ROLES_SUCCESS,
  }),

  getResourceRolesERROR: () => ({
    type: resourcesActions.GET_ROLES_ERROR,
  }),

  /* Create Resource */

  createResourceRequest: resource => ({
    type: resourcesActions.ADD_REQUEST,
    payload:  resource,
  }),

  createResourceSuccess: resource => ({
    type: resourcesActions.ADD_SUCCESS,
    payload: { resource },
  }),

  createResourceError: error => ({
    type: resourcesActions.ADD_ERROR,
    payload: error,
  }),

  /* delete Resource */

  deleteResource: id => ({
    type: resourcesActions.DELETE_RESOURCE_REQUEST,
    payload: {
      id
    },
  }),

  deleteResourceSuccess: id => ({
    type: resourcesActions.DELETE_RESOURCE_SUCCESS,
    payload: {
      id
    },
  }),

  deleteResourceError: () => ({
    type: resourcesActions.DELETE_RESOURCE_ERROR,
  }),

  exportData: (payload) => ({
    type: resourcesActions.EXPORT_DATA,
    payload
  }),

//  Get Resource by type

  showDrawer: ()=>({
    type: resourcesActions.SHOW_DRAWER,
  }),

  hideDrawer: ()=>({
    type: resourcesActions.HIDE_DRAWER,
  }),

  resetState: () => ({
    type: resourcesActions.RESET_STATE,
  }),

};
export default resourcesActions;
