import { all, takeEvery, put, fork, select, debounce } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import resourceByTypeActions from 'redux/resources/resourcesByType/actions';
import { apiRequest } from 'redux/helpers';
import locationAction from 'redux/location/actions';
import technologiesActions from 'redux/technologies/actions';
import { ENDPOINTS } from 'consts';
import { createQueryString } from 'helpers/utility';
import openNotification from 'components/Notification';
import levelActions from 'redux/levels/actions';
import typeActions from 'redux/types/actions';
import { setUrl } from 'helpers/urlSync';

export function* resourcesByTypeRequest() {
  yield takeEvery(resourceByTypeActions.GET_BY_TYPE_REQUEST, function* ({ payload }) {

    const { searchValue } = yield select(state => state.ResourcesByType);
    const queryUrl = `${ENDPOINTS.RESOURCES_BY_ASSIGNMENT_TYPE}?${createQueryString({
      pagination: {pageSize: 0,},
      fields: searchValue,
    })}`;
    const resources = yield apiRequest(superFetch.get, queryUrl);

    setUrl(queryUrl);

    if (resources.errors) {
      yield put(resourceByTypeActions
        .resourcesByTypeError(resources.errors));
      yield openNotification('error', 'Error', resources.errors);
    } else {
      const filters = payload && payload.filters ? payload.filters : null;
      yield put(resourceByTypeActions.resourcesByTypeSuccess(
        {
          resources: resources.resources,
          filters,
        },
      ));
    }
  });
}

export function* updateByTypeFilter() {
  yield debounce(800, resourceByTypeActions.UPDATE_BY_TYPE_FILTERS, function* ({ payload }) {
    const { fields } = payload;
    yield put(resourceByTypeActions.resourcesByTypeRequest({
      fields,
    }));
  });
}

export function* resourcesByTypeInitialRequest() {
  yield takeEvery(resourceByTypeActions.GET_INITIAL_BY_TYPE_REQUEST, function* () {
    const { searchValue } = yield select(state => state.ResourcesByType);
    const queryUrl = `${ENDPOINTS.RESOURCE}?${createQueryString({
      pagination: {pageSize: 0,},
      groupBy: 'assignmentType',
      fields: searchValue,
    })}`;
    const resources = yield apiRequest(superFetch.get, queryUrl);
    const roles = yield apiRequest(superFetch.get, ENDPOINTS.RESOURCE_ROLES);

    setUrl(queryUrl);

    if (resources.errors || roles.errors) {
      if (resources.errors) {
        yield(resourceByTypeActions.getInitialResourceByTypeStateError(resources.errors));
        yield openNotification('error', 'Error', resources.errors);
      } else {
        yield(resourceByTypeActions.getInitialResourceByTypeStateError(roles.errors));
        yield openNotification('error', 'Error', roles.errors);

      }
    } else {
      yield put(resourceByTypeActions.getInitialResourceByTypeStateSuccess(
        {
          resources: resources.resources,
          pagination: resources.pagination,
          roles,
        },
      ));
    }

    const assignments = yield apiRequest(superFetch.get, `${ENDPOINTS.TYPE_ASSIGNMENT}`)

    if (assignments.errors) {
      yield put(typeActions.getAssignmentsError(assignments.errors));
      yield openNotification('error', 'Error', assignments.errors);
    } else {
      yield put({
        type: typeActions.GET_ASSIGNMENT_SUCCESS,
        payload: assignments,
      });
    }


    const locations = yield apiRequest(superFetch.get, ENDPOINTS.LOCATION);
    const technologies = yield apiRequest(superFetch.get, `${ENDPOINTS.TECHNOLOGY}?limit=all`);

    if (technologies.errors) {
      if (locations.errors) {
        yield put(locationAction.getLocationError((locations.errors)));
      }
      yield openNotification('error', 'Error', locations.errors);
      if (technologies.errors) {
        yield put(technologiesActions.getTechnologiesError(technologies.errors));
      }
      yield openNotification('error', 'Error', technologies.errors);
    } else {
      yield put(locationAction.getLocationSuccess(locations));
      yield put(technologiesActions.getTechnologiesSuccess(
        technologies.technologies,
        technologies.pagination
      ));
    }

    const techLvl = yield apiRequest(superFetch.get, `${ENDPOINTS.TECH_LVL}?limit=all`);
    const englishLvl = yield apiRequest(superFetch.get, `${ENDPOINTS.ENG_LVL}?limit=all`);

    if (techLvl.errors) {
      if (techLvl.errors) {
        yield openNotification('error', 'Error', techLvl.errors);
      } else {
        yield openNotification('error', 'Error', englishLvl.errors);
      }
    } else {
      yield put(levelActions.getTechLevelsSuccess(techLvl));
      yield put(levelActions.getEnglishLevelsSuccess(englishLvl));
    }
  });
}

export function* resourcesUpdateAssignmentRequest() {
  yield takeEvery(resourceByTypeActions.UPDATE_ASSIGNMENT_TYPE_REQUEST,
    function* ({ payload }) {

    const newResource = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.RESOURCE}/${payload.id}/updateAssignType`,
      {
        assignmentTypeId: payload.assignmentTypeId,
        assignmentProjectId: payload.assignmentProjectId || null,
      }
    );
    const resources =  yield select(state => state.ResourcesByType);

    if (newResource.errors) {
      yield put(resourceByTypeActions
        .resourcesUpdateAssignmentError(newResource.errors));
      yield openNotification('error', 'Error', newResource.errors);
    }else{
      yield put({
        type: resourceByTypeActions.GET_BY_TYPE_REQUEST,
        payload: {
          fields: resources.searchValue,
        }
      })
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(resourcesByTypeRequest),
    fork(resourcesByTypeInitialRequest),
    fork(updateByTypeFilter),
    fork(resourcesUpdateAssignmentRequest),
  ]);
}
