import documentsActions from './actions';
import { getUpdated } from '../helpers/projectFlag';

const getInitState = () => ({
  loading: false,
  documents: [],
  roles: [],
  searchValue: {},
});

export default function DocumentsReducer (state = getInitState(), action) {
  switch (action.type) {
    case documentsActions.GET_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case documentsActions.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.payload.items,
        roles: action.payload.roles,
      }
    case documentsActions.GET_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
      }

      case documentsActions.DELETE_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case documentsActions.DELETE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: state.documents.filter(elem => elem.id !== action.payload.id),
      }
    case documentsActions.DELETE_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
      }

      case documentsActions.CREATE_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case documentsActions.CREATE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: [action.payload, ...state.documents],
      }
    case documentsActions.CREATE_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
      }
      case documentsActions.UPDATE_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case documentsActions.UPDATE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: getUpdated(state.documents, action.payload),
      }
    case documentsActions.UPDATE_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
      }

    case documentsActions.UPDATE_BY_TITLE_FILTER:
      const { type, value } = action.payload.fields;
      const searchValueCopy = { ...state.searchValue };

      if (value.length !== 0) {
        searchValueCopy[type] = value;
      } else {
        delete searchValueCopy[type];
      }
      return {
        ...state,
        loading: true,
        searchValue: searchValueCopy,
      }
    case documentsActions.UPDATE_BY_TITLE_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        /*searchValue: {
          ...action.payload.fields,
        },*/
      }
    case documentsActions.UPDATE_BY_TITLE_FILTER_ERROR:
      return {
        ...state,
        loading: false,
      }

    case documentsActions.RESET_VIEW_DOCUMENTS_STATE:
      return {
        ...getInitState()
      }

    default:
      return state
  }
}
