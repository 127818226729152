import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { LastModifiedDateWrapper } from './LastModifiedDate.style';

const LastModifiedDate = ({ lastModifiedDate, loadingLastModified }) => {
  return (
      loadingLastModified ? (
        <Spin size='small' />
        ) : (
          <LastModifiedDateWrapper>
            <div className='text'>
              {lastModifiedDate
                && `Last Updated: ${moment(lastModifiedDate).format('DD.MM.YYYY')}`}
            </div>
          </LastModifiedDateWrapper>
        )
  );
};

LastModifiedDate.propTypes = {
  lastModifiedDate: PropTypes.string,
  loadingLastModified: PropTypes.bool.isRequired,
};

LastModifiedDate.defaultProps = {
  lastModifiedDate: '',
};

const mapStateToProps = state => {
  const { lastModifiedDate, loadingLastModified } = state.tableReducer;
  return {
    loadingLastModified,
    lastModifiedDate,
  };
};

export default connect(mapStateToProps, null)(LastModifiedDate);
