import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import tableActions from 'components/NewTable/redux/actions';
import { setUrl } from 'helpers/urlSync';
import { apiRequest } from 'redux/helpers';
import superFetch from 'helpers/superFetch';
import { ENDPOINTS } from 'consts';
import { createQueryString } from 'helpers/utility';
import openNotification from 'components/Notification';
import actions from './actions';

export function* getLogsRequest() {
  yield takeEvery(actions.GET_ACTION_LOGS_REQUEST, function* () {
    const { pagination, sortValues, searchValue } = yield select(state => state.tableReducer);

    const queryUrl = `${ENDPOINTS.LOGS}?${createQueryString({
      pagination,
      multiSort: sortValues,
      fields: searchValue,
    })}`;
    const data = yield apiRequest(superFetch.get, queryUrl);

    setUrl(queryUrl);

    if (data.errors) {
      yield put(actions.getActionLogsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getActionLogsSuccess(data.items, data.pagination));
      yield put(tableActions.setPagination(data.pagination));
    }
  });
}

export function* getLogDetailsRequest() {
  yield takeEvery(actions.GET_LOG_DETAILS_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.LOGS}/${payload}`,
    );

    if (data.errors) {
      yield put(actions.getActionLogsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getLogDetailsSuccess(data));
    }
  });
}

export function* getLogEntityTypesRequest() {
  yield takeEvery(actions.GET_LOG_ENTITY_TYPES_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, ENDPOINTS.LOGS_ENTITY_TYPES);

    if (data.errors) {
      yield put(actions.getLogEntityTypesError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {

      yield put(actions.getLogEntityTypesSuccess(data));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getLogsRequest),
    fork(getLogDetailsRequest),
    fork(getLogEntityTypesRequest),
  ]);
}
