const DOCUMENT = 'TECHNOLOGIES_';

const actions = {
  GET_TECHNOLOGY_TYPES_REQUEST: 'GET_TECHNOLOGY_TYPES_REQUEST',
  GET_TECHNOLOGY_TYPES_SUCCESS: 'GET_TECHNOLOGY_TYPES_SUCCESS',
  GET_TECHNOLOGY_TYPES_ERROR: 'GET_TECHNOLOGY_TYPES_ERROR',
  GET_TECHNOLOGIES_REQUEST: 'GET_TECHNOLOGIES_REQUEST',
  GET_TECHNOLOGIES_SUCCESS: 'GET_TECHNOLOGIES_SUCCESS',
  GET_TECHNOLOGIES_ERROR: 'GET_TECHNOLOGIES_ERROR',
  SET_INITIAL_TECHNOLOGIES_STATE: 'SET_INITIAL_TECHNOLOGIES_STATE',
  ADD_TECHNOLOGY_REQUEST: 'ADD_TECHNOLOGY_REQUEST',
  ADD_TECHNOLOGY_SUCCESS: 'ADD_TECHNOLOGY_SUCCESS',
  ADD_TECHNOLOGY_ERROR: 'ADD_TECHNOLOGY_ERROR',
  UPDATE_TECHNOLOGY_REQUEST: 'UPDATE_TECHNOLOGY_REQUEST',
  UPDATE_TECHNOLOGY_SUCCESS: 'UPDATE_TECHNOLOGY_SUCCESS',
  UPDATE_TECHNOLOGY_ERROR: 'UPDATE_TECHNOLOGY_ERROR',
  DELETE_TECHNOLOGY_REQUEST: 'DELETE_TECHNOLOGY_REQUEST',
  DELETE_TECHNOLOGY_SUCCESS: 'DELETE_TECHNOLOGY_SUCCESS',
  DELETE_TECHNOLOGY_ERROR: 'DELETE_TECHNOLOGY_ERROR',

  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,

  getTechnologyTypesRequest: () => ({
    type: actions.GET_TECHNOLOGY_TYPES_REQUEST,
  }),
  getTechnologyTypesSuccess: (technologyTypes) => ({
    type: actions.GET_TECHNOLOGY_TYPES_SUCCESS,
    payload: { technologyTypes },
  }),
  getTechnologyTypesError: (error) => ({
    type: actions.GET_TECHNOLOGY_TYPES_ERROR,
    payload: { error },
  }),
  getTechnologiesRequest: (options = {}) => ({
    type: actions.GET_TECHNOLOGIES_REQUEST,
    payload: options,
  }),
  getTechnologiesSuccess: (technologies, pagination) => ({
    type: actions.GET_TECHNOLOGIES_SUCCESS,
    payload: { technologies, pagination },
  }),
  getTechnologiesError: (error) => ({
    type: actions.GET_TECHNOLOGIES_ERROR,
    payload: { error },
  }),
  setInitialTechnologiesState: (payload) => ({
    type: actions.SET_INITIAL_TECHNOLOGIES_STATE,
    payload,
  }),
  addTechnologyRequest: (technology) => ({
    type: actions.ADD_TECHNOLOGY_REQUEST,
    payload: {
      title: technology.title,
      abbreviation: technology.abbreviation,
      technologyTypeId: technology.type,
    },
  }),
  addTechnologySuccess: (technology) => ({
    type: actions.ADD_TECHNOLOGY_SUCCESS,
    payload: { technology },
  }),
  addTechnologyError: (error) => ({
    type: actions.ADD_TECHNOLOGY_ERROR,
    payload: { error },
  }),
  updateTechnologyRequest: (payload) => ({
    type: actions.UPDATE_TECHNOLOGY_REQUEST,
    payload,
  }),
  updateTechnologySuccess: (technology) => ({
    type: actions.UPDATE_TECHNOLOGY_SUCCESS,
    payload: { technology },
  }),
  updateTechnologyError: (error) => ({
    type: actions.UPDATE_TECHNOLOGY_ERROR,
    payload: { error },
  }),
  deleteTechnologyRequest: (technologyId) => ({
    type: actions.DELETE_TECHNOLOGY_REQUEST,
    payload: { technologyId },
  }),
  deleteTechnologySuccess: (technologyId) => ({
    type: actions.DELETE_TECHNOLOGY_SUCCESS,
    payload: { technologyId },
  }),
  deleteTechnologyError: (error) => ({
    type: actions.DELETE_TECHNOLOGY_ERROR,
    payload: { error },
  }),

  toggleModal: (technology) => ({
    type: actions.TOGGLE_MODAL,
    payload: { technology },
  }),
};

export default actions;
