const DOCUMENT = 'RESOURCE_';

const resourceActions = {
  SUBMIT_UPDATES: `${DOCUMENT}SUBMIT_UPDATES`,

  GET_INITIAL_REQUEST: `${DOCUMENT}GET_INITIAL_REQUEST`,
  GET_INITIAL_SUCCESS: `${DOCUMENT}GET_INITIAL_SUCCESS`,
  GET_INITIAL_ERROR: `${DOCUMENT}GET_INITIAL_ERROR`,

  GET_REQUEST: `${DOCUMENT}GET_DETAIL_REQUEST`,
  GET_SUCCESS: `${DOCUMENT}GET_DETAIL_SUCCESS`,
  GET_ERROR: `${DOCUMENT}GET_DETAIL_ERROR`,

  EDIT_REQUEST: `${DOCUMENT}EDIT_REQUEST`,
  EDIT_SUCCESS: `${DOCUMENT}EDIT_SUCCESS`,
  EDIT_ERROR: `${DOCUMENT}EDIT_ERROR`,

  UPDATE_TECHNOLOGIES_REQUEST: `${DOCUMENT}UPDATE_TECHNOLOGIES_REQUEST`,
  UPDATE_TECHNOLOGIES_SUCCESS: `${DOCUMENT}UPDATE_TECHNOLOGIES_SUCCESS`,
  UPDATE_TECHNOLOGIES_ERROR: `${DOCUMENT}UPDATE_TECHNOLOGIES_ERROR`,

  EDIT_AVATAR_REQUEST: `${DOCUMENT}EDIT_AVATAR_REQUEST`,
  EDIT_AVATAR_SUCCESS: `${DOCUMENT}EDIT_AVATAR_SUCCESS`,
  EDIT_AVATAR_ERROR: `${DOCUMENT}EDIT_AVATAR_ERROR`,

  CHANGE_VIEW: `${DOCUMENT}CHANGE_VIEW`,

  UPLOAD_AVATAR: `${DOCUMENT}UPLOAD_AVATAR`,
  UPLOAD_AVATAR_SUCCESS: `${DOCUMENT}UPLOAD_AVATAR_SUCCESS`,
  UPLOAD_AVATAR_ERROR: `${DOCUMENT}UPLOAD_AVATAR_ERROR`,

  UPDATE_RESOURCE_REQUEST: `${DOCUMENT}UPDATE_RESOURCE_REQUEST`,
  UPDATE_RESOURCE_SUCCESS: `${DOCUMENT}UPDATE_RESOURCE_SUCCESS`,
  UPDATE_RESOURCE_ERROR: `${DOCUMENT}UPDATE_RESOURCE_ERROR`,

  UPDATE_PROJECT_REQUEST: `${DOCUMENT}UPDATE_PROJECT_REQUEST`,
  UPDATE_PROJECT_SUCCESS: `${DOCUMENT}UPDATE_PROJECT_SUCCESS`,

  RESET_FILE_STATE: `${DOCUMENT}RESET_FILE_STATE:`,

  GET_RESOURCE_CONTRACTS_REQUEST: `${DOCUMENT}GET_CONTRACTS_REQUEST`,
  GET_RESOURCE_CONTRACTS_SUCCESS: `${DOCUMENT}GET_CONTRACTS_SUCCESS`,
  GET_RESOURCE_CONTRACTS_ERROR: `${DOCUMENT}GET_CONTRACTS_ERROR`,

  ADD_RESOURCE_CONTRACTS_REQUEST: `${DOCUMENT}ADD_CONTRACTS_REQUEST`,
  ADD_RESOURCE_CONTRACTS_SUCCESS: `${DOCUMENT}ADD_CONTRACTS_SUCCESS`,
  ADD_RESOURCE_CONTRACTS_ERROR: `${DOCUMENT}ADD_CONTRACTS_ERROR`,

  REMOVE_RESOURCE_CONTRACTS_REQUEST: `${DOCUMENT}REMOVE_CONTRACTS_REQUEST`,
  REMOVE_RESOURCE_CONTRACTS_SUCCESS: `${DOCUMENT}REMOVE_CONTRACTS_SUCCESS`,
  REMOVE_RESOURCE_CONTRACTS_ERROR: `${DOCUMENT}REMOVE_CONTRACTS_ERROR`,

  GET_RESOURCE_HISTORY_DETAIL_PAGE_REQUEST: `GET_RESOURCE_HISTORY_DETAIL_PAGE_REQUEST`,
  GET_RESOURCE_HISTORY_DETAIL_PAGE_SUCCESS: `GET_RESOURCE_HISTORY_DETAIL_PAGE_SUCCESS`,
  GET_RESOURCE_HISTORY_DETAIL_PAGE_ERROR: `GET_RESOURCE_HISTORY_DETAIL_PAGE_ERROR`,


  UPDATE_RESOURCE_CONTRACT_REQUEST: `${DOCUMENT}UPDATE_CONTRACT_REQUEST`,
  UPDATE_RESOURCE_CONTRACT_SUCCESS: `${DOCUMENT}UPDATE_CONTRACT_SUCCESS`,
  UPDATE_RESOURCE_CONTRACT_ERROR: `${DOCUMENT}UPDATE_CONTRACT_ERROR`,

  GET_RESOURCE_SALARY_REQUEST: `${DOCUMENT}GET_SALARY_REQUEST`,
  GET_RESOURCE_SALARY_SUCCESS: `${DOCUMENT}GET_SALARY_SUCCESS`,
  GET_RESOURCE_SALARY_ERROR: `${DOCUMENT}GET_SALARY_ERROR`,

  ADD_RESOURCE_SALARY_REQUEST: `${DOCUMENT}ADD_SALARY_REQUEST`,
  ADD_RESOURCE_SALARY_SUCCESS: `${DOCUMENT}ADD_SALARY_SUCCESS`,

  REMOVE_RESOURCE_SALARY_REQUEST: `${DOCUMENT}REMOVE_SALARY_REQUEST`,
  REMOVE_RESOURCE_SALARY_SUCCESS: `${DOCUMENT}REMOVE_SALARY_SUCCESS`,

  UPDATE_RESOURCE_SALARY_REQUEST: `${DOCUMENT}UPDATE_SALARY_REQUEST`,
  UPDATE_RESOURCE_SALARY_SUCCESS: `${DOCUMENT}UPDATE_SALARY_SUCCESS`,

  SALARY_REQUESTS_ERROR: `${DOCUMENT}SALARY_REQUESTS_ERROR`,

  GET_RESOURCE_SIGNING_BONUS_REQUEST: `${DOCUMENT}GET_RESOURCE_SIGNING_BONUS_REQUEST`,
  GET_RESOURCE_SIGNING_BONUS_SUCCESS: `${DOCUMENT}GET_RESOURCE_SIGNING_BONUS_SUCCESS`,

  ADD_RESOURCE_SIGNING_BONUS_REQUEST: `${DOCUMENT}ADD_RESOURCE_SIGNING_BONUS`,
  ADD_RESOURCE_SIGNING_BONUS_SUCCESS: `${DOCUMENT}ADD_RESOURCE_SIGNING_BONUS_SUCCESS`,

  REMOVE_RESOURCE_SIGNING_BONUS_REQUEST: `${DOCUMENT}REMOVE_RESOURCE_SIGNING_BONUS_REQUEST`,
  REMOVE_RESOURCE_SIGNING_BONUS_SUCCESS: `${DOCUMENT}REMOVE_RESOURCE_SIGNING_BONUS_SUCCESS`,

  UPDATE_RESOURCE_SIGNING_BONUS_REQUEST: `${DOCUMENT}UPDATE_RESOURCE_SIGNING_BONUS_REQUEST`,
  UPDATE_RESOURCE_SIGNING_BONUS_SUCCESS: `${DOCUMENT}UPDATE_RESOURCE_SIGNING_BONUS_SUCCESS`,

  SIGNING_BONUS_REQUESTS_ERROR: `${DOCUMENT}SIGNING_BONUS_REQUESTS_ERROR`,

  GET_RESOURCE_REFERRAL_BONUS_REQUEST: `${DOCUMENT}GET_RESOURCE_REFERRAL_BONUS_REQUEST`,
  GET_RESOURCE_REFERRAL_BONUS_SUCCESS: `${DOCUMENT}GET_RESOURCE_REFERRAL_BONUS_SUCCESS`,

  ADD_RESOURCE_REFERRAL_BONUS_REQUEST: `${DOCUMENT}ADD_RESOURCE_REFERRAL_BONUS`,
  ADD_RESOURCE_REFERRAL_BONUS_SUCCESS: `${DOCUMENT}ADD_RESOURCE_REFERRAL_BONUS_SUCCESS`,

  REMOVE_RESOURCE_REFERRAL_BONUS_REQUEST: `${DOCUMENT}REMOVE_RESOURCE_REFERRAL_BONUS_REQUEST`,
  REMOVE_RESOURCE_REFERRAL_BONUS_SUCCESS: `${DOCUMENT}REMOVE_RESOURCE_REFERRAL_BONUS_SUCCESS`,

  UPDATE_RESOURCE_REFERRAL_BONUS_REQUEST: `${DOCUMENT}UPDATE_RESOURCE_REFERRAL_BONUS_REQUEST`,
  UPDATE_RESOURCE_REFERRAL_BONUS_SUCCESS: `${DOCUMENT}UPDATE_RESOURCE_REFERRAL_BONUS_SUCCESS`,

  REFERRAL_BONUS_REQUESTS_ERROR: `${DOCUMENT}REFERRAL_BONUS_REQUESTS_ERROR`,

  GET_RESOURCE_COMMENTS_REQUEST: `${DOCUMENT}GET_RESOURCE_COMMENTS_REQUEST`,
  GET_RESOURCE_COMMENTS_SUCCESS: `${DOCUMENT}GET_RESOURCE_COMMENTS_SUCCESS`,

  ADD_RESOURCE_COMMENT_REQUEST: `${DOCUMENT}ADD_RESOURCE_COMMENT`,
  ADD_RESOURCE_COMMENT_SUCCESS: `${DOCUMENT}ADD_RESOURCE_COMMENT_SUCCESS`,

  REMOVE_RESOURCE_COMMENT_REQUEST: `${DOCUMENT}REMOVE_RESOURCE_COMMENT_REQUEST`,
  REMOVE_RESOURCE_COMMENT_SUCCESS: `${DOCUMENT}REMOVE_RESOURCE_COMMENT_SUCCESS`,

  UPDATE_RESOURCE_COMMENT_REQUEST: `${DOCUMENT}UPDATE_RESOURCE_COMMENT_REQUEST`,
  UPDATE_RESOURCE_COMMENT_SUCCESS: `${DOCUMENT}UPDATE_RESOURCE_COMMENT_SUCCESS`,

  COMMENTS_REQUESTS_ERROR: `${DOCUMENT}COMMENTS_REQUESTS_ERROR`,

  GET_RESOURCES_LIST: `${DOCUMENT}GET_RESOURCES_LIST`,
  GET_RESOURCES_LIST_SUCCESS: `${DOCUMENT}GET_RESOURCES_LIST_SUCCESS`,
  GET_RESOURCES_LIST_ERROR: `${DOCUMENT}GET_RESOURCES_LIST_ERROR`,

  GET_RESOURCE_EVALUATIONS_REQUEST: `GET_RESOURCE_EVALUATIONS_REQUEST`,
  GET_RESOURCE_EVALUATIONS_SUCCESS: `GET_RESOURCE_EVALUATIONS_SUCCESS`,
  GET_RESOURCE_EVALUATIONS_ERROR: `GET_RESOURCE_EVALUATIONS_ERROR`,

  GET_RESOURCE_TECHNOLOGY_EVALUATIONS_REQUEST: `GET_RESOURCE_TECHNOLOGY_EVALUATIONS_REQUEST`,
  GET_RESOURCE_TECHNOLOGY_EVALUATIONS_SUCCESS: `GET_RESOURCE_TECHNOLOGY_EVALUATIONS_SUCCESS`,
  GET_RESOURCE_TECHNOLOGY_EVALUATIONS_ERROR: `GET_RESOURCE_TECHNOLOGY_EVALUATIONS_ERROR`,

  GET_RESOURCE_ENGLISH_EVALUATIONS_REQUEST: `GET_RESOURCE_ENGLISH_EVALUATIONS_REQUEST`,
  GET_RESOURCE_ENGLISH_EVALUATIONS_SUCCESS: `GET_RESOURCE_ENGLISH_EVALUATIONS_SUCCESS`,
  GET_RESOURCE_ENGLISH_EVALUATIONS_ERROR: `GET_RESOURCE_ENGLISH_EVALUATIONS_ERROR`,

  GET_RESOURCE_VACATION_REQUEST: 'GET_RESOURCE_VACATION_REQUEST',
  GET_RESOURCE_VACATION_SUCCESS: 'GET_RESOURCE_VACATION_SUCCESS',
  GET_RESOURCE_VACATION_ERROR: 'GET_RESOURCE_VACATION_ERROR',

  getInitialState: (resourceId, withoutProjects) => ({
    type: resourceActions.GET_INITIAL_REQUEST,
    payload: { resourceId, withoutProjects },
  }),

  changeView: (modalName, status) => ({
    type: resourceActions.CHANGE_VIEW,
    payload: { modalName, status },
  }),

  // Edit profile request
  editResourceRequest: profile => ({
    type: resourceActions.EDIT_REQUEST,
    payload: {
      profile,
    },
  }),

  editSuccess: resource => ({
    type: resourceActions.EDIT_SUCCESS,
    payload: resource,
  }),

  editError: error => ({
    type: resourceActions.EDIT_ERROR,
    payload: {
      error,
    },
  }),

  getVacationInfoRequest: (id) => ({
    type: resourceActions.GET_RESOURCE_VACATION_REQUEST,
    id,
  }),

  getVacationInfoSuccess: (payload) => ({
    type: resourceActions.GET_RESOURCE_VACATION_SUCCESS,
    payload
  }),

  getVacationInfoError: () => ({
    type: resourceActions.GET_RESOURCE_VACATION_ERROR,
  }),

  // Upload avatar
  uploadAvatar: request => ({
    type: resourceActions.UPLOAD_AVATAR,
    payload: {
      ...request,
    },
  }),

  uploadAvatarSuccess: (data) => ({
    type: resourceActions.UPLOAD_AVATAR_SUCCESS,
    payload: {
      ...data,
    },
  }),

  uploadAvatarError: error => ({
    type: resourceActions.UPLOAD_AVATAR_ERROR,
    payload: {
      error,
    },
  }),

  // Edit Avatar
  editAvatarRequest: file => ({
    type: resourceActions.EDIT_AVATAR_REQUEST,
    payload: file,
  }),

  editAvatarSuccess: () => ({
    type: resourceActions.EDIT_AVATAR_SUCCESS,
  }),

  editAvatarError: error => ({
    type: resourceActions.EDIT_AVATAR_ERROR,
    payload: {
      error,
    },
  }),

  // Get resource request
  getResource: (id) => ({
    type: resourceActions.GET_REQUEST,
    payload: {
      id,
    },
  }),

  getSuccess: resource => ({
    type: resourceActions.GET_SUCCESS,
    payload: resource,
  }),

  getError: error => ({
    type: resourceActions.GET_ERROR,
    payload: {
      error,
    },
  }),

  submitUpdates: (body, modalName) => ({
    type: resourceActions.SUBMIT_UPDATES,
    payload: { body, modalName },
  }),

  // Change Technologies Priority
  changeTechnologiesPriority: (technologies) => ({
    type: resourceActions.CHANGE_TECHNOLOGIES_PRIORITY_SUCCESS,
    payload: technologies,
  }),

  // add technology

  addTechnologyRequest: (resourceId, body) => ({
    type: resourceActions.UPDATE_TECHNOLOGIES_REQUEST,
    payload: {
      resourceId,
      body,
    },
  }),

  updateResourceProject: (resourceId, payload) => ({
    type: resourceActions.UPDATE_PROJECT_REQUEST,
    payload: {
      resourceId,
      data: payload,
    },
  }),

  updateResourceProjectSuccess: (data) => ({
    type: resourceActions.UPDATE_PROJECT_SUCCESS,
    payload: data,
  }),

  resetFile: () => ({
    type: resourceActions.RESET_FILE_STATE,
  }),

  // Get resource contracts request
  getResourceContractsRequest: (id) => ({
    type: resourceActions.GET_RESOURCE_CONTRACTS_REQUEST,
    payload: {
      id,
    },
  }),

  getContractsSuccess: contracts => ({
    type: resourceActions.GET_RESOURCE_CONTRACTS_SUCCESS,
    payload: contracts,
  }),

  getContractsError: error => ({
    type: resourceActions.GET_RESOURCE_CONTRACTS_ERROR,
    payload: {
      error,
    },
  }),

  getResourceEvaluationsRequest(id) {
    return {
      type: resourceActions.GET_RESOURCE_EVALUATIONS_REQUEST,
      payload: {
        id
      },
    };
  },
  getResourceEvaluationsSuccess(payload) {
    return {
      type: resourceActions.GET_RESOURCE_EVALUATIONS_SUCCESS,
      payload,
    };
  },
  getResourceEvaluationsError(payload) {
    return {
      type: resourceActions.GET_RESOURCE_EVALUATIONS_ERROR,
      payload,
    };
  },

  getResourceTechnologyEvaluationRequest(id) {
    return {
      type: resourceActions.GET_RESOURCE_TECHNOLOGY_EVALUATIONS_REQUEST,
      payload: {
        id
      }
    }
  },

  getResourceTechnologyEvaluationSuccess(payload) {
    return {
      type: resourceActions.GET_RESOURCE_TECHNOLOGY_EVALUATIONS_SUCCESS,
      payload,
    }
  },

  getResourceTechnologyEvaluationError() {
    return {
      type: resourceActions.GET_RESOURCE_TECHNOLOGY_EVALUATIONS_ERROR,
    }
  },

  getResourceEnglishEvaluationRequest(id) {
    return {
      type: resourceActions.GET_RESOURCE_ENGLISH_EVALUATIONS_REQUEST,
      payload: {
        id
      }
    }
  },

  getResourceEnglishEvaluationSuccess(payload) {
    return {
      type: resourceActions.GET_RESOURCE_ENGLISH_EVALUATIONS_SUCCESS,
      payload,
    }
  },

  getResourceEnglishEvaluationError() {
    return {
      type: resourceActions.GET_RESOURCE_ENGLISH_EVALUATIONS_ERROR,
    }
  },

  getResourcesHistoryDetailPageRequest(id) {
    return {
      type: resourceActions.GET_RESOURCE_HISTORY_DETAIL_PAGE_REQUEST,
      payload: { id },
    };
  },
  getResourcesHistoryDetailPageSuccess(payload) {
    return {
      type: resourceActions.GET_RESOURCE_HISTORY_DETAIL_PAGE_SUCCESS,
      payload,
    };
  },
  getResourcesHistoryDetailPageError(payload) {
    return {
      type: resourceActions.GET_RESOURCE_HISTORY_DETAIL_PAGE_ERROR,
      payload,
    };
  },

  // Add resource contracts request
  addResourceContractsRequest: (id, body) => ({
    type: resourceActions.ADD_RESOURCE_CONTRACTS_REQUEST,
    payload: {
      id,
      body,
    },
  }),

  addContractsSuccess: contracts => ({
    type: resourceActions.ADD_RESOURCE_CONTRACTS_SUCCESS,
    payload: contracts,
  }),

  addContractsError: error => ({
    type: resourceActions.ADD_RESOURCE_CONTRACTS_ERROR,
    payload: {
      error,
    },
  }),

  // Remove resource contracts request
  removeResourceContractsRequest: (id, contractId) => ({
    type: resourceActions.REMOVE_RESOURCE_CONTRACTS_REQUEST,
    payload: {
      id,
      contractId,
    },
  }),

  removeContractsSuccess: contracts => ({
    type: resourceActions.REMOVE_RESOURCE_CONTRACTS_SUCCESS,
    payload: contracts,
  }),

  removeContractsError: error => ({
    type: resourceActions.REMOVE_RESOURCE_CONTRACTS_ERROR,
    payload: {
      error,
    },
  }),

// Update resource contract request
  updateResourceContractRequest: (contract) => ({
    type: resourceActions.UPDATE_RESOURCE_CONTRACT_REQUEST,
    payload: contract,
  }),

  updateResourceContractSuccess: contract => ({
    type: resourceActions.UPDATE_RESOURCE_CONTRACT_SUCCESS,
    payload: contract,
  }),

  updateResourceContractError: error => ({
    type: resourceActions.UPDATE_RESOURCE_CONTRACT_ERROR,
    payload: {
      error,
    },
  }),

  // Get resource salary
  getResourceSalary: (object) => ({
    type: resourceActions.GET_RESOURCE_SALARY_REQUEST,
    payload: {
      object,
    },
  }),

  getResourceSalarySuccess: (salary) => ({
    type: resourceActions.GET_RESOURCE_SALARY_SUCCESS,
    payload: salary,
  }),

  // Add resource salary request
  addResourceSalary: (id, body) => ({
    type: resourceActions.ADD_RESOURCE_SALARY_REQUEST,
    payload: {
      id,
      body,
    },
  }),

  addResourceSalarySuccess: (salary) => ({
    type: resourceActions.ADD_RESOURCE_SALARY_SUCCESS,
    payload: salary,
  }),

  // Delete resource salary record
  removeResourceSalary: (resourceId, salaryId) => ({
    type: resourceActions.REMOVE_RESOURCE_SALARY_REQUEST,
    payload: {
      resourceId,
      salaryId,
    }
  }),

  // Update resource salary request
  updateResourceSalaryRequest: (salary) => ({
    type: resourceActions.UPDATE_RESOURCE_SALARY_REQUEST,
    payload: salary,
  }),

  updateResourceSalarySuccess: salary => ({
    type: resourceActions.UPDATE_RESOURCE_SALARY_SUCCESS,
    payload: salary,
  }),

  salaryRequestsError: () => ({type: resourceActions.SALARY_REQUESTS_ERROR}),

  // Get resource signing bonus
  getResourceSigningBonus: (object) => ({
    type: resourceActions.GET_RESOURCE_SIGNING_BONUS_REQUEST,
    payload: {
      object,
    },
  }),

  getResourceSigningBonusSuccess: (bonuses) => ({
    type: resourceActions.GET_RESOURCE_SIGNING_BONUS_SUCCESS,
    payload: bonuses,
  }),

  // Add resource signing bonus request
  addResourceSigningBonus: (id, body) => ({
    type: resourceActions.ADD_RESOURCE_SIGNING_BONUS_REQUEST,
    payload: {
      id,
      body,
    },
  }),

  addResourceSigningBonusSuccess: (salary) => ({
    type: resourceActions.ADD_RESOURCE_SIGNING_BONUS_SUCCESS,
    payload: salary,
  }),

  // Delete resource signing bonus record
  removeResourceSigningBonus: (resourceId, bonusId) => ({
    type: resourceActions.REMOVE_RESOURCE_SIGNING_BONUS_REQUEST,
    payload: {
      resourceId,
      bonusId,
    }
  }),

  removeResourceSigningBonusSuccess: (bonuses) => ({
    type: resourceActions.REMOVE_RESOURCE_SIGNING_BONUS_SUCCESS,
    payload: bonuses,
  }),

  // Update resource salary request
  updateResourceSigningBonusRequest: (bonus) => ({
    type: resourceActions.UPDATE_RESOURCE_SIGNING_BONUS_REQUEST,
    payload: bonus,
  }),

  updateResourceSigningBonusRequestSuccess: salary => ({
    type: resourceActions.UPDATE_RESOURCE_SIGNING_BONUS_SUCCESS,
    payload: salary,
  }),

  bonusRequestsError: () => ({type: resourceActions.SIGNING_BONUS_REQUESTS_ERROR}),

  // Referral bonus
  getResourceReferralBonus: (object) => ({
    type: resourceActions.GET_RESOURCE_REFERRAL_BONUS_REQUEST,
    payload: {
      object,
    },
  }),

  getResourceReferralBonusSuccess: (bonuses) => ({
    type: resourceActions.GET_RESOURCE_REFERRAL_BONUS_SUCCESS,
    payload: bonuses,
  }),

  addResourceReferralBonus: (id, body) => ({
    type: resourceActions.ADD_RESOURCE_REFERRAL_BONUS_REQUEST,
    payload: {
      id,
      body,
    },
  }),

  addResourceReferralBonusSuccess: (bonuses) => ({
    type: resourceActions.ADD_RESOURCE_REFERRAL_BONUS_SUCCESS,
    payload: bonuses,
  }),

  removeResourceReferralBonus: (resourceId, bonusId) => ({
    type: resourceActions.REMOVE_RESOURCE_REFERRAL_BONUS_REQUEST,
    payload: {
      resourceId,
      bonusId,
    }
  }),

  removeResourceReferralBonusSuccess: (bonuses) => ({
    type: resourceActions.REMOVE_RESOURCE_REFERRAL_BONUS_SUCCESS,
    payload: bonuses,
  }),

  updateResourceReferralBonusRequest: (bonus) => ({
    type: resourceActions.UPDATE_RESOURCE_REFERRAL_BONUS_REQUEST,
    payload: bonus,
  }),

  updateResourceReferralBonusRequestSuccess: (bonuses) => ({
    type: resourceActions.UPDATE_RESOURCE_REFERRAL_BONUS_SUCCESS,
    payload: bonuses,
  }),

  referralBonusRequestsError: () => ({type: resourceActions.REFERRAL_BONUS_REQUESTS_ERROR}),

  // Comments
  getResourceComments: (id) => ({
    type: resourceActions.GET_RESOURCE_COMMENTS_REQUEST,
    payload: {
      id,
    },
  }),

  getResourceCommentsSuccess: (comments) => ({
    type: resourceActions.GET_RESOURCE_COMMENTS_SUCCESS,
    payload: comments,
  }),

  addResourceComment: (id, body) => ({
    type: resourceActions.ADD_RESOURCE_COMMENT_REQUEST,
    payload: {
      id,
      body,
    },
  }),

  addResourceCommentSuccess: (comment) => ({
    type: resourceActions.ADD_RESOURCE_COMMENT_SUCCESS,
    payload: comment,
  }),

  removeResourceComment: (resourceId, commentId) => ({
    type: resourceActions.REMOVE_RESOURCE_COMMENT_REQUEST,
    payload: {
      resourceId,
      commentId,
    }
  }),

  removeResourceCommentSuccess: (comments) => ({
    type: resourceActions.REMOVE_RESOURCE_COMMENT_SUCCESS,
    payload: comments,
  }),

  updateResourceCommentRequest: (comment) => ({
    type: resourceActions.UPDATE_RESOURCE_COMMENT_REQUEST,
    payload: comment,
  }),

  updateResourceCommentRequestSuccess: (comments) => ({
    type: resourceActions.UPDATE_RESOURCE_COMMENT_SUCCESS,
    payload: comments,
  }),

  commentsRequestsError: () => ({type: resourceActions.COMMENTS_REQUESTS_ERROR}),

  getResources: (data) => ({
    type: resourceActions.GET_RESOURCES_LIST,
    payload: data,
  }),

  getResourcesSuccess: (data) => ({
    type: resourceActions.GET_RESOURCES_LIST_SUCCESS,
    payload: data,
  }),

  getResourcesError: () => ({
    type: resourceActions.GET_RESOURCES_LIST_ERROR,
  }),

};

export default resourceActions;
