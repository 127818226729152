const DOCUMENT = 'MAILINGS_';

const mailingsActions = {
  GET_MAILINGS_LIST_REQUEST: `${DOCUMENT}GET_LIST_REQUEST`,
  GET_MAILINGS_LIST_SUCCESS: `${DOCUMENT}GET_LIST_SUCCESS`,
  GET_MAILINGS_LIST_ERROR: `${DOCUMENT}GET_LIST_ERROR`,

  SET_INITIAL_MAILINGS_LIST_STATE: `SET_INITIAL_${DOCUMENT}LIST_STATE`,

  GET_MAILINGS_STATUS_REQUEST: `${DOCUMENT}GET_STATUS_REQUEST`,
  GET_MAILINGS_STATUS_SUCCESS: `${DOCUMENT}GET_STATUS_SUCCESS`,
  GET_MAILINGS_STATUS_ERROR: `${DOCUMENT}GET_STATUS_ERROR`,

  DELETE_REQUEST: `DELETE_${DOCUMENT}REQUEST`,
  DELETE_ERROR: `DELETE_${DOCUMENT}ERROR`,

  START_REQUEST: `START_${DOCUMENT}REQUEST`,
  START_ERROR: `START_${DOCUMENT}ERROR`,

  PAUSE_REQUEST: `PAUSE_${DOCUMENT}REQUEST`,
  PAUSE_ERROR: `PAUSE_${DOCUMENT}ERROR`,

  ADD_MAILING_REQUEST: `ADD_${DOCUMENT}REQUEST`,
  ADD_MAILING_SUCCESS: `ADD_${DOCUMENT}SUCCESS`,
  ADD_MAILING_ERROR: `ADD_${DOCUMENT}ERROR`,

  SHOW_ADD_MODAL: `${DOCUMENT}SHOW_ADD_MODAL`,
  CLOSE_REDIRECT: `${DOCUMENT}CLOSE_REDIRECT`,
  SET_RELOAD_PAGE: `${DOCUMENT}RELOAD_PAGE`,

  getMailingsListRequest: payload => {
    return {
      type: mailingsActions.GET_MAILINGS_LIST_REQUEST,
      payload,
    };
  },

  getMailingsListSuccess: payload => ({
    type: mailingsActions.GET_MAILINGS_LIST_SUCCESS,
    payload,
  }),

  getMailingsListError: error => ({
    type: mailingsActions.GET_MAILINGS_LIST_ERROR,
    payload: error,
  }),

  setInitialMailingsListState: payload => ({
    type: mailingsActions.SET_INITIAL_MAILINGS_LIST_STATE,
    payload,
  }),

  getMailingsStatusRequest: payload => {
    return {
      type: mailingsActions.GET_MAILINGS_STATUS_REQUEST,
      payload,
    };
  },

  getMailingsStatusSuccess: payload => ({
    type: mailingsActions.GET_MAILINGS_STATUS_SUCCESS,
    payload,
  }),

  getMailingsStatusError: error => ({
    type: mailingsActions.GET_MAILINGS_STATUS_ERROR,
    payload: error,
  }),

  deleteMailingRequest: id => ({
    type: mailingsActions.DELETE_REQUEST,
    payload: {
      id,
    },
  }),

  deleteMailingError: () => ({
    type: mailingsActions.DELETE_ERROR,
  }),

  startMailingRequest: id => ({
    type: mailingsActions.START_REQUEST,
    payload: {
      id,
    },
  }),

  startMailingError: () => ({
    type: mailingsActions.PAUSE_ERROR,
  }),

  pauseMailingRequest: id => ({
    type: mailingsActions.PAUSE_REQUEST,
    payload: {
      id,
    },
  }),

  pauseMailingError: () => ({
    type: mailingsActions.PAUSE_ERROR,
  }),

  addMailingRequest: (formData, formName) => ({
    type: mailingsActions.ADD_MAILING_REQUEST,
    payload: { formData, formName },
  }),

  addMailingSuccess: (formData, formName) => ({
    type: mailingsActions.ADD_MAILING_SUCCESS,
    payload: { formData, formName },
  }),

  addMailingError: error => ({
    type: mailingsActions.ADD_MAILING_ERROR,
    payload: { error },
  }),

  showAddModal: isAddModalActive => ({
    type: mailingsActions.SHOW_ADD_MODAL,
    payload: { isAddModalActive },
  }),

  closeRedirect: () => ({
    type: mailingsActions.CLOSE_REDIRECT,
    payload: { redirectTo: 0 },
  }),

  setReloadPage: (reloadPageInterval, reloadPage) => ({
    type: mailingsActions.SET_RELOAD_PAGE,
    payload: {
      reloadPage,
      reloadPageInterval
    },
  }),
};

export default mailingsActions;
