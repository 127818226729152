import { all } from 'redux-saga/effects';
import typesActions from 'redux/types/saga';
import authSagas from 'redux/auth/saga';
import clientsSaga from 'redux/clients/saga';
import usersSagas from 'redux/users/saga';
import resourcesSaga from 'redux/resources/tableResource/saga';
import manageRoleSaga from 'redux/manageRoleAccess/saga';
import profileSagas from 'redux/profile/saga';
import resourceSaga from 'redux/resources/resource/saga';
import technologiesSaga from 'redux/technologies/saga';
import locationsSaga from 'redux/location/saga';
import levelsSaga from 'redux/levels/saga';
import resourceRolesSaga from 'redux/resourceRoles/saga';
import projectStatusesSaga from 'redux/projectStatuses/saga';
import projectsSaga from 'redux/projects/saga';
import futureProjectsSaga from 'redux/projects/futurePage/saga';
import forgotPasswordSaga from 'redux/forgotPassword/saga';
import resourcesByTypeSaga from 'redux/resources/resourcesByType/saga';
import resourceTimelineSaga from 'redux/resources/resourseTimeline/saga';
import resourcesHistorySaga from 'redux/resources/resourсeHistory/saga';
import resourcesEvalutionSaga from 'redux/resources/resourceEvaluation/saga';
import evaluationDetailsSaga from 'redux/resources/evaluationDetails/saga';
import statsSaga from 'redux/stats/saga';
import settingsSaga from 'redux/settings/saga';
import marketingEmailTemplateSaga
  from 'redux/marketing/marketingEmailTemplates/detailEmailTemplatePage/saga';
import marketingEmailTemplatesSaga from 'redux/marketing/marketingEmailTemplates/listPage/saga';
import recipientsSaga from 'redux/marketing/recipients/saga';
import mailingSaga from 'redux/marketing/mailing/saga';
import mailingRecipientsSaga from 'redux/marketing/mailing/mailingRecipients/saga';
import mailingsSaga from 'redux/marketing/mailings/listPage/saga';
import marketingGroupsSaga from 'redux/marketing/marketingGroups/saga';
import marketingCampaignsSaga from 'redux/marketing/marketingCampaigns/saga';
import marketingSendersSaga from 'redux/marketing/marketingSenders/saga';
import projectManagersSaga from 'redux/projectManagers/saga';
import tableSaga from 'components/NewTable/redux/saga';
import actionLogSaga from 'redux/actionLog/saga';
import vacationsSaga from 'redux/vacation/saga';
import vacationSettings from 'redux/vacation/settings/saga';
import clientDetailsSaga from 'redux/clients/clientDetails/saga';
import documentsSaga from 'redux/documents/saga';
import potentialProjectsSaga from 'redux/projects/potentialProjectsPage/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    clientsSaga(),
    resourcesSaga(),
    resourceSaga(),
    profileSagas(),
    manageRoleSaga(),
    usersSagas(),
    typesActions(),
    locationsSaga(),
    resourceRolesSaga(),
    technologiesSaga(),
    levelsSaga(),
    projectsSaga(),
    futureProjectsSaga(),
    forgotPasswordSaga(),
    resourcesByTypeSaga(),
		resourceTimelineSaga(),
    resourcesHistorySaga(),
    resourcesEvalutionSaga(),
    evaluationDetailsSaga(),
    statsSaga(),
    settingsSaga(),
    marketingEmailTemplateSaga(),
    marketingEmailTemplatesSaga(),
    recipientsSaga(),
    mailingSaga(),
    mailingRecipientsSaga(),
    mailingsSaga(),
    marketingGroupsSaga(),
    marketingCampaignsSaga(),
    marketingSendersSaga(),
    projectManagersSaga(),
    tableSaga(),
    actionLogSaga(),
    vacationsSaga(),
    vacationSettings(),
    projectStatusesSaga(),
    clientDetailsSaga(),
    documentsSaga(),
    potentialProjectsSaga(),
  ]);
}
