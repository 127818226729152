export const onlyLoadingFalse = (state) => ({
    ...state,
    loading: false,
});

export const onlyLoadingTrue = (state) => ({
  ...state,
  loading: true,
});

export const getSettingsSuccess = (state, payload = {}) => {
  const { key, target, items } = payload;
  const settings = !key && !target ? items : state.settings;
  if (key || target) {
    items.forEach(item => {
      if (!settings.find(setting => setting.id === item.id)){
        settings.push(item);
      }
    });
  }
  return {
    ...state,
    settings,
    loading: false,
  };
};
