const DOCUMENT = 'LOCATION_';

const locationActions = {
  GET_LOCATION_REQUEST: `${DOCUMENT}GET_REQUEST`,
  GET_LOCATION_SUCCESS: `${DOCUMENT}GET_SUCCESS`,
  GET_LOCATION_ERROR: `${DOCUMENT}GET_ERROR`,

  getLocationRequest: () => ({
    type: locationActions.GET_LOCATION_REQUEST,
  }),

  getLocationSuccess: (payload) => ({
    type: locationActions.GET_LOCATION_SUCCESS,
    payload,
  }),

  getLocationError: () => ({
    type: locationActions.GET_LOCATION_ERROR,
  }),
};

export default locationActions;