const actions = {
  GET_INITIAL_PROJECTS_LIST_STATE: `GET_INITIAL_PROJECTS_LIST_STATE`,
  GET_INITIAL_PROJECTS_LIST_STATE_SUCCESS: `GET_INITIAL_PROJECTS_LIST_STATE_SUCCESS`,
  GET_INITIAL_PROJECTS_LIST_STATE_ERROR: `GET_INITIAL_PROJECTS_LIST_STATE_ERROR`,
  SET_INITIAL_PROJECTS_LIST_STATE: `SET_INITIAL_PROJECTS_LIST_STATE`,

  GET_PROJECTS_BY_RESOURCES_HISTORY_REQUEST: 'GET_PROJECTS_BY_RESOURCES_HISTORY_REQUEST',
  GET_PROJECTS_BY_RESOURCES_HISTORY_SUCCESS: 'GET_PROJECTS_BY_RESOURCES_HISTORY_SUCCESS',
  GET_PROJECTS_BY_RESOURCES_HISTORY_ERROR: 'GET_PROJECTS_BY_RESOURCES_HISTORY_ERROR',

  GET_PROJECTS_LIST_REQUEST: 'GET_PROJECTS_LIST_REQUEST',
  GET_PROJECTS_LIST_SUCCESS: 'GET_PROJECTS_LIST_SUCCESS',
  GET_PROJECTS_LIST_ERROR: 'GET_PROJECTS_LIST_ERROR',

  GET_PROJECTS_SIMPLE_LIST_REQUEST: 'GET_PROJECTS_SIMPLE_LIST_REQUEST',
  GET_PROJECTS_SIMPLE_LIST_SUCCESS: 'GET_PROJECTS_SIMPLE_LIST_SUCCESS',
  GET_PROJECTS_SIMPLE_LIST_ERROR: 'GET_PROJECTS_SIMPLE_LIST_ERROR',

  GET_ONGOING_PROJECTS_REQUEST: 'GET_ONGOING_PROJECTS_REQUEST',
  GET_ONGOING_PROJECTS_SUCCESS: 'GET_ONGOING_PROJECTS_SUCCESS',
  GET_ONGOING_PROJECTS_ERROR: 'GET_ONGOING_ROJECTS_ERROR',

  GET_TIMELINE_PROJECTS_REQUEST: 'GET_TIMELINE_PROJECTS_REQUEST',
  GET_TIMELINE_PROJECTS_SUCCESS: 'GET_TIMELINE_PROJECTS_SUCCESS',
  GET_TIMELINE_PROJECTS_ERROR: 'GET_TIMELINE_ROJECTS_ERROR',

  GET_PROJECTS_BY_TYPE_REQUEST: 'GET_PROJECTS_BY_TYPE_REQUEST',
  GET_PROJECTS_BY_TYPE_SUCCESS: 'GET_PROJECTS_BY_TYPE_SUCCESS',
  GET_PROJECTS_BY_TYPE_CHANGED_SUCCESS: 'GET_PROJECTS_BY_TYPE_CHANGED_SUCCESS',

  DELETE_PROJECT_REQUEST: `DELETE_PROJECT_REQUEST`,
  DELETE_PROJECT_SUCCESS: `DELETE_PROJECT_SUCCESS`,
  DELETE_PROJECT_ERROR: `DELETE_PROJECT_ERROR`,

  ADD_PROJECT_REQUEST: `ADD_PROJECT_REQUEST`,
  ADD_PROJECT_SUCCESS: `ADD_PROJECT_SUCCESS`,
  ADD_PROJECT_ERROR: `ADD_PROJECT_ERROR`,

  MOVE_PROJECT_REQUEST: `MOVE_PROJECT_REQUEST`,
  MOVE_PROJECT_SUCCESS: `MOVE_PROJECT_SUCCESS`,
  MOVE_PROJECT_ERROR: `MOVE_PROJECT_ERROR`,

  TOGGLE_MODAL: `PROJECTS_TOGGLE_MODAL`,
  UPDATE_FORM: `PROJECTS_UPDATE_FORM`,
  EXPORT_DATA: `PROJECTS_EXPORT_DATA`,
  RESET_STATE: `PROJECTS_RESET_STATE`,

  getInitialProjectsListState: () => ({
    type: actions.GET_INITIAL_PROJECTS_LIST_STATE
  }),

  getInitialProjectsListStateSuccess: () => ({
    type: actions.GET_INITIAL_PROJECTS_LIST_STATE_SUCCESS,
  }),

  getInitialProjectsListStateError: () => ({
    type: actions.GET_INITIAL_PROJECTS_LIST_STATE_ERROR
  }),

  setInitialProjectsListState: (payload) => ({
    type: actions.SET_INITIAL_PROJECTS_LIST_STATE,
    payload,
  }),

  getProjectsListRequest: (payload = {}) => ({
    type: actions.GET_PROJECTS_LIST_REQUEST,
    payload
  }),
  getProjectsListSuccess: (payload) => ({
    type: actions.GET_PROJECTS_LIST_SUCCESS,
    payload,
  }),
  getProjectsListError: (error) => ({
    type: actions.GET_PROJECTS_LIST_ERROR,
    payload: error,
  }),

  getProjectsSimpleListRequest: (payload = {}) => ({
    type: actions.GET_PROJECTS_SIMPLE_LIST_REQUEST,
    payload
  }),
  getProjectsSimpleListSuccess: (payload) => ({
    type: actions.GET_PROJECTS_SIMPLE_LIST_SUCCESS,
    payload,
  }),
  getProjectsSimpleListError: (error) => ({
    type: actions.GET_PROJECTS_SIMPLE_LIST_ERROR,
    payload: error,
  }),

  getOngoingProjectsRequest: (payload = {}) => ({
    type: actions.GET_ONGOING_PROJECTS_REQUEST,
    payload
  }),
  getOngoingProjectsSuccess: (payload) => ({
    type: actions.GET_ONGOING_PROJECTS_SUCCESS,
    payload,
  }),
  getOngoingProjectsError: (error) => ({
    type: actions.GET_ONGOING_PROJECTS_ERROR,
    payload: error,
  }),

  getTimelineProjectsRequest: (payload = {}) => ({
    type: actions.GET_TIMELINE_PROJECTS_REQUEST,
    payload
  }),
  getTimelineProjectsSuccess: (payload) => ({
    type: actions.GET_TIMELINE_PROJECTS_SUCCESS,
    payload,
  }),
  getTimelineProjectsError: (error) => ({
    type: actions.GET_TIMELINE_PROJECTS_ERROR,
    payload: error,
  }),

  getProjectsByResourcesHistoryRequest: (payload) => ({
    type: actions.GET_PROJECTS_BY_RESOURCES_HISTORY_REQUEST,
    payload
  }),
  getProjectsByResourcesHistorySuccess: (payload) => ({
    type: actions.GET_PROJECTS_BY_RESOURCES_HISTORY_SUCCESS,
    payload,
  }),
  getProjectsByResourcesHistoryError: (error) => ({
    type: actions.GET_PROJECTS_BY_RESOURCES_HISTORY_ERROR,
    payload: error,
  }),

  getProjectsByTypeRequest: (payload) => ({
    type: actions.GET_PROJECTS_BY_TYPE_REQUEST,
    payload,
  }),

  getProjectsByTypeSuccess: (payload) => ({
    type: actions.GET_PROJECTS_BY_TYPE_SUCCESS,
    payload,
  }),

  getProjectsByTypeChangedSuccess: (payload) => ({
    type: actions.GET_PROJECTS_BY_TYPE_CHANGED_SUCCESS,
    payload,
  }),

  deleteProjectRequest: (id) => ({
    type: actions.DELETE_PROJECT_REQUEST,
    payload: {
      id,
    },
  }),

  deleteProjectSuccess: (id) => ({
    type: actions.DELETE_PROJECT_SUCCESS,
    payload: {
      id,
    },
  }),

  deleteProjectError: () => ({
    type: actions.DELETE_PROJECT_ERROR,
  }),

  toggleModal: (payload) => ({
    type: actions.TOGGLE_MODAL,
    payload,
  }),

  updateForm: (column, value) => ({
    type: actions.UPDATE_FORM,
    payload: { column, value },
  }),

  addProjectRequest: project => ({
    type: actions.ADD_PROJECT_REQUEST,
    payload:  project,
  }),

  addProjectSuccess: project => ({
    type: actions.ADD_PROJECT_SUCCESS,
    payload: { project },
  }),

  addProjectError: error => ({
    type: actions.ADD_PROJECT_ERROR,
    payload: error,
  }),

  moveProjectRequest: (project, isCopy) => ({
    type: actions.MOVE_PROJECT_REQUEST,
    payload: {
      project,
      isCopy,
    }
  }),

  moveProjectSuccess: () => ({
    type: actions.MOVE_PROJECT_SUCCESS,
  }),

  moveProjectError: error => ({
    type: actions.MOVE_PROJECT_ERROR,
    payload: error,
  }),

  resetState: () => ({
      type: actions.RESET_STATE,
    }),

  exportData: (payload) => ({
    type: actions.EXPORT_DATA,
    payload
  }),
};

export default actions;
