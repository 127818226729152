export const MAILING_STATUSES = {
  NEW: 0,
  ACTIVE: 1,
  PAUSE: 2,
  COMPLETE: 3,
};

export const MAILING_STATUS_OPTIONS = [
  {
    id: MAILING_STATUSES.NEW,
    title: "new",
    color: "gold",
  },
  {
    id: MAILING_STATUSES.ACTIVE,
    title: "active",
    color: "blue",
  },
  {
    id: MAILING_STATUSES.PAUSE,
    title: "pause",
    color: "volcano",
  },
  {
    id: MAILING_STATUSES.COMPLETE,
    title: "complete",
    color: "green",
  },
];
