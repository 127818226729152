import resourcesEvaluationActions from 'redux/resources/resourceEvaluation/actions';

const getInitState = () => ({
  loading: false,
  evaluations: [],
  isModalActive: false,
  isHistoryModalActive: false,
  selectedEvaluation: {
    id: '',
    assessedResourceId: '',
    techInterviewDate: null,
    techInterviewTechnologyId: '',
    techInterviewerId: '',
    engInterviewDate: null,
    engInterviewerId: '',
  },
});

export default function ResourcesEvaluationReducer(state = getInitState(), action) {
  const { type, payload } = action;

  switch (type) {
    case resourcesEvaluationActions.GET_RESOURCES_EVALUATION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case resourcesEvaluationActions.GET_RESOURCES_EVALUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        evaluations: payload,
      }
    case resourcesEvaluationActions.GET_RESOURCES_EVALUATION_ERROR:
      return {
        ...state,
        loading: false,
      }

    case resourcesEvaluationActions.RESOURCES_EVALUATION_TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
        selectedEvaluation: {
          ...(payload.evaluation || getInitState().selectedEvaluation),
        },
      };

      case resourcesEvaluationActions.RESOURCES_EVALUATION_TOGGLE_HISTORY_MODAL:
      return {
        ...state,
        isHistoryModalActive: !state.isHistoryModalActive,
        selectedEvaluation: {
          ...(payload.evaluation || getInitState().selectedEvaluation),
        },
      };

    case resourcesEvaluationActions.RESOURCES_EVALUATION_ADD:
      return {
        ...state,
        loading: true,
      };

    case resourcesEvaluationActions.RESOURCES_EVALUATION_ADD_SUCCESS:
      return {
        ...state,
        evaluations: [...state.evaluations, payload],
        isModalActive: false,
        loading: false,
      };

    case resourcesEvaluationActions.RESOURCES_HISTORY_EVALUATION_ADD:
      return {
        ...state,
        loading: true,
      };

    case resourcesEvaluationActions.RESOURCES_HISTORY_EVALUATION_ADD_SUCCESS:
      return {
        ...state,
        evaluations: [...state.evaluations, payload],
        loading: false,
      };

    case resourcesEvaluationActions.RESOURCES_HISTORY_EVALUATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case resourcesEvaluationActions.RESOURCES_EVALUATION_UPDATE:
    case resourcesEvaluationActions.RESOURCES_EVALUATION_ADD_SUCCESS:
      return {
        ...state,
        isModalActive: false,
      };

    case resourcesEvaluationActions.RESOURCES_EVALUATION_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export { getInitState }
