import { all, takeEvery, put, fork } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import openNotification from 'components/Notification';
import { apiRequest } from 'redux/helpers';
import actions from 'redux/resourceRoles/actions';
import { ENDPOINTS } from 'consts';

export function* resourceRolesRequest() {
  yield takeEvery(actions.GET_RESOURCE_ROLES_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, ENDPOINTS.RESOURCE_ROLES);

    if (data.errors) {
      yield put(actions.getResourceRolesError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getResourceRolesSuccess(data));
    }
  });
}

export function* addResourceRoleRequest() {
  yield takeEvery(actions.ADD_RESOURCE_ROLE_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.post, ENDPOINTS.RESOURCE_ROLES, payload);
    if (data.errors) {
      yield put(actions.addResourceRoleError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.addResourceRoleSuccess(data));
    }
  });
}

export function* updateResourceRoleRequest() {
  yield takeEvery(actions.UPDATE_RESOURCE_ROLE_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.RESOURCE_ROLES}/${payload.roleId}`,
      payload,
    );
    if (data.errors) {
      yield put(actions.updateResourceRoleError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.updateResourceRoleSuccess(data));
    }
  });
}

export function* deleteResourceRoleRequest() {
  yield takeEvery(actions.DELETE_RESOURCE_ROLE_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.RESOURCE_ROLES}/${payload.roleId}`,
      payload,
    );
    if (data.errors) {
      yield put(actions.deleteResourceRoleError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.deleteResourceRoleSuccess(payload.roleId));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(resourceRolesRequest),
    fork(addResourceRoleRequest),
    fork(updateResourceRoleRequest),
    fork(deleteResourceRoleRequest),
  ]);
};
