const DOCUMENT = 'RECIPIENTS_';
const recipientsActions = {
  GET_RECIPIENTS_REQUEST: `GET_${DOCUMENT}REQUEST`,
  GET_RECIPIENTS_SUCCESS: `GET_${DOCUMENT}SUCCESS`,
  GET_RECIPIENTS_ERROR: `GET_${DOCUMENT}ERROR`,

  SET_RECIPIENTS_NEXT: `SET_${DOCUMENT}NEXT`,

  GET_RECIPIENTS_INITIAL_STATE: `GET_${DOCUMENT}INITIAL_STATE`,
  SET_RECIPIENTS_INITIAL_STATE: `SET_${DOCUMENT}INITIAL_STATE`,

  GET_RECIPIENTS_STATES: `GET_${DOCUMENT}STATES`,
  GET_RECIPIENTS_CITIES: `GET_${DOCUMENT}CITIES`,

  ADD_REQUEST: `ADD_${DOCUMENT}REQUEST`,
  ADD_SUCCESS: `ADD_${DOCUMENT}SUCCESS`,
  ADD_ERROR: `ADD_${DOCUMENT}ERROR`,

  DELETE_RECIPIENT_REQUEST: `${DOCUMENT}_DELETE_RECIPIENT_REQUEST`,
  DELETE_RECIPIENT_SUCCESS: `${DOCUMENT}DELETE_RECIPIENT_SUCCESS`,
  DELETE_RECIPIENT_ERROR: `${DOCUMENT}DELETE_RECIPIENT_ERROR`,

  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,
  UPDATE_RECIPIENT: `${DOCUMENT}UPDATE_RECIPIENT`,
  UPDATE_RECIPIENT_ERROR: `${DOCUMENT}UPDATE_RECIPIENT_ERROR`,

  SELECTION_CHANGE: `${DOCUMENT}SELECTION_TOGGLE`,
  MULTIPLE_SELECTION_CHANGE: `${DOCUMENT}MULTIPLE_SELECTION_TOGGLE`,
  MULTIPLE_DELETE_REQUEST: `${DOCUMENT}MULTIPLE_DELETE_REQUEST`,
  MULTIPLE_DELETE_SUCCESS: `${DOCUMENT}MULTIPLE_DELETE_SUCCESS`,

  TOGGLE_IMPORT_MODAL: `${DOCUMENT}TOGGLE_IMPORT_MODAL`,
  IMPORT_DATA: `${DOCUMENT}IMPORT_DATA`,
  IMPORT_DATA_SUCCESS: `${DOCUMENT}IMPORT_DATA_SUCCESS`,
  IMPORT_DATA_ERROR: `${DOCUMENT}IMPORT_DATA_ERROR`,

  TOGGLE_ADD_GROUP_MODAL: `${DOCUMENT}TOGGLE_ADD_GROUP_MODAL`,
  DELETE_FROM_GROUP: `${DOCUMENT}DELETE_FROM_GROUP`,
  DELETE_FROM_GROUP_SUCCESS: `${DOCUMENT}DELETE_FROM_GROUP_SUCCESS`,
  DELETE_FROM_GROUP_ERROR: `${DOCUMENT}DELETE_FROM_GROUP_ERROR`,

  ADD_TO_GROUP: `${DOCUMENT}ADD_TO_GROUP`,
  ADD_TO_GROUP_SUCCESS: `${DOCUMENT}ADD_TO_GROUP_SUCCESS`,
  ADD_TO_GROUP_ERROR: `${DOCUMENT}ADD_TO_GROUP_ERROR`,

  RESET_STATE: `${DOCUMENT}RESET_STATE`,

  getRecipientsInitialState: payload => ({
    type: recipientsActions.GET_RECIPIENTS_INITIAL_STATE,
    payload
  }),

  setRecipientsInitialState: payload => ({
    type: recipientsActions.SET_RECIPIENTS_INITIAL_STATE,
    payload
  }),

  getRecipientsRequest: nextPage => ({
    type: recipientsActions.GET_RECIPIENTS_REQUEST,
    payload: { nextPage }
  }),

  getRecipientsSuccess: payload => ({
    type: recipientsActions.GET_RECIPIENTS_SUCCESS,
    payload
  }),

  getRecipientsError: payload => ({
    type: recipientsActions.GET_RECIPIENTS_ERROR,
    payload
  }),

  setRecipientsNext: payload => ({
    type: recipientsActions.SET_RECIPIENTS_NEXT,
    payload
  }),

  addRecipient: recipient => ({
    type: recipientsActions.ADD_REQUEST,
    payload:  recipient,
  }),

  createRecipientSuccess: recipient => ({
    type: recipientsActions.ADD_SUCCESS,
    payload: { recipient },
  }),

  createRecipientError: error => ({
    type: recipientsActions.ADD_ERROR,
    payload: error,
  }),

  updateRecipient: (recipient, recipientId) => ({
    type: recipientsActions.UPDATE_RECIPIENT,
    payload: { recipient, recipientId },
  }),

  updateRecipientError: (payload) => ({
    type: recipientsActions.UPDATE_RECIPIENT_ERROR,
    payload,
  }),

  deleteRecipient: id => ({
    type: recipientsActions.DELETE_RECIPIENT_REQUEST,
    payload: {
      id
    },
  }),

  multipleDeleteRequest: ids => ({
    type: recipientsActions.MULTIPLE_DELETE_REQUEST,
    payload: {
      ids
    },
  }),

  multipleDeleteSuccess: payload => ({
    type: recipientsActions.MULTIPLE_DELETE_SUCCESS,
    payload
  }),

  selectionChange: (id, isChecked) => ({
    type: recipientsActions.SELECTION_CHANGE,
    payload: { id, isChecked }
  }),

  multipleSelectionChange: (ids, isChecked) => ({
    type: recipientsActions.MULTIPLE_SELECTION_CHANGE,
    payload: { ids, isChecked }
  }),

  toggleModal: (recipient) => ({
    type: recipientsActions.TOGGLE_MODAL,
    payload: { recipient }
  }),

	toggleImportModal: (payload) => ({
    type: recipientsActions.TOGGLE_IMPORT_MODAL,
    payload
  }),

  importData: request => ({
    type: recipientsActions.IMPORT_DATA,
    payload: {
      ...request
    },
  }),
  importDataSuccess: data => ({
    type: recipientsActions.IMPORT_DATA_SUCCESS,
    payload: {
      ...data
    },
  }),
  importDataError: error => ({
    type: recipientsActions.IMPORT_DATA_ERROR,
    payload: {
      error
    },
  }),

	toggleAddGroupModal: () => ({
    type: recipientsActions.TOGGLE_ADD_GROUP_MODAL,
  }),

  deleteRecipientFromGroup: (recipientIds, groupIds) => ({
    type: recipientsActions.DELETE_FROM_GROUP,
    payload: {
      recipientIds,
      groupIds
    }
  }),

  deleteRecipientFromGroupSuccess: () => ({
    type: recipientsActions.DELETE_FROM_GROUP_SUCCESS,
  }),

  deleteRecipientFromGroupError: (error) => ({
    type: recipientsActions.DELETE_FROM_GROUP_ERROR,
    payload: {
      error
    },
  }),

  addRecipientToGroup: (recipientIds, groupIds) => ({
    type: recipientsActions.ADD_TO_GROUP,
    payload: {
      recipientIds, groupIds
    }
  }),

  addRecipientToGroupSuccess: () => ({
    type: recipientsActions.ADD_TO_GROUP_SUCCESS,
  }),

  addRecipientToGroupError: () => ({
    type: recipientsActions.ADD_TO_GROUP_ERROR,
  }),

  resetState: () => ({
    type: recipientsActions.RESET_STATE,
  })
};

export default recipientsActions;
