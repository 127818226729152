export const onlyLoadingFalse = state => ({
  ...state,
  loading: false,
});

export const onlyLoadingTrue = state => ({
  ...state,
  loading: true,
});

export const getFutureProjectsListRequest = state => {
  return {
    ...state,
    loading: true,
  };
};

export const getFutureProjectsListSuccess = (state, payload = {}) => {
  const { futureProjectsList } = payload;
  return {
    ...state,
    futureProjectsList,
    loading: false,
    isLoadNext: false,
    searchTimeOut: false,
  };
};

export const getFutureProjectsListError = state => ({
  ...state,
  loading: false,
  isLoadNext: false,
});

export const deleteFutureProjectSuccess = state => ({
  ...state,
  isModalActive: false,
  loading: false,
});

export const updateSelectedProject = (state, payload = {}) => {
  const { id } = payload;
  return {
    ...state,
    loading: false,
    futureProjectsList: state.futureProjectsList.map(item =>
      item.id === id ? { ...item, ...payload } : item,
    ),
    selectedProject: { ...state.selectedProject, ...payload },
  };
};

export const addFutureProjectSuccess = (state, payload = {}) => {
  const { isModalActive } = payload;
  return {
    ...state,
    isModalActive,
    loading: false,
  };
};

export const resourceByTechnologySuccess = (state, payload = {}) => {
  const { id, resources, type } = payload;
  return {
    ...state,
    loading: false,
    resourceByTechnologyList: {
      ...state.resourceByTechnologyList,
      [`${type}${id}`]: resources,
    },
  };
};

export const setPagination = (state, payload = {}) => ({
  ...state,
  pagination: {
    ...state.pagination,
    ...payload,
  },
});

// export const setNextPage = state => ({
//   ...state,
//   isLoadNext: true,
// });

export const setSearch = (state, payload = {}) => ({
  ...state,
  searchValue: {
    ...state.searchValue,
    title: payload,
  },
});

export const setSelectedProject = (state, payload = {}) => ({
  ...state,
  selectedProject: payload,
});

export const toggleModal = state => ({
  ...state,
  isModalActive: !state.isModalActive,
});

export const toggleModalLoadingFalse = state => ({
  ...state,
  isModalActive: false,
  loading: false,
});

export const getFutureProjectsSectionsRequest = state => {
  return {
    ...state,
    futureProjectsSections: [],
    loading: true,
  };
};

export const getFutureProjectsSectionsSuccess = (state, payload = {}) => {
  return {
    ...state,
    loading: false,
    futureProjectsSections: payload,
  };
};

export const getFutureProjectsSectionsError = state => ({
  ...state,
  loading: false,
});

export const addFutureProjectsSectionsRequest = state => {
  return {
    ...state,
    loading: true,
  };
};

export const addFutureProjectsSectionsSuccess = (state, payload) => {
  return {
    ...state,
    loading: false,
    futureProjectsSections: [payload, ...state.futureProjectsSections],
  };
};

export const addFutureProjectsSectionsError = state => ({
  ...state,
  loading: false,
});

export const updateFutureProjectsSectionsRequest = state => {
  return {
    ...state,
    loading: false,
  };
};

export const updateFutureProjectsSectionsSuccess = state => {
  return {
    ...state,
    loading: false,
  };
};

export const updateFutureProjectsSectionsError = state => ({
  ...state,
  loading: false,
});

export const removeFutureProjectsSectionsRequest = (state, payload) => {
  return {
    ...state,
    loading: true,
  };
};

export const removeFutureProjectsSectionsSuccess = (state, payload) => {
  return {
    ...state,
    loading: false,
    futureProjectsSections: state.futureProjectsSections.filter(elem => elem.id !== payload),
  };
};

export const removeFutureProjectsSectionsError = (state, payload) => ({
  ...state,
  loading: false,
});

export const rescheduleFutureProjectsRequest = state => {
  return {
    ...state,
    loading: false,
  };
};

export const rescheduleFutureProjectsSuccess = state => {
  return {
    ...state,
    loading: false,
    isRescheduleModalActive: false,
  };
};

export const rescheduleFutureProjectsError = state => ({
  ...state,
  loading: false,
});

export const toggleRescheduleModal = state => ({
  ...state,
  isRescheduleModalActive: !state.isRescheduleModalActive,
});

export const resetFutureProjectsState = state => ({
  ...state,
  searchValue: {
    title: '',
  },
  sortValues: [],
  pagination: {
    pageSize: 0,
    currentPage: 1,
    totalItems: 0,
  },
  isLoadNext: false,
  isModalActive: false,
  futureProjectsList: [],
  isRescheduleModalActive: false,
  resourceByTechnologyList: {},
  selectedProject: {},
  futureProjectsSections: {},
});
