const DOCUMENT = 'DOCUMENTS_';
const documentsActions = {
  GET_DOCUMENTS_REQUEST: `GET_${DOCUMENT}REQUEST`,
  GET_DOCUMENTS_SUCCESS: `GET_${DOCUMENT}SUCCESS`,
  GET_DOCUMENTS_ERROR: `GET_${DOCUMENT}ERROR`,

  DELETE_DOCUMENTS_REQUEST: `DELETE_${DOCUMENT}REQUEST`,
  DELETE_DOCUMENTS_SUCCESS: `DELETE_${DOCUMENT}SUCCESS`,
  DELETE_DOCUMENTS_ERROR: `DELETE_${DOCUMENT}ERROR`,

  CREATE_DOCUMENTS_REQUEST: `CREATE_${DOCUMENT}REQUEST`,
  CREATE_DOCUMENTS_SUCCESS: `CREATE_${DOCUMENT}SUCCESS`,
  CREATE_DOCUMENTS_ERROR: `CREATE_${DOCUMENT}ERROR`,

  UPDATE_DOCUMENTS_REQUEST: `UPDATE_${DOCUMENT}REQUEST`,
  UPDATE_DOCUMENTS_SUCCESS: `UPDATE_${DOCUMENT}SUCCESS`,
  UPDATE_DOCUMENTS_ERROR: `UPDATE_${DOCUMENT}ERROR`,

  RESET_VIEW_DOCUMENTS_STATE: `RESET_VIEW_DOCUMENTS_STATE`,

  UPDATE_BY_TITLE_FILTER: `UPDATE_BY_TITLE_FILTER`,
  UPDATE_BY_TITLE_FILTER_SUCCESS: `UPDATE_BY_TITLE_FILTER_SUCCESS`,
  UPDATE_BY_TITLE_FILTER_ERROR: `UPDATE_BY_TITLE_FILTER_ERROR`,

  getDocumentsRequest: payload => ({
    type: documentsActions.GET_DOCUMENTS_REQUEST,
    payload
  }),

  getDocumentsSuccess: payload => ({
    type: documentsActions.GET_DOCUMENTS_SUCCESS,
    payload
  }),

  getDocumentsError: payload => ({
    type: documentsActions.GET_DOCUMENTS_ERROR,
    payload
  }),

  deleteDocumentsRequest: payload => ({
    type: documentsActions.DELETE_DOCUMENTS_REQUEST,
    payload
  }),

  deleteDocumentsSuccess: payload => ({
    type: documentsActions.DELETE_DOCUMENTS_SUCCESS,
    payload
  }),

  deleteDocumentsError: payload => ({
    type: documentsActions.DELETE_DOCUMENTS_ERROR,
    payload
  }),

  createDocumentsRequest: payload => ({
    type: documentsActions.CREATE_DOCUMENTS_REQUEST,
    payload
  }),

  createDocumentsSuccess: payload => ({
    type: documentsActions.CREATE_DOCUMENTS_SUCCESS,
    payload
  }),

  createDocumentsError: payload => ({
    type: documentsActions.CREATE_DOCUMENTS_ERROR,
    payload
  }),

  updateDocumentsRequest: (object, id) => ({
      type: documentsActions.UPDATE_DOCUMENTS_REQUEST,
      payload: {object, id},
    }),

  updateDocumentsSuccess: payload => ({
      type: documentsActions.UPDATE_DOCUMENTS_SUCCESS,
      payload
    }),

  updateDocumentsError: payload => ({
      type: documentsActions.UPDATE_DOCUMENTS_ERROR,
      payload
    }),

  updateByTitleFilter: payload => ({
      type: documentsActions.UPDATE_BY_TITLE_FILTER,
      payload
    }),
  updateByTitleFilterSuccess: payload => ({
      type: documentsActions.UPDATE_BY_TITLE_FILTER_SUCCESS,
      payload
    }),

  resetViewDocumentsState: () => ({
    type: documentsActions.RESET_VIEW_DOCUMENTS_STATE,
  }),
};

export default documentsActions;
