const DOCUMENT = 'STATS_';

const statsActions = {
  GET_RESOURCE_STATS_REQUEST: `${DOCUMENT}RESOURCE_REQUEST`,
  GET_RESOURCE_STATS_SUCCESS: `${DOCUMENT}RESOURCE_SUCCESS`,
  GET_RESOURCE_STATS_ERROR: `${DOCUMENT}RESOURCE_ERROR`,
  GET_PROJECT_STATS_REQUEST: `${DOCUMENT}PROJECT_REQUEST`,
  GET_PROJECT_STATS_SUCCESS: `${DOCUMENT}PROJECT_SUCCESS`,
  GET_PROJECT_STATS_ERROR: `${DOCUMENT}PROJECT_ERROR`,
  GET_CLOSED_PROJECTS_STATS_REQUEST: `${DOCUMENT}CLOSED_PROJECTS_REQUEST`,
  GET_CLOSED_PROJECTS_STATS_SUCCESS: `${DOCUMENT}CLOSED_PROJECTS_SUCCESS`,
  GET_CLOSED_PROJECTS_STATS_ERROR: `${DOCUMENT}CLOSED_PROJECTS_ERROR`,
  GET_PROJECT_FLAGS_REQUEST: `${DOCUMENT}FLAGS_REQUEST`,
  GET_PROJECT_FLAGS_SUCCESS: `${DOCUMENT}FLAGS_SUCCESS`,
  GET_PROJECT_FLAG_COLOR: `${DOCUMENT}FLAG_COLOR`,
  GET_PROJECT_FLAGS_ERROR: `${DOCUMENT}FLAGS_ERROR`,

  getResourceStats: (payload) => ({
    type: statsActions.GET_RESOURCE_STATS_REQUEST,
    payload,
  }),

  getResourceStatsSuccess: (payload) => ({
    type: statsActions.GET_RESOURCE_STATS_SUCCESS,
    payload,
  }),

  getResourceStatsError: (error) => ({
    type: statsActions.GET_RESOURCE_STATS_ERROR,
    error,
  }),

  getProjectStats: (payload) => ({
    type: statsActions.GET_PROJECT_STATS_REQUEST,
    payload,
  }),

  getProjectStatsSuccess: (payload) => ({
    type: statsActions.GET_PROJECT_STATS_SUCCESS,
    payload,
  }),

  getProjectStatsError: (error) => ({
    type: statsActions.GET_PROJECT_STATS_ERROR,
    error,
  }),

  getClosedProjectsStats: (payload) => ({
    type: statsActions.GET_CLOSED_PROJECTS_STATS_REQUEST,
    payload,
  }),

  getClosedProjectsStatsSuccess: (payload) => ({
    type: statsActions.GET_CLOSED_PROJECTS_STATS_SUCCESS,
    payload,
  }),

  getClosedProjectsStatsError: (error) => ({
    type: statsActions.GET_CLOSED_PROJECTS_STATS_ERROR,
    error,
  }),

  getProjectFlags: (payload) => ({
    type: statsActions.GET_PROJECT_FLAGS_REQUEST,
    payload,
  }),

  getProjectFlagsSuccess: (payload) => ({
    type: statsActions.GET_PROJECT_FLAGS_SUCCESS,
    payload,
  }),

  getProjectFlagColor: (flagType) => ({
    type: statsActions.GET_PROJECT_FLAG_COLOR,
    flagType
  }),

  getProjectFlagsError: (error) => ({
    type: statsActions.GET_PROJECT_FLAGS_ERROR,
    error,
  })
}

export default statsActions;
