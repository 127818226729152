const actions = {
  HIDE_MODAL: 'CHART_MODAL_HIDE',
  SHOW_MODAL: 'CHART_MODAL_SHOW',

  showModal: (payload) => ({
    type: actions.SHOW_MODAL,
    payload
  }),

  hideModal: () => ({
    type: actions.HIDE_MODAL
  })
};
export default actions;
