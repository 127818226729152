const actions = {
  GET_PROJECTMANAGERS_REQUEST: 'GET_PROJECTMANAGERS_REQUEST',
  GET_PROJECTMANAGERS_SUCCESS: 'GET_PROJECTMANAGERS_SUCCESS',
  GET_PROJECTMANAGERS_ERROR: 'GET_PROJECTMANAGERS_ERROR',

  UPDATE_MANAGER_REQUEST: 'PROJECT_UPDATE_MANAGER_REQUEST',
  UPDATE_MANAGER_SUCCESS: 'PROJECT_UPDATE_MANAGER_SUCCESS',
  UPDATE_MANAGER_ERROR: 'PROJECT_UPDATE_MANAGER_ERROR',

  updateProjectManagerRequest: payload => ({
    type: actions.UPDATE_MANAGER_REQUEST,
    payload,
  }),
  updateProjectManagerSuccess: payload => ({
    type: actions.UPDATE_MANAGER_SUCCESS,
    payload,
  }),
  updateProjectManagerError: payload => ({
    type: actions.UPDATE_MANAGER_ERROR,
    payload,
  }),

  getProjectManagersRequest: (options) => ({
    type: actions.GET_PROJECTMANAGERS_REQUEST,
    payload: options,
  }),
  getProjectManagersSuccess: (projectManagers) => ({
    type: actions.GET_PROJECTMANAGERS_SUCCESS,
    payload: { projectManagers },
  }),
  getProjectManagersError: (error) => ({
    type: actions.GET_PROJECTMANAGERS_ERROR,
    payload: { error },
  }),
};

export default actions;
