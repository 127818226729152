import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import { ENDPOINTS } from 'consts';
import { apiRequest } from 'redux/helpers';
import actions from 'redux/technologies/actions';
import openNotification from 'components/Notification';
import tableActions from 'components/NewTable/redux/actions';
import { createQueryString } from 'helpers/utility';
import { setUrl } from 'helpers/urlSync';

export function* technologyTypesRequest() {
  yield takeEvery(actions.GET_TECHNOLOGY_TYPES_REQUEST, function*() {
    const data = yield apiRequest(superFetch.get, '/types/technology');
    if (data.errors) {
      yield put(actions.getTechnologyTypesError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getTechnologyTypesSuccess(data));
    }
  });
}

export function* technologiesRequest() {
  yield takeEvery(actions.GET_TECHNOLOGIES_REQUEST, function*({payload: {ignorSetUrl = false, ...restPayload}}) {
    const { pagination, sortValues } = yield select(state => state.tableReducer);
    let querySort;

    if (sortValues.length) {
      querySort = { ...sortValues[0] };
    } else {
      querySort = { ...sortValues };
    }

    if (querySort.columnKey === 'technologyType') {
      querySort.columnKey = 'type';
    }

    const newPagination = restPayload.setPagination ? pagination : { pageSize: 0, currentPage: 1, totalItems: 0 };

    const QUERY = createQueryString({ pagination: newPagination, sort: querySort })
    const queryUrl = `${ENDPOINTS.TECHNOLOGY}?${QUERY}`;
    const data = yield apiRequest(superFetch.get, queryUrl);

    if (!ignorSetUrl) {
      setUrl(queryUrl);
    }

    if (data.errors) {
      yield put(actions.getTechnologiesError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getTechnologiesSuccess(data.technologies));
      if (restPayload.setPagination) {
        yield put(tableActions.setPagination(data.pagination));
      }
    }
  });
}

export function* addTechnologyRequest() {
  yield takeEvery(actions.ADD_TECHNOLOGY_REQUEST, function*({ payload }) {
    const data = yield apiRequest(superFetch.post, ENDPOINTS.TECHNOLOGY, payload);
    if (data.errors) {
      yield put(actions.addTechnologyError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.addTechnologySuccess(data));
      yield put(actions.getTechnologiesRequest());
    }
  });
}

export function* updateTechnologyRequest() {
  yield takeEvery(actions.UPDATE_TECHNOLOGY_REQUEST, function*({ payload }) {
    const techs = yield select(state => state.Technologies.technologies);
    const { index, columnsKey, value } = payload;
    const updatedUser = techs.find(item => item.id === index);
    if (columnsKey === 'technologyType') {
      updatedUser.technologyTypeId = value.shift();
    } else {
      updatedUser[columnsKey] = value;
    }
    const data = yield apiRequest(superFetch.put, `${ENDPOINTS.TECHNOLOGY}/${index}`, updatedUser);
    if (data.errors) {
      yield put(actions.updateTechnologyError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.updateTechnologySuccess(data));
      yield put(actions.getTechnologiesRequest());
    }
  });
}

export function* deleteTechnologyRequest() {
  yield takeEvery(actions.DELETE_TECHNOLOGY_REQUEST, function*({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.TECHNOLOGY}/${payload.technologyId}`,
    );
    if (data.errors) {
      yield put(actions.deleteTechnologyError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.deleteTechnologySuccess(payload.technologyId));
      yield put(actions.getTechnologiesRequest());
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(technologyTypesRequest),
    fork(technologiesRequest),
    fork(addTechnologyRequest),
    fork(updateTechnologyRequest),
    fork(deleteTechnologyRequest),
  ]);
}
