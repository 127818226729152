import openNotification from 'components/Notification';
import { ENDPOINTS } from 'consts';
import superFetch from 'helpers/superFetch';
import { setUrl } from 'helpers/urlSync';
import { createQueryString } from 'helpers/utility';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { apiRequest } from 'redux/helpers';
import tableActions from 'components/NewTable/redux/actions';
import potentialProjectsActions from './actions';
import { getUpdatedPotentialProject } from '../../helpers/projectFlag';

export function* getPotentialProjects() {
  yield takeEvery(potentialProjectsActions.POTENTIAL_PROJECTS_REQUEST,
    function* ({ payload, ignoreSetUrl }) {
      const { searchValue } = yield select(state => state.potentialProjectsReducer);
      const { pagination, searchValue: globalSearchValues, sortValues } = yield select(
        state => state.tableReducer,
      );
      const subParams = {};
      if (payload) {
        if (payload.qType)
          subParams.qType = payload.qType;
      }

      const queryUrl = `${ENDPOINTS.POTENTIAL_PROJECTS}?${createQueryString({
          pagination,
          multiSort: sortValues,
          fields: {
            ...searchValue,
            ...globalSearchValues,
          },
          ...subParams,
        },
      )}`;

      const data = yield apiRequest(superFetch.get, queryUrl);
      if (!ignoreSetUrl) {
        setUrl(queryUrl);
      }

      if (data.errors) {
        yield put(potentialProjectsActions.getPotentialProjectsError(data.errors));
        yield openNotification('error', 'Error', data.errors);
      } else {
        yield put(potentialProjectsActions.getPotentialProjectsSuccess(data.projects));
        yield put(tableActions.setPagination(data.pagination));
      }
    });
}

export function* createPotentialProjects() {
  yield takeEvery(potentialProjectsActions.CREATE_POTENTIAL_PROJECTS_REQUEST,
    function* ({ payload }) {

      let { pagination } = yield select(state => state.tableReducer);
      pagination = {
        ...pagination,
        totalItems: pagination.totalItems + 1,
      }

      const queryUrl = `${ENDPOINTS.POTENTIAL_PROJECTS}`;
      const data = yield apiRequest(superFetch.post, queryUrl, payload);

      if (data.errors) {
        yield put(potentialProjectsActions.getCreatePotentialProjectError(data.errors));
        yield openNotification('error', 'Error', data.errors);
      } else {
        yield put(potentialProjectsActions.getCreatePotentialProjectSuccess(data));
        yield put(tableActions.setPagination(pagination));
      }
    });
}

export function* updatePotentialProjects() {
  yield takeEvery(potentialProjectsActions.UPDATE_POTENTIAL_PROJECTS_REQUEST,
    function* ({ payload }) {
      const { potentialProjects } = yield select(state => state.potentialProjectsReducer);
      const queryUrl = `${ENDPOINTS.POTENTIAL_PROJECTS}/${payload.potentialProjectId}`;
      const data = yield apiRequest(
        superFetch.put,
        queryUrl,
        payload.object,
      );
      const newData = getUpdatedPotentialProject(potentialProjects, data)
      if (data.errors) {
        yield put(potentialProjectsActions.getUpdatePotentialProjectError(data.errors));
        yield openNotification('error', 'Error', data.errors);
      } else {
        yield put(potentialProjectsActions.getUpdatePotentialProjectSuccess(newData));
      }
    });
}

export function* deletePotentialProjects() {
  yield takeEvery(potentialProjectsActions.DELETE_POTENTIAL_PROJECTS_REQUEST,
    function* ({ payload }) {

      let { pagination } = yield select(state => state.tableReducer);
      pagination = {
        ...pagination,
        totalItems: pagination.totalItems - 1,
      }

      const queryUrl = `${ENDPOINTS.POTENTIAL_PROJECTS}/${payload}`;
      const data = yield apiRequest(superFetch.delete, queryUrl);

      if (data.errors) {
        yield put(potentialProjectsActions.getDeletePotentialProjectError(data.errors));
        yield openNotification('error', 'Error', data.errors);
      } else {
        yield put(potentialProjectsActions.getDeletePotentialProjectSuccess(payload));
        yield put(tableActions.setPagination(pagination));
      }
    });
}

export function* getMoveToDiscovery() {
  yield takeEvery(potentialProjectsActions.MOVE_TO_DISCOVERY_REQUEST,
    function* ({ payload }) {

      let { pagination } = yield select(state => state.tableReducer);
      pagination = {
        ...pagination,
        totalItems: pagination.totalItems - 1,
      }

      const queryUrl = `${ENDPOINTS.POTENTIAL_PROJECTS}/${payload.id}/moveToDiscovery`;
      const data = yield apiRequest(superFetch.patch, queryUrl);

      if (data.errors) {
        yield put(potentialProjectsActions.getMoveToDiscoveryError(data.errors));
        yield openNotification('error', 'Error', data.errors);
      } else {
        yield put(potentialProjectsActions.getMoveToDiscoverySuccess(payload.id));
        yield put(tableActions.setPagination(pagination));
      }
    });
}

export default function* rootSaga() {
  yield all([
    fork(getPotentialProjects),
    fork(createPotentialProjects),
    fork(updatePotentialProjects),
    fork(deletePotentialProjects),
    fork(getMoveToDiscovery),
  ]);
}
