import { images } from 'settings';

export const generateEditableView = (state, payload) => {
  return {
    ...state,
    resource: {
      ...state.resource,
      ...payload,
      avatar: payload.avatar || images.avatarPlaceholder,
      jiraLink: payload.jiraLink || '',
      hubstaffLink: payload.hubstaffLink || '',
      cvLink: payload.cvLink || '',
      githubLink: payload.githubLink || '',
      phone: payload.phone || '',
      skype: payload.skype || '',
      lastEvaluationDate: payload.lastEvaluationDate || '',
      lastEnglishEvaluationDate: payload.lastEnglishEvaluationDate || '',
      primaryTechnologyId: payload.primaryTechnologyId || '',
      secondaryTechnologyId: payload.secondaryTechnologyId || '',
      englishLevelId: payload.englishLevelId || '',
      potentialProjectId: payload.potentialProjectId || '',
      techLevelId: payload.techLevelId || '',
      defaultAssignmentTypeId: payload.defaultAssignmentTypeId || '',
      currentAssignmentTypeId: payload.currentAssignmentTypeId || '',
    },
    loading: false
  }
}

export const addSpinByAll = (state) => {
  return {
    editView: {
      info: {
        ...state.editView.info,
        loading: true
      },
      contacts: {
        ...state.editView.contacts,
        loading: true
      },
      projects: {
        ...state.editView.projects,
        loading: true
      },
      technologies: {
        ...state.editView.technologies,
        loading: true
      },
      other: {
        ...state.editView.other,
        loading: true
      },
      vacation: {
        ...state.editView.vacation,
        loading: true
      },
      contract: {
        ...state.editView.contract,
        loading: true
      },
      hire: {
        ...state.editView.hire,
        loading: true
      },
      salary: {
        ...state.editView.salary,
        loading: true
      },
      signingBonus: {
        ...state.editView.salary,
        loading: true
      },
      referralBonus: {
        ...state.editView.referralBonus,
        loading: true
      },
      comments: {
        ...state.editView.comments,
        loading: true
      },
    }
  }
};

export const removeSpinByAll = (state) => {
  return {
    editView: {
      info: {
        ...state.editView.info,
        loading: false
      },
      contacts: {
        ...state.editView.contacts,
        loading: false
      },
      projects: {
        ...state.editView.projects,
        loading: false
      },
      technologies: {
        ...state.editView.technologies,
        loading: false
      },
      other: {
        ...state.editView.other,
        loading: false
      },
      vacation: {
        ...state.editView.vacation,
        loading: false
      },
      contract: {
        ...state.editView.contract,
        loading: false
      },
      hire: {
        ...state.editView.hire,
        loading: false
      },
      salary: {
        ...state.editView.salary,
        loading: false
      },
      signingBonus: {
        ...state.editView.salary,
        loading: false
      },
      referralBonus: {
        ...state.editView.referralBonus,
        loading: false
      },
      comments: {
        ...state.editView.comments,
        loading: false
      },
    }
  }
};
