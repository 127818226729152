export const onlyLoadingFalse = state => ({
  ...state,
  loading: false,
});

export const onlyLoadingTrue = state => ({
  ...state,
  loading: true,
});

export const getVacationReceiversSuccess = (state, payload = {}) => {
  return {
    ...state,
    emailList: payload,
    loading: false,
  };
};

export const getVacationHolidaysSuccess = (state, payload = []) => {
  return {
    ...state,
    holidays: payload,
    loading: false,
  };
};

export const updateVacationHolidaysSuccess = state => ({
  ...state,
  holidaysForm: {
    ...state.holidaysForm,
    toggleEdit: false,
  },
  loading: false,
});

export const updateVacationReceiversSuccess = (state, payload = []) => ({
    ...state,
    emailList: payload,
    loading: false,
});

export const deleteVacationHolidaysSuccess = (state, payload = {}) => {
  const { id } = payload;
  return {
    ...state,
    holidays: state.holidays.filter(holiday => holiday.id !== id),
    loading: false,
  };
};

export const toggleEditReceivers = state => ({
  ...state,
  receiversForm: {
    ...state.receiversForm,
    toggleEdit: !state.receiversForm.toggleEdit,
  },
});

export const toggleEditHolidays = state => ({
  ...state,
  holidaysForm: {
    ...state.holidaysForm,
    toggleEdit: !state.holidaysForm.toggleEdit,
  },
});

export const setEditedHoliday = (state, payload = {}) => ({
  ...state,
  holidaysForm: {
    ...state.holidaysForm,
    editedHolidays: payload,
  },
});

export const onlyEditFalse = state => ({
  ...state,
  receiversForm: {
    ...state.receiversForm,
    toggleEdit: false,
  },
});
