import mailingsActions from 'redux/marketing/mailings/listPage/actions';

export const PAGE_SIZE_LOCALSTORAGE = 'Mailings_PageSize';

const initState = {
  loading: false,
  mailingsList: [],
  columns: [],
  isAddModalActive: false,
  newMailing: {
    redirectTo: 0,
    formNewMailing: {
      status: false,
      loading: false,
    },
  },
  status: [],
  reloadPage: true,
  reloadPageInterval: 0,
};

export default function mailingsReducer(state = initState, action) {
  switch (action.type) {
    case mailingsActions.GET_MAILINGS_LIST_REQUEST:
      return {
        ...state,
        loading:
          typeof action.payload !== 'undefined' && typeof action.payload.loading !== 'undefined'
            ? action.payload.loading
            : true,
        reloadPage: true,
      };

    case mailingsActions.GET_MAILINGS_LIST_SUCCESS:
      const { mailingsList } = action.payload || [];
      return {
        ...state,
        mailingsList,
        loading: false,
        reloadPage: false,
      };

    case mailingsActions.GET_MAILINGS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        reloadPage: false,
      };

    case mailingsActions.SET_INITIAL_MAILINGS_LIST_STATE:
      return {
        ...state,
        ...action?.payload,
        loading: true
      };

    case mailingsActions.GET_MAILINGS_STATUS_REQUEST:
      return {
        ...state,
      };

    case mailingsActions.GET_MAILINGS_STATUS_SUCCESS:
      return {
        ...state,
        status: action?.payload?.status || [],
      };

    case mailingsActions.GET_MAILINGS_STATUS_ERROR:
      return {
        ...state,
        status: action?.payload?.status || [],
      };

    case mailingsActions.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case mailingsActions.DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case mailingsActions.START_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case mailingsActions.START_ERROR:
      return {
        ...state,
        loading: false,
      };

    case mailingsActions.PAUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case mailingsActions.PAUSE_ERROR:
      return {
        ...state,
        loading: false,
      };
      
    case mailingsActions.ADD_MAILING_REQUEST:
      return {
        ...state,
        newMailing: {
          ...state.newMailing,
          [action.payload.formName]: {
            ...state.newMailing[action.payload.formName],
            loading: true,
          },
        },
      };

    case mailingsActions.ADD_MAILING_SUCCESS:
      return {
        ...state,
        isAddModalActive: false,
        newMailing: {
          ...state.newMailing,
          redirectTo: action.payload.formData?.id,
          formNewMailing: {
            ...action.payload.formData,
            loading: false,
          },
        },
      };

    case mailingsActions.ADD_MAILING_ERROR:
      return {
        ...state,
        newMailing: {
          ...state.newMailing,
          formNewMailing: {
            ...state.formNewMailing,
            loading: false,
          },
        },
      };

    case mailingsActions.SHOW_ADD_MODAL:
      return {
        ...state,
        ...action.payload,
      };

    case mailingsActions.CLOSE_REDIRECT:
      return {
        ...state,
        newMailing: {
          ...state.newMailing,
          ...action.payload,
        },
      };

    case mailingsActions.SET_RELOAD_PAGE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
