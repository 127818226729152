import { all, takeEvery, put, fork } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import openNotification from 'components/Notification';
import { apiRequest } from 'redux/helpers';
import actions from 'redux/projectStatuses/actions';
import { ENDPOINTS } from 'consts';

export function* projectStatusesRequest() {
  yield takeEvery(actions.GET_PROJECT_STATUSES_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, `${ENDPOINTS.PROJECT_STATUSES}?limit=all&page=1`);

    if (data.errors) {
      yield put(actions.getProjectStatusesError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getProjectStatusesSuccess(data));
    }
  });
}

export function* addProjectStatusRequest() {
  yield takeEvery(actions.ADD_PROJECT_STATUS_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.post, ENDPOINTS.PROJECT_STATUSES, payload);
    if (data.errors) {
      yield put(actions.addProjectStatusError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.addProjectStatusSuccess(data));
      yield put(actions.getProjectStatusesRequest());
    }
  });
}

export function* updateProjectStatusRequest() {
  yield takeEvery(actions.UPDATE_PROJECT_STATUS_REQUEST, function* ({ payload }) {
    const statusId = payload.statusId;
    delete payload.statusId;
    const data = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.PROJECT_STATUSES}/${statusId}`,
      payload,
    );
    if (data.errors) {
      yield put(actions.updateProjectStatusError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.updateProjectStatusSuccess(data));
    }
  });
}

export function* deleteProjectStatusRequest() {
  yield takeEvery(actions.DELETE_PROJECT_STATUS_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.PROJECT_STATUSES}/${payload.statusId}`,
      payload,
    );
    if (data.errors) {
      yield put(actions.deleteProjectStatusError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.deleteProjectStatusSuccess(payload.statusId));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(projectStatusesRequest),
    fork(addProjectStatusRequest),
    fork(updateProjectStatusRequest),
    fork(deleteProjectStatusRequest),
  ]);
};
