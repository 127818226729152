import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { createQueryString } from 'helpers/utility';
import openNotification from 'components/Notification';
import { ENDPOINTS, MAILING_STATUSES, MAILING_STATUS_OPTIONS } from 'consts';
import superFetch from 'helpers/superFetch';
import { apiRequest } from 'redux/helpers';
import mailingsActions from 'redux/marketing/mailings/listPage/actions';
import TabletActions from 'components/NewTable/redux/actions';
import { setUrl } from 'helpers/urlSync';

export function* getMailingsListRequest() {
  yield takeEvery(mailingsActions.GET_MAILINGS_LIST_REQUEST, function*() {
    const {
      pagination,
      sortValues,
      searchValue,
      reloadPageInterval,
    } = yield select(state => state.tableReducer);
    const queryUrl = `${ENDPOINTS.MAILINGS}?${createQueryString({
      pagination,
      multiSort: sortValues,
      fields: searchValue,
    })}`;
    if (reloadPageInterval) {
      clearTimeout(reloadPageInterval);
    }
    const data = yield apiRequest(superFetch.get, queryUrl);

    setUrl(queryUrl);

    if (data.errors) {
      yield put(mailingsActions.getMailingsListError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(
        mailingsActions.getMailingsListSuccess({
          mailingsList: data.mailings,
        }),
      );
      yield put(TabletActions.setPagination(data.pagination))
    }
  });
}

export function* getMailingsStatusRequest() {
  yield takeEvery(mailingsActions.GET_MAILINGS_STATUS_REQUEST, function*() {
    // working version but no request so far
    // const data = yield apiRequest(
    //   superFetch.get,
    //   `${ENDPOINTS.MARKETING_CAMPAIGNS}`,
    // );
    //
    // if (data.errors) {
    //   yield put(actions.getCampaignsStatusError(data.errors));
    //   yield openNotification('error', 'Error', data.errors);
    // } else {
    //   data.status = [];
    //   data.mailings.forEach(item => {
    //     if (
    //       item.status &&
    //       (!data.status.length || !data.status.find(itemFind => itemFind.title === item.status))
    //     ) {
    //       data.status.push({
    //         id: item.status,
    //         title: item.status,
    //       });
    //     }
    //   });
    //
    //   yield put(
    //     actions.getCampaignsStatusSuccess({
    //       status: data.status,
    //     }),
    //   );
    // }

    // default value

    yield put(
        mailingsActions.getMailingsStatusSuccess({
          status: MAILING_STATUS_OPTIONS,
        }),
      );

  });
}

export function* addMailingRequest() {
  yield takeEvery(mailingsActions.ADD_MAILING_REQUEST, function*({ payload }) {
    const { formData, formName } = payload;

    const data = yield apiRequest(
        superFetch.post,
        `${ENDPOINTS.MAILINGS}/`,
        {...formData}
      );

    if (data.errors) {
      yield put(mailingsActions.addMailingError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(mailingsActions.addMailingSuccess(data, formName));
    }
  });
}

export function* deleteMailingRequest() {
  yield takeEvery(mailingsActions.DELETE_REQUEST, function*({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.MAILINGS}/${payload.id}`,
    );
    const mailingState = yield select(state => state);
    if (data.errors) {
      yield put(mailingsActions.deleteMailingError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: mailingsActions.GET_MAILINGS_LIST_REQUEST,
        payload: mailingState.mailingsList,
      });
    }
  });
}

export function* startMailingRequest() {
  yield takeEvery(mailingsActions.START_REQUEST, function*({ payload }) {
    const data = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.MAILINGS}/${payload.id}`,
      {
        id: payload.id,
        status: MAILING_STATUSES.ACTIVE,
      }
    );
    const mailingState = yield select(state => state);
    if (data.errors) {
      yield put(mailingsActions.startMailingError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: mailingsActions.GET_MAILINGS_LIST_REQUEST,
        payload: mailingState.mailingsList,
      });
    }
  });
}

export function* pauseMailingRequest() {
  yield takeEvery(mailingsActions.PAUSE_REQUEST, function*({ payload }) {
    const data = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.MAILINGS}/${payload.id}`,
      {
        id: payload.id,
        status: MAILING_STATUSES.PAUSE,
      }
    );
    const mailingState = yield select(state => state);
    if (data.errors) {
      yield put(mailingsActions.pauseMailingError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: mailingsActions.GET_MAILINGS_LIST_REQUEST,
        payload: mailingState.mailingsList,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getMailingsListRequest),
    fork(getMailingsStatusRequest),
    fork(addMailingRequest),
    fork(deleteMailingRequest),
    fork(startMailingRequest),
    fork(pauseMailingRequest),
  ]);
}
