import { all, put, takeEvery } from 'redux-saga/effects';
import { ENDPOINTS } from 'consts';
import superFetch from 'helpers/superFetch';
import marketingCampaignsActions from 'redux/marketing/marketingCampaigns/actions';
import { apiRequest } from 'redux/helpers';
import openNotification from 'components/Notification';

export function* getCampaignsState() {
  yield takeEvery(marketingCampaignsActions.GET_CAMPAIGNS_STATE, function*() {
    const campaigns = yield apiRequest(superFetch.get, `${ENDPOINTS.MARKETING_CAMPAIGNS}`);

    if (campaigns.errors) {
      yield put(marketingCampaignsActions.errorCampaignsState(campaigns.errors));
      yield openNotification('error', 'Error', campaigns.errors);
    } else {
      yield put(
        marketingCampaignsActions.setCampaignsState({
          campaigns,
        }),
      );
    }
  });
}

function* addCampaign() {
  yield takeEvery(marketingCampaignsActions.ADD_CAMPAIGN, function*({ payload }) {
    const campaigns = yield apiRequest(
      superFetch.post,
      `${ENDPOINTS.MARKETING_CAMPAIGNS}`,
      payload,
    );
    if (campaigns.errors) {
      yield put(marketingCampaignsActions.errorCampaignsState(campaigns.errors));
      yield openNotification('error', 'Error', campaigns.errors);
    } else {
      yield put(
        marketingCampaignsActions.addCampaignSuccess({
          ...campaigns,
          emailTemplates: [],
          mailings: [],
        }),
      );
    }
  });
}

function* updateCampaign() {
  yield takeEvery(marketingCampaignsActions.UPDATE_CAMPAIGN, function*({ payload }) {
    const { id } = payload;
    const campaigns = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.MARKETING_CAMPAIGNS_AND}${id}`,
      payload,
    );
    if (campaigns.errors) {
      yield put(marketingCampaignsActions.errorCampaignsState(campaigns.errors));
      yield openNotification('error', 'Error', campaigns.errors);
    } else {
      yield put(marketingCampaignsActions.getCampaignsState());
    }
  });
}

function* deleteCampaign() {
  yield takeEvery(marketingCampaignsActions.DELETE_CAMPAIGN, function*({ payload }) {
    const campaigns = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.MARKETING_CAMPAIGNS_AND}${payload}`,
    );
    if (campaigns.errors) {
      yield put(marketingCampaignsActions.errorCampaignsState(campaigns.errors));
      yield openNotification('error', 'Error', campaigns.errors);
    } else {
      yield put(marketingCampaignsActions.getCampaignsState());
    }
  });
}

export default function* rootSaga() {
  yield all([getCampaignsState(), updateCampaign(), deleteCampaign(), addCampaign()]);
}
