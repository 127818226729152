import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import { apiRequest } from 'redux/helpers';
import actions from 'redux/clients/actions';
import { ENDPOINTS } from 'consts';
import { createQueryString } from '../../helpers/utility';
import { setUrl } from '../../helpers/urlSync';
import tableActions from '../../components/NewTable/redux/actions';

export function* clientsRequest() {
  yield takeEvery(actions.GET_CLIENTS_REQUEST, function* ({ payload }) {
    const { pagination, sortValues, searchValue } = yield select(state => state.tableReducer);

    const queryString = createQueryString({
      pagination: payload.isSetPagination ? pagination : {
        "pageSize": 0,
        "totalItems": 0
    },
      multiSort: sortValues,
      fields: searchValue,
    });

    const queryUrl = queryString ? `${ENDPOINTS.CLIENT}?${queryString}` : ENDPOINTS.CLIENT;

    const data = yield apiRequest(
      superFetch.get,
      queryUrl
    );

    if (payload.isSetPagination) {
      setUrl(queryString)
    }

    if (data.errors) {
      yield put(actions.getClientsError(data.errors));
    } else {
      yield put(actions.getClientsSuccess(data.items));
      if (payload.isSetPagination) {
        yield put(tableActions.setPagination(data.pagination))
      }
    }
  });
}

export function* addClientRequest() {
  yield takeEvery(actions.ADD_CLIENT_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.post, ENDPOINTS.CLIENT, payload);
    if (data.errors) {
      yield put(actions.addClientError(data.errors));
    } else {
      yield put(actions.addClientSuccess(data));
    }
  });
}

export function* deleteClientRequest() {
  yield takeEvery(actions.DELETE_CLIENT_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.delete, `${ENDPOINTS.CLIENT}/${payload.id}`, payload);
    if (data.errors) {
      yield put(actions.deleteClientError(data.errors));
    } else {
      yield put(actions.deleteClientSuccess(payload.id));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(clientsRequest),
    fork(addClientRequest),
    fork(deleteClientRequest),
  ]);
};
