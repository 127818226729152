import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { apiRequest } from 'redux/helpers';
import { createQueryString, getSettingsIdFromSettings } from 'helpers/utility';
import superFetch from 'helpers/superFetch';
import settingsActions from 'redux/settings/actions';

import openNotification from 'components/Notification';
import { ENDPOINTS } from 'consts';

export function* getSettingsRequest() {
  yield takeEvery(settingsActions.GET_SETTINGS_REQUEST, function*({ payload }) {
    const { target, key } = payload;
    const data = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.SETTINGS}/key?${createQueryString({ ...payload })}`,
    );
    const { items } = data;
    if (data.errors) {
      yield put(settingsActions.getSettingsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(
        settingsActions.getSettingsSuccess({
          key,
          target,
          items,
        }),
      );

      if (key && target) {
        const settingId = getSettingsIdFromSettings(items, key, target);
        if (settingId) {
          yield put(
            settingsActions.setSettingsId({
              key,
              target,
              settingId,
            }),
          );
        } else {
          yield put(settingsActions.createSettingsRequest(key, target));
        }
      }
    }
  });
}

export function* createSettingsRequest() {
  yield takeEvery(settingsActions.CREATE_SETTINGS_REQUEST, function*({ payload }) {
    const { target, key } = payload;
    const data = yield apiRequest(superFetch.post, `${ENDPOINTS.SETTINGS}/key`, payload);

    if (data.errors) {
      yield put(settingsActions.createSettingsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(
        settingsActions.createSettingsSuccess({
          key,
          target,
          items: [data],
        }),
      );
      if (data?.id) {
        yield put(
          settingsActions.setSettingsId({
            key,
            target,
            settingId: data.id,
          }),
        );
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getSettingsRequest), fork(createSettingsRequest)]);
}
