const LOCATION_BASED_CLAIM_TYPES = {
    VIEW_RESOURCE: 'VIEW_RESOURCE',
    EDIT_RESOURCE: 'EDIT_RESOURCE',
    VIEW_RESOURCE_BY_PROJECT: 'VIEW_RESOURCE_BY_PROJECT',
    VIEW_RESOURCE_BY_ASSIGNMENT_TYPE: 'VIEW_RESOURCE_BY_ASSIGNMENT_TYPE',
    VIEW_RESOURCE_OTHER_INFO: 'VIEW_RESOURCE_OTHER_INFO',
    EDIT_RESOURCE_OTHER_INFO: 'EDIT_RESOURCE_OTHER_INFO',
    VIEW_RESOURCE_VACATION_INFO: 'VIEW_RESOURCE_VACATION_INFO',
    EDIT_RESOURCE_VACATION_INFO: 'EDIT_RESOURCE_VACATION_INFO',
    EDIT_RESOURCE_CONTACT_INFO: 'EDIT_RESOURCE_CONTACT_INFO',
    VIEW_RESOURCE_STATUS_INFO: 'VIEW_RESOURCE_STATUS_INFO',
    EDIT_RESOURCE_STATUS_INFO: 'EDIT_RESOURCE_STATUS_INFO',
    VIEW_RESOURCE_SALARY_INFO: 'VIEW_RESOURCE_SALARY_INFO',
    EDIT_RESOURCE_SALARY_INFO: 'EDIT_RESOURCE_SALARY_INFO',
    VIEW_RESOURCE_SIGNING_BONUS_INFO: 'VIEW_RESOURCE_SIGNING_BONUS_INFO',
    EDIT_RESOURCE_SIGNING_BONUS_INFO: 'EDIT_RESOURCE_SIGNING_BONUS_INFO',
    VIEW_RESOURCE_OVERTIME_INFO: 'VIEW_RESOURCE_OVERTIME_INFO',
    EDIT_RESOURCE_OVERTIME_INFO: 'EDIT_RESOURCE_OVERTIME_INFO',
    VIEW_RESOURCE_REFERRAL_BONUS_INFO: 'VIEW_RESOURCE_REFERRAL_BONUS_INFO',
    EDIT_RESOURCE_REFERRAL_BONUS_INFO: 'EDIT_RESOURCE_REFERRAL_BONUS_INFO',
    VIEW_RESOURCE_COMMENT_INFO: 'VIEW_RESOURCE_COMMENT_INFO',
    EDIT_RESOURCE_COMMENT_INFO: 'EDIT_RESOURCE_COMMENT_INFO',
    VIEW_RESOURCE_PROJECT_HISTORY: 'VIEW_RESOURCE_PROJECT_HISTORY',
    EDIT_RESOURCE_PROJECT_HISTORY: 'EDIT_RESOURCE_PROJECT_HISTORY',
    CREATE_RESOURCE_PROJECT_HISTORY: 'CREATE_RESOURCE_PROJECT_HISTORY',
    DELETE_RESOURCE_PROJECT_HISTORY: 'DELETE_RESOURCE_PROJECT_HISTORY',
    VIEW_RESOURCE_EVALUATION: 'VIEW_RESOURCE_EVALUATION',
    EDIT_RESOURCE_EVALUATION: 'EDIT_RESOURCE_EVALUATION',
    DELETE_RESOURCE_EVALUATION: 'DELETE_RESOURCE_EVALUATION',
    COMPLETE_RESOURCE_EVALUATION: 'COMPLETE_RESOURCE_EVALUATION',

    VIEW_VACATION_PAGE: 'VIEW_VACATION_PAGE',
    CREATE_VACATION: 'CREATE_VACATION',
    UPDATE_VACATION: 'UPDATE_VACATION',
    DELETE_VACATION: 'DELETE_VACATION',
    APPROVE_VACATION: 'APPROVE_VACATION',
}

export default LOCATION_BASED_CLAIM_TYPES;