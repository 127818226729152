import React  from 'react';
import PropTypes from 'prop-types';
import SidebarIcon from 'components/Sidebar/SidebarIcon';

const SidebarLabel = props => {

  const { label, leftIcon, svg } = props;

  return (
    <span
      className="isoMenuHolder"
    >
      <i className={leftIcon || 'new-icons svg-from-class'}>
        {!leftIcon && <SidebarIcon element={svg} />}
      </i>
      <span className="nav-text">
        {label}
      </span>
    </span>
  );
}

SidebarLabel.propTypes = {
  label: PropTypes.string.isRequired,
  leftIcon: PropTypes.string,
  svg: PropTypes.string,
};

SidebarLabel.defaultProps = {
  leftIcon: null,
  svg: '',
};

export default SidebarLabel;
