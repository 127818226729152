import { all, takeEvery, put, fork } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import { ENDPOINTS } from 'consts';
import { apiRequest } from 'redux/helpers';
import actions from 'redux/stats/actions';
import openNotification from 'components/Notification';
import { createQueryString } from 'helpers/utility';

export function* resourceStatsRequest() {
  yield takeEvery(actions.GET_RESOURCE_STATS_REQUEST, function* ({payload}) {

    const queryUrl =  `${ENDPOINTS.GET_RESOURCE_STATS}?${createQueryString({
      fields: payload?.searchValue
    })}`

    const data = yield apiRequest(superFetch.get,queryUrl);
    if (data.errors) {
      yield put(actions.getResourceStatsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getResourceStatsSuccess(data));
    }
  });
}

export function* closedProjectsStatsRequest() {
  yield takeEvery(actions.GET_CLOSED_PROJECTS_STATS_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, ENDPOINTS.GET_CLOSED_PROJECTS_STATS);
    if (data.errors) {
      yield put(actions.getClosedProjectsStatsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getClosedProjectsStatsSuccess(data));
    }
  });
}

export function* projectStatsRequest() {
  yield takeEvery(actions.GET_PROJECT_STATS_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, ENDPOINTS.GET_PROJECT_STATS);
    if (data.errors) {
      yield put(actions.getProjectStatsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getProjectStatsSuccess(data));
    }
  });
}

export function* projectFlagsRequest() {
  yield takeEvery(actions.GET_PROJECT_FLAGS_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, ENDPOINTS.GET_PROJECT_FLAGS);
    if (data.errors) {
      yield put(actions.getProjectFlagsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getProjectFlagsSuccess(data));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(resourceStatsRequest),
    fork(projectStatsRequest),
    fork(projectFlagsRequest),
    fork(closedProjectsStatsRequest),
  ]);
};
