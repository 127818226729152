const POTENTIAL_PROJECTS = 'POTENTIAL_PROJECTS';

const potentialProjectsActions = {
  POTENTIAL_PROJECTS_REQUEST: `${POTENTIAL_PROJECTS}_REQUEST`,
  POTENTIAL_PROJECTS_SUCCESS: `${POTENTIAL_PROJECTS}_SUCCESS`,
  POTENTIAL_PROJECTS_ERROR: `${POTENTIAL_PROJECTS}_ERROR`,

  CREATE_POTENTIAL_PROJECTS_REQUEST: `CREATE_${POTENTIAL_PROJECTS}_REQUEST`,
  CREATE_POTENTIAL_PROJECTS_SUCCESS: `CREATE_${POTENTIAL_PROJECTS}_SUCCESS`,
  CREATE_POTENTIAL_PROJECTS_ERROR: `CREATE_${POTENTIAL_PROJECTS}_ERROR`,

  UPDATE_POTENTIAL_PROJECTS_REQUEST: `UPDATE_${POTENTIAL_PROJECTS}_REQUEST`,
  UPDATE_POTENTIAL_PROJECTS_SUCCESS: `UPDATE_${POTENTIAL_PROJECTS}_SUCCESS`,
  UPDATE_POTENTIAL_PROJECTS_ERROR: `UPDATE_${POTENTIAL_PROJECTS}_ERROR`,

  DELETE_POTENTIAL_PROJECTS_REQUEST: `DELETE_${POTENTIAL_PROJECTS}_REQUEST`,
  DELETE_POTENTIAL_PROJECTS_SUCCESS: `DELETE_${POTENTIAL_PROJECTS}_SUCCESS`,
  DELETE_POTENTIAL_PROJECTS_ERROR: `DELETE_${POTENTIAL_PROJECTS}_ERROR`,

  MOVE_TO_DISCOVERY_REQUEST: `MOVE_TO_DISCOVERY_REQUEST`,
  MOVE_TO_DISCOVERY_SUCCESS: `MOVE_TO_DISCOVERY_SUCCESS`,
  MOVE_TO_DISCOVERY_ERROR: `MOVE_TO_DISCOVERY_ERROR`,

  SET_POTENTIAL_PROJECTS_SEARCH: 'SET_POTENTIAL_PROJECTS_SEARCH',

  getPotentialProjectsRequest: payload => ({
    type: potentialProjectsActions.POTENTIAL_PROJECTS_REQUEST,
    payload,
  }),
  getPotentialProjectsSuccess: payload => ({
    type: potentialProjectsActions.POTENTIAL_PROJECTS_SUCCESS,
    payload,
  }),
  getPotentialProjectsError: payload => ({
    type: potentialProjectsActions.POTENTIAL_PROJECTS_ERROR,
    payload,
  }),

  getCreatePotentialProjectRequest: payload => ({
    type: potentialProjectsActions.CREATE_POTENTIAL_PROJECTS_REQUEST,
    payload,
  }),
  getCreatePotentialProjectSuccess: payload => ({
    type: potentialProjectsActions.CREATE_POTENTIAL_PROJECTS_SUCCESS,
    payload,
  }),
  getCreatePotentialProjectError: payload => ({
    type: potentialProjectsActions.CREATE_POTENTIAL_PROJECTS_ERROR,
    payload,
  }),

  getUpdatePotentialProjectRequest: payload => ({
    type: potentialProjectsActions.UPDATE_POTENTIAL_PROJECTS_REQUEST,
    payload,
  }),
  getUpdatePotentialProjectSuccess: payload => ({
    type: potentialProjectsActions.UPDATE_POTENTIAL_PROJECTS_SUCCESS,
    payload,
  }),
  getUpdatePotentialProjectError: payload => ({
    type: potentialProjectsActions.UPDATE_POTENTIAL_PROJECTS_ERROR,
    payload,
  }),

  getDeletePotentialProjectRequest: payload => ({
    type: potentialProjectsActions.DELETE_POTENTIAL_PROJECTS_REQUEST,
    payload,
  }),
  getDeletePotentialProjectSuccess: payload => ({
    type: potentialProjectsActions.DELETE_POTENTIAL_PROJECTS_SUCCESS,
    payload,
  }),
  getDeletePotentialProjectError: payload => ({
    type: potentialProjectsActions.DELETE_POTENTIAL_PROJECTS_ERROR,
    payload,
  }),

  getMoveToDiscoveryRequest: id => ({
    type: potentialProjectsActions.MOVE_TO_DISCOVERY_REQUEST,
    payload: { id },
  }),
  getMoveToDiscoverySuccess: payload => ({
    type: potentialProjectsActions.MOVE_TO_DISCOVERY_SUCCESS,
    payload,
  }),
  getMoveToDiscoveryError: payload => ({
    type: potentialProjectsActions.MOVE_TO_DISCOVERY_ERROR,
    payload,
  }),

  setSearch: payload => ({
    type: potentialProjectsActions.SET_POTENTIAL_PROJECTS_SEARCH,
    payload,
  }),

};

export default potentialProjectsActions;
