import marketingGroupsActions from 'redux/marketing/marketingGroups/actions';

const initState = {
  groups: [],
  loading: false,
  isModalActive: false,
};

export default function marketingGroupsReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {

    case marketingGroupsActions.GET_GROUPS_STATE:
      return {
        ...state,
        loading: true
      };

    case marketingGroupsActions.SET_GROUPS_STATE:
      return {
        ...state,
        groups: [...payload.groups],
        loading: false
      };

    case marketingGroupsActions.ERROR_GROUPS_STATE:
      return {
        ...state,
        loading: false
      };

    case marketingGroupsActions.ADD_GROUP:
      return {
        ...state,
        loading: true
      };

    case marketingGroupsActions.ADD_GROUP_SUCCESS:
      return {
        ...state,
        groups: [...state.groups, payload],
        isModalActive: false,
        loading: false
      };

    case marketingGroupsActions.UPDATE_GROUP:
      return {
        ...state,
        loading: false
      };

    case marketingGroupsActions.DELETE_GROUP:
      return {
        ...state,
        loading: true
      };

    case marketingGroupsActions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
      };

    default:
      return state;
  }
}
