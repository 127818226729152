import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

export const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-title {
    margin-right: 20px;
  }

  .ant-select-item-option:hover .ant-select-item-option-active {
    background-color: #ff0b0b !important;
  }

  .ant-select-item-option:hover {
    background-color: rgb(225, 236, 249);
  }

  .chrome-picker label {
    color: rgb(128,128,128) !important;
    font-size: 13px !important;
  }

  .change-column-modal-title {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .resource-name-modal-title {
    margin-right: 5px;
  }

  .ant-tag {
    margin-right: 0;
  }

  .ant-modal-body {
    h2 {
      margin-bottom: 16px;
    }

    .ant-input, .ant-picker, .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-sizing: border-box;
      border: 1px solid rgb(182, 184, 191);
      border-radius: 4px;
      height: 32px;
    }

    .ant-picker-input > input::placeholder {
      color: rgb(136, 136, 138);
    }

    .ant-form-item-control-input-content .ant-input::placeholder {
      color: rgb(121,121,121);
      opacity: 1;
      font-size: 14px;
    }

    .ant-picker-input input::placeholder {
      color: rgb(121,121,121);
      opacity: 1;
    }

    .ant-select-arrow, .anticon.anticon-calendar {
      color: rgb(121,121,121);
    }


    .ant-select-selection-placeholder {
      color: rgb(121,121,121);
    }

    .ant-select, .ant-select-in-form-item, .ant-select-single, .ant-select-show-arrow, .ant-select-show-search {
      border: none;
    }
  }

  .ant-modal-footer {
    .ant-btn-default {
      box-sizing: border-box;
      border: 1px solid rgb(121, 121, 121);
      border-radius: 4px;
    }

    .ant-btn-primary {
      border-radius: 4px;
    }
  }

  .ant-modal-header {
    border: none;
    padding: 16px 20px 0 20px;
    margin-bottom: 40px;
    border-radius: 10px;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-modal-body {
    padding: 0 20px;
  }

  .ant-modal-footer {
    border: none;
    padding: 0 20px 16px 20px;
    margin-top: 40px;
  }
`;
