const vacationActions = {
  ADD_VACATION_REQUEST: 'VACATION_ADD_VACATION_REQUEST',
  ADD_VACATION_SUCCESS: 'VACATION_ADD_VACATION_SUCCESS',
  ADD_VACATION_ERROR: 'VACATION_ADD_VACATION_ERROR',

  UPDATE_VACATION_REQUEST: 'VACATION_UPDATE_VACATION_REQUEST',
  UPDATE_VACATION_SUCCESS: 'VACATION_UPDATE_VACATION_SUCCESS',
  UPDATE_VACATION_ERROR: 'VACATION_UPDATE_VACATION_ERROR',

  GET_VACATIONS_REQUEST: 'VACATION_GET_VACATIONS_REQUEST',
  GET_VACATIONS_SUCCESS: 'VACATION_GET_VACATIONS_SUCCESS',
  GET_VACATIONS_ERROR: 'VACATION_GET_VACATIONS_ERROR',

  DELETE_VACATION_REQUEST: 'VACATION_DELETE_VACATION_REQUEST',
  DELETE_VACATION_SUCCESS: 'VACATION_DELETE_VACATION_SUCCESS',
  DELETE_VACATION_ERROR: 'VACATION_DELETE_VACATION_ERROR',

  GET_VACATION_STATUS_REQUEST: 'VACATION_GET_VACATION_STATUS_REQUEST',
  GET_VACATION_STATUS_SUCCESS: 'VACATION_GET_VACATION_STATUS_SUCCESS',
  GET_VACATION_STATUS_ERROR: 'VACATION_GET_VACATION_STATUS_ERROR',

  SHOW_MODAL: `VACATION_SHOW_MODAL`,
  SET_CURRENT_EDIT_VACATION: `SET_CURRENT_EDIT_VACATION`,
  SET_OPEN_MODAL_VACATION_ID: `SET_OPEN_MODAL_VACATION_ID`,

  CALCULATE_VACATION_REQUEST: 'VACATION_CALCULATE_VACATION_REQUEST',
  CALCULATE_VACATION_SUCCESS: 'VACATION_CALCULATE_VACATION_SUCCESS',
  CALCULATE_VACATION_ERROR: 'VACATION_CALCULATE_VACATION_ERROR',

  VACATION_WARNING_REQUEST: 'VACATION_WARNING_REQUEST',
  VACATION_WARNING_SUCCESS: 'VACATION_WARNING_SUCCESS',
  VACATION_WARNING_ERROR: 'VACATION_WARNING_ERROR',

  GET_VACATION_CATEGORIES_REQUEST: 'GET_VACATION_CATEGORIES_REQUEST',
  GET_VACATION_CATEGORIES_SUCCESS: 'GET_VACATION_CATEGORIES_SUCCESS',
  GET_VACATION_CATEGORIES_ERROR: 'GET_VACATION_CATEGORIES_ERROR',

  SET_RANGE_MONTH: 'VACATION_SET_RANGE_MONTH',
  SET_GO_TO: `SET_GO_TO`,
  SET_START_REDIRECT: `SET_START_REDIRECT`,
  CLOSE_REDIRECT: `CLOSE_REDIRECT`,
  UPDATE_FORM_STATE: 'VACATION_UPDATE_FORM_STATE',

  addVacationRequest: (payload) => ({
      type: vacationActions.ADD_VACATION_REQUEST,
      payload,
  }),

  addVacationSuccess: (payload) => ({
    type: vacationActions.ADD_VACATION_SUCCESS,
    payload,
  }),

  addVacationError: () => ({
    type: vacationActions.ADD_VACATION_ERROR,
  }),

  updateVacationRequest: (payload) => ({
    type: vacationActions.UPDATE_VACATION_REQUEST,
    payload
  }),

  updateVacationSuccess: () => ({
    type: vacationActions.UPDATE_VACATION_SUCCESS,
  }),

  updateVacationError: () => ({
    type: vacationActions.UPDATE_VACATION_ERROR,
  }),

  getVacationsRequest: (payload) => ({
    type: vacationActions.GET_VACATIONS_REQUEST,
    payload
  }),

  getVacationsSuccess: (payload) => ({
    type: vacationActions.GET_VACATIONS_SUCCESS,
    payload
  }),

  getVacationsError: () => ({
    type: vacationActions.GET_VACATIONS_ERROR,
  }),

  getVacationCategoriesRequest: () => ({
    type: vacationActions.GET_VACATION_CATEGORIES_REQUEST,
  }),

  getVacationCategoriesSuccess: (data) => ({
    type: vacationActions.GET_VACATION_CATEGORIES_SUCCESS,
    payload: { data }
  }),

  getVacationCategoriesError: () => ({
    type: vacationActions.GET_VACATION_CATEGORIES_ERROR,
  }),

  deleteVacationRequest: (vacationId) => ({
    type: vacationActions.DELETE_VACATION_REQUEST,
    payload: { vacationId }
  }),

  deleteVacationSuccess: (vacationId) => ({
    type: vacationActions.DELETE_VACATION_SUCCESS,
    payload: { vacationId }
  }),

  deleteVacationError: () => ({
    type: vacationActions.DELETE_VACATION_ERROR,
  }),

  getVacationStatusesRequest: () => ({
    type: vacationActions.GET_VACATION_STATUS_REQUEST
  }),

  getVacationStatusesSuccess: (payload) => ({
    type: vacationActions.GET_VACATION_STATUS_SUCCESS,
    payload
  }),

  getVacationStatusesError: () => ({
    type: vacationActions.GET_VACATION_STATUS_ERROR,
  }),

  showModal: payload => ({
    type: vacationActions.SHOW_MODAL,
    payload,
  }),

  setCurrentEditVacation: (payload) => ({
    type: vacationActions.SET_CURRENT_EDIT_VACATION,
    payload,
  }),

  setOpenModalVacationID: (payload) => ({
    type: vacationActions.SET_OPEN_MODAL_VACATION_ID,
    payload,
  }),

  setGoTo: (payload) => ({
    type: vacationActions.SET_GO_TO,
    payload,
  }),

  setStartRedirect: (payload) => ({
    type: vacationActions.SET_START_REDIRECT,
    payload,
  }),

  closeRedirect: () => ({
    type: vacationActions.CLOSE_REDIRECT,
  }),

  calculateVacation: (startDate, endDate, resourceId) => ({
    type: vacationActions.CALCULATE_VACATION_REQUEST,
    payload: { startDate, endDate, resourceId }
  }),

  calculateVacationSuccess: (payload) => ({
    type: vacationActions.CALCULATE_VACATION_SUCCESS,
    payload,
  }),

  calculateVacationError: () => ({
    type: vacationActions.CALCULATE_VACATION_ERROR,
  }),

  vacationWarningRequest: (startDate, endDate, resourceId, typeVacation, vacationId) => ({
    type: vacationActions.VACATION_WARNING_REQUEST,
    payload: { startDate, endDate, resourceId, typeVacation, vacationId }
  }),

  vacationWarningSuccess: (payload) => ({
    type: vacationActions.VACATION_WARNING_SUCCESS,
    payload,
  }),

  vacationWarningError: () => ({
    type: vacationActions.VACATION_WARNING_ERROR,
  }),

  updateFormState: (payload) => ({
    type: vacationActions.UPDATE_FORM_STATE,
    payload,
  }),

  setRangeMonth: (payload) => ({
    type: vacationActions.SET_RANGE_MONTH,
    payload,
  })
};
export default vacationActions;
