import superFetch from 'helpers/superFetch';
import { all, debounce, fork, put, select, takeEvery } from 'redux-saga/effects';
import { ENDPOINTS } from '../../../consts';
import { createQueryString } from '../../../helpers/utility';
import { apiRequest } from '../../../redux/helpers';
import openNotification from '../../Notification';
import tableActions from './actions';
import { LOGS_ENTITY_TYPES } from '../../../consts';

export function* getLastModifiedDateRequest() {
  yield takeEvery(tableActions.GET_LAST_MODIFIED_DATE_REQUEST, function*({ payload, id }) {
    const entityTypeMapper = LOGS_ENTITY_TYPES[payload];

    const entityType = id? entityTypeMapper(id): LOGS_ENTITY_TYPES[payload];

    const queryUrlAction = `${ENDPOINTS.LOGS_LAST_DATE}?${createQueryString({
      ...entityType,
    })}`;

    const lastModifiedDate = yield apiRequest(superFetch.get, queryUrlAction);

    if (lastModifiedDate.errors) {
      yield put(tableActions.getLastModifiedError(lastModifiedDate.errors));
      yield openNotification('error', 'Error', lastModifiedDate.errors);
    } else {
      yield put(
        tableActions.getLastModifiedSuccess({
          lastModifiedDate: lastModifiedDate,
        }),
      );
    }
  });
}

export function* handleSetFilters() {
  yield debounce(800, tableActions.SET_FILTERS, function*({ payload }) {
    const { searchValue, pagination } = yield select(state => state.tableReducer);
    const data = {
      searchValue: { ...searchValue, ...payload.searchValue },
      pagination: { ...pagination, ...{ currentPage: 1 } },
    };
    yield put(tableActions.setFiltersSuccess(data));
    payload.fetchAction(data);
  });
}

export function* handleColumnsVisibility() {
  yield debounce(700, tableActions.SET_VISIBLE_COLUMNS, function*({ payload }) {
    yield put(tableActions.setVisibleColumnsSuccess(payload));
  });
}

export function* handleChangeColumns() {
  yield takeEvery(tableActions.SET_COLUMNS, function*({ payload }) {
    yield put(tableActions.setColumnsSuccess(payload));
  });
}

export function* handleSortersChange() {
  yield takeEvery(tableActions.SET_SORTERS, function*({ payload }) {
    yield put(tableActions.setSortersSuccess(payload));
    const { searchValue, pagination } = yield select(state => state.tableReducer);
    const data = {
      pagination: { ...pagination, currentPage: 1 },
      sort: payload.sort,
      searchValue,
    };
    payload.fetchAction(data);
  });
}

export default function* rootSaga() {
  yield all([
    fork(handleSetFilters),
    fork(handleColumnsVisibility),
    fork(handleChangeColumns),
    fork(handleSortersChange),
    fork(getLastModifiedDateRequest),
  ]);
}
