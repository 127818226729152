// eslint-disable-next-line no-console
const LOG_ERROR = console.error;
// eslint-disable-next-line no-console
const LOG_STRING = console.log;
// eslint-disable-next-line no-console
const LOG_ARRAY = console.table;
// eslint-disable-next-line no-console
const LOG_GROUP = console.group;
// eslint-disable-next-line no-console
const LOG_END_GROUP = console.groupEnd;

const LOG_LEVELS = {
  DEFAULT: 'default',
  INFO: 'info',
  ERROR: 'error',
};

const Logger = (clazz = {},  showTag = false, tag = '') => {

  if (typeof clazz !== 'object') clazz = {};

  Object.defineProperty(clazz, 'getName',{
    value: () => showTag ? tag.toUpperCase() : clazz.constructor.name.toUpperCase(),
  });

  function printArray(arr = []) {
    LOG_GROUP(clazz.getName());
    LOG_ARRAY(arr);
    LOG_END_GROUP(clazz.getName());
  }

  function printString(context = '', level = LOG_LEVELS.DEFAULT) {

    let color = 'grey';

    if (level === LOG_LEVELS.DEFAULT) {
      color = 'black';
    } else if (level === LOG_LEVELS.INFO) {
      color = 'orange';
    } else if (level === LOG_LEVELS.ERROR) {
      color = 'red';
    }

    LOG_STRING(`%c ${clazz.getName()}: ${context} `, `color: ${color}; font-weight: bold`);
  }

  function checkInfoArg(clazz = {}) {
    if (!clazz) {
      throw new Error('Logger: first argument is null or undefined !')
    }  else if (Array.isArray(clazz)) {
      throw new Error('Logger: first argument  is array!')
    } else if (typeof clazz !== 'object') {
      throw new Error('Logger: first argument is not object !')
    }
  }

  function processLogArray(arr =  []) {
    checkInfoArg(clazz);
    printArray(arr);
  }

  function processDefaultLog(context = null) {
    checkInfoArg(clazz);
    printString(context, LOG_LEVELS.DEFAULT);
  }

  function processInfoLog(context = null) {
    checkInfoArg(clazz);
    printString(context, LOG_LEVELS.INFO);
  }

  function processErrorLog(context = null) {
    checkInfoArg(clazz);
    printString(context, LOG_LEVELS.ERROR);
  }

  return {
    logArray (arr) {
      if (process.env.NODE_ENV === 'development') processLogArray(arr);
    },
    log (message) {
      if (process.env.NODE_ENV === 'development') processDefaultLog(message);
    },
    logInfo (message) {
      if (process.env.NODE_ENV === 'development') processInfoLog(message);
    },
    logError (message) {
      if (process.env.NODE_ENV === 'development') processErrorLog(message);
    },
    error(e) {
      if (process.env.NODE_ENV === 'development') LOG_ERROR(e);
    },
  }
};

export default Logger
