import mailingRecipientsActions from 'redux/marketing/mailing/mailingRecipients/actions';

const getInitState = () => ({
  states: [],
  cities: [],
  recipients: [],
  loading: false,
});

export default function mailingRecipients(state = getInitState(), action) {
  const { type, payload } = action;
  switch (type) {

    case mailingRecipientsActions.SET_MAILING_RECIPIENTS_INITIAL_STATE:
      return {
        ...state,
        ...payload
      };
    case mailingRecipientsActions.GET_MAILING_RECIPIENTS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case mailingRecipientsActions.GET_MAILING_RECIPIENTS_SUCCESS:

      return {
        ...state,
        recipients: [...payload.recipients],
        loading: false
      };

    case mailingRecipientsActions.GET_MAILING_RECIPIENTS_ERROR:
      return {
        ...state,
        loading: false
      };


    case mailingRecipientsActions.DESELECT_ALL:
      return {
        ...state,
      };

    case mailingRecipientsActions.DESELECT_ALL_VISIBLE:
      return {
        ...state,
      };

    case mailingRecipientsActions.SELECT_ALL_VISIBLE:
      return {
        ...state,
      };

    case mailingRecipientsActions.RESET_STATE:
      return {
        ...getInitState()
      };

    case mailingRecipientsActions.CHANGE_RECIPIENT:
      return {
        ...state,
      };

    case mailingRecipientsActions.GET_MAILING_RECIPIENTS_NEXT:
      return {
        ...state,
        loading: true
      };

    case mailingRecipientsActions.SET_MAILING_RECIPIENTS_NEXT:
      const { recipients } = state;
      return {
        ...state,
        ...payload,
        recipients: [...recipients, ...payload.recipients],
        loading: false
      };

default:
      return state;
  }
}
