import { all, takeEvery, put, select } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import { CONTENT_TYPES, ENDPOINTS } from 'consts';
import openNotification from 'components/Notification';
import clientDetailsActions from './actions';
import { apiRequest } from '../../helpers';

export function* getClientDetailsRequest() {
  yield takeEvery(clientDetailsActions.GET_CLIENT_DETAILS_REQUEST, function* ({id}){

    const queryUrl = `${ENDPOINTS.CLIENT}/${id}`
    const data = yield apiRequest(
      superFetch.get,
      queryUrl,
    )

    if (data.errors) {
      yield put(clientDetailsActions.getClientDetailsError())
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(clientDetailsActions.getClientDetailsSuccess(data))
    }
  })
}

export function* updateClientDetailsRequest() {
  yield takeEvery(clientDetailsActions.UPDATE_CLIENT_DETAILS_REQUEST, function* ({payload}) {

    const { client: {id} } = yield select(state => state.ClientDetailsPageReducer);

    const urlList = {
      'mainInfo': `${ENDPOINTS.CLIENT}/${id}`,
      'contactsInfo': `${ENDPOINTS.CLIENT}/${id}/contacts`,
    }

    const data = yield apiRequest(
      payload.blockName === 'mainInfo' ? superFetch.put : superFetch.patch,
      urlList[payload.blockName],
      payload.data,
      payload.blockName === 'mainInfo' ? CONTENT_TYPES.MULTIPART_DATA : undefined,
    )

    const fixData = {
      'mainInfo': data,
      'contactsInfo': { clientContacts: data },
    }

    if (data.errors) {
      yield put(clientDetailsActions.updateClientsDetailsError(payload.blockName))
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(clientDetailsActions.updateClientsDetailsSuccess(fixData[payload.blockName], payload.blockName))
    }
  })
}

export default function* rootSaga() {
  yield all([
    getClientDetailsRequest(),
    updateClientDetailsRequest(),
  ]);
}
