const settingsActions = {
  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_ERROR: 'GET_SETTINGS_ERROR',

  GET_SETTINGS_BY_ID_REQUEST: 'GET_SETTINGS_BY_ID_REQUEST',
  GET_SETTINGS_BY_ID_SUCCESS: 'GET_SETTINGS_BY_ID_SUCCESS',
  GET_SETTINGS_BY_ID_ERROR: 'GET_SETTINGS_BY_ID_ERROR',

  CREATE_SETTINGS_REQUEST: 'CREATE_SETTINGS_REQUEST',
  CREATE_SETTINGS_SUCCESS: 'CREATE_SETTINGS_SUCCESS',
  CREATE_SETTINGS_ERROR: 'CREATE_SETTINGS_ERROR',

  SET_SETTINGS_ID: 'SET_SETTINGS_ID',

  // payload = { key, target}
  getSettingsRequest: (payload) => ({
    type: settingsActions.GET_SETTINGS_REQUEST,
    payload,
  }),

  getSettingsSuccess: (payload) => ({
    type: settingsActions.GET_SETTINGS_SUCCESS,
    payload
  }),

  getSettingsError: () => ({
    type: settingsActions.GET_SETTINGS_ERROR,
  }),

  getSettingsByIdRequest: (payload) => ({
    type: settingsActions.GET_SETTINGS_BY_ID_REQUEST,
    payload
  }),

  getSettingsByIdSuccess: (payload) => ({
    type: settingsActions.GET_SETTINGS_BY_ID_SUCCESS,
    payload
  }),

  getSettingsByIdError: () => ({
    type: settingsActions.GET_SETTINGS_BY_ID_ERROR,
  }),

  createSettingsRequest: (key, target) => ({
    type: settingsActions.CREATE_SETTINGS_REQUEST,
    payload: {
      key,
      target,
    },
  }),

  createSettingsSuccess: (payload) => ({
    type: settingsActions.CREATE_SETTINGS_SUCCESS,
    payload
  }),

  createSettingsError: () => ({
    type: settingsActions.CREATE_SETTINGS_ERROR,
  }),

  setSettingsId: (payload) => ({
    type: settingsActions.SET_SETTINGS_ID,
    payload
  }),

};
export default settingsActions;
