import actions from 'redux/resourceRoles/actions';

const initState = {
  isModalActive: false,
  loading: false,
  selectedRole: {
    id: null,
    title: '',
    color: '',
  },
  roles: [],
};

export default function ResourceRolesReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_RESOURCE_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_RESOURCE_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload.roles,
      };
    case actions.GET_RESOURCE_ROLES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_RESOURCE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_RESOURCE_ROLE_SUCCESS:
      return {
        ...state,
        isModalActive: false,
        roles: [
          ...state.roles,
          {
            ...action.payload.role,
          }
        ],
        loading: false,
      };
    case actions.ADD_RESOURCE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_RESOURCE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_RESOURCE_ROLE_SUCCESS:
      const { role } = action.payload;
      return {
        ...state,
        isModalActive: false,
        roles: [
          ...state.roles.map(item => item.id === role.id ? role : item),
        ],
        loading: false,
      };
    case actions.UPDATE_RESOURCE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_RESOURCE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_RESOURCE_ROLE_SUCCESS:
      return {
        ...state,
        roles: state.roles.filter(item => item.id !== action.payload.roleId),
        loading: false,
      };
    case actions.DELETE_RESOURCE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
        selectedRole: action.payload.role || initState.selectedRole,
      };
    default:
      return state;
  }
}
