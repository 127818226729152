import potentialProjectsActions from './actions';

const initState = {
  loading: false,
  potentialProjects: [],
  searchValue: {
    title: '',
  },
};

export default function potentialProjectsReducer(state = initState, action) {

  switch (action.type) {
    case potentialProjectsActions.POTENTIAL_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case potentialProjectsActions.POTENTIAL_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        potentialProjects: action.payload,
      };
    case potentialProjectsActions.POTENTIAL_PROJECTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case potentialProjectsActions.CREATE_POTENTIAL_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case potentialProjectsActions.CREATE_POTENTIAL_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        potentialProjects: [...state.potentialProjects, action.payload],
      };
    case potentialProjectsActions.CREATE_POTENTIAL_PROJECTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case potentialProjectsActions.UPDATE_POTENTIAL_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case potentialProjectsActions.UPDATE_POTENTIAL_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        potentialProjects: action.payload,
      };
    case potentialProjectsActions.UPDATE_POTENTIAL_PROJECTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case potentialProjectsActions.DELETE_POTENTIAL_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case potentialProjectsActions.DELETE_POTENTIAL_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        potentialProjects: state.potentialProjects.filter(elem =>
          elem.id !== action.payload),
      };
    case potentialProjectsActions.DELETE_POTENTIAL_PROJECTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case potentialProjectsActions.MOVE_TO_DISCOVERY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case potentialProjectsActions.MOVE_TO_DISCOVERY_SUCCESS:
      return {
        ...state,
        loading: false,
        potentialProjects: state.potentialProjects.filter(elem => elem.id !== action.payload),
      };
    case potentialProjectsActions.MOVE_TO_DISCOVERY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case potentialProjectsActions.SET_POTENTIAL_PROJECTS_SEARCH:
      return {
        ...state,
        searchValue: {
          ...state.searchValue,
          title: action.payload,
        },
      }

    default:
      return state;
  }
}
