const DOCUMENT = 'RESOURCES_';
const resourcesByTypeActions = {

  GET_INITIAL_BY_TYPE_REQUEST: `${DOCUMENT}GET_INITIAL_BY_TYPE_REQUEST`,
  GET_INITIAL_BY_TYPE_SUCCESS: `${DOCUMENT}GET_INITIAL_BY_TYPE_SUCCESS`,
  GET_INITIAL_BY_TYPE_ERROR: `${DOCUMENT}GET_INITIAL_BY_TYPE_ERROR`,

  SET_INITIAL_RESOURCE_BY_TYPE_STATE: `${DOCUMENT}SET_INITIAL_RESOURCE_BY_TYPE_STATE`,

  GET_BY_TYPE_REQUEST: `${DOCUMENT}GET_BY_TYPE_REQUEST`,
  GET_BY_TYPE_SUCCESS: `${DOCUMENT}GET_BY_TYPE_SUCCESS`,
  GET_BY_TYPE_ERROR: `${DOCUMENT}GET_BY_TYPE_ERROR`,

  UPDATE_ASSIGNMENT_TYPE_REQUEST: `${DOCUMENT}UPDATE_ASSIGNMENT_TYPE_REQUEST`,
  UPDATE_ASSIGNMENT_TYPE_SUCCESS: `${DOCUMENT}UPDATE_ASSIGNMENT_TYPE_SUCCESS`,
  UPDATE_ASSIGNMENT_TYPE_ERROR: `${DOCUMENT}UPDATE_ASSIGNMENT_TYPE_ERROR`,

  UPDATE_BY_TYPE_FILTERS: `${DOCUMENT}UPDATE_BY_TYPE_FILTERS`,
  UPDATE_BY_TYPE_FILTERS_SUCCESS: `${DOCUMENT}UPDATE_BY_TYPE_FILTERS_SUCCESS`,
  UPDATE_BY_TYPE_FILTERS_ERROR: `${DOCUMENT}UPDATE_BY_TYPE_FILTERS_ERROR`,

  SHOW_DRAWER: `${DOCUMENT}SHOW_DRAWER`,
  HIDE_DRAWER: `${DOCUMENT}HIDE_DRAWER`,
  SET_VISIBLE_ITEMS: `${DOCUMENT}SET_VISIBLE_ITEMS`,

  setVisibleItems: (payload) => ({
    type: resourcesByTypeActions.SET_VISIBLE_ITEMS,
    payload
  }),

  getInitialResourceByTypeStateRequest: () => ({
    type: resourcesByTypeActions.GET_INITIAL_BY_TYPE_REQUEST
  }),

  getInitialResourceByTypeStateSuccess: (payload) => ({
    type: resourcesByTypeActions.GET_INITIAL_BY_TYPE_SUCCESS,
    payload,
  }),

  getInitialResourceByTypeStateError: () => ({
    type: resourcesByTypeActions.GET_INITIAL_BY_TYPE_ERROR
  }),

  setInitialResourceByTypeState: payload => ({
    type: resourcesByTypeActions.SET_INITIAL_RESOURCE_BY_TYPE_STATE,
    payload,
  }),

//  Get Resource by type

  resourcesByTypeRequest: (payload) => ({
    type: resourcesByTypeActions.GET_BY_TYPE_REQUEST,
    payload,
  }),

  resourcesByTypeSuccess: (payload) => ({
    type: resourcesByTypeActions.GET_BY_TYPE_SUCCESS,
    payload
  }),

  resourcesByTypeError: error => ({
    type: resourcesByTypeActions.GET_BY_TYPE_ERROR,
    payload: {
      error,
    },
  }),

  updateByTypeFilters: (payload) => ({
    type: resourcesByTypeActions.UPDATE_BY_TYPE_FILTERS,
    payload
  }),

  //

  resourcesUpdateAssignmentRequest: (payload) => ({
    type: resourcesByTypeActions.UPDATE_ASSIGNMENT_TYPE_REQUEST,
    payload,
  }),

  resourcesUpdateAssignmentSuccess: (payload) =>  ({
    type: resourcesByTypeActions.UPDATE_ASSIGNMENT_TYPE_SUCCESS,
    payload
  }),

  resourcesUpdateAssignmentError: error => ({
    type: resourcesByTypeActions.UPDATE_ASSIGNMENT_TYPE_ERROR,
    payload: {
      error,
    },
  }),

  showDrawer: ()=>({
    type: resourcesByTypeActions.SHOW_DRAWER,
  }),

  hideDrawer: ()=>({
    type: resourcesByTypeActions.HIDE_DRAWER,
  })

};
export default resourcesByTypeActions;
