const toColumnChartArray = (prop, flag = "") => {
  const names = prop?.map(i => i[0]);
  const values = prop?.map(i => i[1]);

    if(flag === "projectsByTierStat"){
      const names = prop?.map(i => i[0]).sort();
      const values = [];
      names.forEach(name => {
        const element = prop.find(el => el[0] === name);
        if(name === element[0]){
          values.push(element[1]);
        }
      });
      return !names || !values ? [] : [[''].concat(names), [' '].concat(values)];
    }

  // I added empty string to hide this string https://prnt.sc/qRsTvketeaoM
  return !names || !values ? [] : [[''].concat(names), [' '].concat(values)];
};

export default toColumnChartArray;
