import resourceEvaluationActions from 'redux/resources/evaluationDetails/actions';

const initialState = {
  evaluation: {
    id: null,
    assessedResourceId: null,
    preTechLevelId: null,
    preEnglishLevelId: null,
    isCompleted: false,
    isTechLevelUpdated: true,
    isEngLevelUpdated: true,
    conclusion: '',
    techInterviewDate: '',
    techInterviewTechnologyId: null,
    techInterviewerId: null,
    techInterviewResult: null,
    techInterviewResultLevelId: null,
    engInterviewDate: '',
    engInterviewerId: null,
    engInterviewResult: null,
    engInterviewResultLevelId: null,
    createdAt: '',
    deletedAt: null,
    assessedResource: {
      id: null,
      firstName: '',
      lastName: '',
      resourceRole: {
        id: null,
        title: '',
        color: '',
        orderForBoards: null,
        order: null,
        deletedAt: null
      },
      technologies: [],
      primaryTechnology: {
        id: null,
        title: '',
        abbreviation: '',
        deletedAt: null,
        technologyTypeId: null,
        technologyType: {
          id: null,
          title: null,
          color: '',
          deletedAt: null
        }
      },
      secondaryTechnology: {
        id: null,
        title: '',
        abbreviation: '',
        deletedAt: null,
        technologyTypeId: null,
        technologyType: {
          id: null,
          title: '',
          color: '',
          deletedAt: null
        }
      },
      avatar: ''
    },
    techInterviewer: {
      id: null,
      firstName: '',
      lastName: ''
    },
    engInterviewer: {
      id: null,
      firstName: '',
      lastName: ''
    },
    preTechLevel: {
      id: null,
      value: '',
      deletedAt: null,
    },
    techInterviewResultLevel: {
      id: null,
      value: '',
      deletedAt: null,
    },
    preEnglishLevel: {
      id: null,
      value: '',
      deletedAt: null,
    },
    engInterviewResultLevel: {
      id: null,
      value: '',
      deletedAt: null,
    },
    technology: {
      id: null,
      title: '',
      abbreviation: '',
      deletedAt: null,
      technologyTypeId: null,
      technologyType: {
        id: null,
        title: '',
        color: '',
        deletedAt: null
      }
    }
  },
  loading: false,
  isModalActive: false,
  technicalModalActive: false,
  englishModalActive: false,
  technicalResultModalActive: false,
  englishResultModalActive: false
};

export default function EvaluationReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    // Get resource request
    case resourceEvaluationActions.GET_RESOURCE_EVALUATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case resourceEvaluationActions.GET_RESOURCE_EVALUATION_SUCCESS:
      return {
        ...state,
        evaluation: {
          ...state.evaluation,
          ...payload
        },
        loading: false
      };

    case resourceEvaluationActions.GET_RESOURCE_EVALUATION_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };

      // Toggle modals
    case resourceEvaluationActions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
      };

      case resourceEvaluationActions.TECHNICAL_TOGGLE_MODAL:
        return {
          ...state,
          technicalModalActive: !state.technicalModalActive,
        };

      case resourceEvaluationActions.ENGLISH_TOGGLE_MODAL:
      return {
        ...state,
        englishModalActive: !state.englishModalActive,
      };

      case resourceEvaluationActions.TECHNICAL_RESULT_TOGGLE_MODAL:
        return {
          ...state,
          technicalResultModalActive: !state.technicalResultModalActive,
        };

      case resourceEvaluationActions.ENGLISH_RESULT_TOGGLE_MODAL:
      return {
        ...state,
        englishResultModalActive: !state.englishResultModalActive,
      };

      // Update evaluation
    case resourceEvaluationActions.UPDATE_EVALUATION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case resourceEvaluationActions.UPDATE_EVALUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        evaluation: {
          ...state.evaluation,
          ...payload,
          isCompleted: !state.isCompleted
        }
      }
    case resourceEvaluationActions.UPDATE_EVALUATION_ERROR:
      return {
        ...state,
        loading: false,
      }

    // Default
    default:
      return state;
  }
}
