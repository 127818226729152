export default {
  apiUrl: process.env.REACT_APP_API_URL,
};

const siteConfig = {
  siteName: 'ISOMORPHIC',
  siteIcon: 'ion-flash',
  footerText: 'Isomorphic ©2018 Created by RedQ, Inc',
  dateFormat: 'YYYY-MM-DD',
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};

const images = {
  avatarPlaceholder: '/images/avatar_placeholder.png',
  projectPlaceholder: '/images/project_placeholder.png'
};

export {
  siteConfig,
  themeConfig,
  images,
};
