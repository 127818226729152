import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import App from 'containers/App/App';
import asyncComponent from 'helpers/AsyncFunc';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RestrictedRoute from './RestrictedRoute';

const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Switch>
          <Route
            exact
            path="/signin"
            component={asyncComponent(() => import('./containers/SignIn'))}
          />
          <Route
            exact
            path="/completeInvite"
            component={asyncComponent(() => import('./containers/FinishInvitation'))}
          />
          <Route
            exact
            path="/forgotpassword"
            component={asyncComponent(() => import('./containers/ForgotPassword'))}
          />
          <Route
            exact
            path="/resetPassword"
            component={asyncComponent(() => import('./containers/CompleteResetPassword'))}
          />
          <Route
            exact
            path="/500"
            component={asyncComponent(() => import('./containers/ErrorPages/500'))}
          />
          <Route
            exact
            path="/unsubscribe/*"
            component={asyncComponent(() => import('./containers/Unsubscribe'))}
          />
          <RestrictedRoute path="/" component={App} isLoggedIn={isLoggedIn} />
        </Switch>
      </div>
    </ConnectedRouter>
  );
};

PublicRoutes.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(state => ({
  isLoggedIn: state.Auth.isLoggedIn,
}))(PublicRoutes);
