import actions from 'redux/marketing/marketingEmailTemplates/listPage/actions';
import detailTemplateAction from 
  'redux/marketing/marketingEmailTemplates/detailEmailTemplatePage/actions';

export const PAGE_SIZE_LOCALSTORAGE = 'marketingEmailTemplatesPage';

const emailTemplates = [
  {
    id: 0,
    name: '',
    template: '',
    userId: 1,
    createdAt: null,
    updatedAt: null,
  },
];

const initState = {
  loading: false,
  emailTemplates,
  isAddModalActive: false,
};

export default function marketingEmailTemplatesReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_TEMPLATES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_TEMPLATES_LIST_SUCCESS:
      const { emailTemplates } = action.payload || [];
      return {
        ...state,
        emailTemplates,
        loading: false,
      };

    case actions.GET_TEMPLATES_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.SET_INITIAL_TEMPLATES_LIST_STATE:
      return {
        ...state,
        ...action.payload,
        loading: true
      };

    case actions.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.CHANGE_TABLE_COLUMNS:
      return {
        ...state,
        ...action.payload,
      };
    case detailTemplateAction.UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isAddModalActive: false,
      };

    case actions.SHOW_ADD_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
