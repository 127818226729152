const DOCUMENT = 'MAILING_';

const mailingActions = {
  GET_MAILING_STATE: `GET_${DOCUMENT}STATE`,
  GET_MAILING_STATE_SUCCESS: `GET_${DOCUMENT}STATE_SUCCESS`,
  GET_MAILING_STATE_ERROR: `GET_${DOCUMENT}STATE_ERROR`,

  SET_CURRENT_MAILING_RECIPIENTS: `SET_CURRENT_${DOCUMENT}RECIPIENTS`,
  SET_CURRENT_MAILING_RECIPIENTS_SUCCESS: `SET_CURRENT_${DOCUMENT}RECIPIENTS_SUCCESS`,

  CHANGE_VIEW: `${DOCUMENT}CHANGE_VIEW`,
  UPDATE_MAILING: `${DOCUMENT}UPDATE`,
  UPDATE_MAILING_REQUEST: `UPDATE_${DOCUMENT}REQUEST`,
  UPDATE_MAILING_SUCCESS: `UPDATE_${DOCUMENT}SUCCESS`,
  UPDATE_MAILING_ERROR: `UPDATE_${DOCUMENT}ERROR`,

  CHANGE_STATUS_REQUEST: `CHANGE_STATUS_${DOCUMENT}REQUEST`,
  CHANGE_STATUS_SUCCESS: `CHANGE_STATUS_${DOCUMENT}SUCCESS`,
  CHANGE_STATUS_ERROR: `CHANGE_STATUS_${DOCUMENT}ERROR`,
  START_NOW: `START_NOW_${DOCUMENT}`,

  SET_RECIPIENTS: `${DOCUMENT}SET_RECIPIENTS`,

  ADD_SENDER: `${DOCUMENT}ADD_SENDER`,
  REMOVE_SENDER: `${DOCUMENT}REMOVE_SENDER`,
  CHANGE_SENDERS_SUCCESS: `${DOCUMENT}CHANGE_SENDERS_SUCCESS`,

  RESET_STATE: `${DOCUMENT}RESET_STATE`,

  changeView: (boxName, status) => ({
    type: mailingActions.CHANGE_VIEW,
    payload: { boxName, status },
  }),

  getMailingState: (mailingId, boxName, selectAllVisible) => ({
    type: mailingActions.GET_MAILING_STATE,
    payload: { mailingId, boxName, selectAllVisible },
  }),

  getMailingStateSuccess: (mailing, mailingSenders, senders) => ({
    type: mailingActions.GET_MAILING_STATE_SUCCESS,
    payload: { mailing, mailingSenders, senders },
  }),

  getMailingStateError: error => ({
    type: mailingActions.GET_MAILING_STATE_ERROR,
    payload: error,
  }),

  updateMailing: (mailing, boxName) => ({
    type: mailingActions.UPDATE_MAILING,
    payload: { mailing, boxName },
  }),

  updateMailingRequest: (newMailing, mailingId, boxName) => ({
    type: mailingActions.UPDATE_MAILING_REQUEST,
    payload: { newMailing, mailingId, boxName },
  }),

  updateMailingSuccess: (mailing, boxName) => ({
    type: mailingActions.UPDATE_MAILING_SUCCESS,
    payload: { mailing, boxName },
  }),

  updateMailingError: (mailing, boxName) => ({
    type: mailingActions.UPDATE_MAILING_ERROR,
    payload: { mailing, boxName },
  }),

  changeStatusMailingRequest: (id, status, startedDate = null, sendToSender) => ({
    type: mailingActions.CHANGE_STATUS_REQUEST,
    payload: {
      id,
      status,
      sendToSender,
      ...(startedDate && { startedDate }),
    },
  }),

  changeStatusMailingSuccess: payload => ({
    type: mailingActions.CHANGE_STATUS_SUCCESS,
    payload,
  }),

  changeStatusMailingError: () => ({
    type: mailingActions.CHANGE_STATUS_ERROR,
  }),

  startNow: (id, sendToSender) => ({
    type: mailingActions.START_NOW,
    payload: { id, sendToSender },
  }),

  setRecipients: (mailingRecipients) => ({
    type: mailingActions.SET_RECIPIENTS,
    payload: { mailingRecipients },
  }),

  addSender: (mailingId, authCredentialId) => ({
    type: mailingActions.ADD_SENDER,
    payload: { mailingId, authCredentialId },
  }),

  removeSender: (mailingId, authCredentialId) => ({
    type: mailingActions.REMOVE_SENDER,
    payload: { mailingId, authCredentialId },
  }),

  changeSenderSuccess: payload => ({
    type: mailingActions.CHANGE_SENDERS_SUCCESS,
    payload,
  }),

  resetState: () => ({
    type: mailingActions.RESET_STATE,
  }),
};

export default mailingActions;
