import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import resourceTimelineActions from 'redux/resources/resourseTimeline/actions';
import { apiRequest } from 'redux/helpers';
import { ENDPOINTS } from 'consts';
import { createQueryString, getStartEndDateQuery } from 'helpers/utility';
import openNotification from 'components/Notification';
import technologiesActions from 'redux/technologies/actions';
import levelActions from 'redux/levels/actions';
import resourceRoleActions from 'redux/resourceRoles/actions'
import { setUrl } from 'helpers/urlSync';
import tableActions from 'components/NewTable/redux/actions';

export function* resourceTimelineRequest() {
  yield takeEvery(resourceTimelineActions.GET_RESOURCE_TIMELINE_REQUEST,
		function* ({ignoreSetUrl}) {
    const { pagination, sortValues, searchValue } = yield select(state => state.tableReducer);
    const startEndQuery = getStartEndDateQuery(searchValue?.date)

		const queryUrl = `${ENDPOINTS.RESOURCE_TIMELINE}?${createQueryString({
      pagination,
      multiSort: sortValues,
      fields: {...searchValue, ...startEndQuery, statusFilter:'hired'}
		}
    )}`;
    const data = yield apiRequest(
      superFetch.get,
      queryUrl,
    );

    if (!ignoreSetUrl) {
      setUrl(queryUrl)
    }

    if (data.errors) {
      yield put(resourceTimelineActions
        .resourceTimelineError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceTimelineActions.resourceTimelineSuccess(
        {
          resources: data.items
        },
      ));
      yield put(tableActions.setPagination(data.pagination));
    }
  });
}

export function* resourceTimelineGetInitialState() {
  yield takeEvery(resourceTimelineActions.GET_INITIAL_RESOURCE_TIMELINE_STATE, function* () {
    const roles = yield apiRequest(superFetch.get, ENDPOINTS.RESOURCE_ROLES);
    if (roles.errors) {
      yield put(resourceRoleActions.getResourceRolesError(roles.errors));
      yield openNotification('error', 'Error', roles.errors);
    } else {
      yield put(resourceRoleActions.getResourceRolesSuccess(roles)
      );
      const technologies = yield apiRequest(superFetch.get, `${ENDPOINTS.TECHNOLOGY}?limit=all`);
      if (technologies.errors) {
        if (technologies.errors) {
          yield put(technologiesActions.getTechnologiesError(technologies.errors));
        }
        yield openNotification('error', 'Error', technologies.errors);
      } else {
        yield put(
          technologiesActions.getTechnologiesSuccess(
            technologies.technologies,
            technologies.pagination,
          ),
        );
      }

      const techLvl = yield apiRequest(superFetch.get, `${ENDPOINTS.TECH_LVL}?limit=all`);
      const englishLvl = yield apiRequest(superFetch.get, `${ENDPOINTS.ENG_LVL}?limit=all`);
      if (techLvl.errors || englishLvl.errors) {
        if (techLvl.errors) {
          yield openNotification('error', 'Error', techLvl.errors);
        } else {
          yield openNotification('error', 'Error', englishLvl.errors);
        }
      } else {
        yield put(levelActions.getTechLevelsSuccess(techLvl));
        yield put(levelActions.getEnglishLevelsSuccess(englishLvl));
      }
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(resourceTimelineRequest),
    fork(resourceTimelineGetInitialState),
  ]);
}
