import openNotification from 'components/Notification';
import { CONTENT_TYPES, ENDPOINTS } from 'consts';
import superFetch from 'helpers/superFetch';
import { setUrl } from 'helpers/urlSync';
import { createQueryString } from 'helpers/utility';
import { push } from 'react-router-redux';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { apiRequest } from 'redux/helpers';
import projectsFutureActions from 'redux/projects/futurePage/actions';
import tableActions from 'components/NewTable/redux/actions';
import potentialProjectsActions from '../potentialProjectsPage/actions';

export function* getFutureProjectsListRequest() {
  yield takeEvery(projectsFutureActions.GET_FUTURE_PROJECTS_LIST_REQUEST, function*({payload}) {
    const { searchValue } = yield select(state => state.ProjectsFutureReducer);

    const { pagination, searchValue: globalSearchValues, sortValues } = yield select(
      state => state.tableReducer,
    );
    const subParams = {};
    if(payload) {
      if(payload.qType)
        subParams.qType = payload.qType;
    }

    const queryUrl = `${ENDPOINTS.FUTURE_PROJECT}?${createQueryString({
      pagination,
      multiSort: sortValues,
      fields: {
        ...searchValue,
        ...globalSearchValues,
      },
      ...subParams,
    })}`;
    const data = yield apiRequest(superFetch.get, queryUrl);
    setUrl(queryUrl);

    if (data.errors) {
      yield put(projectsFutureActions.getFutureProjectsListError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(
        projectsFutureActions.getFutureProjectsListSuccess({
          futureProjectsList: [...data.projects],
        }),
      );

      yield put(tableActions.setPagination(data.pagination));
      yield put(projectsFutureActions.setPagination(data.pagination));
    }
  });
}

export function* addFutureProjectRequest() {
  yield takeEvery(projectsFutureActions.ADD_FUTURE_PROJECTS_REQUEST, function*({ payload }) {
    delete payload.avatarLastModified;
    const { id } = payload
    delete payload.id

    const data = yield apiRequest(
          superFetch.put,
          `${ENDPOINTS.FUTURE_PROJECT}/${id}`,
          payload,
          CONTENT_TYPES.JSON,
        )
    if (data.errors) {
      yield put(projectsFutureActions.getFutureProjectsListError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.addFutureProjectSuccess(!id));
      yield put(projectsFutureActions.getFutureProjectsListRequest());
    }
  });
}

export function* deleteFutureProjectRequest() {
  yield takeEvery(projectsFutureActions.DELETE_FUTURE_PROJECTS_REQUEST, function*({ payload }) {
    const data = yield apiRequest(superFetch.delete, `${ENDPOINTS.PROJECT}/${payload.id}`);
    if (data.errors) {
      yield put(projectsFutureActions.deleteFutureProjectError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.deleteFutureProjectSuccess());
      yield put(projectsFutureActions.getFutureProjectsListRequest());
    }
  });
}

export function* addFutureVacancyRequest() {
  yield takeEvery(projectsFutureActions.ADD_FUTURE_VACANCY_REQUEST, function*({ payload }) {
    const { projectId, vacancy } = payload;
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.FUTURE_PROJECT}/${projectId}/addVacancy`,
      vacancy,
    );
    if (data.errors) {
      yield put(projectsFutureActions.addFutureVacancyError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.addFutureVacancySuccess(data));
    }
  });
}

export function* updateFutureVacancyRequest() {
  yield takeEvery(projectsFutureActions.UPDATE_FUTURE_VACANCY_REQUEST, function*({ payload }) {
    const { projectId, vacancy } = payload;
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.FUTURE_PROJECT}/${projectId}/updateVacancy`,
      vacancy,
    );
    if (data.errors) {
      yield put(projectsFutureActions.updateFutureVacancyError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.updateFutureVacancySuccess(data));
    }
  });
}

export function* deleteFutureVacancyRequest() {
  yield takeEvery(projectsFutureActions.DELETE_FUTURE_VACANCY_REQUEST, function*({ payload }) {
    const { projectId, vacancyId } = payload;
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.FUTURE_PROJECT}/${projectId}/removeVacancy`,
      { vacancyId },
    );
    if (data.errors) {
      yield put(projectsFutureActions.deleteFutureVacancyError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.deleteFutureVacancySuccess(data));
    }
  });
}

export function* getResourceByTechnologyRequest() {
  yield takeEvery(projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_REQUEST, function*({ payload }) {
    const { id } = payload;
    const data = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.RESOURCE_SIMPLE_LIST}?technologies=${id}`,
    );

    if (data.errors) {
      yield put(projectsFutureActions.getResourceByTechnologyError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      const { resources } = data;
      yield put(
        projectsFutureActions.getResourceByTechnologySuccess({
          id,
          resources,
          type: 'technology',
        }),
      );
    }
  });
}

export function* getResourceByTechnologyTypeRequest() {
  yield takeEvery(projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_TYPE_REQUEST, function*({
    payload,
  }) {
    const { id } = payload;
    const data = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.RESOURCE_SIMPLE_LIST}?technologyType=${id}`,
    );

    if (data.errors) {
      yield put(projectsFutureActions.getResourceByTechnologyTypeError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      const { resources } = data;
      yield put(
        projectsFutureActions.getResourceByTechnologyTypeSuccess({
          id,
          resources,
          type: 'technologyType',
        }),
      );
    }
  });
}

export function* startProjectRequest() {
  yield takeEvery(projectsFutureActions.START_PROJECT_REQUEST, function*({ payload }) {
    const { id } = payload
    delete payload.id
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.FUTURE_PROJECT}/${id}/startProject`,
      {...payload}
    );
    if (data.errors) {
      yield put(projectsFutureActions.startProjectError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(push(`/projects/${id}`));
      yield put(projectsFutureActions.startProjectSuccess());
    }
  });
}

export function* archiveProjectRequest() {
  yield takeEvery(projectsFutureActions.ARCHIVE_PROJECT_REQUEST, function*() {
    const { id } = yield select(state => state.ProjectsFutureReducer.selectedProject);

    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.PROJECTS}/${id}/archive`,
    )

    if (data.errors) {
      yield put(projectsFutureActions.archiveProjectError())
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.addFutureProjectSuccess());
      yield put(projectsFutureActions.getFutureProjectsListRequest());
    }
  })
}

export function* getFutureProjectsSectionsRequest() {
  yield takeEvery(projectsFutureActions.GET_FUTURE_PROJECTS_SECTIONS_REQUEST, function*({payload}) {

    const data = yield apiRequest(superFetch.get, `${ENDPOINTS.FUTURE_PROJECT_SECTIONS}/?projectsType=${payload.projectsType}`);

    if (data.errors) {
      yield put(projectsFutureActions.getFutureProjectsListError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.getFutureProjectsSectionsSuccess(data));

      yield put(projectsFutureActions.setPagination(data.pagination));
    }
  });
}
export function* addFutureProjectSectionRequest() {
  yield takeEvery(projectsFutureActions.ADD_FUTURE_PROJECTS_SECTIONS_REQUEST, function*({payload}) {
    const data = yield apiRequest(superFetch.post, `${ENDPOINTS.FUTURE_PROJECT_SECTIONS}`, payload);
    if (data.errors) {
      yield put(projectsFutureActions.addFutureProjectsSectionsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.addFutureProjectsSectionsSuccess(data));
    }
  });
}

export function* updateFutureProjectSectionRequest() {
  yield takeEvery(projectsFutureActions.UPDATE_FUTURE_PROJECTS_SECTIONS_REQUEST, function*({
    payload,
  }) {
    const data = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.FUTURE_PROJECT_SECTIONS}/${payload.id}`,
      payload,
    );
    if (data.errors) {
      yield put(projectsFutureActions.updateFutureProjectsSectionsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.updateFutureProjectsSectionsSuccess(data));
      yield put(
        projectsFutureActions.getFutureProjectsSectionsRequest({projectsType: payload.projectsType})
      );
    }
  });
}

export function* removeFutureProjectSectionRequest() {
  yield takeEvery(projectsFutureActions.REMOVE_FUTURE_PROJECTS_SECTIONS_REQUEST,
    function*({ payload }){

      const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.FUTURE_PROJECT_SECTIONS}/${payload.id}`,
    );

    if (data.errors) {
      yield put(projectsFutureActions.removeFutureProjectsSectionsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.removeFutureProjectsSectionsSuccess(payload.id));
      yield put(projectsFutureActions.getFutureProjectsListRequest());
      yield put(potentialProjectsActions.getPotentialProjectsRequest());
    }
  });
}

export function* updateProjectSectionRequest() {
  yield takeEvery(projectsFutureActions.UPDATE_FUTURE_PROJECT_SECTION_REQUEST, function*({
    payload,
  }) {
    const body = {
      sectionId: payload.sectionId,
    };
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.FUTURE_PROJECT}/${payload.projectId}/updateSection`,
      body,
    );
    if (data.errors) {
      yield put(projectsFutureActions.updateFutureProjectSectionError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.updateFutureProjectSectionSuccess());
      yield put(projectsFutureActions.getFutureProjectsListRequest());
      yield put(potentialProjectsActions.getPotentialProjectsRequest({projectsType: payload.projectsType}));
    }
  });
}
export function* rescheduleFutureProjectsRequest() {
  yield takeEvery(projectsFutureActions.RESCHEDULE_FUTURE_PROJECTS_REQUEST, function*({ payload }) {
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.FUTURE_PROJECT}/reschedule`,
      payload,
    );
    if (data.errors) {
      yield put(projectsFutureActions.rescheduleFutureProjectsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectsFutureActions.rescheduleFutureProjectsSuccess());
      yield put(projectsFutureActions.getFutureProjectsListRequest());
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getFutureProjectsListRequest),
    fork(addFutureProjectRequest),
    fork(deleteFutureProjectRequest),
    fork(addFutureVacancyRequest),
    fork(updateFutureVacancyRequest),
    fork(deleteFutureVacancyRequest),
    fork(getResourceByTechnologyRequest),
    fork(getResourceByTechnologyTypeRequest),
    fork(startProjectRequest),
    fork(getFutureProjectsSectionsRequest),
    fork(addFutureProjectSectionRequest),
    fork(updateFutureProjectSectionRequest),
    fork(removeFutureProjectSectionRequest),
    fork(updateProjectSectionRequest),
    fork(rescheduleFutureProjectsRequest),
    fork(archiveProjectRequest),
  ]);
}
