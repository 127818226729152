import actions from 'redux/projectManagers/actions';

const initState = {
  loading: false,
  projectManagers: [],
};

export default function projectManagersReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_PROJECTMANAGERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PROJECTMANAGERS_SUCCESS:
      return {
        ...state,
        projectManagers: action.payload.projectManagers || [],
        loading: false,
      };
    case actions.GET_PROJECTMANAGERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
