import { urlQueryKeys } from 'library/constants';
import { DEVICE_TYPES, TIMELINE_TYPES } from 'consts/index';
import notification from 'components/theme/Notification';
import queryString from 'query-string';
import { isNaN, get, isEmpty } from 'lodash';

export function isHexColor(hexCode) {
  return /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(hexCode);
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => (num > 9 ? `${num}` : `0${num}`);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return `${days} day${numberEnding(days)}`;
      }
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const month = months[givenTime.getUTCMonth()];
      const day = number(givenTime.getUTCDate());
      return `${day} ${month}`;
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export const createQueryString = ({
  pagination,
  sort,
  multiSort,
  filters,
  fields,
  searchValue,
  groupBy,
  ...query
}) => {
  if (pagination) {
    query.limit = query.limit || (pagination.pageSize === 0 ? 'all' : pagination.pageSize);
    if (pagination.currentPage) {
      query.page = pagination.currentPage;
    }
  }

  if (multiSort && multiSort.length) {
    query.sort = multiSort
      .map(
        sort => `${sort.columnKey}:${sort.order.length > 4 ? sort.order.slice(0, -3) : sort.order}`,
      )
      .join(',');
  }

  if (sort && sort.columnKey && sort.order) {
    query.sort = `${sort.columnKey}:${sort.order.slice(0, -3)}`;
  }

  if (groupBy && groupBy.length !== 0) {
    query.groupBy = groupBy;
  }

  if (fields && fields !== 'undefined') {
    Object.keys(fields).map(queryKey =>
      fields[queryKey].length !== 0 ? (query[queryKey] = fields[queryKey]) : null,
    );
  }

  return Object.keys(query)
    .map(queryKey => `${queryKey}=${encodeURIComponent(query[queryKey])}`)
    .join('&');
};

export const setInitialState = (
  callback,
  location,
  searchKey,
  pageSizeKey,
  columns,
  singleSort,
  handleGetData,
) => {
  const parsedUrl = queryString.parse(location.search);
  const initialState = {};

  Object.keys(parsedUrl).forEach(key => {
    switch (key) {
      case urlQueryKeys.limit:
        initialState.pagination = {
          pageSize:
            parsedUrl.limit === 'all' || isNaN(parseInt(parsedUrl.limit, 10))
              ? 0
              : +parsedUrl.limit,
          totalItems: null,
          totalPages: null,
        };

        if (pageSizeKey) {
          localStorage.setItem(pageSizeKey, parsedUrl.limit);
        }
        break;
      case urlQueryKeys.page:
        initialState.pagination.currentPage = +parsedUrl.page;
        break;
      case urlQueryKeys.sort:
        const sorters = parsedUrl.sort.split(',').map(el => el.split(':'));

        initialState.sortValues = sorters.map(el => {
          const key = el[0] === 'type' ? 'technologyType' : el[0];
          const column = columns.find(col => col.key === key);
          let order = '';
          if (el[1] === 'asc' || el[1] === 'ascend') {
            order = 'ascend';
          } else if (el[1] === 'desc' || el[1] === 'descend') {
            order = 'descend';
          }

          return {
            columnKey: key,
            order,
            lastChange: new Date(),
            field: column?.dataIndex,
            column,
          };
        });

        initialState.sortValues = singleSort ? initialState.sortValues[0] : initialState.sortValues;
        break;
      default:
        if (!initialState.searchValue) {
          initialState.searchValue = {};
        }

        if (key === searchKey) {
          initialState.searchValue[key] = parsedUrl[key];
        } else {
          initialState.searchValue[key] = parsedUrl[key]
            .split(',')
            .map(el => (isNaN(+el) ? el : +el));
        }
        break;
    }
  });

  if (!initialState?.pagination && pageSizeKey && localStorage.getItem(pageSizeKey)) {
    const storageSize = localStorage.getItem(pageSizeKey);
    initialState.pagination = {
      pageSize: storageSize === 'all' ? 0 : storageSize,
      currentPage: 1,
    };
  }

  callback(initialState);
  if (handleGetData) {
    handleGetData();
  }
};

export const checkPermission = (claim, claims) => {
  return claims && claims.includes(claim);
};

export function onSuccessUpload() {
  notification('success', 'Image uploaded successfully!', '');
  return true;
}

export const removeNullValues = (values, key = 'name') =>
  values.filter(item => item?.[key] && item?.[key].length && !item?.[key].match(/^\s+$/));

export function deepEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

export function generateUniqueId() {
  return (
    Math.random()
      .toString(36)
      .substring(2) + Date.now().toString(36)
  );
}

export function generateSelectedFromArray(arr) {
  return Object.fromEntries(arr.map(key => [key, true]));
}

export function getImagesFromEditor(content) {
  return content.ops.filter(i => i.insert && i.insert.image).map(i => i.insert.image);
}

export function shortText(text, maxLength = 50) {
  if (!text) return ' ';
  if (text.length <= maxLength) return text;

  return `${text.substr(0, maxLength)}...`;
}

export const countTitleMarginRightForRibbon = resource => {
  const { vacationsDaysLeft } = resource;
  // if you want to show more one ribbon
  return [vacationsDaysLeft].filter(item => item).length;
};

export function capitalize(text) {
  if (typeof text !== 'string') return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const humanizeEnum = (enumValue) => {
  return enumValue.charAt(0) + enumValue.slice(1).toLowerCase();
};

export const getView = width => {
  switch (true) {
    case width > 1220:
      return DEVICE_TYPES.DESKTOP_VIEW;
    case width > 767:
      return DEVICE_TYPES.TAB_VIEW;
    default:
      return DEVICE_TYPES.MOBILE_VIEW;
  }
};

export const getResourceFromReceiverId = (resources, receiver) =>
  resources.find(resource => resource.id === parseInt(receiver?.resourceId, 10)) || false;

export const getSettingsIdFromSettings = (settings, key, target) => {
  if (!settings || !Array.isArray(settings)) {
    return false;
  }
  return (settings.find(item => item.key === key && item.target === target) || {})?.id;
};

export const getVacancyTechnologyKey = (technologyId, technologyTypeId) =>
  `${technologyId ? 'technology' : 'technologyType'}${technologyId || technologyTypeId}`;

export const getStartEndDateQuery = value => {
  if (isEmpty(value)) return { start: '', end: '' };

  return Array.isArray(value)
    ? {
        start: value?.[0],
        end: value?.[1],
      }
    : {
        start: value.split(',')[0],
        end: value.split(',')[1],
      };
};

export const getTimelineStyle = timelineStatus => {
  const statusObj =
    TIMELINE_TYPES.find(item => item.id === timelineStatus) ||
    TIMELINE_TYPES.find(item => item.id === 'Active');
  return get(statusObj, 'color', '');
};

export const convertEnumToPayload = (data) => {
  return Object.keys(data).map((key, index) => ({
    id: data[key],
    title: humanizeEnum(key),
  }));
};