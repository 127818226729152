const CLAIM_TYPES = {
  EDIT_ROLE: 'EDIT_ROLE',
  VIEW_ROLE: 'VIEW_ROLE',
  INVITE_USER: 'INVITE_USER',
  VIEW_RESOURCE: 'VIEW_RESOURCE',
  EDIT_RESOURCE: 'EDIT_RESOURCE',
  VIEW_USER: 'VIEW_USER',
  EDIT_USER: 'EDIT_USER',
  VIEW_TECHNOLOGY: 'VIEW_TECHNOLOGY',
  EDIT_TECHNOLOGY: 'EDIT_TECHNOLOGY',
  VIEW_RESOURCE_ROLE: 'VIEW_RESOURCE_ROLE',
  EDIT_RESOURCE_ROLE: 'EDIT_RESOURCE_ROLE',
  VIEW_LEVELS: 'VIEW_LEVELS',
  EDIT_LEVELS: 'EDIT_LEVELS',
  VIEW_CLIENT: 'VIEW_CLIENT',
  EDIT_CLIENT: 'EDIT_CLIENT',
  VIEW_PROJECT: 'VIEW_PROJECT',
  EDIT_PROJECT: 'EDIT_PROJECT',
  VIEW_PROJECT_STATUS: 'VIEW_PROJECT_STATUS',
  EDIT_PROJECT_STATUS: 'EDIT_PROJECT_STATUS',
  VIEW_RESOURCE_BY_PROJECT: 'VIEW_RESOURCE_BY_PROJECT',
  VIEW_RESOURCE_BY_ASSIGNMENT_TYPE: 'VIEW_RESOURCE_BY_ASSIGNMENT_TYPE',
	VIEW_RESOURCE_PLANNING: 'VIEW_RESOURCE_PLANNING',
  VIEW_FUTURE_BY_PROJECT: 'VIEW_FUTURE_BY_PROJECT',
  VIEW_TIMELINE_PROJECT: 'VIEW_TIMELINE_PROJECT',
  EDIT_TIMELINE_PROJECT: 'EDIT_TIMELINE_PROJECT',
  VIEW_FUTURE_PROJECT: 'VIEW_FUTURE_PROJECT',
  EDIT_FUTURE_PROJECT: 'EDIT_FUTURE_PROJECT',
  EDIT_FUTURE_PROJECT_SECTION: 'EDIT_FUTURE_PROJECT_SECTION',
  VIEW_ONGOING_PROJECT: 'VIEW_ONGOING_PROJECT',
  EDIT_ONGOING_PROJECT: 'EDIT_ONGOING_PROJECT',
  VIEW_EMAIL_TEMPLATE: 'VIEW_EMAIL_TEMPLATE',
  EDIT_EMAIL_TEMPLATE: 'EDIT_EMAIL_TEMPLATE',
  VIEW_RECIPIENT: 'VIEW_RECIPIENT',
  EDIT_RECIPIENT: 'EDIT_RECIPIENT',
  VIEW_CAMPAIGN: 'VIEW_CAMPAIGN',
  EDIT_CAMPAIGN: 'EDIT_CAMPAIGN',
  VIEW_MAILING: 'VIEW_MAILING',
  EDIT_MAILING: 'EDIT_MAILING',
  VIEW_GROUPS: 'VIEW_GROUPS',
  EDIT_GROUPS: 'EDIT_GROUPS',
  VIEW_AUTH_CREDENTIAL: 'VIEW_AUTH_CREDENTIAL',
  EDIT_AUTH_CREDENTIAL: 'EDIT_AUTH_CREDENTIAL',
  VIEW_ACTION_LOGS: 'VIEW_ACTION_LOGS',
  VIEW_VACATION_PAGE: 'VIEW_VACATION_PAGE',
  CREATE_VACATION: 'CREATE_VACATION',
  UPDATE_VACATION: 'UPDATE_VACATION',
  DELETE_VACATION: 'DELETE_VACATION',
  APPROVE_VACATION: 'APPROVE_VACATION',
  VIEW_VACATION_SETTINGS: 'VIEW_VACATION_SETTINGS',
  EDIT_VACATION_SETTINGS: 'EDIT_VACATION_SETTINGS',

  VIEW_RESOURCE_MAIN_INFO: 'VIEW_RESOURCE_MAIN_INFO',
  EDIT_RESOURCE_MAIN_INFO: 'EDIT_RESOURCE_MAIN_INFO',
  VIEW_RESOURCE_CONTRACT_INFO: 'VIEW_RESOURCE_CONTRACT_INFO',
  EDIT_RESOURCE_CONTRACT_INFO: 'EDIT_RESOURCE_CONTRACT_INFO',
  VIEW_RESOURCE_OTHER_INFO: 'VIEW_RESOURCE_OTHER_INFO',
  EDIT_RESOURCE_OTHER_INFO: 'EDIT_RESOURCE_OTHER_INFO',
  VIEW_RESOURCE_VACATION_INFO: 'VIEW_RESOURCE_VACATION_INFO',
  EDIT_RESOURCE_VACATION_INFO: 'EDIT_RESOURCE_VACATION_INFO',
  VIEW_RESOURCE_CONTACT_INFO: 'VIEW_RESOURCE_CONTACT_INFO',
  EDIT_RESOURCE_CONTACT_INFO: 'EDIT_RESOURCE_CONTACT_INFO',
  VIEW_RESOURCE_STATUS_INFO: 'VIEW_RESOURCE_STATUS_INFO',
  EDIT_RESOURCE_STATUS_INFO: 'EDIT_RESOURCE_STATUS_INFO',
  VIEW_RESOURCE_SALARY_INFO: 'VIEW_RESOURCE_SALARY_INFO',
  EDIT_RESOURCE_SALARY_INFO: 'EDIT_RESOURCE_SALARY_INFO',
  VIEW_RESOURCE_SIGNING_BONUS_INFO: 'VIEW_RESOURCE_SIGNING_BONUS_INFO',
  EDIT_RESOURCE_SIGNING_BONUS_INFO: 'EDIT_RESOURCE_SIGNING_BONUS_INFO',
  VIEW_RESOURCE_OVERTIME_INFO: 'VIEW_RESOURCE_OVERTIME_INFO',
  EDIT_RESOURCE_OVERTIME_INFO: 'EDIT_RESOURCE_OVERTIME_INFO',
  VIEW_RESOURCE_REFERRAL_BONUS_INFO: 'VIEW_RESOURCE_REFERRAL_BONUS_INFO',
  EDIT_RESOURCE_REFERRAL_BONUS_INFO: 'EDIT_RESOURCE_REFERRAL_BONUS_INFO',
  VIEW_RESOURCE_COMMENT_INFO: 'VIEW_RESOURCE_COMMENT_INFO',
  EDIT_RESOURCE_COMMENT_INFO: 'EDIT_RESOURCE_COMMENT_INFO',
  VIEW_RESOURCE_PROJECT_HISTORY: 'VIEW_RESOURCE_PROJECT_HISTORY',
  EDIT_RESOURCE_PROJECT_HISTORY: 'EDIT_RESOURCE_PROJECT_HISTORY',
  CREATE_RESOURCE_PROJECT_HISTORY: 'CREATE_RESOURCE_PROJECT_HISTORY',
  DELETE_RESOURCE_PROJECT_HISTORY: 'DELETE_RESOURCE_PROJECT_HISTORY',
  VIEW_RESOURCE_EVALUATION: 'VIEW_RESOURCE_EVALUATION',
  EDIT_RESOURCE_EVALUATION: 'EDIT_RESOURCE_EVALUATION',
  DELETE_RESOURCE_EVALUATION: 'DELETE_RESOURCE_EVALUATION',
  COMPLETE_RESOURCE_EVALUATION: 'COMPLETE_RESOURCE_EVALUATION',
  VIEW_PROJECT_HISTORY: 'VIEW_PROJECT_HISTORY',
  EDIT_PROJECT_HISTORY: 'EDIT_PROJECT_HISTORY',
  CREATE_PROJECT_HISTORY: 'CREATE_PROJECT_HISTORY',
  DELETE_PROJECT_HISTORY: 'DELETE_PROJECT_HISTORY',
  VIEW_DOCUMENTS: 'VIEW_DOCUMENTS',
  EDIT_DOCUMENTS: 'EDIT_DOCUMENTS',
  CREATE_DOCUMENTS: 'CREATE_DOCUMENTS',
  DELETE_DOCUMENTS: 'DELETE_DOCUMENTS',
};

export default CLAIM_TYPES;
