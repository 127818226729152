import dashboardActions from 'redux/dashboard/actions';

const initState = {
  chartModal: {
    isOpen: false
  }
}

export default function DashboardReducer (state = initState, action) {
  switch (action.type) {
    case dashboardActions.SHOW_MODAL:
      return {
        ...state,
        chartModal: {
          isOpen: true,
          ...action.payload
        }
      }

    case dashboardActions.HIDE_MODAL:
      return {
        ...state,
        chartModal: {
          isOpen: false
        }
      }

    default:
      return state
  }
}
