import styled from 'styled-components';
import { palette } from 'styled-theme';

const AppHolder = styled.div`

  .resource-technologies-level {
    text-align: center;
    color: #2D3447;
    font-size: 12px;
    font-family: Roboto,sans-serif;
    font-weight: 400;
    line-height: 12px;
    word-wrap: break-word;
  }

  .resource-technologies-tag {
    padding: 5px 8px 4px 8px;
    border-radius: 5px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 21.5px;
    margin-left: -1px;
  }

  .technologies-tag-wrapper {
    display: flex;
    color: #fff;
    height: 21.5px;
    padding: 7px 4px 7px 0;
    border-radius: 5px;
    align-items: center;
    border: 1px #D9D9D9 solid;
    justify-content: flex-start;
    gap: 4px;
  }

  .technology-text {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }

  .resource-level-tag {
    color: #40434E;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    display: flex;
    gap: 8px;
    cursor: pointer;
    width: fit-content;
    justify-content: center;
    padding: 4px;
  }

  .resource-level-tag-empty {
    padding-left: 10px;
  }

  .technologies-tag-wrapper-open-drawer {
    cursor: pointer;
  }

  .resource-technologies-priority-type {
    color: #595959;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: 16px;
    word-wrap: break-word;
    margin: 1px 6px 0 0;
  }

  .tag-middle-size {
    border-radius: 5px;
  }

  .tag-small-size {
    margin-left: 6px;

    .ant-tag {
      border-radius: 5px;
      margin: 0px 3px 0px 0px;
      font-size: 10px;
      line-height: 18px;
      font-weight: 400;
    }
  };

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${palette('primary', 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette('secondary', 1)};

    &.isoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;

        &.collapsedMainLayout {
          width: calc(100% - 265px);
        }

        .ant-badge-count, .ant-pagination-disabled, .pagination-size-select {
          z-index: 0 !important;
        }
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 1;
      }
    }
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: #ffffff;
    border: 1px solid ${palette('border', 0)};
    height: 100%;
  }

  .isomorphicLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
    }
  }

  .ant-layout-footer {
    font-size: 13px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }

  button {
    border-radius: 0;
  }

  .app-spin-wrapper {
    margin: auto;

    .ant-spin-dot {
      width: 70px;
      height: 70px;

      .ant-spin-dot-item {
        width: 25px;
        height: 25px;
      }
    }
  }

  .scrollBox {
    scrollBehavior: 'smooth'
  }
`;

export default AppHolder;
