import config from 'settings';
import { isNullOrUndefined } from 'helpers/utility';
import { CONTENT_TYPES } from 'consts';

const customHeader = contentType => {
  const headers = {
    Accept: 'application/json',
  };
  if (contentType === CONTENT_TYPES.JSON) {
    headers['Content-Type'] = CONTENT_TYPES.JSON;
  }
  return headers;
};

const getBody = (contentType, data) => {
  switch (contentType) {
    case CONTENT_TYPES.JSON:
      return JSON.stringify(data);
    case CONTENT_TYPES.MULTIPART_DATA:
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        formData.append(key, isNullOrUndefined(data[key]) ? '' : data[key]);
      });
      return formData;
    default:
      return JSON.stringify(data);
  }
};

const base = (method, url, data, contentType = CONTENT_TYPES.JSON) => {
  return fetch(`${config.apiUrl}${url}`, {
    method: method.toUpperCase(),
    headers: customHeader(contentType),
    body: getBody(contentType, data),
    credentials: 'include',
  }).catch(errors => {
    return { errors: errors.message || errors };
  });
};

const SuperFetch = {};
['get', 'post', 'put', 'delete', 'patch'].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
