import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import openNotification from 'components/Notification';
import { ENDPOINTS } from 'consts';
import superFetch from 'helpers/superFetch';
import { apiRequest } from 'redux/helpers';
import actions from 'redux/marketing/marketingEmailTemplates/detailEmailTemplatePage/actions';
import marketingEmailTemplatesActions
  from 'redux/marketing/marketingEmailTemplates/listPage/actions';

export function* updateTemplateRequest() {
  yield takeEvery(actions.UPDATE_TEMPLATE_REQUEST, function*({ payload }) {
    const {
      emailTemplate: { emailTemplate },
      editEmailTemplateField,
    } = yield select(state => state.MarketingEmailTemplate);

    const { formData, formName } = payload;
    formData.template = editEmailTemplateField?.template;
    formData.userId = editEmailTemplateField?.userId;
    // eslint-disable-next-line max-len
    formData.imageIds = editEmailTemplateField.imageIds ? JSON.stringify(editEmailTemplateField.imageIds) : null;

    const updatedMarketingTemplate = {
      ...emailTemplate,
      ...formData,
    };

    const data = emailTemplate?.id
      ? yield apiRequest(
          superFetch.put,
          `${ENDPOINTS.EMAIL_TEMPLATE}/${updatedMarketingTemplate.id}`,
          {...updatedMarketingTemplate}
        )
      : yield apiRequest(
          superFetch.post,
          `${ENDPOINTS.EMAIL_TEMPLATE}/`,
          {...updatedMarketingTemplate}
        );
    if (data.errors) {
      yield put(actions.updateTemplateError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.updateTemplateSuccess(data, formName));
      if (emailTemplate?.id) {
        yield put(actions.getTemplateRequest(data.id));
      } else {
        yield put(marketingEmailTemplatesActions.getTemplatesListRequest({}));
      }
    }
  });
}

export function* getTemplateRequest() {
  yield takeEvery(actions.GET_TEMPLATE_REQUEST, function*({ payload }) {
    const data = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.EMAIL_TEMPLATE}/${payload.id}`,
    );
    data.imageIds = JSON.parse(data.imageIds);

    if (data.errors) {
      yield put(actions.getTemplateError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getTemplateSuccess(data));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(updateTemplateRequest), fork(getTemplateRequest)]);
}
