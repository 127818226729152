export const flagWithCount = (newArr, unicFlag) => {
  for (let i = 0; i < unicFlag.length; i++){
    unicFlag[i].countFlag = newArr[i]
  }
  return unicFlag;
}

export const countFlag = (typeFlags,unicFlag) => {
  const arr = []
  for (let i = 0; i < unicFlag.length; i++){
    arr[i] = typeFlags.filter(elem => elem === unicFlag[i].flagType).length
  }
  return arr;
}

export const sortFlags = (a,b) => {
  return a.sequenceNumber - b.sequenceNumber
}

export const getUpdated = (array, object) => {
  return array.map(elem => {
    if(elem.id === object.id) {
      elem.isVisible = object.isVisible;
      elem.title = object?.title;
      elem.viewIn = object?.viewIn;
      elem.viewFor = object?.viewFor;
    }
    return elem;
  });
}

export const getUpdatedPotentialProject = (array, object) => {
  return array.map(elem => {
    if (elem.id === object.id) {
      return {
        ...elem,
        title: object?.title,
        description: object?.description,
        startDate: object?.startDate,
        clientId: object?.clientId,
        client: object?.client,
        tierId: object?.tierId,
        amId: object?.amId,
        baId: object?.baId,
        am: object?.am,
        ba: object?.ba,
      };
    }
    return elem;
  });
}


