import typesActions from 'redux/types/actions';

const initialState = {
  assignments: [],
  technologies: [],
  flags: [],
  bonus: [],
  projectStatus: [],
  tiers: [],
  workloads: [],
  loading: false,
};

export default function TypesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {

    // GET ALL TYPES

    case typesActions.GET_ALL:
      return {
        loading: true,
      };

    case typesActions.GET_ALL_SUCCESS:
      return {
        assignments: payload.assignments,
        technologies: payload.technologies,
        flags: payload.flags,
        bonus: payload.bonus,
        projectStatus: payload.projectStatus,
        loading: true,
      };

    // GET ASSIGNMENTS

    case typesActions.GET_ASSIGNMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case typesActions.GET_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        assignments: payload,
        loading: false,
      };

    case typesActions.GET_ASSIGNMENT_ERROR:
      return {
        loading: false,
      };

    // GET TECHNOLOGY TYPES

    case typesActions.GET_TECHNOLOGY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case typesActions.GET_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        technologies: [...payload],
        loading: false,
      };

    case typesActions.GET_TECHNOLOGY_ERROR:
      return {
        ...state,
        loading: false,
      };

    // GET FLAG TYPES

    case typesActions.GET_FLAG_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case typesActions.GET_FLAG_SUCCESS:
      return {
        ...state,
        flags: [...payload.flags],
        loading: false,
      };

    case typesActions.GET_FLAG_ERROR:
      return {
        ...state,
        loading: false,
      };

    // GET BONUS TYPES

    case typesActions.GET_BONUS_REQUEST:
      return {
        loading: true,
      };

    case typesActions.GET_BONUS_SUCCESS:
      return {
        loading: true,
      };

    case typesActions.GET_BONUS_ERROR:
      return {
        loading: true,
      };

    // GET PROJECT STATUS TYPES

    case typesActions.GET_PROJECT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case typesActions.GET_PROJECT_STATUS_SUCCESS:
      return {
        ...state,
        projectStatus: [...payload],
        loading: false,
      };

    case typesActions.GET_PROJECT_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    // Get Tiers

    case typesActions.GET_TIERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case typesActions.GET_TIERS_SUCCESS:
      return {
        ...state,
        tiers: [...payload],
        loading: false,
      };

    case typesActions.GET_TIERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    // Get Workload

    case typesActions.GET_WORKLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case typesActions.GET_WORKLOAD_SUCCESS:
      return {
        ...state,
        workloads: [...payload],
        loading: false,
      };

    case typesActions.GET_WORKLOAD_ERROR:
      return {
        ...state,
        loading: false,
      };

    // Default

    default:
      return state;
  }
};
