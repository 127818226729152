import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { apiRequest } from 'redux/helpers';
import superFetch from 'helpers/superFetch';
import vacationSettingsActions from 'redux/vacation/settings/actions';

import openNotification from 'components/Notification';
import { ENDPOINTS } from 'consts';
import { createQueryString } from '../../../helpers/utility';
import tableActions from '../../../components/NewTable/redux/actions';

export function* getVacationReceiversRequest() {
  yield takeEvery(vacationSettingsActions.GET_VACATION_RECEIVERS_REQUEST, function*() {
    const data = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.VACATION_SETTINGS}/recipientList?limit=all`,
    );

    if (data.errors) {
      yield put(vacationSettingsActions.getVacationReceiversError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(vacationSettingsActions.getVacationReceiversSuccess(data.data));
    }
  });
}

export function* getVacationHolidaysRequest() {
  yield takeEvery(vacationSettingsActions.GET_VACATION_HOLIDAYS_REQUEST, function*() {
    const data = yield apiRequest(superFetch.get, `${ENDPOINTS.VACATION_HOLIDAY}?limit=all`);

    if (data.errors) {
      yield put(vacationSettingsActions.getVacationHolidaysError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(vacationSettingsActions.getVacationHolidaysSuccess(data.items));
    }
  });
}

export function* createVacationHolidaysRequest() {
  yield takeEvery(vacationSettingsActions.CREATE_VACATION_HOLIDAYS_REQUEST, function*({ payload }) {
    const { name, day, month, availableIn } = payload;
    const data = yield apiRequest(superFetch.post, `${ENDPOINTS.VACATION_HOLIDAY}/`, {
      name,
      day,
      month,
      availableIn,
    });

    if (data.errors) {
      yield put(vacationSettingsActions.createVacationHolidaysError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(vacationSettingsActions.createVacationHolidaysSuccess());
      yield put(vacationSettingsActions.getVacationHolidaysRequest());
    }
  });
}

export function* updateVacationHolidaysRequest() {
  yield takeEvery(vacationSettingsActions.UPDATE_VACATION_HOLIDAYS_REQUEST, function*({ payload }) {
    const { id, name, day, month, availableIn } = payload;
    const data = yield apiRequest(superFetch.put, `${ENDPOINTS.VACATION_HOLIDAY}/${id}`, {
      name,
      day,
      month,
      availableIn,
    });

    if (data.errors) {
      yield put(vacationSettingsActions.updateVacationHolidaysError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(vacationSettingsActions.updateVacationHolidaysSuccess());
      yield put(vacationSettingsActions.getVacationHolidaysRequest());
    }
  });
}

export function* updateVacationReceiversRequest() {
  yield takeEvery(vacationSettingsActions.UPDATE_VACATION_RECEIVERS_REQUEST,
    function* ({ payload }) {
    const data = yield apiRequest(superFetch.put,
      `${ENDPOINTS.VACATION_SETTINGS}/u/vacationRecipientEmail`, payload);

    if (data.errors) {
      yield put(vacationSettingsActions.updateVacationReceiversError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(vacationSettingsActions.updateVacationReceiversSuccess(data.data));
    }
  });
}

export function* deleteVacationHolidaysRequest() {
  yield takeEvery(vacationSettingsActions.DELETE_VACATION_HOLIDAYS_REQUEST, function*({ payload }) {
    const { id } = payload;
    const data = yield apiRequest(superFetch.delete, `${ENDPOINTS.VACATION_HOLIDAY}/${id}`);

    if (data.errors) {
      yield put(vacationSettingsActions.deleteVacationHolidaysError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(vacationSettingsActions.deleteVacationHolidaysSuccess(payload));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getVacationReceiversRequest),
    fork(getVacationHolidaysRequest),
    fork(createVacationHolidaysRequest),
    fork(updateVacationHolidaysRequest),
    fork(updateVacationReceiversRequest),
    fork(deleteVacationHolidaysRequest),
  ]);
}
