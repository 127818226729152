import { ENDPOINTS } from 'consts';
import superFetch from 'helpers/superFetch';
import { createQueryString } from 'helpers/utility';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { apiRequest } from 'redux/helpers';
import resourcesHistoryActions from 'redux/resources/resourсeHistory/actions';
import tableActions from '../../../components/NewTable/redux/actions';
import openNotification from '../../../components/Notification';
import { setUrl } from '../../../helpers/urlSync';
import Logger from '../../../library/logger';
import resourceActions from '../resource/actions';
import { getAllProjectsByHistory, getAllResourcesByHistory } from '../../../helpers/resourceHelper';
import { notification } from 'antd';

export function* deleteResourceHistoryRequest() {
  yield takeEvery(resourcesHistoryActions.DELETE_RESOURCES_HISTORY_REQUEST, function*({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.RESOURCE_PROJECT_HISTORY}/${payload.id}`,
    );

    if (data.errors) {
      yield put(resourcesHistoryActions.deleteResourcesHistoryError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourcesHistoryActions.deleteResourcesHistorySuccess(payload.id));
      yield put(resourceActions.getResourcesHistoryDetailPageRequest(payload.resourceId));
    }
  });
}

function* getResourcesHistoryRequest() {
  yield takeEvery(resourcesHistoryActions.GET_RESOURCES_HISTORY_REQUEST, function*({
    payload,
    ignoreSetUrl,
  }) {

    const { pagination, sortValues, searchValue } = yield select(state => state.tableReducer);
    // eslint-disable-next-line max-len
    const searchValues = payload?.defaultFilters
      ? { ...searchValue, ...payload.defaultFilters }
      : searchValue;

    const queryUrl = `${ENDPOINTS.RESOURCE_PROJECT_HISTORY}?${createQueryString({
      pagination,
      multiSort: sortValues,
      fields: searchValues,
    })}`;
    const data = yield apiRequest(superFetch.get, queryUrl);
    const allData = yield apiRequest(superFetch.get, "/resourceProjectHistory?limit=all&page=1");

    const newFilteredResources = getAllResourcesByHistory(allData);
    const newFilteredProjects = getAllProjectsByHistory(allData);

    if (!ignoreSetUrl) {
      setUrl(queryUrl);
    }

    if (data.errors) {
      yield put(resourcesHistoryActions.getResourcesHistoryError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourcesHistoryActions.getResourcesHistorySuccess(
        data.resourceProjectHistories,
        newFilteredResources,
        newFilteredProjects
        ));
      yield put(tableActions.setPagination(data.pagination));
    }
  });
}

export function* createResourceHistoryRequest() {
  yield takeEvery(resourcesHistoryActions.CREATE_RESOURCE_HISTORY_REQUEST, function*({ payload }) {
    const body = {
      assignmentTypeId: payload.assignmentTypeId,
      // eslint-disable-next-line no-restricted-globals
      projectId: isNaN(payload.projectId) ? null : payload.projectId,
      resourceId: payload.resourceId,
      startDate: payload.startDate,
      endDate: payload.expectedEndDate,
    };
    const data = yield apiRequest(superFetch.post, `${ENDPOINTS.RESOURCE_PROJECT_HISTORY}`, body);

    if (data.errors) {
      yield put(resourcesHistoryActions.createResourceHistoryError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourcesHistoryActions.getResourcesHistoryRequest());
      yield put(resourceActions.getResourcesHistoryDetailPageRequest(payload.resourceId));
    }
  });
}

export function* updateResourceHistoryRequest() {
  yield takeEvery(resourcesHistoryActions.UPDATE_RESOURCES_HISTORY_REQUEST, function*({ payload }) {
    if (payload.record === undefined) {
      return;
    }

    const startDate =
      payload.columnsKey === 'startDate' &&
      (yield apiRequest(
        superFetch.put,
        `${ENDPOINTS.RESOURCE_PROJECT_HISTORY}/${payload.object.id}`,
        {
          assignmentTypeId: payload.object.assignmentTypeId,
          projectId: payload.object.projectId,
          resourceId: payload.record.resourceId,
          startDate: payload.value,
          endDate: payload.object.endDate,
        },
      ));

    const endDate =
      payload.columnsKey === 'endDate' &&
      (yield apiRequest(
        superFetch.put,
        `${ENDPOINTS.RESOURCE_PROJECT_HISTORY}/${payload.object.id}`,
        {
          assignmentTypeId: payload.object.assignmentTypeId,
          projectId: payload.object.projectId,
          resourceId: payload.record.resourceId,
          startDate: payload.object.startDate,
          endDate: payload.value,
        },
      ));

    const assignmentType = payload.columnsKey === 'assignmentType' &&
      (
        yield apiRequest(superFetch.put, `${ENDPOINTS.RESOURCE_PROJECT_HISTORY}/${payload.object.id}`,{
          assignmentTypeId: payload.value[0],
          projectId: payload.object.projectId,
          resourceId: payload.record.resourceId,
          startDate: payload.object.startDate,
          endDate: payload.object.endDate,
        })
      );

    if (startDate.errors) {
      yield put(resourcesHistoryActions.updateResourcesHistoryError(startDate.errors));
      yield openNotification('error', 'Error', startDate.errors);
    } else {
      yield put(resourcesHistoryActions.getResourcesHistoryRequest());
    }

    if (endDate.errors) {
      yield put(resourcesHistoryActions.updateResourcesHistoryError(endDate.errors));
      yield openNotification('error', 'Error', endDate.errors);
    } else {
      yield put(resourcesHistoryActions.getResourcesHistoryRequest());
      yield put(resourceActions.getResourcesHistoryDetailPageRequest(payload.record.resourceId));
    }

    if (assignmentType.errors) {
      yield put(resourcesHistoryActions.updateResourcesHistoryError(assignmentType.errors));
      yield openNotification('error', 'Error', assignmentType.errors);
    } else {
      yield put(resourcesHistoryActions.getResourcesHistoryRequest());
      yield put(resourceActions.getResourcesHistoryDetailPageRequest(payload.record.resourceId));
    }
  });
}

export function* exportDataRequest() {
  yield takeEvery(resourcesHistoryActions.EXPORT_DATA, function*({ payload }) {
    try {
      const queryParams = {};
      const { selectedColumns, sort, fields } = payload;

      if (selectedColumns) {
        queryParams.columns = { columns: selectedColumns } || '';
      }

      notification.success({
        message: `Download file`,
        description:
          'Your file will be downloaded in a few moments. Please wait.',
        placement: 'top',
        duration: 5,
      });

      const blob = yield apiRequest(
        superFetch.post,
        `${ENDPOINTS.RESOURCE_PROJECT_HISTORY_EXPORT}?${createQueryString({
          fields: fields || null,
          multiSort: sort || null,
          pagination: { pageSize: 0 },
        })}`,
        queryParams.columns,
      );

      if (blob.errors) {
        yield openNotification('error', 'Error', blob.errors);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'report.xlsx';
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (e) {
      Logger().error(e);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getResourcesHistoryRequest),
    fork(deleteResourceHistoryRequest),
    fork(createResourceHistoryRequest),
    fork(updateResourceHistoryRequest),
    fork(exportDataRequest),
  ]);
}
