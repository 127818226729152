export const TIMELINE_TYPES = [
  {
    id: 'Empty',
    title: 'No project',
		color: "#eb6c65"
  },
  {
    id: 'Bench',
    title: 'Bench',
		color: "#ffa5a1"
  },
  {
    id: 'Active',
    title: 'Active',
		color: "#7db882"
  },
  {
    id: 'Upcoming',
    title: 'Upcoming',
		color: "#4e9f79"
  },
];