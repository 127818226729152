import React from 'react';
import { Link } from 'react-router-dom';
import { siteConfig } from 'library';

export default ({ collapsed, changeCurrent }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <h3>
          <span>
            <img className="siteLogoImg" alt="icon-logo" src="/images/swan-hero-logo.svg" />
          </span>
        </h3>
      ) : (
        <h3>
          <span>
            <img className="siteLogoImg" alt="icon-logo" src="/images/swan-hero-logo.svg" />
            <div className="siteName">{siteConfig.siteName}</div>
          </span>
        </h3>
      )}
    </div>
  );
};
