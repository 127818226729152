import recipientsActions from 'redux/marketing/recipients/actions';

const getInitState = () => ({
  isModalActive: false,
  isImportModalActive: false,
  isAddGroupModalActive: false,
  fileUrl: '',
  states: [],
  cities: [],
  groups: [],
  recipients: [],
  loading: false,
  selectedRecipient: {
    id: '',
    firstName: '',
    lastName: '',
    email: null,
    title: null,
    date: null,
    industry: null,
    type: null,
    companyName: null,
    url: null,
    resourcesCount: null,
    directPhone: null,
    managersPhone: null,
    phone: null,
    jobSite: null,
    jobTitle: null,
    postingLink: null,
    address: null,
    city: null,
    state: null,
    postalCode: null,
    firstEmail: null,
    firstResult: null,
    secondEmail: null,
    secondResult: null,
    thirdEmail: null,
    thirdResult: null,
    groups: [],
  },
  selectedRecipients: {}
});

export default function recipientsReducer(state = getInitState(), action) {
  const { type, payload } = action;

  switch (type) {
    case recipientsActions.GET_RECIPIENTS_INITIAL_STATE:
      return {
        ...state,
      };

    case recipientsActions.SET_RECIPIENTS_INITIAL_STATE:
      return {
        ...state,
        ...payload,
      };
    case recipientsActions.GET_RECIPIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case recipientsActions.GET_RECIPIENTS_SUCCESS:
      return {
        ...state,
        ...payload,
        isModalActive: false,
        loading: false,
      };

    case recipientsActions.GET_RECIPIENTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case recipientsActions.SELECTION_CHANGE:
      const selected = { ...state.selectedRecipients };
      if (payload.isChecked) {
        selected[payload.id] = true
      } else {
          delete selected[payload.id]
      }
      return {
        ...state,
        selectedRecipients: {
          ...selected
        }
      };

    case recipientsActions.MULTIPLE_SELECTION_CHANGE:
      let selectedAll = {};
      if (payload.isChecked === false) {
        selectedAll = {}
      } else {
        selectedAll = { ...payload.ids };
      }

      return {
        ...state,
        selectedRecipients: {
          ...selectedAll
        }
      };

    case recipientsActions.MULTIPLE_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case recipientsActions.MULTIPLE_DELETE_SUCCESS:
      return {
        ...state,
        selectedRecipients: {},
        loading: false
      };

    case recipientsActions.ADD_REQUEST:
    case recipientsActions.DELETE_FROM_GROUP:
    case recipientsActions.ADD_TO_GROUP:
      return {
        ...state,
        loading: true,
      };

    case recipientsActions.ADD_ERROR:
    case recipientsActions.DELETE_FROM_GROUP_SUCCESS:
    case recipientsActions.DELETE_FROM_GROUP_ERROR:
    case recipientsActions.ADD_TO_GROUP_SUCCESS:
    case recipientsActions.ADD_TO_GROUP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case recipientsActions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
        selectedRecipient: {
          ...(payload.recipient || getInitState().selectedRecipient),
        },
      };

      case recipientsActions.TOGGLE_ADD_GROUP_MODAL:
        return {
          ...state,
          isAddGroupModalActive: !state.isAddGroupModalActive,
        };

    case recipientsActions.UPDATE_RECIPIENT:
    case recipientsActions.ADD_SUCCESS:
      return {
        ...state,
        isModalActive: false,
      };

    case recipientsActions.UPDATE_RECIPIENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case recipientsActions.TOGGLE_IMPORT_MODAL:
    case recipientsActions.IMPORT_DATA_SUCCESS:
    return {
        ...state,
        isImportModalActive: !state.isImportModalActive,
      };

    case recipientsActions.IMPORT_DATA:
      return {
        ...state,
      };

    case recipientsActions.SET_RECIPIENTS_NEXT:
      const { recipients } = state;
      return {
        ...state,
        recipients: [...recipients, ...payload.recipients],
        loading: false
      };

    case recipientsActions.RESET_STATE:
      return {
        ...getInitState(),
      };

    default:
      return state;
  }
}

export { getInitState }
