const DOCUMENT = 'RESOURCES_BY_PROJECT_';
const resourcesByTypeActions = {

  SHOW_MODAL: `${DOCUMENT}SHOW_MODAL`,
  HIDE_MODAL: `${DOCUMENT}HIDE_MODAL`,

  SHOW_DRAWER: `${DOCUMENT}SHOW_DRAWER`,
  HIDE_DRAWER: `${DOCUMENT}HIDE_DRAWER`,

  showModal: (payload)=>({
    type: resourcesByTypeActions.SHOW_MODAL,
    payload
  }),

  hideModal: ()=>({
    type: resourcesByTypeActions.HIDE_MODAL,
  }),

  showDrawer: ()=>({
    type: resourcesByTypeActions.SHOW_DRAWER,
  }),

  hideDrawer: ()=>({
    type: resourcesByTypeActions.HIDE_DRAWER,
  })

};
export default resourcesByTypeActions;
