import { all, fork, put, takeEvery, select } from 'redux-saga/effects';
import openNotification from 'components/Notification';
import { CONTENT_TYPES, ENDPOINTS } from 'consts';
import superFetch from 'helpers/superFetch';
import tableActions from 'components/NewTable/redux/actions';
import { createQueryString } from 'helpers/utility';
import { setUrl } from 'helpers/urlSync';
import documentsActions from './actions';
import { apiRequest } from '../helpers';

export function* getDocumentsRequest(){
  yield takeEvery(documentsActions.GET_DOCUMENTS_REQUEST, function*({payload, ignoreSetUrl}) {
    const { pagination, sortValues, searchValue } = yield select(state => state.tableReducer);
    const searchValues = payload?.defaultFilters
      ? {...searchValue, ...payload.defaultFilters}
      : searchValue;
    const subParams = {};
    if(payload) {
      if(payload.forEdit)
        subParams.forEdit = payload.forEdit;
    }
    if(payload) {
      if(payload.fields)
        subParams.title = payload.fields.value;
    }
    const queryUrl = `${ENDPOINTS.DOCUMENT}?${createQueryString({
      pagination,
      multiSort: sortValues,
      fields: searchValues,
      ...subParams, }
    )}`;

    const data = yield apiRequest(superFetch.get, queryUrl);
    if (!ignoreSetUrl) {
      setUrl(queryUrl)
    }
    const rolesData = yield apiRequest(superFetch.get, ENDPOINTS.ROLES);
    const newData = {
      ...data,
      roles: rolesData,
    }

    if (data.errors) {
      yield put(documentsActions.getDocumentsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(documentsActions.getDocumentsSuccess(newData));
      yield put(tableActions.setPagination(newData.pagination));
    }
  });
}

export function* updateByTitleFilter() {
  yield takeEvery(documentsActions.UPDATE_BY_TITLE_FILTER, function* ({ payload }) {
    /*yield put(documentsActions.updateByTitleFilterSuccess(payload));*/
    yield put(documentsActions.getDocumentsRequest(payload));
  });
}

export function* deleteDocumentsRequest() {
  yield takeEvery(documentsActions.DELETE_DOCUMENTS_REQUEST, function*({payload}) {

    const queryUrl = `${ENDPOINTS.DOCUMENT}/${payload}`;
    const data = yield apiRequest(superFetch.delete, queryUrl);

    if (data.errors) {
      yield put(documentsActions.deleteDocumentsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(
        documentsActions.deleteDocumentsSuccess(data),
      );
    }
  });
}

export function* createDocumentsRequest() {
  yield takeEvery(documentsActions.CREATE_DOCUMENTS_REQUEST, function*({payload}) {
    const data = yield apiRequest(
      superFetch.post,
      ENDPOINTS.DOCUMENT,
      payload,
      CONTENT_TYPES.MULTIPART_DATA,
    );
    if (data.errors) {
      yield put(documentsActions.createDocumentsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(documentsActions.createDocumentsSuccess(data));
      yield put(tableActions.setPagination(data.pagination));
    }
  });
}

export function* updateDocumentsRequest() {
  yield takeEvery(documentsActions.UPDATE_DOCUMENTS_REQUEST, function*({payload}) {
    const queryUrl = `${ENDPOINTS.DOCUMENT}/${payload.id}`;
    const data = yield apiRequest(
      superFetch.put,
      queryUrl,
      payload.object,
      CONTENT_TYPES.MULTIPART_DATA,
      );
    if (data.errors) {
      yield put(documentsActions.updateDocumentsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(
        documentsActions.updateDocumentsSuccess(data),
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getDocumentsRequest),
    fork(deleteDocumentsRequest),
    fork(createDocumentsRequest),
    fork(updateDocumentsRequest),
    fork(updateByTitleFilter),
  ]);
}
