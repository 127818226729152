import actions from 'redux/projectStatuses/actions';

const initState = {
  isModalActive: false,
  loading: false,
  selectedStatus: {
    id: null,
    title: '',
    color: '',
    groupId: null,
  },
  statuses: [],
};

export default function ProjectStatusesReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_PROJECT_STATUSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PROJECT_STATUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        statuses: action.payload.statuses,
      };
    case actions.GET_PROJECT_STATUSES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_PROJECT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_PROJECT_STATUS_SUCCESS:
      return {
        ...state,
        isModalActive: false,
        statuses: [
          ...state.statuses,
          {
            ...action.payload.status,
          }
        ],
        loading: false,
      };
    case actions.ADD_PROJECT_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_PROJECT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_PROJECT_STATUS_SUCCESS:
      const { status } = action.payload;
      return {
        ...state,
        isModalActive: false,
        statuses: [
          ...state.statuses.map(item => item.id === status.id ? status : item),
        ],
        loading: false,
      };
    case actions.UPDATE_PROJECT_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_PROJECT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_PROJECT_STATUS_SUCCESS:
      return {
        ...state,
        statuses: state.statuses.filter(item => item.id !== action.payload.statusId),
        loading: false,
      };
    case actions.DELETE_PROJECT_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
        selectedStatus: action.payload.status || initState.selectedStatus,
      };
    default:
      return state;
  }
}
