import { all, takeEvery, put, fork } from 'redux-saga/effects';
import resourceEvaluationActions from 'redux/resources/evaluationDetails/actions';
import superFetch from 'helpers/superFetch';
import { apiRequest } from 'redux/helpers';
import { ENDPOINTS } from 'consts';
import { resolveImgUrl } from 'helpers/urlSync';
import openNotification from 'components/Notification';

function* getResourceEvaluationRequest() {
  yield takeEvery(resourceEvaluationActions.GET_RESOURCE_EVALUATION_REQUEST, function*({ payload }) {
    const data = yield apiRequest(superFetch.get, `${ENDPOINTS.RESOURCE_EVALUATION}/${payload.id}`);
    data.assessedResource.avatar = data.assessedResource.avatar ? resolveImgUrl(data.assessedResource.avatar) : '';
    if (data.errors) {
      yield put(resourceEvaluationActions.getResourceEvaluationError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceEvaluationActions.getResourceEvaluationSuccess(data));
    }
  });
}

export function* updateEvaluation() {
  yield takeEvery(resourceEvaluationActions.UPDATE_EVALUATION_REQUEST, function*({ payload }) {
    const { evaluation, evaluationId } = payload;

    const data = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.RESOURCE_EVALUATION}/${evaluationId}`,
      evaluation,
    );

    if (data.errors) {
      yield put(resourceEvaluationActions.updateEvaluationError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceEvaluationActions.updateEvaluationSuccess(data));
      yield put(resourceEvaluationActions.getResourceEvaluationRequest(evaluationId));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getResourceEvaluationRequest),
    fork(updateEvaluation),
  ]);
}
