const DOCUMENT = 'PROJECTS_ONGOING_';

const projectsOngoingActions = {
  SELECT_PROJECT: `${DOCUMENT}SELECT_PROJECT`,
  SHOW_MODAL: `${DOCUMENT}SHOW_MODAL`,
  RESET_STATE: `ONGOING_PROJECTS_RESET_STATE`,

  showModal: (isOngoingModalActive)=>({
    type: projectsOngoingActions.SHOW_MODAL,
    payload: { isOngoingModalActive }
  }),
  resetState: ()=>({
    type: projectsOngoingActions.RESET_STATE
  }),

};

export default projectsOngoingActions;
