import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import mailingRecipientsActions from 'redux/marketing/mailing/mailingRecipients/actions';
import tableActions from 'components/NewTable/redux/actions';
import { apiRequest } from 'redux/helpers';
import { ENDPOINTS } from 'consts';
import { isNumber } from 'lodash';
import { createQueryString, generateSelectedFromArray } from 'helpers/utility';
import openNotification from 'components/Notification';
import mailingActions from '../actions';

export function* getMailingRecipientsRequest() {
  yield takeEvery(mailingRecipientsActions.GET_MAILING_RECIPIENTS_REQUEST, function*({ payload }) {
    const { nextPage } = payload;
    const { currentMailing } = yield select(state => state.Mailing);
    const { pagination, sortValues, searchValue, pageSize } = yield select(
      state => state.tableReducer,
    );
    const recipients = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.RECIPIENT}?${createQueryString({
        pagination: {
          ...pagination,
          currentPage: (isNumber(nextPage) ? nextPage : 1),
        },
        multiSort: sortValues,
        fields: {...searchValue, group: currentMailing.groups.map(item => item.id)},
        limit: pageSize,
      })}`,
    );
    if (recipients.errors) {
      yield put(mailingRecipientsActions.getMailingRecipientsError(recipients.errors));
      yield openNotification('error', 'Error', recipients.errors);
    } else if (isNumber(nextPage)) {
      yield put(
        mailingRecipientsActions.setMailingRecipientsNext({
          recipients: recipients.recipients.map(e => ({ ...e, key: e.id })),
        }),
      );
      yield put(tableActions.setPagination(recipients.pagination))
    } else {
      yield put(
        mailingRecipientsActions.getMailingRecipientsSuccess({
          recipients: recipients.recipients.map(e => ({ ...e, key: e.id })),
        }),
      );
      yield put(tableActions.setPagination(recipients.pagination))
    }
  });
}

export function* getMailingRecipientsInitialState() {
  yield takeEvery(mailingRecipientsActions.GET_MAILING_RECIPIENTS_INITIAL_STATE, function*() {
    const states = yield apiRequest(superFetch.get, `${ENDPOINTS.RECIPIENT}/states`);
    const cities = yield apiRequest(superFetch.get, `${ENDPOINTS.RECIPIENT}/cities`);
    yield put(
      mailingRecipientsActions.setMailingRecipientsInitialState({
        states: states.states.map((e, index) => ({ ...e, id: e.name, key: index })),
        cities: cities.cities.map((e, index) => ({ ...e, id: e.name, key: index })),
      }),
    );
  });
}

export function* deselectAll() {
  yield takeEvery(mailingRecipientsActions.DESELECT_ALL, function*() {
    const { id } = yield select(state => state.Mailing.currentMailing);
    const mailingRecipients = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.MAILINGS_AND}${id}/deselectAll`,
    );
    yield put(mailingActions.setRecipients(generateSelectedFromArray(mailingRecipients)));
  });
}

export function* selectAllVisible() {
  yield takeEvery(mailingRecipientsActions.SELECT_ALL_VISIBLE, function*({payload}) {
    const { sortValues, searchValue } = yield select(state => state.tableReducer);
    const { id, groups } = yield select(state => state.Mailing.currentMailing);
    const mailingRecipients = payload.recipients.map(item => item.id)
    const data = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.MAILINGS_AND}${id}/selectAllVisible?${createQueryString({
        pagination: { pageSize: 0 },
        multiSort: sortValues,
        fields: {...searchValue, group: groups.map(item => item.id)},
      })}`,
    );
    yield put(mailingActions.setRecipients(generateSelectedFromArray(mailingRecipients)));
  });
}

export function* deselectAllVisible() {
  yield takeEvery(mailingRecipientsActions.DESELECT_ALL_VISIBLE, function*() {
    const { sortValues, searchValue } = yield select(state => state.tableReducer);
    const { id } = yield select(state => state.Mailing.currentMailing);
    const mailingRecipients = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.MAILINGS_AND}${id}/deselectAllVisible?${createQueryString({
        pagination: { pageSize: 0 },
        multiSort: sortValues,
        fields: searchValue,
      })}`,
    );
    yield put(mailingActions.setRecipients(generateSelectedFromArray(mailingRecipients)));
  });
}

export function* changeRecipient() {
  yield takeEvery(mailingRecipientsActions.CHANGE_RECIPIENT, function*({ payload }) {
    const { mailingRecipients, id } = yield select(state => state.Mailing.currentMailing);
    if (payload.value) {
      yield put(
        mailingActions.setRecipients({
          ...mailingRecipients,
          [payload.id]: payload.value,
        }),
      );
      yield apiRequest(superFetch.put, `${ENDPOINTS.MAILING_RECIPIENT}${payload.id}/${id}`);
    } else {
      const { [payload.id]: _, ...recipients } = { ...mailingRecipients };
      yield put(mailingActions.setRecipients(recipients));
      yield apiRequest(superFetch.delete, `${ENDPOINTS.MAILING_RECIPIENT}${payload.id}/${id}`);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getMailingRecipientsRequest),
    fork(getMailingRecipientsInitialState),
    fork(deselectAll),
    fork(selectAllVisible),
    fork(deselectAllVisible),
    fork(changeRecipient),
  ]);
}
