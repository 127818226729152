import actions from 'redux/projects/detailPage/actions';
import { handleAllSpinners } from 'redux/projects/helpers';
import projectManagerActions from 'redux/projectManagers/actions';
import appAactions from 'redux/app/actions';
import {images} from '../../../settings';
import resourcesHistoryActions from '../../resources/resourсeHistory/actions';

const initialProject = {
  actualEndDate: '',
  avatarUrl: '',
  client: '',
  clientId: null,
  contacts: null,
  deletedAt: '',
  resources: [],
  expectedEndDate: '',
  flagText: '',
  flagType: '',
  flagTypeId: null,
  id: null,
  links: null,
  pmWorkload: '',
  projectStatus: '',
  projectStatusId: null,
  startDate: '',
  statusText: '',
  technologies: [],
  technologiesList: [],
  amId: null,
  baId: null,
  pmId: null,
  title: '',
};

const initState = {
  loading: false,
  project: initialProject,
  isModalActive: false,
  editView: {
    info: {
      status: false,
      loading: false,
    },
    technologies: {
      status: false,
      loading: false,
    },
    resources: {
      status: false,
      loading: false,
    },
    flag: {
      status: false,
      loading: false,
    },
    projectStatus: {
      status: false,
      loading: false,
    },
    links: {
      status: false,
      loading: false,
    },
    contacts: {
      status: false,
      loading: false,
    },
    pmAmBa: {
      status: false,
      loading: false,
    },
  },
  fileUrl: '',
  file: '',
  projectHistory: {
    data: [],
    loading: false,
  },
};

export default function projectReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_PROJECT_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        projectHistory: {
          ...state.projectHistory,
          loading: true,
        },
      }
    case actions.GET_PROJECT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        projectHistory: {
          data: action.payload,
          loading: false,
        },
      }
    case actions.GET_PROJECT_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        projectHistory: {
          ...state.projectHistory,
          loading: false,
        },
      }

    case actions.CREATE_PROJECT_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        projectHistory: {
          ...state.projectHistory,
          loading: true,
        },
      }
    case actions.CREATE_PROJECT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        projectHistory: {
          data: [
            ...state.projectHistory.data,
            action.payload
          ],
          loading: false,
        },
      }
    case actions.CREATE_PROJECT_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        projectHistory: {
          ...state.projectHistory,
          loading: false,
        },
      }

    case actions.DELETE_PROJECT_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        projectHistory: {
          ...state.projectHistory,
          loading: true,
        },
      }
    case actions.DELETE_PROJECT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        projectHistory: {
          ...state.projectHistory,
          data: state.projectHistory.data.filter(elem =>
            elem.id !== action.payload.projectHistoryId),
          loading: false,
        },
      }
    case actions.DELETE_PROJECT_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        projectHistory: {
          ...state.projectHistory,
          loading: false,
        },
      }

    case actions.UPDATE_PROJECT_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        projectHistory: {
          ...state.projectHistory,
          loading: true,
        },
      }
    case actions.UPDATE_PROJECT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        projectHistory: {
          ...state.projectHistory,
          loading: false,
        },
      }
    case actions.UPDATE_PROJECT_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        projectHistory: {
          ...state.projectHistory,
          loading: false,
        },
      }

    case actions.GET_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        fileUrl: null,
        ...handleAllSpinners(state, true),
      };
    case actions.GET_PROJECT_SUCCESS:
      const avatar = action.payload.project.avatarUrl
        ? action.payload.project.avatarUrl
        : images.projectPlaceholder
      return {
        ...state,
        project: {
          ...action.payload.project,
          avatarUrl: avatar
        },
        loading: false,
        ...handleAllSpinners(state, false),
      };
    case actions.GET_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        ...handleAllSpinners(state, false),
      };

    case actions.UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        editView: {
          ...state.editView,
          [action.payload.formName]: {
            loading: true,
          },
        },
      };
    case actions.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          ...action.payload.project,
        },
        editView: {
          ...state.editView,
          [action.payload.formName]:{
            status: false,
            loading: false,
          },
        },
        fileUrl: null,
      };

    case actions.UPDATE_PROJECT_WITHOUT_FORM_SUCCESS:
      return {
        ...state,
        project: action.payload.project,
        fileUrl: null,
      };
    case actions.UPDATE_PROJECT_ERROR:
      return {
        ...state,
        ...handleAllSpinners(state, false),
      };

    case actions.UPLOAD_AVATAR:
      return {
        ...state,
        loading: true,
      };
    case actions.UPLOAD_AVATAR_SUCCESS:
      const { fileUrl, file } = action.payload;
      return {
        ...state,
        file,
        fileUrl,
        loading: false,
      };
    case actions.UPLOAD_AVATAR_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case actions.ADD_TECHNOLOGY_REQUEST:
      return {
        ...state,
        editView: {
          ...state.editView,
          technologies: {
            ...state.editView.technologies,
            loading: true,
          },
        }
      };

    case actions.ADD_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          technologies: action.payload.technologies,
        },
        editView: {
          ...state.editView,
          technologies: {
            ...state.editView.technologies,
            loading: false,
          },
        }
      };
    case actions.ADD_TECHNOLOGY_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.REMOVE_TECHNOLOGY_REQUEST:
      return {
        ...state,
        editView: {
          ...state.editView,
          technologies: {
            ...state.editView.technologies,
            loading: true,
          }
        },
      };
    case actions.REMOVE_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          technologies: action.payload.technologies,
        },
        editView: {
          ...state.editView,
          technologies: {
            ...state.editView.technologies,
            loading: false,
          }
        },
      };
    case actions.REMOVE_TECHNOLOGY_ERROR:
      return {
        ...state,
        editView: {
          ...state.editView,
          technologies: {
            ...state.editView.technologies,
            loading: false,
          },
        }
      };
    case actions.ADD_RESOURCE_REQUEST:
      return {
        ...state,
        editView: {
          ...state.editView,
          resources: {
            ...state.editView.resources,
            loading: true,
          },
        }
      };
    case actions.ADD_RESOURCE_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          resources: action.payload.resources,
        },
        editView: {
          ...state.editView,
          resources: {
            ...state.editView.resources,
            loading: false,
          },
        }
      };
    case actions.ADD_RESOURCE_ERROR:
      return {
        ...state,
        editView: {
          ...state.editView,
          resources: {
            ...state.editView.resources,
            loading: false,
          },
        }
      };
    case actions.REMOVE_RESOURCE_REQUEST:
      return {
        ...state,
        editView: {
          ...state.editView,
          resources: {
            ...state.editView.resources,
            loading: true,
          },
        },
      };
    case actions.UPDATE_RESOURCE_REQUEST:
      return {
        ...state,
        editView: {
          ...state.editView,
          resources: {
            ...state.editView.resources,
            loading: true,
          },
        },
      };
    case actions.UPDATE_RESOURCE_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          resources: action.payload.resources,
        },
        editView: {
          ...state.editView,
          resources: {
            ...state.editView.projects,
            loading: false,
          },
        },
        loading: false,
      };
    case actions.UPDATE_RESOURCE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case projectManagerActions.UPDATE_MANAGER_REQUEST:
      return {
        ...state,
        loading: true,
        editView: {
          ...state.editView,
          pmAmBa: {
            ...state.editView.pmAmBa,
            loading: true,
          },
        },
      };
    case projectManagerActions.UPDATE_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        project: {
          ...state.project,
          ...action.payload,
        },
        editView: {
          ...state.editView,
          pmAmBa: {
            ...state.editView.pmAmBa,
            status: false,
            loading: false,
          },
        },
      };
    case projectManagerActions.UPDATE_MANAGER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.REMOVE_RESOURCE_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          resources: action.payload.resources,
        },
        editView: {
          ...state.editView,
          resources: {
            ...state.editView.resources,
            loading: false,
          },
        },
      };
    case actions.REMOVE_RESOURCE_ERROR:
      return {
        ...state,
        editView: {
          ...state.editView,
          resources: {
            ...state.editView.resources,
            loading: false,
          },
        },
      };

    case actions.CHANGE_VIEW:
      const { status } = action.payload;
      return {
        ...state,
        editView: {
          ...state.editView,
          [action.payload.formName]: {
            ...state.editView[action.payload.formName],
            status,
          },
        },
      };

    case appAactions.CHANGE_CURRENT:
      return {
        ...state,
        editView: {
          ...state.editView,
          info: {
            ...state.editView.info,
            status: false,
          },
          technologies: {
            ...state.editView.technologies,
            status: false,
          },
          resources: {
            ...state.editView.resources,
            status: false,
          },
          flag: {
            ...state.editView.flag,
            status: false,
          },
          projectStatus: {
            ...state.editView.projectStatus,
            status: false,
          },
          links: {
            ...state.editView.links,
            status: false,
          },
          contacts: {
            ...state.editView.contacts,
            status: false,
          },
          projectManager: {
            ...state.editView.projectManager,
            status: false,
          },
        },
      };

    // RESET SELECTED FILE
    case actions.RESET_FILE_STATE:
      return {
        ...state,
        fileUrl: '',
        file: '',
      };

    case actions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive
      };

    default:
      return state;
  }
};
