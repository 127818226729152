import { all, takeEvery, put, fork, call, select, debounce } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import { CONTENT_TYPES, ENDPOINTS } from 'consts';
import {createQueryString, onSuccessUpload} from 'helpers/utility';
import { apiRequest } from 'redux/helpers';
import actions from 'redux/projects/actions';
import projectDetailActions from 'redux/projects/detailPage/actions';
import projectManagerActions from 'redux/projectManagers/actions';
import clientsActions from 'redux/clients/actions';
import typesActions from 'redux/types/actions';
import tableActions from 'components/NewTable/redux/actions';
import openNotification from 'components/Notification';
import getDataAsUrl from 'helpers/fileReader';
import { resolveImgUrl, setUrl } from 'helpers/urlSync';
import resourceActions from '../resources/resource/actions';
import {images} from "../../settings";
import { notification } from 'antd';

function* getProjectHistoryRequest() {
  yield takeEvery(projectDetailActions.GET_PROJECT_HISTORY_REQUEST, function*({ payload }) {

    const queryUrl = `${ENDPOINTS.PROJECT_HISTORY}?${createQueryString({
      project: payload,
      limit: 'all',
      page: 1,
    })}`;
    const data = yield apiRequest(superFetch.get, queryUrl);

    if (data.errors) {
      yield put(projectDetailActions.getProjectHistoryError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectDetailActions.getProjectHistorySuccess(data.resourceProjectHistories));
    }
  });
}

export function* createProjectHistoryRequest() {
  yield takeEvery(projectDetailActions.CREATE_PROJECT_HISTORY_REQUEST, function*({ payload }) {
    const body = {
      assignmentTypeId: payload.assignmentTypeId,
      projectId: Number.isNaN(payload.projectId) ? null : payload.projectId,
      resourceId: payload.resourceId,
      startDate: payload.startDate,
      endDate: payload.expectedEndDate,
    };
    const data = yield apiRequest(superFetch.post, `${ENDPOINTS.PROJECT_HISTORY}`, body);

    if (data.errors) {
      yield put(projectDetailActions.createProjectHistoryError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectDetailActions.getProjectHistoryRequest(payload.projectId));
    }
  });
}

export function* deleteProjectHistoryRequest() {
  yield takeEvery(projectDetailActions.DELETE_PROJECT_HISTORY_REQUEST, function*({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.PROJECT_HISTORY}/${payload.projectHistoryId}`,
    );

    if (data.errors) {
      yield put(projectDetailActions.deleteProjectHistoryError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectDetailActions.deleteProjectHistorySuccess(payload.projectHistoryId));
    }
  });
}

export function* updateProjectHistoryRequest() {
  yield takeEvery(projectDetailActions.UPDATE_PROJECT_HISTORY_REQUEST, function*({ payload }) {
    if (payload.record === undefined) {
      return;
    }
    const startDate =
      payload.columnsKey === 'startDate' &&
      (yield apiRequest(
        superFetch.put,
        `${ENDPOINTS.PROJECT_HISTORY}/${payload.record.id}`,
        {
          assignmentTypeId: payload.record.assignmentTypeId,
          projectId: payload.record.projectId,
          resourceId: payload.record.resourceId,
          startDate: payload.value,
          endDate: payload.record.endDate,
        },
      ));

    const endDate =
      payload.columnsKey === 'endDate' &&
      (yield apiRequest(
        superFetch.put,
        `${ENDPOINTS.PROJECT_HISTORY}/${payload.record.id}`,
        {
          assignmentTypeId: payload.record.assignmentTypeId,
          projectId: payload.record.projectId,
          resourceId: payload.record.resourceId,
          startDate: payload.record.startDate,
          endDate: payload.value,
        },
      ));

    const assignmentType = payload.columnsKey === 'assignmentType' &&
      (
        yield apiRequest(superFetch.put, `${ENDPOINTS.PROJECT_HISTORY}/${payload.record.id}`,{
          assignmentTypeId: payload.value[0],
          projectId: payload.record.projectId,
          resourceId: payload.record.resourceId,
          startDate: payload.record.startDate,
          endDate: payload.record.endDate,
        })
      );

    if (startDate.errors) {
      yield put(projectDetailActions.updateProjectHistoryError(startDate.errors));
      yield openNotification('error', 'Error', startDate.errors);
    } else {
      yield put(projectDetailActions.getProjectHistoryRequest(payload.record.projectId));
    }

    if (endDate.errors) {
      yield put(projectDetailActions.updateProjectHistoryError(endDate.errors));
      yield openNotification('error', 'Error', endDate.errors);
    } else {
      yield put(projectDetailActions.getProjectHistoryRequest(payload.record.projectId));
    }

    if (assignmentType.errors) {
      yield put(projectDetailActions.updateProjectHistoryError(assignmentType.errors));
      yield openNotification('error', 'Error', assignmentType.errors);
    } else {
      yield put(projectDetailActions.getProjectHistoryRequest(payload.record.projectId));
    }
  });
}

export function* getProjectsListRequest() {
  yield takeEvery(actions.GET_PROJECTS_LIST_REQUEST, function* ({payload: {ignorSetUrl = false}}) {
    const {pagination, sortValues, searchValue} = yield select(state => state.tableReducer);
    const queryUrl = `${ENDPOINTS.PROJECTS}?${createQueryString(
      {pagination, multiSort: sortValues, fields: searchValue})}`;
    const data = yield apiRequest(superFetch.get, queryUrl);

    if (!ignorSetUrl)
      setUrl(queryUrl);

    if (data.errors) {
      yield put(actions.getProjectsListError(data.errors));
      yield openNotification(
        'error',
        'Error',
        data.errors,
      );
    } else {
      yield put(actions.getProjectsListSuccess({projectsList: data.projects}));
      yield put(tableActions.setPagination(data.pagination));
    }
  });
}

export function* getProjectsSimpleListRequest() {
  yield takeEvery(actions.GET_PROJECTS_SIMPLE_LIST_REQUEST, function* ({payload: {ignorSetUrl = false, withoutPagination = false}}) {
    const {pagination, sortValues, searchValue} = yield select(state => state.tableReducer);
    const queryUrl = `${ENDPOINTS.PROJECTS_SIMPLE_LIST}?${createQueryString(
      {pagination: withoutPagination ? {
          pageSize: 0,
          totalItems: 0
        } : pagination, multiSort: sortValues, fields: searchValue})}`;
    const data = yield apiRequest(superFetch.get, queryUrl);

    if (!ignorSetUrl)
      setUrl(queryUrl);

    if (data.errors) {
      yield put(actions.getProjectsSimpleListError(data.errors));
      yield openNotification(
        'error',
        'Error',
        data.errors,
      );
    } else {
      yield put(actions.getProjectsSimpleListSuccess({projectsList: data.projects}));
      yield put(tableActions.setPagination(data.pagination));
    }
  });
}

export function* getOngoingProjectsRequest() {
  yield takeEvery(actions.GET_ONGOING_PROJECTS_REQUEST, function* ({payload: {ignorSetUrl = false}}) {
    const {pagination, sortValues, searchValue} = yield select(state => state.tableReducer);
    const queryUrl = `${ENDPOINTS.ONGOING_PROJECTS}?${createQueryString(
      {pagination, multiSort: sortValues, fields: searchValue})}`;
    const data = yield apiRequest(superFetch.get, queryUrl);

    if (!ignorSetUrl)
    setUrl(queryUrl);

    if (data.errors) {
      yield put(actions.getOngoingProjectsError(data.errors));
      yield openNotification(
        'error',
        'Error',
        data.errors,
      );
    } else {
      yield put(actions.getOngoingProjectsSuccess({projectsList: data.projects}));
      yield put(tableActions.setPagination(data.pagination));
    }
  });
}

export function* getTimelineProjectsRequest() {
  yield takeEvery(actions.GET_TIMELINE_PROJECTS_REQUEST, function* ({payload: {ignorSetUrl = false}}) {
    const {pagination, sortValues, searchValue} = yield select(state => state.tableReducer);
    const queryUrl = `${ENDPOINTS.TIMELINE_PROJECTS}?${createQueryString(
      {pagination, multiSort: sortValues, fields: searchValue})}`;
    const data = yield apiRequest(superFetch.get, queryUrl);

    if (!ignorSetUrl)
    setUrl(queryUrl);

    if (data.errors) {
      yield put(actions.getTimelineProjectsError(data.errors));
      yield openNotification(
        'error',
        'Error',
        data.errors,
      );
    } else {
      yield put(actions.getTimelineProjectsSuccess({projectsList: data.projects}));
      yield put(tableActions.setPagination(data.pagination));
    }
  });
}

export function* getProjectsByResourcesHistoryRequest() {
  yield takeEvery(actions.GET_PROJECTS_BY_RESOURCES_HISTORY_REQUEST,
    function* () {
      const {pagination, sortValues, searchValue} = yield select(state => state.tableReducer);
      const queryUrl = `${ENDPOINTS.PROJECTS}?${createQueryString(
        {pagination, multiSort: sortValues, fields: searchValue})}`;
      const data = yield apiRequest(superFetch.get, queryUrl);
      const object = {id:'null', title:'Without a project'};
      const dataAndWithoutProjects = [object, ...data.projects];

      if (data.errors) {
        yield put(actions.getProjectsByResourcesHistoryError(data.errors));
        yield openNotification(
          'error',
          'Error',
          data.errors,
        );
      } else {
        yield put(actions.getProjectsByResourcesHistorySuccess(
          {projectsList: dataAndWithoutProjects}));
        yield put(tableActions.setPagination(data.pagination));
      }
    });
}

export function* getProjectsByTypeRequest() {
  yield debounce(800, actions.GET_PROJECTS_BY_TYPE_REQUEST, function* ({payload}) {
    // let filters, subParams = {};
    let filters = {};
    const subParams = {};
    if(payload) {
      filters = payload.filters;
      if(payload.id)
        subParams.id = payload.id;
    }

    const resources = yield apiRequest(
      superFetch.get, `${ENDPOINTS.RESOURCE_WITH_PROJECTS}?limit=all&page=1`);

     if (payload === undefined) {
      let data = yield apiRequest(superFetch.get,
        `${ENDPOINTS.RESOURCES_BY_PROJECT}?limit=all&statusFilter=hired`);
       data = {
         ...data,
         resources: resources.resources,
       }
       if (data.errors) {
         yield put(actions.getProjectsListError(data.errors));
         yield openNotification(
           'error',
           'Error',
           data.errors,
         );
       } else {
         yield put(actions.getProjectsByTypeSuccess(
           {
             projectsList: data.projects,
             resources: data.resources,
           },
         ));
       }
     }else {
       // let filters, subParams = {};
       let filters = {};
       const subParams = {};
       if(payload) {
         filters = payload.filters;
         if(payload.id)
           subParams.id = payload.id;
       }

       const queryUrl = `${ENDPOINTS.RESOURCES_BY_PROJECT}?${createQueryString(
         {
           pagination: { pageSize: 0 },
           fields: filters || null,
           ...subParams,
         })}`;
       let data = yield apiRequest(superFetch.get, queryUrl);

       data = {
         ...data,
         resources: resources.resources,
       }

       if(subParams.id){
         const changedQueryUrl = queryUrl.replace(`&id=${subParams.id.replace(/,/g, "%2C")}`, "");
         setUrl(changedQueryUrl);
       }else{
         setUrl(queryUrl);
       }

       if (data.errors) {
         yield put(actions.getProjectsListError(data.errors));
         yield openNotification(
           'error',
           'Error',
           data.errors,
         );
       } else if (subParams.id) {
         yield put(actions.getProjectsByTypeChangedSuccess(
           {
             projectsList: data.projects,
             resources: data.resources,
           },
         ));
       } else {
         yield put(actions.getProjectsByTypeSuccess(
           {
             projectsList: data.projects,
             resources: data.resources,
           },
         ));
       }
     }
  });
}

export function* deleteProjectRequest() {
  yield takeEvery(actions.DELETE_PROJECT_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.delete, `${ENDPOINTS.PROJECTS}/${payload.id}`);
    const projectsState = yield select((state) => state);
    if (data.errors) {
      yield put({
        type: actions.DELETE_PROJECT_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: actions.GET_PROJECTS_LIST_REQUEST,
        payload: projectsState.ProjectsList,
      });
    }
  });
}

export function* addProjectRequest() {
  yield takeEvery(actions.ADD_PROJECT_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.post, `${ENDPOINTS.PROJECTS}`, payload);
    if (data.errors) {
      yield put({
        type: actions.ADD_PROJECT_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.addProjectSuccess(data));
      yield put(actions.getProjectsListRequest());
    }
  });
}

export function* projectsGetInitialState() {
  yield takeEvery(actions.GET_INITIAL_PROJECTS_LIST_STATE, function* ({ payload }) {
    const clients = yield apiRequest(superFetch.get, `${ENDPOINTS.CLIENT}`, payload);

    if (clients.errors) {
      yield put(clientsActions.getClientsError(clients.errors));
      yield openNotification('error', 'Error', clients.errors);
    } else {
      yield put(clientsActions.getClientsSuccess(clients));
    }

    const projectStatuses = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.TYPE_PROJECT_STATUS}`,
      payload
    );
    if (projectStatuses.errors) {
      yield put(typesActions.getProjectStatusError(projectStatuses.errors));
      yield openNotification('error', 'Error', projectStatuses.errors);
    } else {
      yield put(typesActions.getProjectStatusSuccess(projectStatuses));
    }

    const flagTypes = yield apiRequest(superFetch.get, `${ENDPOINTS.TYPE_FLAG}`, payload);

    if (flagTypes.errors) {
      yield put(typesActions.getFlagsError(flagTypes.errors));
      yield openNotification('error', 'Error', flagTypes.errors);
    } else {
      yield put(typesActions.getFlagsSuccess({flags: flagTypes}));
    }
    yield put(actions.getInitialProjectsListStateSuccess());
  });
}

export function* projectRequest() {
  yield takeEvery(projectDetailActions.GET_PROJECT_REQUEST, function*({payload}) {
    const technologies = yield apiRequest(superFetch.get, `${ENDPOINTS.TECHNOLOGY}?limit=1000`);

    let data = yield apiRequest(superFetch.get, `${ENDPOINTS.PROJECT}/${payload.id}`);
    data = {
      ...data,
      technologiesList:technologies.technologies,
    }
    data.avatarUrl = data.avatarUrl ? resolveImgUrl(data.avatarUrl) : '';

    if (data.errors) {
      yield put(projectDetailActions.getProjectError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectDetailActions.getProjectSuccess(data));
    }
  });
}

function* editProjectRequest(profileData, id, modalName, fileObj, realodProjectList, pageName) {
  let url = '';
  let method = 'patch'
  switch (modalName) {
    case 'info':
      url = `${ENDPOINTS.PROJECT}/${id}/mainInfo`;
      break;
    case 'flag':
      url = `${ENDPOINTS.PROJECT}/${id}/flagInfo`;
      break;
    case 'projectStatus':
      url = `${ENDPOINTS.PROJECT}/${id}/projectStatusInfo`;
      break;
    case 'links':
      url = `${ENDPOINTS.PROJECT}/${id}/linksInfo`;
      break;
    case 'ongoingModalChange':
      url = `${ENDPOINTS.PROJECT}/ongoing/${id}`;
      break;
    default:
      url = `${ENDPOINTS.PROJECT}/${id}`;
      method = 'put';
  }

  if (modalName === 'info' && fileObj.file) {
    yield apiRequest(
      superFetch.patch,
      url,
      { avatar: fileObj.file },
      CONTENT_TYPES.MULTIPART_DATA
    );
    onSuccessUpload();
  }

  const project = yield apiRequest(
    superFetch[method],
    url,
    profileData
  );

  if (project.errors) {
    yield put(resourceActions.editError({ project, modalName }));
    yield openNotification('error', 'Error', project.errors);
  } else {
    project.avatarUrl = project.avatarUrl ? resolveImgUrl(project.avatarUrl) : '';
    if (modalName) {
      if (fileObj.file) {
        project.avatarUrl = fileObj.fileUrl;
        yield put(projectDetailActions.resetFile());
      }
      yield put(projectDetailActions.updateProjectSuccess(project, modalName));
    } else {
      yield put(projectDetailActions.updateProjectSuccessWithoutForm(project));
    }
    yield realodProjectList &&
    (pageName === 'ongoingPage'
      ? put(actions.getOngoingProjectsRequest())
      : put(actions.getProjectsListRequest()));
  }
}

export function* updateProjectRequest() {
  yield takeEvery(projectDetailActions.UPDATE_PROJECT_REQUEST, function*({payload}) {
    const { file, project, fileUrl } = yield select(state => state.Project);
    const { formData, formName, realodProjectList, pageName } = payload;
    const fileObj = {
      file,
      fileUrl,
    };

    delete formData.avatarLastModified;
    if (formData.avatarUrl) {
      formData.avatarUrl = resolveImgUrl(formData.avatarUrl)
    }

    yield call(editProjectRequest, formData, project.id, formName, fileObj, realodProjectList, pageName);
  });
}

function* uploadAvatar() {
  yield takeEvery(projectDetailActions.UPLOAD_AVATAR, function* ({payload}) {
    const result = yield call(getDataAsUrl, payload.file);
    if (!result) {
      yield put(projectDetailActions.uploadAvatarError());
    } else {
      yield put(
        projectDetailActions.uploadAvatarSuccess({
          fileUrl: result,
          file: payload.file,
        }),
      );
    }
  });
}

export function* addTechnologyRequest() {
  yield takeEvery(projectDetailActions.ADD_TECHNOLOGY_REQUEST, function*({payload}) {
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.PROJECT}/${payload.projectId}${ENDPOINTS.UPDATE_TECHNOLOGIES_BY_PROJECT}`,
      payload.data
    );

    if (data.errors) {
      yield put(projectDetailActions.addTechnologyError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectDetailActions.addTechnologySuccess(data.technologies));
    }
  });
}

export function* removeTechnologyRequest() {
  yield takeEvery(projectDetailActions.REMOVE_TECHNOLOGY_REQUEST, function*({payload}) {
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.PROJECT}/${payload.projectId}${ENDPOINTS.REMOVE_TECHNOLOGY}`,
      payload.data,
    );
    if (data.errors) {
      yield put(projectDetailActions.removeTechnologyError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectDetailActions.removeTechnologySuccess(data.technologies));
    }
  });
}

export function* addResourceRequest() {
  yield takeEvery(projectDetailActions.ADD_RESOURCE_REQUEST, function*({payload}) {
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.PROJECT}/${payload.projectId}${ENDPOINTS.ADD_RESOURCE}`,
      payload.data,
    );
    if (data.errors) {
      yield put(projectDetailActions.addResourceError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectDetailActions.addResourceSuccess(data.resources));
    }
  });
}

export function* updateResourceRequest() {
  yield takeEvery(projectDetailActions.UPDATE_RESOURCE_REQUEST, function*({payload}) {

    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.PROJECT}/${payload.projectId}${ENDPOINTS.UPDATE_RESOURCES}`,
      payload.data,
    );

    if (data.errors) {
      yield put(projectDetailActions.updateResourceError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectDetailActions.updateResourceSuccess(data.resources));
    }
  });
}

export function* removeResourceRequest() {
  yield takeEvery(projectDetailActions.REMOVE_RESOURCE_REQUEST, function*({payload}) {
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.PROJECT}/${payload.projectId}${ENDPOINTS.REMOVE_RESOURCE}`,
      payload.data,
    );
    if (data.errors) {
      yield put(projectDetailActions.removeResourceError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectDetailActions.removeResourceSuccess(data.resources));
    }
  });
}

export function* moveProjectRequest() {
  yield takeEvery(actions.MOVE_PROJECT_REQUEST, function*({payload}) {
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.PROJECT}${ENDPOINTS.MOVE_RESOURCE}?isCopy=${payload.isCopy}`,
      payload.project,
    );

    if (data.errors) {
      yield put(actions.moveProjectError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.moveProjectSuccess(data));
    }
  });
}
export function* updateFilters() {
  yield takeEvery(actions.UPDATE_FILTERS, function* () {
    yield put(actions.getProjectsListRequest());
  })
}

export function* updateSorting() {
  yield takeEvery(actions.UPDATE_SORTING, function* () {
    yield put(actions.getProjectsListRequest());
  })
}

export function* changePage() {
  yield takeEvery(actions.CHANGE_PAGE, function* () {
    yield put(actions.getProjectsListRequest());
  })
}

export function* changePageSize() {
  yield takeEvery(actions.CHANGE_PAGE_SIZE, function* () {
    yield put(actions.getProjectsListRequest());
  })
}

export function* exportDataRequest() {
  yield takeEvery(actions.EXPORT_DATA, function* ({ payload }) {
    const { selectedColumns, sort, fields } = payload;
    const queryParams = {};

    if (selectedColumns) {
      queryParams.columns = { columns: selectedColumns } || '';
    }

    notification.success({
      message: `Download file`,
      description:
        'Your file will be downloaded in a few moments. Please wait.',
      placement: 'top',
      duration: 5,
    });

    const blob = yield apiRequest(
      superFetch.post,
      `${ENDPOINTS.PROJECTS_EXPORT}?${createQueryString({
        fields: fields || null,
        multiSort: sort || null,
        pagination: {
          pageSize: 0,
        }
      })}`,
      queryParams.columns,
    );

    if (blob.errors) {
      yield openNotification('error', 'Error', blob.errors);

    } else {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'report.xlsx';
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  });
}

export function* updateProjectManagersRequest() {
  yield takeEvery(projectManagerActions.UPDATE_MANAGER_REQUEST, function*({payload}) {
    const { formData } = payload;
    const { project } = yield select(state => state.Project);

    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.PROJECT}/${project.id}/managersInfo`,
      { ...formData },
    );

    data.avatarUrl = data.avatarUrl ? resolveImgUrl(data.avatarUrl) : images.projectPlaceholder;

    if (data.errors) {
      yield put(projectManagerActions.updateProjectManagerError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(projectManagerActions.updateProjectManagerSuccess(data));
    }
  });
}

export function* closeProjectRequest() {
  yield takeEvery(projectDetailActions.CLOSE_PROJECT_REQUEST, function* ({ payload }) {
    yield put(projectDetailActions.updateProjectRequest(payload, 'projectStatus'));
  });
}

export default function* rootSaga() {
  yield all([
    fork(getProjectsListRequest),
    fork(getProjectsByResourcesHistoryRequest),
    fork(getProjectsByTypeRequest),
    fork(deleteProjectRequest),
    fork(addProjectRequest),
    fork(projectsGetInitialState),
    fork(projectRequest),
    fork(updateProjectRequest),
    fork(uploadAvatar),
    fork(addTechnologyRequest),
    fork(removeTechnologyRequest),
    fork(addResourceRequest),
    fork(updateResourceRequest),
    fork(removeResourceRequest),
    fork(moveProjectRequest),
    fork(exportDataRequest),
    fork(updateProjectManagersRequest),
    fork(getOngoingProjectsRequest),
    fork(getTimelineProjectsRequest),
    fork(getProjectsSimpleListRequest),
    fork(closeProjectRequest),
    fork(getProjectHistoryRequest),
    fork(createProjectHistoryRequest),
    fork(deleteProjectHistoryRequest),
    fork(updateProjectHistoryRequest),
  ]);
};
