export const INTERVALS = [
  {
    id: 30,
    name: '30 second',
  },
  {
    id: 60,
    name: '1 minute',
  },
  {
    id: 90,
    name: '1,5 minute',
  },
];
