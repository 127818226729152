import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import { DEVICE_TYPES } from 'consts/index';
import Menu from 'components/theme/uielements/menu';
import SidebarLabel from 'components/Sidebar/SidebarLabel';
import themes from 'settings/themes';
import options from 'containers/Sidebar/options';
import Topbar from 'containers/Topbar/Topbar';
import authAction from 'redux/auth/actions';
import appActions from 'redux/app/actions';
import { themeConfig } from 'settings';
import { checkPermission } from 'helpers/utility';
import { stripTrailingSlash } from 'helpers/urlSync';
import createModal from 'containers/Sidebar/modalLogoutConfirm';
import SidebarWrapper from 'containers/Sidebar/sidebar.style';
import LastModifiedDate from '../../components/LastModifiedDate/LastModifiedDate';

const { SubMenu } = Menu;
const { Sider } = Layout;
const ConfirmLogout = createModal();

const Sidebar = props => {
  const {
    appActions: { toggleOpenDrawer, changeCurrent },
    profile: { avatarUrl, firstName, lastName, role },
    authAction: { logoutRequest },
    openDrawer,
    collapsed,
    view,
    openKeys,
    current,
    claims,
    appHeight,
    fileUrl,
  } = props;

  const [visible, setVisible] = useState(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);

  useEffect(() => {
    const { appActions: { changeCurrent }, current } = props;
    const currentPage = window.location.pathname.substr(1);
    if (currentPage !== current[0]) {
      changeCurrent([currentPage]);
    }
  }, []);

  const getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  const onOpenChange = newOpenKeys => {
    const { appActions: { changeOpenKeys }, openKeys } = props;
    const latestOpenKey = newOpenKeys.find(key => !(openKeys.indexOf(key) > -1));
    const latestCloseKey = openKeys.find(key => !(newOpenKeys.indexOf(key) > -1));
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  };

  const getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, children, claim, svg } = singleOption;
    const { url: urlProp } = props;
    const url = stripTrailingSlash(urlProp);
    const newSvg = svg ? { svg } : {};
    const propsToSidebarLabel = leftIcon ? { leftIcon } : newSvg;
    if (children) {
      return (
        children.some(item => checkPermission(item.claim, claims)) && (
          <SubMenu
            key={key}
            title={(
              <SidebarLabel
                label={label}
                submenuColor={submenuColor}
                {...propsToSidebarLabel}
              />
            )}
          >
            {children.map(child => {
              const linkTo = child.withoutDashboard ? `/${child.key}` : `${url}/${child.key}`;
              return (
                checkPermission(child.claim, claims) && (
                  <Menu.Item
                    style={submenuStyle}
                    key={child.key}
                  >
                    <Link
                      style={submenuColor}
                      to={linkTo}
                    >
                      {child.label}
                    </Link>
                  </Menu.Item>
                )
              );
            })}
          </SubMenu>
        ));
    }
    return (
      checkPermission(claim, claims) && (
        <Menu.Item key={key}>
          <Link to={`${url}/${key}`}>
            <SidebarLabel
              label={label}
              submenuColor={submenuColor}
              {...propsToSidebarLabel}
            />
          </Link>
        </Menu.Item>
      )
    );
  };

  const handleClick = e => {
    const {
      appActions: { changeCurrent, toggleCollapsed, toggleOpenDrawer },
      view,
    } = props;

    changeCurrent([e.key]);
    if (view === DEVICE_TYPES.MOBILE_VIEW) {
      setTimeout(() => {
        toggleCollapsed();
        toggleOpenDrawer();
      }, 100);
    }
  };

  const handleVisibleChange = () => {
    setVisible(visible => !visible);
  };

  const logoutConfirmShow = e => {
    e.preventDefault();
    setIsVisibleConfirm(true);
  };

  const logoutConfirmClose = () => {
    setIsVisibleConfirm(false);
  };

  const logout = () => {
    logoutRequest();
  };

  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';
  const isMobileView = view === DEVICE_TYPES.MOBILE_VIEW;

  const onMouseEnter = () => {
    if (openDrawer === false) {
      toggleOpenDrawer();
    }
  };
  const onMouseLeave = () => {
    if (openDrawer === true) {
      toggleOpenDrawer();
    }
  };
  const customizedTheme = themes[themeConfig.theme];
  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
  };
  const submenuStyle = {
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: customizedTheme.textColor,
  };
  const submenuColor = {
    color: customizedTheme.textColor,
  };

  const [isShowSideBar, setIsShowSideBar] = useState(true);

  return (
    <SidebarWrapper height={appHeight} isMobileView={isMobileView}>
      <Topbar
        collapsed={collapsed}
        changeCurrent={changeCurrent}
        isMobileView={isMobileView}
        isCollapsed={isCollapsed}
      />
      <Sider
        trigger={null}
        collapsible
        collapsed={isMobileView ? collapsed : isCollapsed}
        width={272}
        collapsedWidth={isMobileView ? 0 : 71}
        className='isomorphicSidebar'
        onClick={isMobileView ? toggleOpenDrawer : undefined}
        onMouseEnter={!isMobileView ? onMouseEnter : undefined}
        onMouseLeave={!isMobileView ? onMouseLeave : undefined}
        style={styling}
      >
        <div className="menu-wrapper">
          <Menu
            onClick={handleClick}
            theme='dark'
            className='isoDashboardMenu sidebar-menu'
            mode={mode}
            openKeys={isCollapsed ? [] : openKeys}
            selectedKeys={current}
            onOpenChange={onOpenChange}
          >
            <Menu.Item className='isoMenuWrapper'>
              <div className='sidebarProfileInfo'>
                <Link to='/profile'>
                  <span className='isoMenuHolder' style={submenuColor}>
                    <img
                      src={fileUrl || avatarUrl}
                      alt=''
                    />
                    <div className="userMenuItem">
                      <div className="nav-text userName">
                        {`${firstName || ''} ${lastName || ''}`}
                      </div>
                      <div className="nav-text userRole">
                        {role.name || ''}
                      </div>
                      </div>
                  </span>
                </Link>
              </div>
            </Menu.Item>
            {isShowSideBar && options.map(singleOption =>
              getMenuItem({ submenuStyle, submenuColor, singleOption }),
            )}
            {isShowSideBar && (
              <Menu.Item>
                <a href='#' onClick={logoutConfirmShow}>
                  <SidebarLabel
                    label='Logout'
                    submenuColor={submenuColor}
                    svg='logout'
                  />
                </a>
                <ConfirmLogout
                  isVisible={isVisibleConfirm}
                  onCancel={logoutConfirmClose}
                  onClose={logoutConfirmClose}
                  onOk={logout}
                />
              </Menu.Item>
              )}
          </Menu>
          {!isCollapsed && <LastModifiedDate />}
        </div>
      </Sider>
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  authAction: PropTypes.object.isRequired,
  appActions: PropTypes.object.isRequired,
  appHeight: PropTypes.number.isRequired,
  view: PropTypes.string.isRequired,
  current: PropTypes.array,
  openKeys: PropTypes.array.isRequired,
  collapsed: PropTypes.bool.isRequired,
  openDrawer: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  claims: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  fileUrl: PropTypes.string,
};

Sidebar.defaultProps = {
  current: undefined,
  fileUrl: '',
};

const mapStateToProps = state => {
  const { current, openKeys, collapsed, openDrawer } = state.AppReducer;
  const { userDetails: { claims } } = state.Auth;
  const { profile, fileUrl } = state.Profile;

  return {
    current,
    openKeys,
    collapsed,
    openDrawer,
    claims,
    profile,
    fileUrl,
  };
};

const mapDispatchToProps = dispatch => ({
  authAction: bindActionCreators(authAction, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);
