import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import { isEmpty, isNumber } from 'lodash';
import recipientsActions from 'redux/marketing/recipients/actions';
import { apiRequest } from 'redux/helpers';
import tableActions from 'components/NewTable/redux/actions';
import { ENDPOINTS, CONTENT_TYPES } from 'consts';
import { createQueryString } from 'helpers/utility';
import openNotification from 'components/Notification';
import { setUrl } from 'helpers/urlSync';

export function* importData() {
  yield takeEvery(recipientsActions.IMPORT_DATA, function*({ payload }) {
    const { profile } = yield select(state => state.Profile);
    const data = yield apiRequest(
      superFetch.post,
      `${ENDPOINTS.RECIPIENT}/import?userId=${profile.id}&roleId=${profile.role.id}
      &groupId=${encodeURIComponent(payload.ids.join())}`,
      { data: payload?.file.file },
      CONTENT_TYPES.MULTIPART_DATA,
    );
    if (data.errors || data.error) {
      yield put({
        type: recipientsActions.IMPORT_DATA_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(recipientsActions.toggleImportModal());
      yield put({
        type: recipientsActions.GET_RECIPIENTS_REQUEST,
        payload: {}
      });
    }
  });
}

export function* getRecipientsRequest() {
  yield takeEvery(recipientsActions.GET_RECIPIENTS_REQUEST, function*({payload}) {
    const { nextPage } = payload;
    const { pagination, sortValues, searchValue } = {
      ...(yield select(state => state.tableReducer)),
    };

    const queryUrl = `${ENDPOINTS.RECIPIENT}?${createQueryString({
      pagination: {...pagination,
        currentPage: isNumber(nextPage) ? nextPage : pagination.currentPage},
      multiSort: sortValues,
      fields: searchValue,
      limit: pagination.pageSize,
    })}`;
    const recipients = yield apiRequest(superFetch.get, queryUrl);

    setUrl(queryUrl);
    if (recipients.errors) {
      yield put(recipientsActions.getRecipientsError(recipients.errors));
      yield openNotification('error', 'Error', recipients.errors);
    } else if (isNumber(nextPage)) {
      yield put(
        recipientsActions.setRecipientsNext({
          recipients: recipients.recipients.map(e => ({ ...e, key: e.id })),
        }));
      yield put(tableActions.setPagination(recipients.pagination))
    } else {
      yield put(
        recipientsActions.getRecipientsSuccess({
          recipients: recipients.recipients.map(e => ({ ...e, key: e.id })),
        }),
      );
      yield put(tableActions.setPagination(recipients.pagination))
    }
  });
}

export function* getRecipientsInitialState() {
  yield takeEvery(recipientsActions.GET_RECIPIENTS_INITIAL_STATE, function*() {
    const states = yield apiRequest(superFetch.get, `${ENDPOINTS.RECIPIENT}/states`);
    const cities = yield apiRequest(superFetch.get, `${ENDPOINTS.RECIPIENT}/cities`);
    yield put(
      recipientsActions.setRecipientsInitialState({
        states: states.states.map((e, index) => ({ ...e, id: e.name, key: index })),
        cities: cities.cities.map((e, index) => ({ ...e, id: e.name, key: index })),
      }),
    );
  });
}

export function* addRecipient() {
  yield takeEvery(recipientsActions.ADD_REQUEST, function*({ payload }) {
    const data = yield apiRequest(superFetch.post, `${ENDPOINTS.RECIPIENT}`, payload);
    if (data.errors || data.error) {
      yield put({
        type: recipientsActions.ADD_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(recipientsActions.createRecipientSuccess)
      yield put({
        type: recipientsActions.GET_RECIPIENTS_REQUEST,
        payload: {}
      });
    }
  });
}

export function* updateRecipient() {
  yield takeEvery(recipientsActions.UPDATE_RECIPIENT, function*({ payload }) {
    const { recipient, recipientId } = payload;
    const data = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.RECIPIENT_AND}${recipientId}`,
      recipient,
    );

    if (data.errors) {
      yield put({
        type: recipientsActions.UPDATE_RECIPIENT_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: recipientsActions.GET_RECIPIENTS_REQUEST,
        payload: {}
      });
    }
  });
}

export function* deleteRecipient() {
  yield takeEvery(recipientsActions.DELETE_RECIPIENT_REQUEST, function*({ payload }) {
    const data = yield apiRequest(superFetch.delete, `${ENDPOINTS.RECIPIENT_AND}${payload.id}`);

    if (data.errors) {
      yield put({
        type: recipientsActions.DELETE_RECIPIENT_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: recipientsActions.GET_RECIPIENTS_REQUEST,
        payload: {}
      });
    }
  });
}

export function* multipleDeleteRequest() {
  yield takeEvery(recipientsActions.MULTIPLE_DELETE_REQUEST, function*({ payload }) {
    const { selectedRecipients } = yield select(state => state.Recipients);
    const ids = isEmpty(selectedRecipients) ? payload.ids : Object.keys(selectedRecipients);
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.RECIPIENT}`,
      {ids}
      );
    if (data.errors) {
      yield put(recipientsActions.getRecipientsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(recipientsActions.multipleDeleteSuccess());
      yield put(recipientsActions.getRecipientsRequest());
    }
  });
}

export function* deletRecipienFromGroup() {
  yield takeEvery(recipientsActions.DELETE_FROM_GROUP, function*({payload}) {
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.RECIPIENT_REMOVE_FROM_GROUPS}`,
      payload,
    );

    if (data.errors) {
      yield put(recipientsActions.deleteRecipientFromGroupError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(recipientsActions.deleteRecipientFromGroupSuccess());
      yield put({
        type: recipientsActions.GET_RECIPIENTS_REQUEST,
        payload: {}
      });
    }
  });
}


export function* addRecipienToGroup() {
  yield takeEvery(recipientsActions.ADD_TO_GROUP, function*({payload}) {
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.RECIPIENT_ADD_TO_GROUP}`,
      payload,
    );

    if (data.errors) {
      yield put(recipientsActions.addRecipientToGroupError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(recipientsActions.addRecipientToGroupSuccess());
      yield put({
        type: recipientsActions.GET_RECIPIENTS_REQUEST,
        payload: {}
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getRecipientsRequest),
    fork(getRecipientsInitialState),
    fork(addRecipient),
    fork(updateRecipient),
    fork(importData),
    fork(deleteRecipient),
    fork(multipleDeleteRequest),
    fork(deletRecipienFromGroup),
    fork(addRecipienToGroup)
  ]);
}
