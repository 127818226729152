import manageRolesAndClaimsActions from 'redux/manageRoleAccess/actions';

const initState = {
  loading: false,
  rolesAndClaims: {},
  roles: [],
  isAddRolesModalActive: false,
};

export default function manageRoleReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
        // GET ROLE AND CLAIMS
    case manageRolesAndClaimsActions.GET_ROLES_AND_CLAIMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageRolesAndClaimsActions.GET_ROLES_AND_CLAIMS_SUCCESS:
      return {
        ...state,
        rolesAndClaims: payload.rolesAndClaims,
        loading: false
      };
    case manageRolesAndClaimsActions.GET_ROLES_AND_CLAIMS_ERROR:
      return {
        ...state,
        loading: false,
      };
    // GET ROLE
    case manageRolesAndClaimsActions.GET_ROLES_REQUEST:
      return {
        ...state,
        loading: true
      };

    case manageRolesAndClaimsActions.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: payload.roles,
        loading: false
      };
    case manageRolesAndClaimsActions.GET_ROLES_ERROR:
      return {
        ...state,
        loading: false
      };
    case manageRolesAndClaimsActions.REMOVE_CLAIM_FROM_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageRolesAndClaimsActions.REMOVE_CLAIM_FROM_ROLE_SUCCESS:
      const removedRolesAndClaims = {
        roles: state.rolesAndClaims.roles.map(role => {
          if (payload.roleId === role.id)
            return { ...role, claims: role.claims.filter(claim => claim !== payload.claimId) };
          return role;
        }),
        claims: [...state.rolesAndClaims.claims],
      };
      return {
        ...state,
        rolesAndClaims: removedRolesAndClaims,
        loading: false,
      };
    case manageRolesAndClaimsActions.REMOVE_CLAIM_FROM_ROLE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case manageRolesAndClaimsActions.ADD_CLAIM_TO_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case manageRolesAndClaimsActions.ADD_CLAIM_TO_SUCCESS:
      const addedRolesAndClaims = {
        roles: state.rolesAndClaims.roles.map(role => {
          if (payload.roleId === role.id)
            return { ...role, claims: [...role.claims, payload.claimId] };
          return role;
        }),
        claims: [...state.rolesAndClaims.claims],
      };
      return {
        ...state,
        rolesAndClaims: addedRolesAndClaims,
        loading: false,
      };
    case manageRolesAndClaimsActions.ADD_CLAIM_TO_ERROR:
      return {
        ...state,
        loading: false,
      };
    case manageRolesAndClaimsActions.CREATE_NEW_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageRolesAndClaimsActions.CREATE_NEW_ROLE_SUCCESS:
      payload.newRole.claims = [];
      const addedNewRole = {
        roles: [...state.rolesAndClaims.roles, payload.newRole],
        claims: state.rolesAndClaims.claims,
      };
      return {
        ...state,
        rolesAndClaims: addedNewRole,
        isAddRolesModalActive: false,
        loading: false,
      };
    case manageRolesAndClaimsActions.CREATE_NEW_ROLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    /* CHANGE LOCATION BASED */
    case manageRolesAndClaimsActions.CHANGE_LOCATION_BASED_SUCCESS:
    return {
      ...state,
      rolesAndClaims: {
        ...state.rolesAndClaims,
        claims: state.rolesAndClaims.claims.map((claim) => {
          if (claim.id === payload.id) {
            return {
              ...claim,
              isLocationBased: !claim.isLocationBased,
            };
          }
          return claim;
        }),
      },
    };

    /* RENAME ROLE */
    case manageRolesAndClaimsActions.RENAME_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageRolesAndClaimsActions.RENAME_ROLE_SUCCESS:
      const changeSelectedRoleName = state.rolesAndClaims.roles.map(e => {
        if (payload.roleIdAndName.id === e.id)
          return {...e, name: payload.roleIdAndName.name }
        return e

      });
      const rolesAndClaimsRenamed = { ...state.rolesAndClaims, roles: changeSelectedRoleName };
      return {
        ...state,
        rolesAndClaims: rolesAndClaimsRenamed,
        loading: false,
      };
    case manageRolesAndClaimsActions.RENAME_ROLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    /* DELETE ROLE */
    case manageRolesAndClaimsActions.DELETE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageRolesAndClaimsActions.DELETE_ROLE_SUCCESS:
      const rolesAndClaimsRemoved = {
        ...state.rolesAndClaims,
        roles: state.rolesAndClaims.roles.filter(role => payload.roleId !== role.id),
      };
      return {
        ...state,
        rolesAndClaims: rolesAndClaimsRemoved,
        loading: false,
      };
    case manageRolesAndClaimsActions.DELETE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case manageRolesAndClaimsActions.TOGGLE_NEW_ROLE_MODAL:
      return {
        ...state,
        isAddRolesModalActive: !state.isAddRolesModalActive,
      };
    default:
      return state;
  }
};
