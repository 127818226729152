const tableActions = {
  SET_FILTERS: 'TABLE_SET_FILTERS',
  SET_FILTERS_SUCCESS: 'TABLE_SET_FILTERS_SUCCESS',
  SET_VISIBLE_COLUMNS: 'TABLE_SET_VISIBLE_COLUMNS',
  SET_VISIBLE_COLUMNS_SUCCESS: 'TABLE_SET_VISIBLE_COLUMNS_SUCCESS',
  SET_COLUMNS: 'TABLE_SET_COLUMNS',
  SET_COLUMNS_SUCCESS: 'TABLE_SET_COLUMNS_SUCCESS',
  SET_SORTERS: 'TABLE_SET_SORTERS',
  SET_SORTERS_SUCCESS: 'TABLE_SET_SORTERS_SUCCESS',
  SET_PAGINATION: 'TABLE_SET_PAGINATION',
  SET_IMPORT_MODAL_STATE: 'TABLE_SET_IMPORT_MODAL_STATE',
  SET_INITIAL_STATE: 'TABLE_SET_INITIAL_STATE',
  RESET_STATE: 'TABLE_RESET_STATE',

  GET_LAST_MODIFIED_DATE_REQUEST: `GET_LAST_MODIFIED_DATE_REQUEST`,
  GET_LAST_MODIFIED_DATE_SUCCESS: `GET_LAST_MODIFIED_DATE_SUCCESS`,
  GET_LAST_MODIFIED_DATE_ERROR: `GET_LAST_MODIFIED_DATE_ERROR`,

  /* get last date upade*/
  getLastModifiedDateRequest: (payload, id) => ({
    type: tableActions.GET_LAST_MODIFIED_DATE_REQUEST,
    payload,
    id,
  }),

  getLastModifiedSuccess: payload => ({
    type: tableActions.GET_LAST_MODIFIED_DATE_SUCCESS,
    payload
  }),

  getLastModifiedError: error => ({
    type: tableActions.GET_LAST_MODIFIED_DATE_ERROR,
    payload: {
      error,
    },
  }),

  setFilters: payload => {
    return {
      type: tableActions.SET_FILTERS,
      payload,
    }
  },

  setFiltersSuccess: payload => {
    return {
      type: tableActions.SET_FILTERS_SUCCESS,
      payload
    }
  },

  setColumns: payload => {
    return {
      type: tableActions.SET_COLUMNS,
      payload,
    }
  },

  setColumnsSuccess: payload => {
    return {
      type: tableActions.SET_COLUMNS_SUCCESS,
      payload,
    }
  },

  setVisibleColumns: payload => {
    return {
      type: tableActions.SET_VISIBLE_COLUMNS,
      payload,
    }
  },

  setVisibleColumnsSuccess: payload => {
    return {
      type: tableActions.SET_VISIBLE_COLUMNS_SUCCESS,
      payload,
    }
  },

  setSorters: payload => {
    return {
      type: tableActions.SET_SORTERS,
      payload,
    }
  },

  setSortersSuccess: payload => {
    return {
      type: tableActions.SET_SORTERS_SUCCESS,
      payload,
    }
  },

  setPagination: payload => {
    return {
      type: tableActions.SET_PAGINATION,
      payload,
    }
  },

  setImportModalState: payload => {
    return {
      type: tableActions.SET_IMPORT_MODAL_STATE,
      payload,
    }
  },

  setInitialState: payload => {
    return {
      type: tableActions.SET_INITIAL_STATE,
      payload,
    }
  },

  resetState: () => {
    return {
     type: tableActions.RESET_STATE
    }
  }
};

export default tableActions;
