const DOCUMENT = 'MARKETING_GROUPS_';

const marketingGroupsActions = {
  GET_GROUPS_STATE: `GET_${DOCUMENT}STATE`,
  SET_GROUPS_STATE: `SET_${DOCUMENT}STATE`,
  ERROR_GROUPS_STATE: `ERROR_${DOCUMENT}STATE`,

  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,
  ADD_GROUP: `${DOCUMENT}ADD`,
  ADD_GROUP_SUCCESS: `${DOCUMENT}ADD_SUCCESS`,
  UPDATE_GROUP: `${DOCUMENT}UPDATE`,
  DELETE_GROUP: `${DOCUMENT}DELETE`,

  getGroupsState: (payload) => ({
    type: marketingGroupsActions.GET_GROUPS_STATE,
    payload,
  }),

  setGroupsState: (payload) => ({
    type: marketingGroupsActions.SET_GROUPS_STATE,
    payload,
  }),

  errorGroupsState: (payload) => ({
    type: marketingGroupsActions.ERROR_GROUPS_STATE,
    payload,
  }),

  addGroup: (payload) => ({
    type: marketingGroupsActions.ADD_GROUP,
    payload,
  }),

  addGroupSuccess: (payload) => ({
    type: marketingGroupsActions.ADD_GROUP_SUCCESS,
    payload,
  }),

  updateGroup: (id, value) => ({
    type: marketingGroupsActions.UPDATE_GROUP,
    payload: {id, value},
  }),

  deleteGroup: (payload) => ({
    type: marketingGroupsActions.DELETE_GROUP,
    payload,
  }),

  toggleModal: () => ({
    type: marketingGroupsActions.TOGGLE_MODAL,
  }),
};

export default marketingGroupsActions
