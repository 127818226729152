import { all, takeEvery, put, fork } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import locationActions from 'redux/location/actions';
import { ENDPOINTS } from 'consts';
import { apiRequest } from 'redux/helpers';

export function* getLocationRequest() {
  yield takeEvery(locationActions.GET_LOCATION_REQUEST, function* () {
    const location = yield apiRequest(superFetch.get, `${ENDPOINTS.LOCATION}`);
    if (location.errors) {
      yield put(locationActions.getLocationError());
    } else {
      yield put(locationActions.getLocationSuccess(location));
    }
  });
};

export default function* rootSaga() {
  yield all([
    fork(getLocationRequest),
  ]);
};
