const DOCUMENT = 'RESOURCE_';

const resourceEvaluationActions = {
  GET_RESOURCE_EVALUATION_REQUEST: `${DOCUMENT}GET_EVALUATION_REQUEST`,
  GET_RESOURCE_EVALUATION_SUCCESS: `${DOCUMENT}GET_EVALUATION_SUCCESS`,
  GET_RESOURCE_EVALUATION_ERROR: `${DOCUMENT}GET_EVALUATION_ERROR`,
  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,
  TECHNICAL_TOGGLE_MODAL: `${DOCUMENT}TECHNICAL_TOGGLE_MODAL`,
  ENGLISH_TOGGLE_MODAL: `${DOCUMENT}ENGLISH_TOGGLE_MODAL`,
  TECHNICAL_RESULT_TOGGLE_MODAL: `${DOCUMENT}TECHNICAL_RESULT_TOGGLE_MODAL`,
  ENGLISH_RESULT_TOGGLE_MODAL: `${DOCUMENT}ENGLISH_RESULT_TOGGLE_MODAL`,
  UPDATE_EVALUATION_REQUEST: `UPDATE_EVALUATION_REQUEST`,
  UPDATE_EVALUATION_SUCCESS: `UPDATE_EVALUATION_SUCCESS`,
  UPDATE_EVALUATION_ERROR: `UPDATE_EVALUATION_ERROR`,

  getResourceEvaluationRequest: (id) => ({
    type: resourceEvaluationActions.GET_RESOURCE_EVALUATION_REQUEST,
    payload: {
      id
    },
  }),
  getResourceEvaluationSuccess: evaluation => ({
    type: resourceEvaluationActions.GET_RESOURCE_EVALUATION_SUCCESS,
    payload: evaluation,
  }),
  getResourceEvaluationError: error => ({
      type: resourceEvaluationActions.GET_RESOURCE_EVALUATION_ERROR,
      payload: {
        error
      },
  }),

  toggleModal: () => ({
    type: resourceEvaluationActions.TOGGLE_MODAL,
  }),

  technicalToggleModal: () => ({
    type: resourceEvaluationActions.TECHNICAL_TOGGLE_MODAL,
  }),

  englishToggleModal: () => ({
    type: resourceEvaluationActions.ENGLISH_TOGGLE_MODAL,
  }),

  technicalResultToggleModal: () => ({
    type: resourceEvaluationActions.TECHNICAL_RESULT_TOGGLE_MODAL,
  }),

  englishResultToggleModal: () => ({
    type: resourceEvaluationActions.ENGLISH_RESULT_TOGGLE_MODAL,
  }),


  updateEvaluationRequest: (evaluation, evaluationId) => ({
    type: resourceEvaluationActions.UPDATE_EVALUATION_REQUEST,
    payload: { evaluation, evaluationId },
  }),

  updateEvaluationSuccess: payload => ({
    type: resourceEvaluationActions.UPDATE_EVALUATION_SUCCESS,
    payload,
  }),
  
  updateEvaluationError: (payload) => ({
    type: resourceEvaluationActions.UPDATE_EVALUATION_ERROR,
    payload,
  }),
};

export default resourceEvaluationActions;
