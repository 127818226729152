import actions from 'redux/stats/actions';
import toColumnChartArray from 'helpers/statsHelper';
import { uniqBy } from 'lodash';
import { countFlag, flagWithCount, sortFlags } from '../helpers/projectFlag';

const initState = {
  resources: {
    engLvls: [],
    resRole: [],
    techLvls: [],
    loading: false,
  },
  projects: {
    projectsByTechnologyStat: [],
    projectsByStatusStat: [],
    projectsByTierStat: [],
    loading: false,
  },
  closedProjects: {
    data: [],
    loading: false,
  },
  flags: {
    data: [],
    loading: false,
    flagTypes: [],
    dataCopy: [],
    flagType: '',
  },
};

export default function statsReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_RESOURCE_STATS_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: true,
        },
      };

    case actions.GET_RESOURCE_STATS_SUCCESS:
      const resources = {};
      Object.keys(action.payload).forEach(key => {
        resources[key] = [...action.payload[key].map(item => [item.name, item.resCount])];
      });
      const engLvls = toColumnChartArray(resources?.engLvls);
      const techLvls = toColumnChartArray(resources?.techLvls);

      return {
        ...state,
        resources: {
          ...resources,
          engLvls,
          techLvls,
          loading: false,
        },
      };
    case actions.GET_RESOURCE_STATS_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
        },
      };
    case actions.GET_PROJECT_STATS_REQUEST:
      return {
        ...state,
        projects: {
          ...state.projects,
          loading: true,
          projectStatusGroup: 2,
        },
      };
    case actions.GET_PROJECT_STATS_SUCCESS:
      const projects = {};

      Object.keys(action.payload).forEach(key => {
        projects[key] = [...action.payload[key].map(item => [item.name, item.projectCount])];
      });

      const projectsByStatusStat = toColumnChartArray(projects?.projectsByStatusStat);
      const projectsByTierStat =
        toColumnChartArray(projects?.projectsByTierStat, "projectsByTierStat");

      return {
        ...state,
        projects: {
          ...projects,
          projectsByStatusStat,
          projectsByTierStat,
          loading: false,
        },
      };
    case actions.GET_PROJECT_STATS_ERROR:
      return {
        ...state,
        projects: {
          ...state.projects,
          loading: false,
        },
      };
    case actions.GET_CLOSED_PROJECTS_STATS_REQUEST:
      return {
        ...state,
        closedProjects: {
          ...state.closedProjects,
          loading: true,
        },
      };
    case actions.GET_CLOSED_PROJECTS_STATS_SUCCESS:
      return {
        ...state,
        closedProjects: {
          data: action.payload,
          loading: false,
        },
      };
    case actions.GET_CLOSED_PROJECTS_STATS_ERROR:
      return {
        ...state,
        closedProjects: {
          ...state.closedProjects,
          loading: false,
        },
      };
    case actions.GET_PROJECT_FLAGS_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          loading: true,
        },
      };
    case actions.GET_PROJECT_FLAGS_SUCCESS:
      const unicFlag = uniqBy(action.payload, 'flagType')
      unicFlag.sort(sortFlags).reverse()
      const typeFlags = action.payload.map(elem => elem.flagType);
      const newArr = countFlag(typeFlags,unicFlag);
      return {
        ...state,
        flags: {
          data: action.payload,
          loading: false,
          flagTypes: flagWithCount(newArr, unicFlag),
          dataCopy: action.payload,
        },
      };
    case actions.GET_PROJECT_FLAG_COLOR:
      const dataFilter = action.flagType === state.flags.flagType
        ? {
          flagType: undefined,
          data: state.flags.dataCopy
        }
        : {
          flagType: action.flagType,
          data: state.flags.dataCopy.filter(elem => elem.flagType === action.flagType)
        }

      return {
        ...state,
        flags: {
          ...state.flags,
          ...dataFilter,
        },
      };
    case actions.GET_PROJECT_FLAGS_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          loading: false,
        },
      };
    default:
      return state;
  }
}
