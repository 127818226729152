const DOCUMENT = 'PROJECT_STATUSES_';

const actions = {
  GET_PROJECT_STATUSES_REQUEST: 'GET_PROJECT_STATUSES_REQUEST',
  GET_PROJECT_STATUSES_SUCCESS: 'GET_PROJECT_STATUSES_SUCCESS',
  GET_PROJECT_STATUSES_ERROR: 'GET_PROJECT_STATUSES_ERROR',
  ADD_PROJECT_STATUS_REQUEST: 'ADD_PROJECT_STATUS_REQUEST',
  ADD_PROJECT_STATUS_SUCCESS: 'ADD_PROJECT_STATUS_SUCCESS',
  ADD_PROJECT_STATUS_ERROR: 'ADD_PROJECT_STATUS_ERROR',
  UPDATE_PROJECT_STATUS_REQUEST: 'UPDATE_PROJECT_STATUS_REQUEST',
  UPDATE_PROJECT_STATUS_SUCCESS: 'UPDATE_PROJECT_STATUS_SUCCESS',
  UPDATE_PROJECT_STATUS_ERROR: 'UPDATE_PROJECT_STATUS_ERROR',
  DELETE_PROJECT_STATUS_REQUEST: 'DELETE_PROJECT_STATUS_REQUEST',
  DELETE_PROJECT_STATUS_SUCCESS: 'DELETE_PROJECT_STATUS_SUCCESS',
  DELETE_PROJECT_STATUS_ERROR: 'DELETE_PROJECT_STATUS_ERROR',

  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,

  getProjectStatusesRequest: () => ({
    type: actions.GET_PROJECT_STATUSES_REQUEST,
  }),
  getProjectStatusesSuccess: statuses => ({
    type: actions.GET_PROJECT_STATUSES_SUCCESS,
    payload: { statuses },
  }),
  getProjectStatusesError: error => ({
    type: actions.GET_PROJECT_STATUSES_ERROR,
    payload: { error },
  }),
  addProjectStatusRequest: status => ({
    type: actions.ADD_PROJECT_STATUS_REQUEST,
    payload: {
      title: status.title,
      color: status.color.hex,
      groupId: status.groupId,
    },
  }),
  addProjectStatusSuccess: status => ({
    type: actions.ADD_PROJECT_STATUS_SUCCESS,
    payload: { status },
  }),
  addProjectStatusError: error => ({
    type: actions.ADD_PROJECT_STATUS_ERROR,
    payload: { error },
  }),
  updateProjectStatusRequest: status => ({
    type: actions.UPDATE_PROJECT_STATUS_REQUEST,
    payload: {
      title: status.title,
      color: status.color.hex,
      groupId: status.groupId,
      statusId: status.id,
    },
  }),
  updateProjectStatusSuccess: status => ({
    type: actions.UPDATE_PROJECT_STATUS_SUCCESS,
    payload: { status },
  }),
  updateProjectStatusError: error => ({
    type: actions.UPDATE_PROJECT_STATUS_ERROR,
    payload: { error },
  }),
  deleteProjectStatusRequest: statusId => ({
    type: actions.DELETE_PROJECT_STATUS_REQUEST,
    payload: { statusId },
  }),
  deleteProjectStatusSuccess: statusId => ({
    type: actions.DELETE_PROJECT_STATUS_SUCCESS,
    payload: { statusId },
  }),
  deleteProjectStatusError: error => ({
    type: actions.DELETE_PROJECT_STATUS_ERROR,
    payload: { error },
  }),

  toggleModal: status => ({
    type: actions.TOGGLE_MODAL,
    payload: { status },
  }),
};

export default actions;
