import * as authHelper from 'helpers/authHelper';
import actions from 'redux/auth/actions';

const initState = {
  loading: false,
  isLoggedIn: authHelper.getAuthStatus(),
  forbiden: false,
  error: null,
  userDetails: {
    claims: [],
    avatarUrl: null,
  },
  fetched: false,
  collapsed: false,
};

export default function authReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case actions.LOGIN_REQUEST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        isLoggedIn: false,
      };
    case actions.FORBIDEN_ERROR:
      return {
        ...state,
        error: payload.errors,
        loading: false,
        fetched: true,
      };
    case actions.USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: payload,
        loading: false,
        fetched: true
      };
    case actions.USER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case actions.UNAUTHORIZED_ERROR:
    case actions.LOGOUT_PREV_USER:
    case actions.LOGOUT_REQUEST:
      return {
        ...initState,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
