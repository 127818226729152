const DOCUMENT = 'RESOURCE_ROLES_';

const actions = {
  GET_RESOURCE_ROLES_REQUEST: 'GET_RESOURCE_ROLES_REQUEST',
  GET_RESOURCE_ROLES_SUCCESS: 'GET_RESOURCE_ROLES_SUCCESS',
  GET_RESOURCE_ROLES_ERROR: 'GET_RESOURCE_ROLES_ERROR',
  ADD_RESOURCE_ROLE_REQUEST: 'ADD_RESOURCE_ROLE_REQUEST',
  ADD_RESOURCE_ROLE_SUCCESS: 'ADD_RESOURCE_ROLE_SUCCESS',
  ADD_RESOURCE_ROLE_ERROR: 'ADD_RESOURCE_ROLE_ERROR',
  UPDATE_RESOURCE_ROLE_REQUEST: 'UPDATE_RESOURCE_ROLE_REQUEST',
  UPDATE_RESOURCE_ROLE_SUCCESS: 'UPDATE_RESOURCE_ROLE_SUCCESS',
  UPDATE_RESOURCE_ROLE_ERROR: 'UPDATE_RESOURCE_ROLE_ERROR',
  DELETE_RESOURCE_ROLE_REQUEST: 'DELETE_RESOURCE_ROLE_REQUEST',
  DELETE_RESOURCE_ROLE_SUCCESS: 'DELETE_RESOURCE_ROLE_SUCCESS',
  DELETE_RESOURCE_ROLE_ERROR: 'DELETE_RESOURCE_ROLE_ERROR',

  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,

  getResourceRolesRequest: () => ({
    type: actions.GET_RESOURCE_ROLES_REQUEST,
  }),
  getResourceRolesSuccess: roles => ({
    type: actions.GET_RESOURCE_ROLES_SUCCESS,
    payload: { roles },
  }),
  getResourceRolesError: error => ({
    type: actions.GET_RESOURCE_ROLES_ERROR,
    payload: { error },
  }),
  addResourceRoleRequest: role => ({
    type: actions.ADD_RESOURCE_ROLE_REQUEST,
    payload: {
      title: role.title,
      color: role.color.hex,
    },
  }),
  addResourceRoleSuccess: role => ({
    type: actions.ADD_RESOURCE_ROLE_SUCCESS,
    payload: { role },
  }),
  addResourceRoleError: error => ({
    type: actions.ADD_RESOURCE_ROLE_ERROR,
    payload: { error },
  }),
  updateResourceRoleRequest: role => ({
    type: actions.UPDATE_RESOURCE_ROLE_REQUEST,
    payload: {
      title: role.title,
      color: role.color.hex,
      roleId: role.id,
    },
  }),
  updateResourceRoleSuccess: role => ({
    type: actions.UPDATE_RESOURCE_ROLE_SUCCESS,
    payload: { role },
  }),
  updateResourceRoleError: error => ({
    type: actions.UPDATE_RESOURCE_ROLE_ERROR,
    payload: { error },
  }),
  deleteResourceRoleRequest: roleId => ({
    type: actions.DELETE_RESOURCE_ROLE_REQUEST,
    payload: { roleId },
  }),
  deleteResourceRoleSuccess: roleId => ({
    type: actions.DELETE_RESOURCE_ROLE_SUCCESS,
    payload: { roleId },
  }),
  deleteResourceRoleError: error => ({
    type: actions.DELETE_RESOURCE_ROLE_ERROR,
    payload: { error },
  }),

  toggleModal: role => ({
    type: actions.TOGGLE_MODAL,
    payload: { role },
  }),
};

export default actions;
