import marketingSendersActions from 'redux/marketing/marketingSenders/actions';

const initState = {
  senders: [],
  loading: false,
  isModalActive: false,
  currentSender: {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    pass: '',
    signatures: [],
    loading: false,
    loadingSignatures: false,
  },
  currentSignature: {
    id: null,
    name: '',
    company: '',
    mobile: '',
    address: '',
    linkedin: '',
    title: '',
  },
  editView: {
    settings: {
      status: false,
      loading: false,
    },
    signature: {
      status: false,
      loading: false,
    }
  },
};

export default function marketingSendersReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case marketingSendersActions.GET_SENDERS_STATE:
      return {
        ...state,
        loading: true,
      };

    case marketingSendersActions.SET_SENDERS_STATE:
      return {
        ...state,
        senders: [...payload.senders],
        loading: false,
      };

    case marketingSendersActions.ERROR_SENDERS_STATE:
      return {
        ...state,
        loading: false,
      };

    case marketingSendersActions.GET_SENDER_REQUEST:
      return {
        ...state,
        currentSender: {
          ...state.currentSender,
          loading: true,
        },
        editView: {
          ...state.editView,
          settings: {
            loading: true,
          },
          signature: {
            loading: true,
          }
        },
      };

    case marketingSendersActions.GET_SENDER_SUCCESS:
      const currentSignature = payload.sender.signatures[0] ?
      payload.sender.signatures[0] : initState.currentSignature;
      return {
        ...state,
        currentSender: {
          ...state.currentSender,
          ...payload.sender,
          loading: false,
        },
        currentSignature: {
          ...state.currentSignature,
          ...currentSignature,
        },
        editView: {
          settings: {
            loading: false,
          },
          signature: {
            loading: false
          }
        },
      };

    case marketingSendersActions.GET_SENDER_ERROR:
      return {
        ...state,
        currentSender: {
          ...state.currentSender,
          emailTemplate: payload.sender,
          loading: false,
        },
        editView: {
          settings: {
            loading: false,
          },
          signature: {
            loading: false,
          }
        },
      };

    case marketingSendersActions.CHANGE_VIEW:
      return {
        ...state,
        editView: {
          ...state.editView,
          [payload.boxName]: {
            ...state.editView[payload.boxName],
            status: payload.status,
          },
        },
      };

    case marketingSendersActions.ADD_SENDER:
      return {
        ...state,
        loading: true,
      };

    case marketingSendersActions.ADD_SENDER_SUCCESS:
      return {
        ...state,
        senders: [...state.senders, payload],
        isModalActive: false,
        loading: false,
      };

    case marketingSendersActions.UPDATE_SENDER:
      return {
        ...state,
        editView: {
          ...state.editView,
          settings: {
            status: false,
            loading: true,
          },
        },
      };

      case marketingSendersActions.UPDATE_SENDER_SUCCESS:
        return {
          ...state,
          loading: false,
          currentSender: {
            ...payload
          },
          editView: {
            ...state.editView,
            settings: {
              ...state.editView.settings,
              loading: false,
            },
          },
        };

    case marketingSendersActions.DELETE_SENDER:
      return {
        ...state,
        loading: true,
      };

    case marketingSendersActions.ADD_SIGNATURE:
      return {
        ...state,
        editView: {
          ...state.editView,
          signature: {
            ...state.editView.signature,
            loading: false,
          },
        }
      };

    case marketingSendersActions.ADD_SIGNATURE_SUCCESS:
      return {
        ...state,
        currentSender: {
          ...state.currentSender,
          signatures: [payload],
        },
        currentSignature: {
          ...state.currentSignature,
          ...payload,
        },
        editView: {
          ...state.editView,
          signature: {
            status: false,
            loading: false,
          },
        },
      };

    case marketingSendersActions.UPDATE_SIGNATURE:
      return {
        ...state,
        editView: {
          ...state.editView,
          signature: {
            status: false,
            loading: true
          }
        }
      };

      case marketingSendersActions.UPDATE_SIGNATURE_SUCCESS:
        return {
          ...state,
          currentSender: {
            ...state.currentSender,
            signatures: [ payload ]
          },
          currentSignature: { ...payload},
          editView: {
            ...state.editView,
            signature: {
              status: false,
              loading: false,
            }
          }
        };

    case marketingSendersActions.DELETE_SIGNATURE:
      return {
        ...state,
        currentSender: {
          ...state.currentSender,
        },
      };

    case marketingSendersActions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
      };

    default:
      return state;
  }
}
