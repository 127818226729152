import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { apiRequest } from 'redux/helpers';
import { push } from 'react-router-redux';
import superFetch from 'helpers/superFetch';
import openNotification from 'components/Notification';
import usersActions from 'redux/users/actions';
import tableActions from 'components/NewTable/redux/actions';
import { ENDPOINTS } from 'consts';
import { createQueryString } from 'helpers/utility';
import { setUrl } from 'helpers/urlSync';

export function* getUsersRequest() {
  yield takeEvery(usersActions.GET_USERS_REQUEST, function* ({payload}) {
    const { pagination, sortValues, searchValue } =
      {...yield select((state) => state.tableReducer), payload};

    const QUERY = createQueryString({pagination, multiSort:sortValues, fields: searchValue})
    const queryUrl = `${ENDPOINTS.USER}?${QUERY}`;

    const userData = yield apiRequest(superFetch.get, queryUrl);
    if(!payload?.ignoreSetUrl)
    setUrl(queryUrl);

    const rolesData = yield apiRequest(superFetch.get, ENDPOINTS.ROLES);
    if (userData.errors || rolesData.errors) {
      yield put(usersActions
        .getUsersError(userData.errors || rolesData.errors));
      yield openNotification(
        'error',
        'Error',
        userData.errors ||
        rolesData.errors,
      );
    } else {
      yield put(usersActions.getUsersSuccess({
        users: userData.users.map(e => ({...e, key: e.id})),
        roles: rolesData,
      }));
      if(!payload?.ignoreSetUrl) yield put(tableActions.setPagination(userData.pagination))
    }
  })
}

export function* updateUserRequest() {
  yield takeEvery(usersActions.UPDATE_USER_REQUEST, function* ({ payload }) {
    const users = yield select((state) => state.Users.users);
    const { index, columnsKey, value } = payload;
    let updatedUser = {
      ...users.find(item => item.id === index)
    };

    if (columnsKey === 'role') {
      updatedUser = {
        ...updatedUser,
        roleId: value.pop(),
        locations: updatedUser.locations.map(elem => ({
          id:elem.id,
          title:elem.title,
        })),
      }
    } else {
      updatedUser = {
        ...updatedUser,
        columnsKey: value,
        locations: updatedUser.locations.map(elem => ({
          id:elem.id,
          title:elem.title,
        })),
      }
    }

    if (columnsKey === 'locations') {
      updatedUser.locations = value.map(elem => ({id: elem}))
    } else {
      updatedUser[columnsKey] = value;
    }

    const data = yield apiRequest(superFetch.put, `${ENDPOINTS.USER}/${index}`, updatedUser);

    if (data.errors) {
      yield put(usersActions.updateUserError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(usersActions.updateUserSuccess(data));
    }
  });
}

export function* inviteUserRequest() {
  yield takeEvery(usersActions.INVITE_USER_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.post, ENDPOINTS.INVITE_USER, payload);
    if (data.errors) {
      yield put({
        type: usersActions.INVITE_USER_ERROR,
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      const { pendingUsers } = yield select((state) => state.Users);
      const pagination = { current: pendingUsers.pagination.currentPage };
      yield put({
        type: usersActions.INVITE_USER_SUCCESS,
      });
      yield put(usersActions.getPendingUsersRequest({ pagination }));
    }
  });
}

export function* completeInviteRequest() {
  yield takeEvery(usersActions.COMPLETE_INVITE_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.post, ENDPOINTS.COMPLETE_INVITE, payload);
    if (data.errors) {
      yield put({
        type: usersActions.INVITE_USER_ERROR,
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(push('/signin'));
      yield put({
        type: usersActions.INVITE_USER_SUCCESS,
      });
    }
  });
}

export function* deleteUserRequest() {
  yield takeEvery(usersActions.DELETE_USER_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.delete, `${ENDPOINTS.USER}/${payload.id}`);
    if (data.errors) {
      yield put(usersActions.deleteUserError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(usersActions.deleteUserSuccess());
      yield put(usersActions.getUsersRequest());
    }
  });
}

export function* resetPasswordRequest() {
  yield takeEvery(usersActions.RESET_PASSWORD_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.USER}/${payload.id}${ENDPOINTS.NEW_PASSWORD}`,
      payload,
    );
    if (data.errors) {
      yield put(usersActions.resetPasswordError(data.errors));
    } else {
      yield put(usersActions.resetPasswordSuccess(data));
    }
  });
}

export function* changePendingUsersPageSize() {
  yield takeEvery(usersActions.CHANGE_PENDING_USERS_PAGE_SIZE, function* () {
    yield put(usersActions.getPendingUsersRequest());
  })
}

export function* changePendingUsersPage() {
  yield takeEvery(usersActions.CHANGE_PENDING_USERS_PAGE, function* () {
    yield put(usersActions.getPendingUsersRequest());
  })
}

export function* getPendingUsersRequest() {
  yield takeEvery(usersActions.GET_PENDING_USERS_REQUEST, function* () {
    const { pagination } = yield select((state) => state.Users.pendingUsers);
    const queryUrl = `${ENDPOINTS.PENDING_USERS}?${createQueryString({pagination})}`;
    const pendingUsersData = yield apiRequest(superFetch.get, queryUrl);

    setUrl(queryUrl);

    if (pendingUsersData.errors) {
      yield put(usersActions
        .getPendingUsersError(pendingUsersData.errors));
      yield openNotification(
        'error',
        'Error',
        pendingUsersData.errors,
      );
    } else {
      yield put(usersActions.getPendingUsersSuccess(
      {
        pendingUsers: {
          invites: pendingUsersData.invites.map(e => ({ ...e, key: e.id })),
          pagination: pendingUsersData.pagination,
        }
      })
    )}
  })
}

export function* deletePendingUserRequest() {
  yield takeEvery(usersActions.DELETE_PENDING_USERS_REQUEST, function* ({ payload }) {
    const data =
      yield apiRequest(superFetch.delete, `${ENDPOINTS.DELETE_PENDING_USER}/${payload.id}`);
    if (data.errors) {
      yield put(usersActions.deletePendingUserError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(usersActions.deletePendingUserSuccess());
      const { pagination } = yield select((state) => state.Users.pendingUsers);
      yield put(usersActions.getPendingUsersRequest({
        pagination,
      }));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getUsersRequest),
    fork(updateUserRequest),
    fork(deleteUserRequest),
    fork(inviteUserRequest),
    fork(completeInviteRequest),
    fork(resetPasswordRequest),
    fork(changePendingUsersPage),
    fork(changePendingUsersPageSize),
    fork(getPendingUsersRequest),
    fork(deletePendingUserRequest),
  ]);
};
