import {all, fork, put, select, takeEvery} from "redux-saga/effects";
import resourcesEvaluationActions from "./actions";

import {ENDPOINTS} from "../../../consts";
import {createQueryString} from "../../../helpers/utility";
import {apiRequest} from "../../helpers";
import superFetch from "../../../helpers/superFetch";
import {setUrl} from "../../../helpers/urlSync";
import openNotification from "../../../components/Notification";
import tableActions from "../../../components/NewTable/redux/actions";

function* getResourcesEvaluationRequest() {

  yield takeEvery(
    resourcesEvaluationActions.GET_RESOURCES_EVALUATION_REQUEST,
    function* ({payload, ignoreSetUrl}
    ) {
      const { pagination, sortValues, searchValue } = yield select(state => state.tableReducer);
    // eslint-disable-next-line max-len

    const subParams = {};

    if(payload) {
      if(payload.qType)
        subParams.qType = payload.qType;
    }

    const queryUrl = `${ENDPOINTS.RESOURCE_EVALUATION}?${createQueryString({
      pagination,
      multiSort: sortValues,
      fields: {...searchValue, statusFilter:'hired', qType: 'forEvaluation'},
      ...subParams,}
    )}`;
    const data = yield apiRequest(
      superFetch.get,
      queryUrl,
    );

    if (!ignoreSetUrl) {
      setUrl(queryUrl)
    }

    if (data.errors) {
      yield put(resourcesEvaluationActions.getResourcesEvaluationError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourcesEvaluationActions.getResourcesEvaluationSuccess(data.evaluations));
      yield put(tableActions.setPagination(data.pagination));
    }
  });
}

function* addEvaluation() {
  yield takeEvery(resourcesEvaluationActions.RESOURCES_EVALUATION_ADD, function*({ payload }) {
    const evaluation = yield apiRequest(superFetch.post, `${ENDPOINTS.RESOURCE_EVALUATION}`, payload);
    if (evaluation.errors) {
      yield put(resourcesEvaluationActions.getResourcesEvaluationError(evaluation.errors));
      yield openNotification('error', 'Error', evaluation.errors);
    } else {
      yield put(resourcesEvaluationActions.addEvaluationSuccess(evaluation));
      yield put({
        type: resourcesEvaluationActions.GET_RESOURCES_EVALUATION_REQUEST,
        payload: {}
      });
    }
  });
}

export function* updateEvaluation() {
  yield takeEvery(resourcesEvaluationActions.RESOURCES_EVALUATION_UPDATE, function*({ payload }) {
    const { evaluation, evaluationId } = payload;
    const data = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.RESOURCE_EVALUATION}/${evaluationId}`,
      evaluation,
    );

    if (data.errors) {
      yield put({
        type: resourcesEvaluationActions.RESOURCES_EVALUATION_UPDATE_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: resourcesEvaluationActions.GET_RESOURCES_EVALUATION_REQUEST,
        payload: {}
      });
    }
  });
}

export function* deleteEvaluation() {
  yield takeEvery(resourcesEvaluationActions.RESOURCES_EVALUATION_DELETE, function*({ payload }) {
    const data = yield apiRequest(superFetch.delete, `${ENDPOINTS.RESOURCE_EVALUATION}/${payload.id}`);

    if (data.errors) {
      yield put({
        type: resourcesEvaluationActions.RESOURCES_EVALUATION_DELETE_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: resourcesEvaluationActions.GET_RESOURCES_EVALUATION_REQUEST,
        payload: {}
      });
    }
  });
}

function* addHistoryEvaluation() {
  yield takeEvery(resourcesEvaluationActions.RESOURCES_HISTORY_EVALUATION_ADD, function*({ payload }) {
    const evaluation =
      yield apiRequest(superFetch.post, `${ENDPOINTS.RESOURCE_EVALUATION_COMPLETE}`, payload);
    if (evaluation.errors) {
      yield put(resourcesEvaluationActions.getHistoryEvaluationError(evaluation.errors));
      yield openNotification('error', 'Error', evaluation.errors);
    } else {
      yield put(resourcesEvaluationActions.addHistoryEvaluationSuccess(evaluation));
      yield put({
        type: resourcesEvaluationActions.GET_RESOURCES_EVALUATION_REQUEST,
        payload: {}
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getResourcesEvaluationRequest),
    fork(addEvaluation),
    fork(updateEvaluation),
    fork(deleteEvaluation),
    fork(addHistoryEvaluation),
  ]);
}

