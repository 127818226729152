import locationActions from 'redux/location/actions';

const initState = {
  loading: false,
  locations: [],
};

export default function locationReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case locationActions.GET_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case locationActions.GET_LOCATION_SUCCESS:
      return {
        ...state,
        locations: payload,
        loading: false,
      };
    case locationActions.GET_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};