const actions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS',
  LOGIN_REQUEST_ERROR: 'LOGIN_REQUEST_ERROR',
  UNAUTHORIZED_ERROR: 'UNAUTHORIZED_ERROR',
  FORBIDEN_ERROR: 'FORBIDEN_ERROR',
  LOGOUT_PREV_USER: 'LOGOUT_PREV_USER',

  USER_DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
  USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
  USER_DETAILS_ERROR: 'USER_DETAILS_ERROR',

  loginRequest: (payload) => {
    return {
      type: actions.LOGIN_REQUEST,
      payload,
    };
  },

  logOutPrevUser: () => ({
    type: actions.LOGOUT_PREV_USER,
  }),

  

  loginSuccess: payload => {
    return {
      type: actions.LOGIN_REQUEST_SUCCESS,
      payload,
    };
  },

  logoutRequest: () => {
    return {
      type: actions.LOGOUT_REQUEST,
    };
  },

  loginError: payload => {
    return {
      type: actions.LOGIN_REQUEST_ERROR,
      payload,
    };
  },

  unauthorizedError: payload => {
    return {
      type: actions.UNAUTHORIZED_ERROR,
      payload,
    };
  },

  forbidenError: payload => {
    return {
      type: actions.FORBIDEN_ERROR,
      payload,
    };
  },

  getUserDetails: () => {
    return {
      type: actions.USER_DETAILS_REQUEST,
    };
  },

  getUserDetailsSuccess: payload => {
    return {
      type: actions.USER_DETAILS_SUCCESS,
      payload,
    };
  },

  getUserDetailsError: payload => {
    return {
      type: actions.USER_DETAILS_ERROR,
      payload,
    };
  },
};
export default actions;