import actions from 'redux/vacation/actions';

const initVacationForm = {
  vacationDays: null,
  daysLeft: null,
}

const initState = {
  isModalActive: false,
  isModalSubmitting: false,
  openModalVacationId: 0,
  vacations: [],
  selectedVacation: {},
  vacationStatus: [],
  startRedirect: false,
  goTo: false,
  monthRange: null,
  loading: false,
  vacationForm: {
    ...initVacationForm
  },
  vacationWarning: {},
  vacationCategories: [],
};

export default function vacationsReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.ADD_VACATION_REQUEST:
    case actions.UPDATE_VACATION_REQUEST:
      return {
        ...state,
        isModalSubmitting: true
      };

    case actions.GET_VACATIONS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case actions.GET_VACATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        vacations: payload
      };

    case actions.GET_VACATION_STATUS_SUCCESS:
      return {
        ...state,
        vacationStatus: payload
      };

    case actions.SHOW_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
        vacationForm: {
          ...initVacationForm,
        },
        vacationWarning: {},
      };

    case actions.SET_CURRENT_EDIT_VACATION:
      return {
        ...state,
        selectedVacation: payload,
      };

    case actions.SET_OPEN_MODAL_VACATION_ID:
      return {
        ...state,
        openModalVacationId: payload,
      };

    case actions.SET_GO_TO:
      return {
        ...state,
        goTo: payload,
      };

    case actions.SET_START_REDIRECT:
      return {
        ...state,
        startRedirect: payload,
      };

    case actions.CLOSE_REDIRECT:
      return {
        ...state,
        startRedirect: false,
        goTo: false,
      };

    case actions.CALCULATE_VACATION_REQUEST:
      return {
        ...state,
      };

    case actions.VACATION_WARNING_REQUEST:
      return {
        ...state,
      };
    case actions.VACATION_WARNING_SUCCESS:
      return {
        ...state,
        vacationWarning:{
          ...state.vacationWarning,
          ...payload,
        },
      };

    case actions.GET_VACATION_CATEGORIES_REQUEST:
      return {
        ...state
      };

    case actions.GET_VACATION_CATEGORIES_SUCCESS:
      return {
        ...state,
        vacationCategories: payload.data,
      }

    case actions.GET_VACATION_CATEGORIES_ERROR:
      return {
        ...state,
      }

    case actions.CALCULATE_VACATION_SUCCESS:
    case actions.UPDATE_FORM_STATE:
      return {
        ...state,
        vacationForm:{
          ...state.vacationForm,
          ...payload,
        },
      };

    case actions.DELETE_VACATION_SUCCESS:
    case actions.UPDATE_VACATION_SUCCESS:
    case actions.ADD_VACATION_SUCCESS:
      return {
        ...state,
        isModalActive: false,
        isModalSubmitting: false,
      }

    case actions.SET_RANGE_MONTH:
      return {
        ...state,
        monthRange: payload,
      };

      case actions.ADD_VACATION_ERROR:
      case actions.UPDATE_VACATION_ERROR:
      case actions.DELETE_VACATION_ERROR:
      return {
        ...state,
        isModalSubmitting: false,
      };

    default:
      return state;
  }
};
