const DOCUMENT = 'MARKETING_SENDERS_';
const SENDER_DOCUMENT = 'MARKETING_SENDER_';

const marketingSendersActions = {
  GET_SENDERS_STATE: `GET_${DOCUMENT}STATE`,
  SET_SENDERS_STATE: `SET_${DOCUMENT}STATE`,
  ERROR_SENDERS_STATE: `ERROR_${DOCUMENT}STATE`,

  GET_SENDER_REQUEST: `GET_${SENDER_DOCUMENT}REQUEST`,
  GET_SENDER_SUCCESS: `GET_${SENDER_DOCUMENT}SUCCESS`,
  GET_SENDER_ERROR: `GET_${SENDER_DOCUMENT}ERROR`,

  CHANGE_VIEW: `${SENDER_DOCUMENT}CHANGE_VIEW`,

  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,
  ADD_SENDER: `${DOCUMENT}ADD`,
  ADD_SENDER_SUCCESS: `${DOCUMENT}ADD_SUCCESS`,
  UPDATE_SENDER: `${DOCUMENT}UPDATE`,
  UPDATE_SENDER_SUCCESS: `${DOCUMENT}UPDATE_SUCCESS`,
  DELETE_SENDER: `${DOCUMENT}DELETE`,

  TOGGLE_SIGNATURE_MODAL: `${SENDER_DOCUMENT}TOGGLE_SIGNATURE_MODAL`,
  ADD_SIGNATURE: `${SENDER_DOCUMENT}ADD_SIGNATURE`,
  ADD_SIGNATURE_SUCCESS: `${SENDER_DOCUMENT}ADD_SIGNATURE_SUCCESS`,
  UPDATE_SIGNATURE: `${SENDER_DOCUMENT}UPDATE_SIGNATURE`,
  UPDATE_SIGNATURE_SUCCESS: `${SENDER_DOCUMENT}UPDATE_SIGNATURE_SUCCESS`,
  DELETE_SIGNATURE: `${SENDER_DOCUMENT}DELETE_SIGNATURE`,

  getSendersState: payload => ({
    type: marketingSendersActions.GET_SENDERS_STATE,
    payload,
  }),

  setSendersState: payload => ({
    type: marketingSendersActions.SET_SENDERS_STATE,
    payload,
  }),

  errorSendersState: payload => ({
    type: marketingSendersActions.ERROR_SENDERS_STATE,
    payload,
  }),

  getSenderRequest: id => ({
    type: marketingSendersActions.GET_SENDER_REQUEST,
    payload: { id },
  }),

  getSenderSuccess: sender => ({
    type: marketingSendersActions.GET_SENDER_SUCCESS,
    payload: { sender },
  }),

  getSenderError: error => ({
    type: marketingSendersActions.GET_SENDER_ERROR,
    payload: { error },
  }),

  changeView: (boxName, status) => ({
    type: marketingSendersActions.CHANGE_VIEW,
    payload: { boxName, status },
  }),

  addSender: payload => ({
    type: marketingSendersActions.ADD_SENDER,
    payload,
  }),

  addSenderSuccess: payload => ({
    type: marketingSendersActions.ADD_SENDER_SUCCESS,
    payload,
  }),

  updateSender: (id, sender) => ({
    type: marketingSendersActions.UPDATE_SENDER,
    payload: { id, sender },
  }),

  updateSenderSuccess: (sender) => ({
    type: marketingSendersActions.UPDATE_SENDER_SUCCESS,
    payload: sender,
  }),

  deleteSender: payload => ({
    type: marketingSendersActions.DELETE_SENDER,
    payload,
  }),

  addSignature: payload => ({
    type: marketingSendersActions.ADD_SIGNATURE,
    payload,
  }),

  addSignatureSuccess: payload => ({
    type: marketingSendersActions.ADD_SIGNATURE_SUCCESS,
    payload,
  }),

  updateSignature: (id, signature) => ({
    type: marketingSendersActions.UPDATE_SIGNATURE,
    payload: { id, signature },
  }),

  updateSignatureSuccess: (signature) => ({
    type: marketingSendersActions.UPDATE_SIGNATURE_SUCCESS,
    payload: signature,
  }),

  deleteSignature: payload => ({
    type: marketingSendersActions.DELETE_SIGNATURE,
    payload,
  }),

  toggleModal: () => ({
    type: marketingSendersActions.TOGGLE_MODAL,
  }),

  toggleSignatureModal: signature => ({
    type: marketingSendersActions.TOGGLE_SIGNATURE_MODAL,
    payload: { signature },
  }),
};

export default marketingSendersActions;
