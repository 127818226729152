import styled from 'styled-components';

export const LastModifiedDateWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 16px 12px 16px;
  width: 100%;
  background: #282c3a;
  gap: 10px;

  .text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto-Regular", sans-serif;
    color: #bcbcbc;
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
`;
