import resourcesByProjectActions from 'redux/resources/resourceByProject/actions';

const initState = {
  loading: false,
  isModalActive: false,
  isUpdate: false,
  resourceId: 0,
  fromProjectId:0,
  showDrawer: false,
};

export default function resourceByProjectReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    // MODAL

    case resourcesByProjectActions.SHOW_MODAL:
      return {
        ...state,
        ...payload,
        isModalActive: true,
      };

    case resourcesByProjectActions.HIDE_MODAL:
      return {
        ...state,
        isModalActive: false,
        isUpdate: false,
      };

    case resourcesByProjectActions.SHOW_DRAWER:
      return {
        ...state,
        showDrawer: true,
      };

    case resourcesByProjectActions.HIDE_DRAWER:
      return {
        ...state,
        showDrawer: false,
      };

    default:
      return state;
  }
}
