const DOCUMENT = 'FORGOT_PASS_';

const forgotPassActions = {
  FORGOT_PASS_REQUEST: `${DOCUMENT}REQUEST`,
  FORGOT_PASS_SUCCESS: `${DOCUMENT}SUCCESS`,
  FORGOT_PASS_ERROR: `${DOCUMENT}ERROR`,
  
  COMPLETE_RESET_PASSWORD_REQUEST: `${DOCUMENT}_COMPLETE_REQUEST`,
  COMPLETE_RESET_PASSWORD_REQUEST_SUCCESS: `${DOCUMENT}_COMPLETE_SUCCESS`,
  COMPLETE_RESET_PASSWORD_REQUEST_ERROR: `${DOCUMENT}_COMPLETE_ERROR`,

  forgotPassword: (payload) => ({
    type: forgotPassActions.FORGOT_PASS_REQUEST,
    payload,
  }),

  forgotPassSuccess: (payload) => ({
    type: forgotPassActions.FORGOT_PASS_SUCCESS,
    payload,
  }),

  forgotPassError: () => ({
    type: forgotPassActions.FORGOT_PASS_ERROR,
  }),

  completeResetPassword: (payload) => ({
    type: forgotPassActions.COMPLETE_RESET_PASSWORD_REQUEST,
    payload,
  }),
  completeResetPasswordSuccess: () => ({
    type: forgotPassActions.COMPLETE_RESET_PASSWORD_REQUEST_SUCCESS,
  }),
  completeResetPasswordError: (error) => ({
    type: forgotPassActions.COMPLETE_RESET_PASSWORD_REQUEST_ERROR,
    error,
  })
};

export default forgotPassActions;
