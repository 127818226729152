const DOCUMENT = 'MAILING_RECIPIENTS_';

const mailingRecipientsActions = {
  GET_MAILING_RECIPIENTS_REQUEST: `GET_${DOCUMENT}REQUEST`,
  GET_MAILING_RECIPIENTS_SUCCESS: `GET_${DOCUMENT}SUCCESS`,
  GET_MAILING_RECIPIENTS_ERROR: `GET_${DOCUMENT}ERROR`,

  GET_MAILING_RECIPIENTS_NEXT: `GET_${DOCUMENT}NEXT`,
  SET_MAILING_RECIPIENTS_NEXT: `SET_${DOCUMENT}NEXT`,

  GET_MAILING_RECIPIENTS_INITIAL_STATE: `GET_${DOCUMENT}INITIAL_STATE`,
  SET_MAILING_RECIPIENTS_INITIAL_STATE: `SET_${DOCUMENT}INITIAL_STATE`,

  GET_MAILING_RECIPIENTS_STATES: `GET_${DOCUMENT}STATES`,
  GET_MAILING_RECIPIENTS_CITIES: `GET_${DOCUMENT}CITIES`,

  DESELECT_ALL: `${DOCUMENT}DESELECT_ALL`,
  DESELECT_ALL_VISIBLE: `${DOCUMENT}DESELECT_ALL_VISIBLE`,
  SELECT_ALL_VISIBLE: `${DOCUMENT}SELECT_ALL_VISIBLE`,

  RESET_STATE: `${DOCUMENT}RESET_STATE`,

  CHANGE_RECIPIENT: `${DOCUMENT}CHANGE_RECIPIENT`,

  getMailingRecipientsInitialState: payload => ({
    type: mailingRecipientsActions.GET_MAILING_RECIPIENTS_INITIAL_STATE,
    payload
  }),

  setMailingRecipientsInitialState: payload => ({
    type: mailingRecipientsActions.SET_MAILING_RECIPIENTS_INITIAL_STATE,
    payload
  }),

  getMailingRecipientsRequest: (nextPage) => ({
    type: mailingRecipientsActions.GET_MAILING_RECIPIENTS_REQUEST,
    payload: {nextPage}
  }),

  getMailingRecipientsSuccess: payload => ({
    type: mailingRecipientsActions.GET_MAILING_RECIPIENTS_SUCCESS,
    payload
  }),


  getMailingRecipientsError: payload => ({
    type: mailingRecipientsActions.GET_MAILING_RECIPIENTS_ERROR,
    payload
  }),

  deselectAll: (id) => ({
    type: mailingRecipientsActions.DESELECT_ALL,
    payload: { id }
  }),

  deselectAllVisible: (id) => ({
    type: mailingRecipientsActions.DESELECT_ALL_VISIBLE,
    payload: { id }
  }),

  selectAllVisible: (recipients) => ({
    type: mailingRecipientsActions.SELECT_ALL_VISIBLE,
    payload: {recipients}
  }),

  resetState: () => ({
    type: mailingRecipientsActions.RESET_STATE,
  }),

  changeRecipient: (id, value) => ({
    type: mailingRecipientsActions.CHANGE_RECIPIENT,
    payload: { id, value }
  }),

  getMailingRecipientsNext: payload => ({
    type: mailingRecipientsActions.GET_MAILING_RECIPIENTS_NEXT,
    payload
  }),

  setMailingRecipientsNext: payload => ({
    type: mailingRecipientsActions.SET_MAILING_RECIPIENTS_NEXT,
    payload
  }),
};
export default mailingRecipientsActions;
