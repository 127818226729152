const vacationSettingsActions = {
  GET_VACATION_RECEIVERS_REQUEST: 'GET_VACATION_RECEIVERS_REQUEST',
  GET_VACATION_RECEIVERS_SUCCESS: 'GET_VACATION_RECEIVERS_SUCCESS',
  GET_VACATION_RECEIVERS_ERROR: 'GET_VACATION_RECEIVERS_ERROR',

  GET_VACATION_HOLIDAYS_REQUEST: 'GET_VACATION_HOLIDAYS_REQUEST',
  GET_VACATION_HOLIDAYS_SUCCESS: 'GET_VACATION_HOLIDAYS_SUCCESS',
  GET_VACATION_HOLIDAYS_ERROR: 'GET_VACATION_HOLIDAYS_ERROR',

  CREATE_VACATION_HOLIDAYS_REQUEST: 'CREATE_VACATION_HOLIDAYS_REQUEST',
  CREATE_VACATION_HOLIDAYS_SUCCESS: 'CREATE_VACATION_HOLIDAYS_SUCCESS',
  CREATE_VACATION_HOLIDAYS_ERROR: 'CREATE_VACATION_HOLIDAYS_ERROR',

  UPDATE_VACATION_HOLIDAYS_REQUEST: 'UPDATE_VACATION_HOLIDAYS_REQUEST',
  UPDATE_VACATION_HOLIDAYS_SUCCESS: 'UPDATE_VACATION_HOLIDAYS_SUCCESS',
  UPDATE_VACATION_HOLIDAYS_ERROR: 'UPDATE_VACATION_HOLIDAYS_ERROR',

  UPDATE_VACATION_RECEIVERS_REQUEST: 'UPDATE_VACATION_RECEIVERS_REQUEST',
  UPDATE_VACATION_RECEIVERS_SUCCESS: 'UPDATE_VACATION_RECEIVERS_SUCCESS',
  UPDATE_VACATION_RECEIVERS_ERROR: 'UPDATE_VACATION_RECEIVERS_ERROR',

  DELETE_VACATION_HOLIDAYS_REQUEST: 'DELETE_VACATION_HOLIDAYS_REQUEST',
  DELETE_VACATION_HOLIDAYS_SUCCESS: 'DELETE_VACATION_HOLIDAYS_SUCCESS',
  DELETE_VACATION_HOLIDAYS_ERROR: 'DELETE_VACATION_HOLIDAYS_ERROR',

  TOGGLE_EDIT_RECEIVERS: 'TOGGLE_EDIT_RECEIVERS',
  TOGGLE_EDIT_HOLIDAYS: 'TOGGLE_EDIT_HOLIDAYS',

  SET_RECEIVERS_SETTING_ID: 'SET_RECEIVERS_SETTING_ID',

  SET_EDITED_HOLIDAY: 'SET_EDITED_HOLIDAY',

  getVacationReceiversRequest: payload => ({
    type: vacationSettingsActions.GET_VACATION_RECEIVERS_REQUEST,
    payload,
  }),

  getVacationReceiversSuccess: (payload) => ({
    type: vacationSettingsActions.GET_VACATION_RECEIVERS_SUCCESS,
    payload,
  }),

  getVacationReceiversError: () => ({
    type: vacationSettingsActions.GET_VACATION_RECEIVERS_ERROR,
  }),

  getVacationHolidaysRequest: payload => ({
    type: vacationSettingsActions.GET_VACATION_HOLIDAYS_REQUEST,
    payload,
  }),

  getVacationHolidaysSuccess: payload => ({
    type: vacationSettingsActions.GET_VACATION_HOLIDAYS_SUCCESS,
    payload,
  }),

  getVacationHolidaysError: () => ({
    type: vacationSettingsActions.GET_VACATION_HOLIDAYS_ERROR,
  }),

  createVacationHolidaysRequest: (payload) => ({
    type: vacationSettingsActions.CREATE_VACATION_HOLIDAYS_REQUEST,
    payload,
  }),

  createVacationHolidaysSuccess: (payload) => ({
    type: vacationSettingsActions.CREATE_VACATION_HOLIDAYS_SUCCESS,
    payload,
  }),

  createVacationHolidaysError: () => ({
    type: vacationSettingsActions.CREATE_VACATION_HOLIDAYS_ERROR,
  }),

  updateVacationHolidaysRequest: (payload) => ({
    type: vacationSettingsActions.UPDATE_VACATION_HOLIDAYS_REQUEST,
    payload,
  }),

  updateVacationHolidaysSuccess: (payload) => ({
    type: vacationSettingsActions.UPDATE_VACATION_HOLIDAYS_SUCCESS,
    payload,
  }),

  updateVacationHolidaysError: () => ({
    type: vacationSettingsActions.UPDATE_VACATION_HOLIDAYS_ERROR,
  }),

  updateVacationReceiversRequest: (data) => ({
    type: vacationSettingsActions.UPDATE_VACATION_RECEIVERS_REQUEST,
    payload: {
      data,
    },
  }),

  updateVacationReceiversSuccess: (payload) => ({
    type: vacationSettingsActions.UPDATE_VACATION_RECEIVERS_SUCCESS,
    payload,
  }),

  updateVacationReceiversError: () => ({
    type: vacationSettingsActions.UPDATE_VACATION_RECEIVERS_ERROR,
  }),

  deleteVacationHolidaysRequest: (id) => ({
    type: vacationSettingsActions.DELETE_VACATION_HOLIDAYS_REQUEST,
    payload: {
      id,
    },
  }),

  deleteVacationHolidaysSuccess: payload => ({
    type: vacationSettingsActions.DELETE_VACATION_HOLIDAYS_SUCCESS,
    payload,
  }),

  deleteVacationHolidaysError: () => ({
    type: vacationSettingsActions.DELETE_VACATION_HOLIDAYS_ERROR,
  }),

  toggleEditReceivers: () => ({
    type: vacationSettingsActions.TOGGLE_EDIT_RECEIVERS,
  }),

  toggleEditHolidays: () => ({
    type: vacationSettingsActions.TOGGLE_EDIT_HOLIDAYS,
  }),

  setReceiversSettingId: payload => ({
    type: vacationSettingsActions.SET_RECEIVERS_SETTING_ID,
    payload,
  }),

  setEditedHoliday: payload => ({
    type: vacationSettingsActions.SET_EDITED_HOLIDAY,
    payload,
  }),
};
export default vacationSettingsActions;
