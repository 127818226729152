const DOCUMENT = 'RESOURCE_TIMELINE_';
const resourceTimelineActions = {
  GET_INITIAL_RESOURCE_TIMELINE_STATE: `GET_INITIAL_${DOCUMENT}STATE`,
  GET_INITIAL_RESOURCE_TIMELINE_STATE_SUCCESS: `GET_INITIAL_${DOCUMENT}STATE_SUCCESS`,
  GET_INITIAL_RESOURCE_TIMELINE_STATE_ERROR: `GET_INITIAL_${DOCUMENT}STATE_ERROR`,

  SET_INITIAL_RESOURCE_TIMELINE_STATE: `SET_INITIAL_${DOCUMENT}STATE`,

  GET_RESOURCE_TIMELINE_REQUEST: `GET_${DOCUMENT}REQUEST`,
  GET_RESOURCE_TIMELINE_SUCCESS: `GET_${DOCUMENT}SUCCESS`,
  GET_RESOURCE_TIMELINE_ERROR: `GET_${DOCUMENT}ERROR`,

  RESET_STATE: `${DOCUMENT}RESET_STATE`,

  getInitialResourceTimelineState: payload => ({
    type: resourceTimelineActions.GET_INITIAL_RESOURCE_TIMELINE_STATE,
    payload,
  }),

  getInitialResourceTimelineStateSuccess: (newState) => ({
    type: resourceTimelineActions.GET_INITIAL_RESOURCE_TIMELINE_STATE_SUCCESS,
    payload: {
      newState
    }
  }),

  getInitialResourceTimelineStateError: () => ({
    type: resourceTimelineActions.GET_INITIAL_RESOURCE_TIMELINE_STATE_ERROR
  }),

  setInitialResourceTimelineState: payload => ({
    type: resourceTimelineActions.SET_INITIAL_RESOURCE_TIMELINE_STATE,
    payload,
  }),

  /* get Resources */

  resourceTimelineRequest: (payload, ignoreSetUrl) => ({
    type: resourceTimelineActions.GET_RESOURCE_TIMELINE_REQUEST,
    payload,
    ignoreSetUrl
  }),

  resourceTimelineSuccess: (payload) => ({
    type: resourceTimelineActions.GET_RESOURCE_TIMELINE_SUCCESS,
    payload
  }),

  resourceTimelineError: error => ({
    type: resourceTimelineActions.GET_RESOURCE_TIMELINE_ERROR,
    payload: {
      error,
    },
  }),
	
  resetState: () => ({
    type: resourceTimelineActions.RESET_STATE,
  }),

};
export default resourceTimelineActions;
