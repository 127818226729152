import { all, takeEvery, put, fork } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import openNotification from 'components/Notification';
import { apiRequest } from 'redux/helpers';
import manageRolesAndClaimsActions from 'redux/manageRoleAccess/actions';
import { ENDPOINTS } from '../../consts';

export function* ManageRoleAndClaimsRequest() {
  yield takeEvery(manageRolesAndClaimsActions.GET_ROLES_AND_CLAIMS_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, '/roles/claims');

    if (data.errors) {
      yield put({
        type: manageRolesAndClaimsActions.GET_ROLES_AND_CLAIMS_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: manageRolesAndClaimsActions.GET_ROLES_AND_CLAIMS_SUCCESS,
        payload: {
          rolesAndClaims: data,
        },
      });
    }
  });
}

export function* handleRolesRequest() {
  yield takeEvery(manageRolesAndClaimsActions.GET_ROLES_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, '/roles');
    if (data.errors) {
      yield put({
        type: manageRolesAndClaimsActions.GET_ROLES_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: manageRolesAndClaimsActions.GET_ROLES_SUCCESS,
        payload: {
          roles: data,
        },
      });
    }
  });
}

export function* RemoveClaimsFromRoleRequest() {
  yield takeEvery(
    manageRolesAndClaimsActions.REMOVE_CLAIM_FROM_ROLE_REQUEST,
    function* ({ payload: { roleId, claimId}}) {
    const data = yield apiRequest(superFetch.patch, `/roles/${roleId}/removeClaim`, { claimId });
    if (data.errors) {
      yield put({
        type: manageRolesAndClaimsActions.REMOVE_CLAIM_FROM_ROLE_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: manageRolesAndClaimsActions.REMOVE_CLAIM_FROM_ROLE_SUCCESS,
        payload: {
          roleId,
          claimId,
        },
      });
    }
  });
}

export function* AddClaimToRoleRequest() {
  yield takeEvery(
    manageRolesAndClaimsActions.ADD_CLAIM_TO_REQUEST,
    function* ({
                 payload: { roleId, claimId },
               }) {
    const data = yield apiRequest(superFetch.patch, `/roles/${roleId}/addClaim`, { claimId });
    if (data.errors) {
      yield put({
        type: manageRolesAndClaimsActions.ADD_CLAIM_TO_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: manageRolesAndClaimsActions.ADD_CLAIM_TO_SUCCESS,
        payload: {
          roleId,
          claimId,
        },
      });
    }
  });
}


export function* CreateNewRoleRequest() {
  yield takeEvery(manageRolesAndClaimsActions.CREATE_NEW_ROLE_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.post, '/roles', payload);
    if (data.errors) {
      yield put({
        type: manageRolesAndClaimsActions.CREATE_NEW_ROLE_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: manageRolesAndClaimsActions.CREATE_NEW_ROLE_SUCCESS,
        payload: {
          newRole: data,
        },
      });
    }
  });
}

export function* RenameRoleRequest() {
  yield takeEvery(manageRolesAndClaimsActions.RENAME_ROLE_REQUEST, function* ({ payload }) {
    const data =
      yield apiRequest(superFetch.put, `/roles/${payload.roleId}`, { name: payload.name });
    if (data.errors) {
      yield put({
        type: manageRolesAndClaimsActions.RENAME_ROLE_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: manageRolesAndClaimsActions.RENAME_ROLE_SUCCESS,
        payload: {
          roleIdAndName: data,
        },
      });
    }
  });
}


export function* DeleteRoleRequest() {
  yield takeEvery(manageRolesAndClaimsActions.DELETE_ROLE_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.delete, `/roles/${payload.roleId}`);
    if (data.errors) {
      yield put({
        type: manageRolesAndClaimsActions.DELETE_ROLE_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: manageRolesAndClaimsActions.DELETE_ROLE_SUCCESS,
        payload: {
          roleId: payload.roleId,
        },
      });
    }
  });
}

export function* changeLocationBasedRequest() {
  yield takeEvery(manageRolesAndClaimsActions.CHANGE_LOCATION_BASED_REQUEST, function* ({ payload }) {
    const { id, isLocationBased } = payload;

    const data = yield apiRequest(superFetch.patch, `/claim/${id}/changeLocationBased`,
     { isLocationBased: isLocationBased });
     
    if (data.errors) {
      yield put({
        type: manageRolesAndClaimsActions.CHANGE_LOCATION_BASED_ERROR,
        payload: {
          message: 'Error',
        },
      });
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: manageRolesAndClaimsActions.CHANGE_LOCATION_BASED_SUCCESS,
        payload: {
          id,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(ManageRoleAndClaimsRequest),
    fork(RemoveClaimsFromRoleRequest),
    fork(AddClaimToRoleRequest),
    fork(CreateNewRoleRequest),
    fork(RenameRoleRequest),
    fork(DeleteRoleRequest),
    fork(handleRolesRequest),
    fork(changeLocationBasedRequest),
  ]);
};
