import { all, takeEvery, put, fork } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import { ENDPOINTS } from 'consts';
import { apiRequest } from 'redux/helpers';
import actions from 'redux/projectManagers/actions';
import openNotification from 'components/Notification';


export function* projectManagersRequest() {
  yield takeEvery(actions.GET_PROJECTMANAGERS_REQUEST, function*() {
    const data = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.RESOURCE_SIMPLE_LIST}?role=3,5`,
    );

    if (data.errors) {
      yield put(actions.getProjectManagersError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getProjectManagersSuccess(data.resources));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(projectManagersRequest),
  ]);
}
