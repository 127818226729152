import projectsOngoingActions from 'redux/projects/ongoingPage/actions';

const getInitState = () => ({
  isOngoingModalActive: false,
  selectedProject: {}
});

export default function projectsOngoingReducer(state = getInitState(), action) {
  const {type, payload} = action;
  switch (type) {

    case projectsOngoingActions.SHOW_MODAL:
      return {
        ...state,
        ...payload,
      };
    case projectsOngoingActions.RESET_STATE:
      return {
        ...getInitState()
      };
    default:
      return state;
  }
};
