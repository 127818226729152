import { CLAIM_TYPES } from 'consts';

const options = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    leftIcon: false,
    svg: 'dashboard',
    claim: 'VIEW_RESOURCE',
  },
  {
    key: 'documents',
    label: 'Documents',
    leftIcon: false,
    svg: 'documents',
    claim: 'VIEW_DOCUMENTS',
    children: [
      {
        key: 'view-documents',
        label: 'View documents',
        leftIcon: '',
        claim: 'VIEW_DOCUMENTS',
      },
      {
        key: 'edit-documents',
        label: 'Edit documents',
        leftIcon: '',
        claim: 'EDIT_DOCUMENTS',
      },
    ],
  },
  {
    key: 'resources',
    label: 'Resources',
    leftIcon: false,
    svg: 'resources',
    claim: 'VIEW_RESOURCE',
    children: [
      {
        key: 'resources',
        label: 'Resources List',
        leftIcon: 'ion-ios-people',
        claim: 'VIEW_RESOURCE',
      },
      {
        key: 'resources/projects',
        label: 'Resources by Project',
        leftIcon: 'ion-ios-people',
        claim: 'VIEW_RESOURCE_BY_PROJECT',
      },
      {
        key: 'resources/assignment-type',
        label: 'Resources by Assignment Type',
        leftIcon: 'ion-ios-people',
        claim: 'VIEW_RESOURCE_BY_ASSIGNMENT_TYPE',
      },
			{
        key: 'resources/planning',
        label: 'Resource Planning',
        leftIcon: 'ion-ios-people',
        claim: 'VIEW_RESOURCE_PLANNING',
      },
      {
        key: 'resources/history',
        label: 'Resources History Log',
        leftIcon: 'ion-ios-people',
        claim: 'VIEW_RESOURCE_PROJECT_HISTORY',
      },

      //added
      {
        key: 'resources/evaluation',
        label: 'Resources Evaluation',
        leftIcon: 'ion-ios-people',
        claim: 'VIEW_RESOURCE_EVALUATION',
      },
    ],
  },
  {
    key: 'projects_list',
    label: 'Projects',
    leftIcon: false,
    svg: 'projects',
    claim: 'VIEW_RESOURCE',
    children: [
      {
        key: 'projects',
        label: 'Projects List',
        leftIcon: 'ion-android-checkbox-outline',
        claim: 'VIEW_PROJECT',
      },
      {
        key: 'clients',
        label: 'Clients List',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_CLIENT',
      },
      {
        key: 'projects/ongoing',
        label: 'Ongoing Projects',
        leftIcon: 'ion-ios-person',
        claim: CLAIM_TYPES.VIEW_ONGOING_PROJECT,
      },
      {
        key: 'projects/timeline',
        label: 'Timeline Projects',
        leftIcon: 'ion-ios-person',
        claim: CLAIM_TYPES.VIEW_TIMELINE_PROJECT,
      },
      {
        key: 'projects/future',
        label: 'Discovery Projects',
        leftIcon: 'ion-ios-person',
        claim: CLAIM_TYPES.VIEW_FUTURE_PROJECT,
      },
      {
        key: 'projects/potential',
        label: 'Potential Projects',
        leftIcon: 'ion-ios-person',
        claim: CLAIM_TYPES.VIEW_FUTURE_PROJECT,
      },
    ],
  },
  {
    key: 'user_management',
    label: 'User management',
    leftIcon: false,
    svg: 'userManagement',
    claim: 'VIEW_RESOURCE',
    children: [
      {
        key: 'users',
        label: 'Users List',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_USER',
      },
      {
        key: 'roles',
        label: 'Roles',
        leftIcon: 'ion-android-checkbox-outline',
        claim: 'VIEW_ROLE',
      },
    ],
  },
  {
    key: 'settings_list',
    label: 'Settings',
    leftIcon: false,
    svg: 'settings',
    claim: 'VIEW_RESOURCE',
    children: [
      {
        key: 'settings/levels',
        label: 'Levels',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_LEVELS',
      },
      {
        key: 'settings/technologies',
        label: 'Technologies',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_TECHNOLOGY',
      },
      {
        key: 'settings/resource-roles',
        label: 'Resource Roles',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_RESOURCE_ROLE',
      },
      {
        key: 'settings/statuses',
        label: 'Statuses',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_PROJECT_STATUS',
      },
      {
        key: 'actionLogs',
        label: 'Action Logs',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_ACTION_LOGS',
      },
    ],
  },
  {
    key: 'marketing_campaign',
    label: 'Marketing',
    leftIcon: false,
    svg: 'marketing',
    claim: 'VIEW_EMAIL_TEMPLATE',
    children: [
      {
        key: 'marketing/senders/',
        label: 'Senders',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_AUTH_CREDENTIAL',
      },
      {
        key: 'marketing/groups/',
        label: 'Groups',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_GROUPS',
      },
      {
        key: 'marketing/recipients',
        label: 'Recipients',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_RECIPIENT',
      },
      {
        key: 'marketing/campaigns/',
        label: 'Campaigns',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_CAMPAIGN',
      },
      {
        key: 'marketing/email-templates/',
        label: 'Email Templates',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_EMAIL_TEMPLATE',
      },
      {
        key: 'marketing/mailings/',
        label: 'Mailings',
        leftIcon: 'ion-ios-person',
        claim: 'VIEW_MAILING',
      },
    ],
  },
  {
    key: 'vacations_list',
    label: 'Vacations',
    leftIcon: false,
    svg: 'calendar',
    claim: CLAIM_TYPES.VIEW_VACATION_PAGE,
    children: [
      {
        key: 'vacations',
        label: 'Vacation Calendar',
        leftIcon: false,
        svg: 'calendar',
        claim: CLAIM_TYPES.VIEW_VACATION_PAGE,
      },
      {
        key: 'vacations/settings/',
        label: 'Vacation Settings',
        leftIcon: false,
        svg: 'settings',
        claim: CLAIM_TYPES.VIEW_VACATION_SETTINGS,
      },
    ],
  },
];
export default options;
