import { call, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import openNotification from 'components/Notification';
import authActions from 'redux/auth/actions';

const xlsxContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export default function* apiRequest(fn, ...args) {
  const response = yield call(fn, ...args);

  if (response.errors) {
    yield put(push('/500'));
    return response;
  }
  switch (response.status) {
    case 401:
      yield put(authActions.logOutPrevUser());
      break;
    case 403:
      break;
    case 500:
      openNotification('error', 'Error', response.statusText);
      break;
    default:
      break;
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return yield call([response, response.json]);
  }
  if (contentType && contentType.indexOf(xlsxContent) !== -1) {
    return yield call([response, response.blob]);
  }
  return yield call([response, response.text]);
}

export function simpleRequest(fun, ...args) {
  const response = fun(...args);
  response.then((res) => {
    if (res.status >= 200 && res.status < 300) {  
      return Promise.resolve(res);
    } 
      return Promise.reject(new Error(res.statusText));
    })

  return response;
}