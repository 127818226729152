import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import { apiRequest } from 'redux/helpers';
import superFetch from 'helpers/superFetch';
import actions from 'redux/vacation/actions';
import moment from 'moment';
import { createQueryString } from 'helpers/utility';

import openNotification from 'components/Notification';
import { ENDPOINTS, DATE_FORMAT, VACATION_DATA_FORMAT } from 'consts';
import { setUrl } from 'helpers/urlSync';

export function* addVacationRequest() {
  yield takeEvery(actions.ADD_VACATION_REQUEST, function*({ payload }) {
    const data = yield apiRequest(superFetch.post, ENDPOINTS.VACATION, payload);

    if (data.errors) {
      yield put(actions.addVacationError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.addVacationSuccess(data));
      yield put(actions.getVacationsRequest());
    }
  });
}

export function* updateVacation() {
  yield takeEvery(actions.UPDATE_VACATION_REQUEST, function*({ payload }) {
    const { id } = payload;

    delete payload.createdByUserId;
    delete payload.createdAt;

    const data = yield apiRequest(superFetch.put, `${ENDPOINTS.VACATION}/${id}`, payload);
    const { openModalVacationId } = yield select(state => state.VacationReducer);

    if (data.errors) {
      yield put(actions.updateVacationError(data.errors));
      yield openNotification('error', 'Error', data.errors);
      if (openModalVacationId) {
        yield put(actions.showModal());
      }
    } else {
      yield put(actions.updateVacationSuccess());
      yield put(actions.getVacationsRequest());
      if (openModalVacationId) {
        yield put(actions.setStartRedirect(true));
      }
    }
  });
}

export function* getVacations() {
  yield takeEvery(actions.GET_VACATIONS_REQUEST, function*() {
    const { monthRange } = yield select(state => state.VacationReducer);
    const monthRangeOrNull = monthRange || moment().format('YYYY-MM-DD');

    const { searchValue } = yield select(state => state.tableReducer);
    const month = !searchValue.name ? { month: monthRangeOrNull } : {};

    const queryUrl = `${ENDPOINTS.VACATION}?${createQueryString({
      fields: { ...month, ...searchValue },
    })}`;
    const data = yield apiRequest(superFetch.get, queryUrl);
    setUrl(queryUrl);

    const vacations = data.map(vacation => {
      return {
        ...vacation,
        allDay: true,
        title: vacation.name,
        start: moment(vacation.start)
          .startOf('day')
          .format('DD MMM YYYY HH:mm'),
        end: moment(vacation.end)
          .endOf('day')
          .format('DD MMM YYYY HH:mm'),
      };
    });

    if (data.errors) {
      yield put(actions.getVacationsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getVacationsSuccess(vacations));
    }
  });
}

export function* deleteVacation() {
  yield takeEvery(actions.DELETE_VACATION_REQUEST, function*({ payload }) {
    const { vacationId } = payload;
    const data = yield apiRequest(superFetch.delete, `${ENDPOINTS.VACATION}/${vacationId}`);
    const { openModalVacationId } = yield select(state => state.VacationReducer);

    if (data.errors) {
      yield put(actions.deleteVacationError(data.errors));
      yield openNotification('error', 'Error', data.errors);
      if (openModalVacationId) {
        yield put(actions.showModal());
      }
    } else {
      yield put(actions.deleteVacationSuccess(data));
      yield put(actions.getVacationsRequest());
      if (openModalVacationId) {
        yield put(actions.setStartRedirect(true));
      }
    }
  });
}

export function* calculateVacation() {
  yield takeEvery(actions.CALCULATE_VACATION_REQUEST, function*({ payload }) {
    const { startDate, endDate, resourceId } = payload;
    const query = createQueryString({
      fields: {
        start: startDate.format(DATE_FORMAT.SIMPLE_DATE),
        end: endDate.format(DATE_FORMAT.SIMPLE_DATE),
        resourceId: resourceId || [],
      },
    });
    const data = yield apiRequest(superFetch.get, `${ENDPOINTS.VACATION_CALULATE_DAYS}?${query}`);
    if (data.errors) {
      yield put(actions.calculateVacationError());
    } else {
      yield put(actions.calculateVacationSuccess(data));
    }
  });
}

export function* vacationWarning() {
  yield takeEvery(actions.VACATION_WARNING_REQUEST, function*({ payload }) {
    const { startDate, endDate, resourceId, typeVacation, vacationId } = payload;

    const query = createQueryString({
      fields: {
        start: startDate.format(DATE_FORMAT.SIMPLE_DATE),
        end: endDate.format(DATE_FORMAT.SIMPLE_DATE),
        resourceId: resourceId || [],
        type: typeVacation,
        ...(vacationId && { vacationId })
      },
    });
    const data = yield apiRequest(superFetch.get, `${ENDPOINTS.VACATION_WARNING}?${query}`);

    if (data.errors) {
      yield put(actions.vacationWarningError());
    } else {
      yield put(actions.vacationWarningSuccess(data));
    }
  });
}

export function* getVacationCategories() {
  yield takeEvery(actions.GET_VACATION_CATEGORIES_REQUEST, function*() {

    const data = yield apiRequest(superFetch.get, `${ENDPOINTS.VACATION_CATEGORIES}`);
    if (data.errors) {
      yield put(actions.getVacationCategoriesError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getVacationCategoriesSuccess(data));
    }
  });
}

export function* getVacationStatuses() {
  yield takeEvery(actions.GET_VACATION_STATUS_REQUEST, function*() {
    const data = yield apiRequest(superFetch.get, ENDPOINTS.VACATION_STATUS);

    if (data.errors) {
      yield put(actions.getVacationStatusesError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getVacationStatusesSuccess(data));
    }
  });
}

export function* setOpenModal() {
  yield takeEvery(actions.SET_OPEN_MODAL_VACATION_ID, function*({ payload }) {
    const data = yield apiRequest(superFetch.get, `${ENDPOINTS.VACATION}/${payload}`);
    if (data.errors) {
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.setCurrentEditVacation(data));
      yield put(actions.showModal());
      yield put(actions.setRangeMonth(moment(data.start).format(VACATION_DATA_FORMAT)));
      yield put(actions.getVacationsRequest());
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addVacationRequest),
    fork(updateVacation),
    fork(getVacations),
    fork(deleteVacation),
    fork(getVacationStatuses),
    fork(calculateVacation),
    fork(setOpenModal),
    fork(getVacationCategories),
    fork(vacationWarning),
  ]);
}
