import { ConfigProvider } from 'antd';
import React from 'react';
import { Provider } from 'react-redux';
import { history, store } from 'redux/store';
import { ThemeProvider } from 'styled-components';

import DashAppHolder from './dashAppStyle';
import PublicRoutes from './router';
import { themeConfig } from './settings';
import themes from './settings/themes';

const DashApp = () => (
  <ConfigProvider>
    <ThemeProvider theme={themes[themeConfig.theme]}>
      <DashAppHolder>
        <Provider store={store}>
          <PublicRoutes history={history} />
        </Provider>
      </DashAppHolder>
    </ThemeProvider>
  </ConfigProvider>
);

export default DashApp;
