const DOCUMENT = 'PROJECT_';

const actions = {
  GET_PROJECT_REQUEST: `GET_${DOCUMENT}REQUEST`,
  GET_PROJECT_SUCCESS: `GET_${DOCUMENT}SUCCESS`,
  GET_PROJECT_ERROR: `GET_${DOCUMENT}ERROR`,
  UPDATE_PROJECT_REQUEST: `UPDATE_${DOCUMENT}REQUEST`,
  UPDATE_PROJECT_SUCCESS: `UPDATE_${DOCUMENT}SUCCESS`,
  UPDATE_PROJECT_WITHOUT_FORM_SUCCESS: `UPDATE_${DOCUMENT}WITHOUT_FORMSUCCESS`,
  UPDATE_PROJECT_ERROR: `UPDATE_${DOCUMENT}ERROR`,
  UPLOAD_AVATAR: `${DOCUMENT}UPLOAD_AVATAR`,
  UPLOAD_AVATAR_SUCCESS: `${DOCUMENT}UPLOAD_AVATAR_SUCCESS`,
  UPLOAD_AVATAR_ERROR: `${DOCUMENT}UPLOAD_AVATAR_ERROR`,
  ADD_TECHNOLOGY_REQUEST: `${DOCUMENT}ADD_TECHNOLOGY_REQUEST`,
  ADD_TECHNOLOGY_SUCCESS: `${DOCUMENT}ADD_TECHNOLOGY_SUCCESS`,
  ADD_TECHNOLOGY_ERROR: `${DOCUMENT}ADD_TECHNOLOGY_ERROR`,
  REMOVE_TECHNOLOGY_REQUEST: `${DOCUMENT}REMOVE_TECHNOLOGY_REQUEST`,
  REMOVE_TECHNOLOGY_SUCCESS: `${DOCUMENT}REMOVE_TECHNOLOGY_SUCCESS`,
  REMOVE_TECHNOLOGY_ERROR: `${DOCUMENT}REMOVE_TECHNOLOGY_ERROR`,
  ADD_RESOURCE_REQUEST: `${DOCUMENT}ADD_RESOURCE_REQUEST`,
  ADD_RESOURCE_SUCCESS: `${DOCUMENT}ADD_RESOURCE_SUCCESS`,
  ADD_RESOURCE_ERROR: `${DOCUMENT}ADD_RESOURCE_ERROR`,
  UPDATE_RESOURCE_REQUEST: `${DOCUMENT}UPDATE_RESOURCE_REQUEST`,
  UPDATE_RESOURCE_SUCCESS: `${DOCUMENT}UPDATE_RESOURCE_SUCCESS`,
  UPDATE_RESOURCE_ERROR: `${DOCUMENT}UPDATE_RESOURCE_ERROR`,
  REMOVE_RESOURCE_REQUEST: `${DOCUMENT}REMOVE_RESOURCE_REQUEST`,
  REMOVE_RESOURCE_SUCCESS: `${DOCUMENT}REMOVE_RESOURCE_SUCCESS`,
  REMOVE_RESOURCE_ERROR: `${DOCUMENT}REMOVE_RESOURCE_ERROR`,

  CLOSE_PROJECT_REQUEST: `${DOCUMENT}CLOSE_PROJECT_REQUEST`,

  CHANGE_VIEW: `${DOCUMENT}CHANGE_VIEW`,
  RESET_FILE_STATE: "RESET_FILE_STATE",
  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,

  GET_PROJECT_HISTORY_REQUEST: `GET_PROJECT_HISTORY_REQUEST`,
  GET_PROJECT_HISTORY_SUCCESS: `GET_PROJECT_HISTORY_SUCCESS`,
  GET_PROJECT_HISTORY_ERROR: `GET_PROJECT_HISTORY_ERROR`,

  CREATE_PROJECT_HISTORY_REQUEST: `CREATE_PROJECT_HISTORY_REQUEST`,
  CREATE_PROJECT_HISTORY_SUCCESS: `CREATE_PROJECT_HISTORY_SUCCESS`,
  CREATE_PROJECT_HISTORY_ERROR: `CREATE_PROJECT_HISTORY_ERROR`,

  DELETE_PROJECT_HISTORY_REQUEST: `DELETE_PROJECT_HISTORY_REQUEST`,
  DELETE_PROJECT_HISTORY_SUCCESS: `DELETE_PROJECT_HISTORY_SUCCESS`,
  DELETE_PROJECT_HISTORY_ERROR: `DELETE_PROJECT_HISTORY_ERROR`,

  UPDATE_PROJECT_HISTORY_REQUEST: `UPDATE_PROJECT_HISTORY_REQUEST`,
  UPDATE_PROJECT_HISTORY_SUCCESS: `UPDATE_PROJECT_HISTORY_SUCCESS`,
  UPDATE_PROJECT_HISTORY_ERROR: `UPDATE_PROJECT_HISTORY_ERROR`,

  getProjectHistoryRequest(payload) {
    return {
      type: actions.GET_PROJECT_HISTORY_REQUEST,
      payload
    };
  },
  getProjectHistorySuccess(payload) {
    return {
      type: actions.GET_PROJECT_HISTORY_SUCCESS,
      payload,
    };
  },
  getProjectHistoryError(payload) {
    return {
      type: actions.GET_PROJECT_HISTORY_ERROR,
      payload,
    };
  },

  createProjectHistoryRequest: (payload) => ({
    type: actions.CREATE_PROJECT_HISTORY_REQUEST,
    payload,
  }),
  createProjectHistorySuccess: (payload) => ({
    type: actions.CREATE_PROJECT_HISTORY_SUCCESS,
    payload,
  }),
  createProjectHistoryError: (payload) => ({
    type: actions.CREATE_PROJECT_HISTORY_ERROR,
    payload,
  }),

  deleteProjectHistoryRequest: (projectHistoryId) => ({
    type: actions.DELETE_PROJECT_HISTORY_REQUEST,
    payload: { projectHistoryId },
  }),
  deleteProjectHistorySuccess: (projectHistoryId) => ({
    type: actions.DELETE_PROJECT_HISTORY_SUCCESS,
    payload: { projectHistoryId },
  }),
  deleteProjectHistoryError: (payload) => ({
    type: actions.DELETE_PROJECT_HISTORY_ERROR,
    payload,
  }),

  updateProjectHistoryRequest: (value, columnsKey, record) => ({
    type: actions.UPDATE_PROJECT_HISTORY_REQUEST,
    payload: {value, columnsKey, record},
  }),
  updateProjectHistorySuccess: payload => ({
    type: actions.UPDATE_PROJECT_HISTORY_SUCCESS,
    payload,
  }),
  updateProjectHistoryError: payload => ({
    type: actions.UPDATE_PROJECT_HISTORY_ERROR,
    payload,
  }),

  getProjectRequest: id => ({
    type: actions.GET_PROJECT_REQUEST,
    payload: { id },
  }),
  getProjectSuccess: project => ({
    type: actions.GET_PROJECT_SUCCESS,
    payload: { project },
  }),
  getProjectError: error => ({
    type: actions.GET_PROJECT_ERROR,
    payload: { error },
  }),
  updateProjectRequest: (formData, formName, realodProjectList, pageName) => ({
    type: actions.UPDATE_PROJECT_REQUEST,
    payload: { formData, formName, realodProjectList, pageName },
  }),
  updateProjectSuccess: (project, formName) => ({
    type: actions.UPDATE_PROJECT_SUCCESS,
    payload: { project, formName },
  }),
  updateProjectSuccessWithoutForm: (project) => ({
    type: actions.UPDATE_PROJECT_WITHOUT_FORM_SUCCESS,
    payload: { project },
  }),
  updateProjectError: error => ({
    type: actions.UPDATE_PROJECT_ERROR,
    payload: { error },
  }),
  uploadAvatar: request => ({
    type: actions.UPLOAD_AVATAR,
    payload: {
      ...request
    },
  }),
  uploadAvatarSuccess: data => ({
    type: actions.UPLOAD_AVATAR_SUCCESS,
    payload: {
      ...data
    },
  }),
  uploadAvatarError: error => ({
    type: actions.UPLOAD_AVATAR_ERROR,
    payload: {
      error
    },
  }),
  addTechnologyRequest: (data, projectId) => ({
    type: actions.ADD_TECHNOLOGY_REQUEST,
    payload: { data, projectId },
  }),
  addTechnologySuccess: technologies => ({
    type: actions.ADD_TECHNOLOGY_SUCCESS,
    payload: { technologies },
  }),
  addTechnologyError: error => ({
    type: actions.ADD_TECHNOLOGY_ERROR,
    payload: { error },
  }),
  removeTechnologyRequest: (data, projectId) => ({
    type: actions.REMOVE_TECHNOLOGY_REQUEST,
    payload: { data, projectId },
  }),
  removeTechnologySuccess: technologies => ({
    type: actions.REMOVE_TECHNOLOGY_SUCCESS,
    payload: { technologies },
  }),
  removeTechnologyError: error => ({
    type: actions.REMOVE_TECHNOLOGY_ERROR,
    payload: { error },
  }),
  addResourceRequest: (data, projectId) => ({
    type: actions.ADD_RESOURCE_REQUEST,
    payload: { data, projectId },
  }),
  addResourceSuccess: resources => ({
    type: actions.ADD_RESOURCE_SUCCESS,
    payload: { resources },
  }),
  addResourceError: error => ({
    type: actions.ADD_RESOURCE_ERROR,
    payload: { error },
  }),
  updatePmRequest: (data) => ({
    type: actions.UPDATE_PROJECT_REQUEST,
    payload: { formData: data.formData, formName: data.formName },
  }),
  updatePmSuccess: resources => ({
    type: actions.UPDATE_PROJECT_SUCCESS,
    payload: { resources },
  }),
  updatePmError: error => ({
    type: actions.UPDATE_PROJECT_ERROR,
    payload: { error },
  }),
  updateResourceRequest: (projectId, data ) => ({
    type: actions.UPDATE_RESOURCE_REQUEST,
    payload: { projectId,  data  },
  }),
  updateResourceSuccess: resources => ({
    type: actions.UPDATE_RESOURCE_SUCCESS,
    payload: { resources },
  }),
  updateResourceError: error => ({
    type: actions.UPDATE_RESOURCE_ERROR,
    payload: { error },
  }),
  removeResourceRequest: (data, projectId) => ({
    type: actions.REMOVE_RESOURCE_REQUEST,
    payload: { data, projectId },
  }),
  removeResourceSuccess: resources => ({
    type: actions.REMOVE_RESOURCE_SUCCESS,
    payload: { resources },
  }),
  removeResourceError: error => ({
    type: actions.REMOVE_RESOURCE_ERROR,
    payload: { error },
  }),

  changeView: (formName, status) => ({
    type: actions.CHANGE_VIEW,
    payload: { formName, status },
  }),

  resetFile: () => ({
    type: actions.RESET_FILE_STATE,
  }),

  toggleModal: () => ({
    type: actions.TOGGLE_MODAL,
  }),

  closeProjectRequest: (payload) => ({
    type: actions.CLOSE_PROJECT_REQUEST,
    payload
  }),
};

export default actions;
