
const actions = {
  GET_TECH_LEVELS_REQUEST: 'GET_TECH_LEVELS_REQUEST',
  GET_TECH_LEVELS_SUCCESS: 'GET_TECH_LEVELS_SUCCESS',
  GET_TECH_LEVELS_ERROR: 'GET_TECH_LEVELS_ERROR',
  GET_ENGLISH_LEVELS_REQUEST: 'GET_ENGLISH_LEVELS_REQUEST',
  GET_ENGLISH_LEVELS_SUCCESS: 'GET_ENGLISH_LEVELS_SUCCESS',
  GET_ENGLISH_LEVELS_ERROR: 'GET_ENGLISH_LEVELS_ERROR',
  GET_LEVELS_REQUEST: 'GET_LEVELS_REQUEST',
  GET_LEVELS_SUCCESS: 'GET_LEVELS_SUCCESS',
  GET_LEVELS_ERROR: 'GET_LEVELS_ERROR',
  ADD_TECH_LEVEL_REQUEST: 'ADD_TECH_LEVEL_REQUEST',
  ADD_TECH_LEVEL_SUCCESS: 'ADD_TECH_LEVEL_SUCCESS',
  ADD_TECH_LEVEL_ERROR: 'ADD_TECH_LEVEL_ERROR',
  ADD_ENGLISH_LEVEL_REQUEST: 'ADD_ENGLISH_LEVEL_REQUEST',
  ADD_ENGLISH_LEVEL_SUCCESS: 'ADD_ENGLISH_LEVEL_SUCCESS',
  ADD_ENGLISH_LEVEL_ERROR: 'ADD_ENGLISH_LEVEL_ERROR',
  UPDATE_TECH_LEVEL_REQUEST: 'UPDATE_TECH_LEVEL_REQUEST',
  UPDATE_TECH_LEVEL_SUCCESS: 'UPDATE_TECH_LEVEL_SUCCESS',
  UPDATE_TECH_LEVEL_ERROR: 'UPDATE_TECH_LEVEL_ERROR',
  UPDATE_ENGLISH_LEVEL_REQUEST: 'UPDATE_ENGLISH_LEVEL_REQUEST',
  UPDATE_ENGLISH_LEVEL_SUCCESS: 'UPDATE_ENGLISH_LEVEL_SUCCESS',
  UPDATE_ENGLISH_LEVEL_ERROR: 'UPDATE_ENGLISH_LEVEL_ERROR',
  DELETE_TECH_LEVEL_REQUEST: 'DELETE_TECH_LEVEL_REQUEST',
  DELETE_TECH_LEVEL_SUCCESS: 'DELETE_TECH_LEVEL_SUCCESS',
  DELETE_TECH_LEVEL_ERROR: 'DELETE_TECH_LEVEL_ERROR',
  DELETE_ENGLISH_LEVEL_REQUEST: 'DELETE_ENGLISH_LEVEL_REQUEST',
  DELETE_ENGLISH_LEVEL_SUCCESS: 'DELETE_ENGLISH_LEVEL_SUCCESS',
  DELETE_ENGLISH_LEVEL_ERROR: 'DELETE_ENGLISH_LEVEL_ERROR',

  TOGGLE_TECH_MODAL: `LEVELS_TOGGLE_TECH_MODAL`,
  TOGGLE_ENGLISH_MODAL: `LEVELS_TOGGLE_ENGLISH_MODAL`,

  getLevelsRequest: () => ({
    type: actions.GET_LEVELS_REQUEST
  }),
  getLevelsSuccess: (techLevels, englishLevels) => ({
    type: actions.GET_LEVELS_SUCCESS,
    payload: { techLevels, englishLevels }
  }),
  getLevelsError: () => ({
    type: actions.GET_LEVELS_ERROR,
  }),
  getTechLevelsRequest: () => ({
    type: actions.GET_TECH_LEVELS_REQUEST,
  }),
  getTechLevelsSuccess: techLevels => ({
    type: actions.GET_TECH_LEVELS_SUCCESS,
    payload: { techLevels },
  }),
  getTechLevelsError: error => ({
    type: actions.GET_TECH_LEVELS_ERROR,
    payload: { error },
  }),
  getEnglishLevelsRequest: () => ({
    type: actions.GET_ENGLISH_LEVELS_REQUEST,
  }),
  getEnglishLevelsSuccess: englishLevels => ({
    type: actions.GET_ENGLISH_LEVELS_SUCCESS,
    payload: { englishLevels },
  }),
  getEnglishLevelsError: error => ({
    type: actions.GET_ENGLISH_LEVELS_ERROR,
    payload: { error },
  }),
  addTechLevelRequest: level => ({
    type: actions.ADD_TECH_LEVEL_REQUEST,
    payload: {
      value: level.techLevel,
    },
  }),
  addTechLevelSuccess: level => ({
    type: actions.ADD_TECH_LEVEL_SUCCESS,
    payload: { level },
  }),
  addTechLevelError: error => ({
    type: actions.ADD_TECH_LEVEL_ERROR,
    payload: { error },
  }),
  addEnglishLevelRequest: level => ({
    type: actions.ADD_ENGLISH_LEVEL_REQUEST,
    payload: {
      value: level.englishLevel,
    },
  }),
  addEnglishLevelSuccess: level => ({
    type: actions.ADD_ENGLISH_LEVEL_SUCCESS,
    payload: { level },
  }),
  addEnglishLevelError: error => ({
    type: actions.ADD_ENGLISH_LEVEL_ERROR,
    payload: { error },
  }),
  updateTechLevelRequest: (newValue, level) => ({
    type: actions.UPDATE_TECH_LEVEL_REQUEST,
    payload: {
      value: newValue,
      techLevelId: level.id,
    },
  }),
  updateTechLevelSuccess: level => ({
    type: actions.UPDATE_TECH_LEVEL_SUCCESS,
    payload: { level },
  }),
  updateTechLevelError: error => ({
    type: actions.UPDATE_TECH_LEVEL_ERROR,
    payload: { error },
  }),
  updateEnglishLevelRequest: (newValue, level) => ({
    type: actions.UPDATE_ENGLISH_LEVEL_REQUEST,
    payload: {
      value: newValue,
      englishLevelId: level.id,
    },
  }),
  updateEnglishLevelSuccess: level => ({
    type: actions.UPDATE_ENGLISH_LEVEL_SUCCESS,
    payload: { level },
  }),
  updateEnglishLevelError: error => ({
    type: actions.UPDATE_ENGLISH_LEVEL_ERROR,
    payload: { error },
  }),
  deleteTechLevelRequest: techLevelId => ({
    type: actions.DELETE_TECH_LEVEL_REQUEST,
    payload: { techLevelId },
  }),
  deleteTechLevelSuccess: techLevelId => ({
    type: actions.DELETE_TECH_LEVEL_SUCCESS,
    payload: { techLevelId },
  }),
  deleteTechLevelError: error => ({
    type: actions.DELETE_TECH_LEVEL_ERROR,
    payload: { error },
  }),
  deleteEnglishLevelRequest: englishLevelId => ({
    type: actions.DELETE_ENGLISH_LEVEL_REQUEST,
    payload: { englishLevelId },
  }),
  deleteEnglishLevelSuccess: englishLevelId => ({
    type: actions.DELETE_ENGLISH_LEVEL_SUCCESS,
    payload: { englishLevelId },
  }),
  deleteEnglishLevelError: error => ({
    type: actions.DELETE_ENGLISH_LEVEL_ERROR,
    payload: { error },
  }),

  toggleTechModal: () => ({
    type: actions.TOGGLE_TECH_MODAL,
  }),
  toggleEnglishModal: () => ({
    type: actions.TOGGLE_ENGLISH_MODAL,
  }),
};

export default actions;
