import { all, takeEvery, put, fork } from 'redux-saga/effects';
import superFetch from 'helpers/superFetch';
import openNotification from 'components/Notification';
import actions from 'redux/levels/actions';
import { apiRequest } from 'redux/helpers';
import { ENDPOINTS } from 'consts';

export function* getLevelsRequest() {
  yield takeEvery(actions.GET_LEVELS_REQUEST, function* () {
    const techLevels = yield apiRequest(superFetch.get, ENDPOINTS.TECH_LVL);
    const englishLevels = yield apiRequest(superFetch.get, ENDPOINTS.ENG_LVL);
    if (techLevels.errors || englishLevels.errors) {
      const errors = techLevels.errors || englishLevels.errors;
      yield put(actions.getLevelsError(errors));
      yield openNotification('error', 'Error', errors);
    } else {
      yield put(actions.getLevelsSuccess(techLevels, englishLevels));
    }
  });
}

export function* techLevelsRequest() {
  yield takeEvery(actions.GET_TECH_LEVELS_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, ENDPOINTS.TECH_LVL);

    if (data.errors) {
      yield put(actions.getTechLevelsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getTechLevelsSuccess(data));
    }
  });
}

export function* englishLevelsRequest() {
  yield takeEvery(actions.GET_ENGLISH_LEVELS_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, ENDPOINTS.ENG_LVL);
    if (data.errors) {
      yield put(actions.getEnglishLevelsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.getEnglishLevelsSuccess(data));
    }
  });
}

export function* addTechLevelRequest() {
  yield takeEvery(actions.ADD_TECH_LEVEL_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.post, ENDPOINTS.TECH_LVL, payload);
    if (data.errors) {
      yield put(actions.addTechLevelError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.addTechLevelSuccess(data));
    }
  });
}

export function* addEnglishLevelRequest() {
  yield takeEvery(actions.ADD_ENGLISH_LEVEL_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.post, ENDPOINTS.ENG_LVL, payload);
    if (data.errors) {
      yield put(actions.addEnglishLevelError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.addEnglishLevelSuccess(data));
    }
  });
}

export function* updateTechLevelRequest() {
  yield takeEvery(actions.UPDATE_TECH_LEVEL_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.TECH_LVL}/${payload.techLevelId}`,
      payload,
    );
    if (data.errors) {
      yield put(actions.updateTechLevelError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.updateTechLevelSuccess(data));
    }
  });
}

export function* updateEnglishLevelRequest() {
  yield takeEvery(actions.UPDATE_ENGLISH_LEVEL_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.put,
      `${ENDPOINTS.ENG_LVL}/${payload.englishLevelId}`,
      payload,
    );
    if (data.errors) {
      yield put(actions.updateEnglishLevelError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.updateEnglishLevelSuccess(data));
    }
  });
}

export function* deleteTechLevelRequest() {
  yield takeEvery(actions.DELETE_TECH_LEVEL_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.TECH_LVL}/${payload.techLevelId}`,
      payload,
    );
    if (data.errors) {
      yield put(actions.deleteTechLevelError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.deleteTechLevelSuccess(payload.techLevelId));
    }
  });
}

export function* deleteEnglishLevelRequest() {
  yield takeEvery(actions.DELETE_ENGLISH_LEVEL_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.ENG_LVL}/${payload.englishLevelId}`,
      payload,
    );
    if (data.errors) {
      yield put(actions.deleteEnglishLevelError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(actions.deleteEnglishLevelSuccess(payload.englishLevelId));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getLevelsRequest),
    fork(techLevelsRequest),
    fork(englishLevelsRequest),
    fork(addTechLevelRequest),
    fork(addEnglishLevelRequest),
    fork(updateTechLevelRequest),
    fork(updateEnglishLevelRequest),
    fork(deleteTechLevelRequest),
    fork(deleteEnglishLevelRequest),
  ]);
}
