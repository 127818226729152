import actions from 'redux/levels/actions';

const initState = {
  isTechModalActive: false,
  isEnglishModalActive: false,
  loading: false,
  techLevels: [],
  englishLevels: [],
};

export default function LevelsReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_TECH_LEVELS_REQUEST:
    case actions.GET_LEVELS_REQUEST:
    case actions.ADD_TECH_LEVEL_REQUEST:
    case actions.GET_ENGLISH_LEVELS_REQUEST:
    case actions.ADD_ENGLISH_LEVEL_REQUEST:
    case actions.UPDATE_TECH_LEVEL_REQUEST:
    case actions.UPDATE_ENGLISH_LEVEL_REQUEST:
    case actions.DELETE_ENGLISH_LEVEL_REQUEST:
    case actions.DELETE_TECH_LEVEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TECH_LEVELS_ERROR:
    case actions.GET_ENGLISH_LEVELS_ERROR:
    case actions.ADD_TECH_LEVEL_ERROR:
    case actions.ADD_ENGLISH_LEVEL_ERROR:
    case actions.UPDATE_TECH_LEVEL_ERROR:
    case actions.DELETE_ENGLISH_LEVEL_ERROR:
    case actions.DELETE_TECH_LEVEL_ERROR:
    case actions.UPDATE_ENGLISH_LEVEL_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.GET_LEVELS_SUCCESS: 
      return {
        ...state,
        techLevels: action.payload.techLevels,
        englishLevels: action.payload.englishLevels,
        loading: false,
      }
    case actions.GET_TECH_LEVELS_SUCCESS:
      return {
        ...state,
        techLevels: action.payload.techLevels,
        loading: false,
      };
    case actions.GET_ENGLISH_LEVELS_SUCCESS:
      return {
        ...state,
        englishLevels: action.payload.englishLevels,
        loading: false,
      };
    case actions.ADD_TECH_LEVEL_SUCCESS:
      return {
        ...state,
        techLevels: [
          ...state.techLevels,
          action.payload.level,
        ],
        isTechModalActive: false,
        loading: false,
      };
    case actions.ADD_ENGLISH_LEVEL_SUCCESS:
      return {
        ...state,
        englishLevels: [
          ...state.englishLevels,
          action.payload.level,
        ],
        isEnglishModalActive: false,
        loading: false,
      };
    case actions.UPDATE_TECH_LEVEL_SUCCESS:
      return {
        ...state,
        techLevels: [
          ...state.techLevels.map(
            item => item.id === action.payload.level.id ? action.payload.level : item
          )
        ],
        loading: false,
      };
    case actions.UPDATE_ENGLISH_LEVEL_SUCCESS:
      return {
        ...state,
        englishLevels: [
          ...state.englishLevels.map(
            item => item.id === action.payload.level.id ? action.payload.level : item
          )
        ],
        loading: false,
      };
    case actions.DELETE_TECH_LEVEL_SUCCESS:
      return {
        ...state,
        techLevels: state.techLevels.filter(item => item.id !== action.payload.techLevelId),
        loading: false,
      };
    case actions.DELETE_ENGLISH_LEVEL_SUCCESS:
      return {
        ...state,
        englishLevels: state.englishLevels.filter(
          item => item.id !== action.payload.englishLevelId
        ),
        loading: false,
      };
    case actions.TOGGLE_TECH_MODAL:
      return {
        ...state,
        isTechModalActive: !state.isTechModalActive,
      };
    case actions.TOGGLE_ENGLISH_MODAL:
      return {
        ...state,
        isEnglishModalActive: !state.isEnglishModalActive,
      };
    default:
      return state;
  }
}