import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'antd';
import { siteConfig } from 'library';

const searchList = [
  'resources',
  'resources/projects',
  'resources/assignment-type',
  'projects',
  'projects/ongoing',
  'projects/timeline',
  'projects/future',
  'users',
  'actionLogs',
  'marketing/recipients',
  'marketing/email-templates',
  'marketing/mailings',
  'vacations',
]

export default ({ collapsed, changeCurrent, toggleCollapsed, current, setFilters, tableReducer}) => {
  const [searchMode, setSarchVisible] = useState(false);
  const [search, setSearch] = useState('');
  const isSearchList = !!searchList.find(item => item === current);

  useEffect(() => {
    setSearch('');
  },[current]);

  const togleSearchMode = () => {
    if (!!!search) {
      setSarchVisible(!searchMode);
    }
    handleFilters(window.onSeachTable.searchKey, search)
  }

  const forceToggleCollapsed = () => {
    toggleCollapsed();
    changeCurrent();
  }
  
  const handleFilters = (key, value) => {
    const filter = {[key]: value};
    const newFilters = { ...tableReducer.searchValue, ...filter };
    
    setFilters({
      searchValue: newFilters,
      fetchAction: window.onSeachTable.onTableStateChange
    });
  };

  const handleStateChange = ({ target : {value}}) => {
    const trimValue = value.trim();
    setSearch(trimValue);
  }

  const handleKeyDown = ({key}) => {
    if (key === 'Enter') {
      handleFilters(window.onSeachTable.searchKey, search)
    }
  }

  return (
    <div className="isoMobileLogoWrapper">
        <div className="imgEvent">
          { collapsed ? (
          <img
            onClick={forceToggleCollapsed}
            alt="menu"
            src="/images/icon/25-menu.svg"
          />) : ( 
            <img
              onClick={forceToggleCollapsed}
              alt="menu"
              src="/images/sidebar/clouse.svg"
            />
            )
          }
        </div>
        { (!searchMode || !isSearchList) ? (
        <Link to='/dashboard'>
          <img 
            className="siteLogoImg" 
            alt="icon-logo" 
            src="/images/swan-hero-logo.svg" 
          />
          <div className="siteName">{siteConfig.siteName}</div>
        </Link>
        ) : (
          <Input value={search} onChange={handleStateChange} onKeyDown={handleKeyDown} />
          )
        }
        { isSearchList ? (
          <div   
            className="imgEvent" 
            onClick={togleSearchMode}
          >
            <img
              alt="search"
              src="/images/icon/26-search.svg"
            />
          </div>) : (<div></div>)
        }
    </div>
  );
};
