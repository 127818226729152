export const handleAllSpinners = (state, isLoading) => {
  const forms = {};
  Object.keys(state.editView).forEach(key => {
    forms[key] = {
      ...state.editView[key],
      loading: isLoading,
    }
  });
  return { editView: forms };
};
