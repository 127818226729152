import { all, takeEvery, put } from 'redux-saga/effects';
import typesActions from 'redux/types/actions';
import superFetch from 'helpers/superFetch';
import { apiRequest } from 'redux/helpers';

function* getAllTypes() {
  yield takeEvery(typesActions.GET_ALL, function* () {
    const assignments = yield apiRequest(superFetch.get, `/types/assignment`);
    const technologies = yield apiRequest(superFetch.get, `/types/technology`);
    const flags = yield apiRequest(superFetch.get, `/types/flag`);
    const bonus = yield apiRequest(superFetch.get, `/types/bonus`);
    const projectStatus = yield apiRequest(superFetch.get, `/types/projectStatus`);
    const workloads = yield apiRequest(superFetch.get, `/types/workload`);

    if (assignments.errors || technologies.errors || workloads.errors) {
      yield put({
        type: typesActions.GET_ALL_ERROR,
      });
    } else {
      yield put({
        type: typesActions.GET_ALL_SUCCESS,
        payload: { assignments, technologies, flags, bonus, projectStatus, workloads },
      });
    }
  });
}

function* getAssignmentTypes() {
  yield takeEvery(typesActions.GET_ASSIGNMENT_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, `/types/assignment`);
    if (data.errors) {
      yield put({
        type: typesActions.GET_ASSIGNMENT_ERROR,
        payload: data.errors,
      });
    } else {
      yield put({
        type: typesActions.GET_ASSIGNMENT_SUCCESS,
        payload: data,
      });
    }
  });
}

function* getTechnologyTypes() {
  yield takeEvery(typesActions.GET_TECHNOLOGY_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, `/types/technology`);
    if (data.errors) {
      yield put({
        type: typesActions.GET_TECHNOLOGY_ERROR,
        payload: data.errors,
      });
    } else {
      yield put({
        type: typesActions.GET_TECHNOLOGY_SUCCESS,
        payload: data,
      });
    }
  });
}

function* getFlagTypes() {
  yield takeEvery(typesActions.GET_FLAG_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, `/types/flag`);
    if (data.errors) {
      yield put({
        type: typesActions.GET_FLAG_ERROR,
        payload: data.errors,
      });
    } else {
      yield put({
        type: typesActions.GET_FLAG_SUCCESS,
        payload: { flags: data },
      });
    }
  });
}

function* getBonusTypes() {
  yield takeEvery(typesActions.GET_BONUS_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, `/types/bonus`);
    if (data.errors) {
      yield put({
        type: typesActions.GET_BONUS_ERROR,
        payload: data.errors,
      });
    } else {
      yield put({
        type: typesActions.GET_BONUS_SUCCESS,
        payload: { bonus: data },
      });
    }
  });
}

function* getProjectStatusTypes() {
  yield takeEvery(typesActions.GET_PROJECT_STATUS_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, `/types/projectStatus`);
    if (data.errors) {
      yield put({
        type: typesActions.GET_PROJECT_STATUS_ERROR,
        payload: data.errors,
      });
    } else {
      const url = new URL(location.href);
      const projectStatusGroup = url.searchParams.get('projectStatusGroup');
      if (projectStatusGroup) {
        const projectStatusGroups = projectStatusGroup?.split(',').map(Number) || [];
        const filteredData = data.filter(item => projectStatusGroups.includes(item.groupId));

        yield put({
          type: typesActions.GET_PROJECT_STATUS_SUCCESS,
          payload: filteredData,
        });
      } else {
        yield put({
          type: typesActions.GET_PROJECT_STATUS_SUCCESS,
          payload: data,
        });
      }
    }
  });
}

function* getTiers() {
  yield takeEvery(typesActions.GET_TIERS_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, `/types/tier`);
    if (data.errors) {
      yield put({
        type: typesActions.GET_TIERS_ERROR,
        payload: data.errors,
      });
    } else {
      yield put({
        type: typesActions.GET_TIERS_SUCCESS,
        payload: data,
      });
    }
  });
};


function* getWorkload() {
  yield takeEvery(typesActions.GET_WORKLOAD_REQUEST, function* () {
    const data = yield apiRequest(superFetch.get, `/types/workload`);
    if (data.errors) {
      yield put({
        type: typesActions.GET_WORKLOAD_ERROR,
        payload: data.errors,
      });
    } else {
      yield put({
        type: typesActions.GET_WORKLOAD_SUCCESS,
        payload: data,
      });
    }
  });
};

export default function* rootSaga() {
  yield all([
    getAllTypes(),
    getAssignmentTypes(),
    getTechnologyTypes(),
    getFlagTypes(),
    getBonusTypes(),
    getProjectStatusTypes(),
    getTiers(),
    getWorkload(),
  ]);
};
