import settingsActions from 'redux/settings/actions';
import * as handlers from 'redux/settings/handlers';

const initState = {
  loading: false,
  settings: [],
};

export default function settingsReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case settingsActions.GET_SETTINGS_REQUEST:
      return handlers.onlyLoadingTrue(state);

    case settingsActions.GET_SETTINGS_SUCCESS:
      return handlers.getSettingsSuccess(state, payload);

    case settingsActions.GET_SETTINGS_ERROR:
      return handlers.onlyLoadingFalse(state);

    case settingsActions.CREATE_SETTINGS_REQUEST:
      return handlers.onlyLoadingTrue(state);

    case settingsActions.CREATE_SETTINGS_SUCCESS:
      return handlers.getSettingsSuccess(state, payload);

    case settingsActions.CREATE_SETTINGS_ERROR:
      return handlers.onlyLoadingFalse(state);

    default:
      return state;
  }
};
