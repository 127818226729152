import React, { useEffect, useState } from 'react';
import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';
import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';

const asyncComponent = (importComponent) => {
  const AsyncFunc = (props) => {
    const [component, setComponent] = useState(null);
    const [mounted, setMounted] = useState(true);

    useEffect(() => {
      Nprogress.start();

      const fetchComponent = async () => {
        const { default: Component } = await importComponent();
        Nprogress.done();

        if (mounted) {
          setComponent(() => <Component {...props} />);
        }
      };

      fetchComponent();

      return () => {
        setMounted(false);
      };
    }, [importComponent, props, mounted]);

    const Component = component || <div />;
    return (
      <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
        {Component}
      </ReactPlaceholder>
    );
  };

  return AsyncFunc;
};

export default asyncComponent;
