import { all, put, takeEvery, select } from 'redux-saga/effects';
import { ENDPOINTS } from 'consts';
import superFetch from 'helpers/superFetch';
import marketingSendersActions from 'redux/marketing/marketingSenders/actions';
import { apiRequest } from 'redux/helpers';
import openNotification from 'components/Notification';

export function* getSendersState() {
  yield takeEvery(marketingSendersActions.GET_SENDERS_STATE, function*() {
    const senders = yield apiRequest(superFetch.get, `${ENDPOINTS.AUTH_CREDENTIALS}`);

    if (senders.errors) {
      yield put(marketingSendersActions.errorSendersState(senders.errors));
      yield openNotification('error', 'Error', senders.errors);
    } else {
      yield put(
        marketingSendersActions.setSendersState({
          senders,
        }),
      );
    }
  });
}

function* getSenderRequest() {
  yield takeEvery(marketingSendersActions.GET_SENDER_REQUEST, function*({ payload }) {
    const data = yield apiRequest(superFetch.get, `${ENDPOINTS.AUTH_CREDENTIALS}/${payload.id}`);
    if (data.errors) {
      yield put(marketingSendersActions.getSenderError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(marketingSendersActions.getSenderSuccess(data));
    }
  });
}

function* addSender() {
  yield takeEvery(marketingSendersActions.ADD_SENDER, function*({ payload }) {
    const senders = yield apiRequest(superFetch.post, `${ENDPOINTS.AUTH_CREDENTIALS}`, payload);
    if (senders.errors) {
      yield put(marketingSendersActions.errorSendersState(senders.errors));
      yield openNotification('error', 'Error', senders.errors);
    } else {
      yield put(marketingSendersActions.addSenderSuccess(senders));
    }
  });
}

function* updateSender() {
  yield takeEvery(marketingSendersActions.UPDATE_SENDER, function*({ payload }) {
    const { id, sender } = payload;
    const senders = yield apiRequest(superFetch.put, `${ENDPOINTS.AUTH_CREDENTIALS}${id}`, sender);
    if (senders.errors) {
      yield put(marketingSendersActions.errorSendersState(senders.errors));
      yield openNotification('error', 'Error', senders.errors);
    } else {
      yield put(marketingSendersActions.updateSenderSuccess(senders));
    }
  });
}

function* deleteSender() {
  yield takeEvery(marketingSendersActions.DELETE_SENDER, function*({ payload }) {
    const senders = yield apiRequest(superFetch.delete, `${ENDPOINTS.AUTH_CREDENTIALS}${payload}`);
    if (senders.errors) {
      yield put(marketingSendersActions.errorSendersState(senders.errors));
      yield openNotification('error', 'Error', senders.errors);
    } else {
      yield put(marketingSendersActions.getSendersState());
    }
  });
}

function* addSignature() {
  yield takeEvery(marketingSendersActions.ADD_SIGNATURE, function*({ payload }) {
    const signature = yield apiRequest(superFetch.post, `${ENDPOINTS.SIGNATURES}`, payload);
    if (signature.errors) {
      yield put(marketingSendersActions.errorSendersState(signature.errors));
      yield openNotification('error', 'Error', signature.errors);
    } else {
      yield put(marketingSendersActions.addSignatureSuccess(signature));
    }
  });
}

function* updateSignature() {
  yield takeEvery(marketingSendersActions.UPDATE_SIGNATURE, function*({ payload }) {
    const { id, signature } = payload;
    const signatures = yield apiRequest(superFetch.put, `${ENDPOINTS.SIGNATURES}${id}`, signature);
    if (signatures.errors) {
      yield put(marketingSendersActions.errorSendersState(signatures.errors));
      yield openNotification('error', 'Error', signatures.errors);
    } else {
      yield put(marketingSendersActions.updateSignatureSuccess(signatures));
    }
  });
}

function* deleteSignature() {
  yield takeEvery(marketingSendersActions.DELETE_SIGNATURE, function*({ payload }) {
    const signatures = yield apiRequest(superFetch.delete, `${ENDPOINTS.SIGNATURES}${payload}`);
    if (signatures.errors) {
      yield put(marketingSendersActions.errorSendersState(signatures.errors));
      yield openNotification('error', 'Error', signatures.errors);
    } else {
      const {
        currentSender: { id },
      } = yield select(state => state.MarketingSenders);
      yield put(marketingSendersActions.getSenderRequest(id));
    }
  });
}

export default function* rootSaga() {
  yield all([
    getSendersState(),
    updateSender(),
    deleteSender(),
    addSender(),
    getSenderRequest(),
    addSignature(),
    updateSignature(),
    deleteSignature(),
  ]);
}
