import resourcesByTypeActions from 'redux/resources/resourcesByType/actions';

const initState = {
  loading: false,
  isModalActive: false,
  selectedResource: {
    name: '',
    techLevel: '',
    englishLevel: '',
    location: '',
    resourceRole: '',
    email: '',
    phone: '',
    assignmentType: '',
  },
  filters: null,
  roles: [],
  locations: [],
  resources: {},
  searchValue: {},
  sorter: null,
  pagination: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
  },
  showDrawer: false,
  contractVacation: [],
  visibleItem: [],
};

export default function manageRoleReducer(state = initState, action) {
  const { type, payload } = action;
  const { resources, filters } = payload || [];

  switch (type) {

    case resourcesByTypeActions.SET_VISIBLE_ITEMS:
      return {
        ...state,
        visibleItem: payload
      };

    case resourcesByTypeActions.GET_INITIAL_BY_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case resourcesByTypeActions.GET_INITIAL_BY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
      };

    case resourcesByTypeActions.GET_INITIAL_BY_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case resourcesByTypeActions.SET_INITIAL_RESOURCE_BY_TYPE_STATE:
      return {
        ...state,
        ...payload,
        loading: true
      };

    //  GET Resource by type

    case resourcesByTypeActions.GET_BY_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case resourcesByTypeActions.GET_BY_TYPE_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case resourcesByTypeActions.GET_BY_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    // UPDATE

    case resourcesByTypeActions.UPDATE_ASSIGNMENT_TYPE_REQUEST:
      return {
        ...state,
      };
    case resourcesByTypeActions.UPDATE_ASSIGNMENT_TYPE_SUCCESS:
      return {
        ...state,
        resources,
        filters,
        loading: false
      };
    case resourcesByTypeActions.UPDATE_ASSIGNMENT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    //  UPDATE FILTERS
    case resourcesByTypeActions.UPDATE_BY_TYPE_FILTERS:
      const { type, value } = payload;
      const searchValueCopy = { ...state.searchValue };

      if (value.length !== 0) {
        searchValueCopy[type] = value;
      } else {
        delete searchValueCopy[type];
      }

      return {
        ...state,
        searchValue: searchValueCopy,
        loading: true
      };

    case resourcesByTypeActions.UPDATE_BY_TYPE_FILTERS_SUCCESS:
      return{
        ...state,
        resources,
        filters,
        loading: false
      }

    case resourcesByTypeActions.UPDATE_BY_TYPE_FILTERS_ERROR:
      return{
        ...state,
        filters,
        loading: false
      }
    // MODAL

    case resourcesByTypeActions.SHOW_DRAWER:
      return {
        ...state,
        showDrawer: true,
      };

    case resourcesByTypeActions.HIDE_DRAWER:
      return {
        ...state,
        showDrawer: false,
      };


    default:
      return state;
  }
}
