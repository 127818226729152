const ENDPOINTS = {
  CURRENT_USER: '/user/current',
  CURRENT_USER_DETAILS: '/user/current/details',
  DELETE_PENDING_USER: '/invite',
  SIGN_IN: '/signIn',
  SIGN_OUT: '/signOut',
  CHANGE_PASSWORD: '/user/current/changePassword',
  CLIENT: '/client',
  NEW_PASSWORD: '/newPassword',
  USER: '/user',
  COMPLETE_INVITE: '/completeInvite',
  INVITE_USER: '/inviteUser',
  LOCATION: '/location',
  RESOURCE: '/resource',
  RESOURCE_SIMPLE_LIST: '/resource/simpleList',
  RESOURCE_AND: '/resource/',
	RESOURCE_TIMELINE: '/resource/u/timeline',
  RESOURCE_ROLES: '/resource/roles',
  PROJECT_STATUSES: '/project/statuses',
  RESOURCE_EXPORT: '/resource/export/xls',
  RESOURCE_PROJECT_HISTORY_EXPORT: '/resourceProjectHistory/export/xls',
  ADD_RESOURCE: '/addResource',
  UPDATE_RESOURCE: '/updateResource',
  UPDATE_RESOURCES: '/updateResources',
  REMOVE_RESOURCE: '/removeResource',
  MOVE_RESOURCE: '/moveResource',
  RESOURCE_PROJECT_HISTORY: '/resourceProjectHistory',
  RESOURCES_BY_PROJECT: '/project/board',
  RESOURCE_WITH_PROJECTS: '/resource/withProjects',
  RESOURCES_BY_ASSIGNMENT_TYPE: '/resource/byAssignmentType',
  RESOURCES_EVALUATION: '/resource/forEvaluation',
  TECHNOLOGY: '/technology',
  ADD_TECHNOLOGY: '/addTechnology',
  UPDATE_TECHNOLOGIES_BY_PROJECT: '/updateTechnologies',
  REMOVE_TECHNOLOGY: '/removeTechnology',
  UPDATE_RESOURCE_TECHNOLOGIES: '/updateTechnologies',
  ROLES: '/roles',
  TECH_LVL: '/techLvl',
  ENG_LVL: '/engLvl',
  PROJECT: '/project',
  PROJECTS_EXPORT: '/project/export/xls',
  FORGOT_PASSWORD: '/user/resetPassword',
  TYPE_ASSIGNMENT: '/types/assignment',
  PROJECTS: '/project',
  PROJECTS_SIMPLE_LIST: '/project/simpleList',
  ONGOING_PROJECTS: '/project/ongoing',
  TIMELINE_PROJECTS: '/project/timeline',
  FUTURE_PROJECT: '/futureProject',
  ADD_PROJECT: '/addProject',
  REMOVE_PROJECT: '/removeProject',
  UPDATE_PROJECT: '/updateProject',
  UPDATE_RESOURCE_PROJECTS: '/updateProjects',
  PENDING_USERS: '/invite/pending',
  GET_RESOURCE_STATS: '/stats/resource',
  GET_PROJECT_STATS: '/stats/project',
  GET_CLOSED_PROJECTS_STATS: '/stats/closedProjects',
  GET_PROJECT_FLAGS: '/stats/flag',
  TYPE_PROJECT_STATUS: '/types/projectStatus',
  TYPE_FLAG: '/types/flag',
  COMPLETE_RESET: '/user/completeReset',
  // MARKETING: '/marketing',
  EMAIL_TEMPLATE: '/emailTemplate',
  RECIPIENT: '/recipient',
  RECIPIENT_IMPORT: '/recipient/import',
  RECIPIENT_REMOVE_FROM_GROUPS: `/recipient/bulkRemoveFromGroups`,
  RECIPIENT_ADD_TO_GROUP: '/recipient/bulkAddToGroups',
  RECIPIENT_AND: '/recipient/',
  MAILINGS: '/mailing',
  MAILINGS_AND: '/mailing/',
  MAILING_RECIPIENT: '/mailingRecipient/',
  MAILING_SENDERS: '/mailingSender',
  MAILING_SENDERS_AND: '/mailingSender/',
  MARKETING_GROUP: '/group',
  MARKETING_GROUP_AND: '/group/',
  MARKETING_CAMPAIGNS: '/campaign',
  MARKETING_CAMPAIGNS_AND: '/campaign/',
  AUTH_CREDENTIALS: '/authCredential/',
  SIGNATURES: '/signature/',
  SETTINGS: '/settings',
  ASSETS_UPLOAD: '/emailTemplate/assetsUpload',
  LOGS: '/logs',
  VACATION: '/vacation',
  VACATION_STATUS: '/vacationStatus',
  VACATION_CALULATE_DAYS: '/vacation/u/calculateVacationDays',
  VACATION_WARNING: '/vacation/u/warnings',
  VACATION_SETTINGS: '/vacationSettings',
  VACATION_HOLIDAY: '/holiday',
  LOGS_ENTITY_TYPES: '/logs/u/entityTypes',
  LOGS_LAST_DATE: '/logs/u/lastDate',
  CONTRACTS: '/contract',
  SALARY: '/salary',
  SIGNING_BONUS: '/signingBonus',
  OVERTIME: '/overtime',
  REFERRAL_BONUS: '/referralBonus',
  COMMENT: '/comment',
  FUTURE_PROJECT_SECTIONS: '/projectSection',
  RESOURCE_EVALUATION: '/evaluation',
  RESOURCE_EVALUATION_COMPLETE: '/evaluation/complete',
  RESOURCE_FOR_EVALUATION: '/resource/forEvaluation',
  VACATION_CATEGORIES: '/vacationCategory',
  PROJECT_HISTORY: '/projectHistory',
  DOCUMENT: '/document',
  POTENTIAL_PROJECTS: '/potentialProject',
};

export default ENDPOINTS;
