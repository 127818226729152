const DOCUMENT = 'MARKETING_CAMPAIGNS_';

const marketingCampaignsActions = {
  GET_CAMPAIGNS_STATE: `GET_${DOCUMENT}STATE`,
  SET_CAMPAIGNS_STATE: `SET_${DOCUMENT}STATE`,
  ERROR_CAMPAIGNS_STATE: `ERROR_${DOCUMENT}STATE`,

  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,
  ADD_CAMPAIGN: `${DOCUMENT}ADD`,
  ADD_CAMPAIGN_SUCCESS: `${DOCUMENT}ADD_SUCCESS`,
  UPDATE_CAMPAIGN: `${DOCUMENT}UPDATE`,
  DELETE_CAMPAIGN: `${DOCUMENT}DELETE`,

  getCampaignsState: payload => ({
    type: marketingCampaignsActions.GET_CAMPAIGNS_STATE,
    payload,
  }),

  setCampaignsState: payload => ({
    type: marketingCampaignsActions.SET_CAMPAIGNS_STATE,
    payload,
  }),

  errorCampaignsState: payload => ({
    type: marketingCampaignsActions.ERROR_CAMPAIGNS_STATE,
    payload,
  }),

  addCampaign: payload => ({
    type: marketingCampaignsActions.ADD_CAMPAIGN,
    payload,
  }),

  addCampaignSuccess: payload => ({
    type: marketingCampaignsActions.ADD_CAMPAIGN_SUCCESS,
    payload,
  }),

  updateCampaign: (id, value) => ({
    type: marketingCampaignsActions.UPDATE_CAMPAIGN,
    payload: { id, value },
  }),

  deleteCampaign: payload => ({
    type: marketingCampaignsActions.DELETE_CAMPAIGN,
    payload,
  }),

  toggleModal: () => ({
    type: marketingCampaignsActions.TOGGLE_MODAL,
  }),
};

export default marketingCampaignsActions;
