const DOCUMENT = 'PROFILE_';

const profileActions = {
  PROFILE_VIEW: `${DOCUMENT}VIEW`,
  PROFILE_EDIT: `${DOCUMENT}EDIT`,

  TOGGLE_MODAL: `${DOCUMENT}TOGGLE_MODAL`,

  UPDATE_PROFILE: `${DOCUMENT}UPDATE_REQUEST`,
  UPDATE_PROFILE_SUCCESS: `${DOCUMENT}UPDATE_SUCCESS`,
  UPDATE_PROFILE_ERROR: `${DOCUMENT}UPDATE_ERROR`,

  CHANGE_PASSWORD_REQUEST: `${DOCUMENT}CHANGE_PASSWORD_REQUEST`,
  CHANGE_PASSWORD_SUCCESS: `${DOCUMENT}CHANGE_PASSWORD_SUCCESS`,
  CHANGE_PASSWORD_ERROR: `${DOCUMENT}CHANGE_PASSWORD_ERROR`,

  ON_CHANGE: `${DOCUMENT}ON_CHANGE`,
  FORM_CLOSE: `${DOCUMENT}FORM_CLOSE`,

  GET_REQUEST: `${DOCUMENT}GET_REQUEST`,
  GET_SUCCESS: `${DOCUMENT}GET_SUCCESS`,
  GET_ERROR: `${DOCUMENT}GET_ERROR`,

  UPLOAD_AVATAR: `${DOCUMENT}UPLOAD_AVATAR`,
  UPLOAD_AVATAR_SUCCESS: `${DOCUMENT}UPLOAD_AVATAR_SUCCESS`,
  UPLOAD_AVATAR_ERROR: `${DOCUMENT}UPLOAD_AVATAR_ERROR`,

  // View change
  profileView: () => ({
    type: profileActions.PROFILE_VIEW,
  }),

  profileEdit: () => ({
    type: profileActions.PROFILE_EDIT,
  }),

  toggleModal: () => ({
    type: profileActions.TOGGLE_MODAL,
  }),

  // Change password

  changePassword: (password) => ({
    type: profileActions.CHANGE_PASSWORD_REQUEST,
    payload: password
  }),

  changePasswordSuccess: () => ({
    type: profileActions.CHANGE_PASSWORD_SUCCESS
  }),

  changePasswordError: () => ({
    type: profileActions.CHANGE_PASSWORD_ERROR
  }),

  // Get profile request
  getProfile: () => ({
    type: profileActions.GET_REQUEST,
  }),
  getSuccess: payload => ({
    type: profileActions.GET_SUCCESS,
    payload,
  }),
  getError: error => ({
    type: profileActions.GET_ERROR,
    payload: {
      error,
    },
  }),
  // Undo updates
  formClose: () => ({
    type: profileActions.FORM_CLOSE,
  }),

  updateProfile: payload => ({
    type: profileActions.UPDATE_PROFILE,
    payload,
  }),
  updateProfileSuccess: payload => ({
    type: profileActions.UPDATE_PROFILE_SUCCESS,
    payload,
  }),
  updateProfileError: payload => ({
    type: profileActions.UPDATE_PROFILE_ERROR,
    payload,
  }),

  uploadAvatar: request => ({
    type: profileActions.UPLOAD_AVATAR,
    payload: {
      ...request
    },
  }),
  uploadAvatarSuccess: data => ({
    type: profileActions.UPLOAD_AVATAR_SUCCESS,
    payload: {
      ...data
    },
  }),
  uploadAvatarError: error => ({
    type: profileActions.UPLOAD_AVATAR_ERROR,
    payload: {
      error
    },
  }),
};

export default profileActions;
