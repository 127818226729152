import projectsFutureActions from 'redux/projects/futurePage/actions';
import * as handlers from 'redux/projects/futurePage/handlers';

const initState = {
  searchValue: {
    title: '',
  },
  sortValues: [],
  pagination: {
    pageSize: 0,
    currentPage: 1,
    totalItems: 0,
  },
  isLoadNext: false,
  loading: false,
  isModalActive: false,
  isRescheduleModalActive: false,
  futureProjectsList: [],
  resourceByTechnologyList: {},
  selectedProject: {},
  futureProjectsSections: [],
};

export default function projectsFutureReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case projectsFutureActions.GET_FUTURE_PROJECTS_LIST_REQUEST:
      return handlers.getFutureProjectsListRequest(state, payload);

    case projectsFutureActions.GET_FUTURE_PROJECTS_LIST_SUCCESS:
      return handlers.getFutureProjectsListSuccess(state, payload);

    case projectsFutureActions.GET_FUTURE_PROJECTS_LIST_ERROR:
      return handlers.getFutureProjectsListError(state);

    case projectsFutureActions.ADD_FUTURE_PROJECTS_SUCCESS:
      return handlers.addFutureProjectSuccess(state, payload);

    case projectsFutureActions.DELETE_FUTURE_PROJECTS_SUCCESS:
      return handlers.deleteFutureProjectSuccess(state);

    case projectsFutureActions.ADD_FUTURE_VACANCY_SUCCESS:
      return handlers.updateSelectedProject(state, payload);

    case projectsFutureActions.UPDATE_FUTURE_VACANCY_SUCCESS:
      return handlers.updateSelectedProject(state, payload);

    case projectsFutureActions.DELETE_FUTURE_VACANCY_SUCCESS:
      return handlers.updateSelectedProject(state, payload);

    case projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_SUCCESS:
    case projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_TYPE_SUCCESS:
      return handlers.resourceByTechnologySuccess(state, payload);

    case projectsFutureActions.UPDATE_FUTURE_VACANCY_REQUEST:
    case projectsFutureActions.DELETE_FUTURE_VACANCY_REQUEST:
    case projectsFutureActions.ADD_FUTURE_PROJECTS_REQUEST:
    case projectsFutureActions.ADD_FUTURE_VACANCY_REQUEST:
    case projectsFutureActions.DELETE_FUTURE_PROJECTS_REQUEST:
    case projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_REQUEST:
    case projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_TYPE_REQUEST:
    case projectsFutureActions.ARCHIVE_PROJECT_REQUEST:
    case projectsFutureActions.START_PROJECT_REQUEST:
      return handlers.onlyLoadingTrue(state);

    case projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_ERROR:
    case projectsFutureActions.UPDATE_FUTURE_VACANCY_ERROR:
    case projectsFutureActions.DELETE_FUTURE_PROJECTS_ERROR:
    case projectsFutureActions.ADD_FUTURE_VACANCY_ERROR:
    case projectsFutureActions.DELETE_FUTURE_VACANCY_ERROR:
    case projectsFutureActions.ADD_FUTURE_PROJECTS_ERROR:
    case projectsFutureActions.GET_RESOURCE_BY_TECHNOLOGY_TYPE_ERROR:
    case projectsFutureActions.START_PROJECT_ERROR:
    case projectsFutureActions.ARCHIVE_PROJECT_SUCCESS:
    case projectsFutureActions.ARCHIVE_PROJECT_ERROR:
      return handlers.onlyLoadingFalse(state);

    case projectsFutureActions.START_PROJECT_SUCCESS:
      return handlers.toggleModalLoadingFalse(state);

    case projectsFutureActions.SET_FUTURE_PROJECTS_PAGINATION:
      return handlers.setPagination(state, payload);

    case projectsFutureActions.SET_FUTURE_PROJECTS_SEARCH:
      return handlers.setSearch(state, payload);

    case projectsFutureActions.SET_SELECTED_PROJECT:
      return handlers.setSelectedProject(state, payload);

    case projectsFutureActions.TOGGLE_MODAL:
      return handlers.toggleModal(state);

    case projectsFutureActions.GET_FUTURE_PROJECTS_SECTIONS_REQUEST:
      return handlers.getFutureProjectsSectionsRequest(state, payload);

    case projectsFutureActions.GET_FUTURE_PROJECTS_SECTIONS_SUCCESS:
      return handlers.getFutureProjectsSectionsSuccess(state, payload);

    case projectsFutureActions.GET_FUTURE_PROJECTS_SECTIONS_ERROR:
      return handlers.getFutureProjectsSectionsError(state);

    case projectsFutureActions.ADD_FUTURE_PROJECTS_SECTIONS_REQUEST:
      return handlers.addFutureProjectsSectionsRequest(state, payload);

    case projectsFutureActions.ADD_FUTURE_PROJECTS_SECTIONS_SUCCESS:
      return handlers.addFutureProjectsSectionsSuccess(state, payload);

    case projectsFutureActions.ADD_FUTURE_PROJECTS_SECTIONS_ERROR:
      return handlers.addFutureProjectsSectionsError(state);

    case projectsFutureActions.UPDATE_FUTURE_PROJECTS_SECTIONS_REQUEST:
      return handlers.updateFutureProjectsSectionsRequest(state, payload);

    case projectsFutureActions.UPDATE_FUTURE_PROJECTS_SECTIONS_SUCCESS:
      return handlers.updateFutureProjectsSectionsSuccess(state, payload);

    case projectsFutureActions.UPDATE_FUTURE_PROJECTS_SECTIONS_ERROR:
      return handlers.updateFutureProjectsSectionsError(state);

    case projectsFutureActions.REMOVE_FUTURE_PROJECTS_SECTIONS_REQUEST:
      return handlers.removeFutureProjectsSectionsRequest(state, payload);

    case projectsFutureActions.REMOVE_FUTURE_PROJECTS_SECTIONS_SUCCESS:
      return handlers.removeFutureProjectsSectionsSuccess(state, payload);

    case projectsFutureActions.REMOVE_FUTURE_PROJECTS_SECTIONS_ERROR:
      return handlers.removeFutureProjectsSectionsError(state, payload);

    case projectsFutureActions.UPDATE_FUTURE_PROJECT_SECTION_REQUEST:
      return handlers.updateFutureProjectsSectionsRequest(state, payload);

    case projectsFutureActions.UPDATE_FUTURE_PROJECT_SECTION_SUCCESS:
      return handlers.updateFutureProjectsSectionsSuccess(state, payload);

    case projectsFutureActions.UPDATE_FUTURE_PROJECT_SECTION_ERROR:
      return handlers.updateFutureProjectsSectionsError(state);

      case projectsFutureActions.RESCHEDULE_FUTURE_PROJECTS_REQUEST:
        return handlers.rescheduleFutureProjectsRequest(state, payload);

      case projectsFutureActions.RESCHEDULE_FUTURE_PROJECTS_SUCCESS:
        return handlers.rescheduleFutureProjectsSuccess(state, payload);

      case projectsFutureActions.RESCHEDULE_FUTURE_PROJECTS_ERROR:
        return handlers.rescheduleFutureProjectsError(state);


    case projectsFutureActions.TOGGLE_RESCHEDULE_MODAL:
      return handlers.toggleRescheduleModal(state);

    case projectsFutureActions.RESET_STATE:
          return handlers.resetFutureProjectsState(state);

    default:
      return state;
  }
}
