import actions from './actions';

const initState = {
  loading: false,
  actionLogs: [],
  entityTypes: [],
  log: {},
};

export default function actionLogReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ACTION_LOGS_REQUEST:
    case actions.GET_LOG_DETAILS_REQUEST:
    case actions.GET_LOG_ENTITY_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ACTION_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        actionLogs: action.payload.data || [],
      };
    case actions.GET_LOG_DETAILS_SUCCESS:
      return {
        ...state,
        log: action.payload,
        loading: false,
      };
    case actions.GET_LOG_ENTITY_TYPES_SUCCESS:
      return {
        ...state,
        entityTypes: action.payload || [],
        loading: false,
      };
    default:
      return state;
  }
}
